import { createSlice } from "@reduxjs/toolkit"
import { NewsState } from "../types"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { toast } from "react-toastify"
import { setLoading } from "../loading"

const initialState: NewsState = {
  drafts: {},
  data: [],
  error: null,
}

const NewsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    loadDataSuccess: (state, action) => {
      state.data = action.payload
    },
    setItems: (state, { payload }) => {
      state.data = payload
    },
    addItem(state, { payload }) {
      state.data.push(payload[0])
    },
    addItemDraft: (state, { payload }) => {
      state.drafts = payload
      localStorage.setItem("newsDrafts", JSON.stringify(state.drafts))
      window.location.replace("/news")
    },
    editNews: (state, { payload }) => {
      state.data = payload
    },
    addItemsLast: (state, { payload }) => {
      state.data.results.push(...payload)
    },
    deleteItem(state, { payload }) {
      state.data.results = state.data.results.filter(
        (item: any) => item.id !== payload,
      )
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const {
  setItems,
  setError,
  addItem,
  editNews,
  deleteItem,
  loadDataSuccess,
  addItemsLast,
  addItemDraft,
} = NewsSlice.actions
export default NewsSlice.reducer

//fetchNews
export const fetchNews = (params: any, AfterGet?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/news/`, {
        params: params,
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
        AfterGet(response?.data)
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const fetchNewsById = (id: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .get(`${API_URL}api/v1/news/${id}`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        After(response.data)
        dispatch(setItems(response.data))
      })
      .catch((er) => {})
  }
}

//addNews
export const addNews = (data = {}, After?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/news/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
          "Content-type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        After()
        dispatch(addItem(response.data))
        toast.success("✅ Пост успешно добавлен", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

// edidBrand
export const EditNews = (data = {}, id: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    setLoading(true)
    axios
      .patch(`${API_URL}api/v1/news/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
          "Content-type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        dispatch(editNews(res.data))
        After()
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => setLoading(false))
  }
}

// edidBrand
export const EditAllNews = (data = {}, id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .put(`${API_URL}news/${id}`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editNews(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
  }
}

//deleteNews
export const deleteNews = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .delete(`${API_URL}api/v1/news/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        toast.success("Пост успешно удален", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      })
      .catch((er) => {
        console.log(er?.request)

        if (er?.request?.status === 204) {
          dispatch(deleteItem(id))
        }
        dispatch(setError(er.res.data))
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(deleteItem(id))
        dispatch(setLoading(false))
      })
  }
}

import React, { useEffect, useState } from "react"
import CloseIcon from "../../../../assets/svg/closeIcon.svg"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import { editCargoStatus } from "../../../../redux/cargos/cargo"
import {
  fetchOwnNotifications,
  makeRead,
  makeReadAndConfirmCargo,
  makeReadAsMana,
} from "../../../../redux/auth/notifications"

const ConfirmCargo = ({ onModal, setOnModal, id }: any) => {
  const dispatch = useAppDispatch()

  const cargoAllow = () => {
    dispatch(
      makeReadAndConfirmCargo(id, () => {
        setOnModal(false)
        dispatch(
          fetchOwnNotifications({ limit: 24, offset: 1, is_read: false }),
        )
      }),
    )
  }


  return (
    <div className={onModal ? "exitModal" : "none"}>
      <div className="exitModal__content" onClick={(e) => e.stopPropagation()}>
        <img src={CloseIcon} alt="" onClick={() => setOnModal(!onModal)} />
        <div className="exitModal__content_title">
          Вы действительно хотите потвердить?
        </div>
        <div className="exitModal__content_btns">
          <button className="button red" onClick={() => setOnModal(!onModal)}>
            Отмена
          </button>
          <button
            onClick={() => cargoAllow()}
            // style={{ background: "#EB5757", border: "none" }}
            className="button"
          >
            Потвердить
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmCargo

import { createSlice } from "@reduxjs/toolkit"
import { AuthState } from "../types"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token, refresh_token } from "../../services"
import { toast } from "react-toastify"
import { setLoading } from "../loading"

const initialState: AuthState = {
  data: [],
  error: false,
  messege: "",
  oneData: {},
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.data = payload
    },
    setItem: (state, { payload }) => {
      state.oneData = payload
    },
    setMessege: (state, { payload }) => {
      state.messege = payload
    },
    setProfile: (state, { payload }) => {
      state.data = payload
    },
    editProfile: (state, { payload }) => {
      state.data = payload
    },
    editItems: (state, { payload }) => {
      const updatedResults = state.data.results.map((item: any) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
      state.data.results = updatedResults
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const {
  setItems,
  setItem,
  editProfile,
  setProfile,
  setError,
  setMessege,
  editItems,
} = authSlice.actions
export default authSlice.reducer

export const SignIn = (data = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    axios

      .post(`${API_URL}api/v1/auth/login/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        if (
          response?.data?.role === "manager" ||
          response?.data?.role === "admin" ||
          response?.data?.role === "dispatcher"
        ) {
          dispatch(setItems(response.data))
          localStorage.setItem("user", JSON.stringify(response.data))
          localStorage.setItem("id", response.data.id)
          localStorage.setItem(
            "access_token",
            JSON.stringify(response.data.token),
          )
          if (response?.data?.role === "dispatcher") {
            window.location.replace("/chat/none/active")
          } else {
            window.location.replace("/")
          }
        } else {
          toast.error(`У вас нету доступа!!!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
        }
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        dispatch(setMessege(er?.response?.data?.login))
        console.log(er)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const fetchProfile = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))

    axios
      .get(`${API_URL}api/v1/auth/profile/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(setProfile(response.data))
        localStorage.setItem("user", JSON.stringify(response.data))
      })
      .catch((er) => {
        if (er?.request?.status === 401) {
          localStorage.clear()
          window.location.replace("login")
        }
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//editProfile
export const profileEdit = (data = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/auth/profile/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editProfile(res.data))

        dispatch(setError(false))
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const editStaff = (data = {}, id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/auth/staffs/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(editItems(response.data))
      })
      .catch((er) => {
        if (er?.request?.status === 401) {
          localStorage.clear()
          window.location.replace("login")
        }
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import HeadOfTruck from "../../../assets/svg/theHeadOfTruck.svg"
import BigTruck from "../../../assets/svg/theLongestTruck.svg"
import MediumTruck from "../../../assets/svg/theMediumTruck.svg"
import SmallTruck from "../../../assets/svg/theSmallestTruck.svg"
import { editGeneralTransport } from "../../../redux/cargos/general-transports"

const TruckWayInfo = ({ item, user }: any) => {
  const { trucks, users } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const { handleSubmit, control } = useForm<any>()

  const handleDriverChange = (e: any) => {
    dispatch(editGeneralTransport({ driver: e?.id }, user?.id))
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px",
      cursor: "pointer",
      background: "#fff",
      // marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent", // LC Green color on focus
      color: "var(--lc-black-800, #333)",
    }),
  }



  return (
    <div className="catalog_items">
      {/* <div className="catalog_item">
        <div>Выбрать машину:</div>
        <div style={{ width: "57%", background: "#fff", border: "none" }}>
          <Controller
            control={control}
            name="select"
            render={({ field }) => (
              <Select
                styles={customStyless}
                id="favouriteAvenger"
                {...field}
                placeholder={
                  item?.item?.model?.name + " - номер " + item?.item?.number
                }
                options={[]}
                options={trucks?.data?.results?.map((item: any) => ({
                  value: item.model?.name + " - номер " + item?.number,
                  label: item.model?.name + " - номер " + item?.number,
                  id: item.id,
                }))}
              />
            )}
          />
        </div>
      </div> */}
      {/* <div className="catalog_item">
        <div>Водитель:</div>
        <div style={{ width: "56.8%" }}>
          <Controller
            control={control}
            name="select"
            render={({ field }) => (
              <Select
                isDisabled={true}
                styles={customStyless}
                {...field}
                placeholder={
                  user?.driver?.user?.last_name +
                  " " +
                  user?.driver?.user?.first_name
                }
                defaultValue={user?.driver?.id}
                options={users?.status?.results?.map((item: any) => ({
                  value: item.user?.last_name + " " + item?.user?.first_name,
                  label: item.user?.last_name + " " + item?.user?.first_name,
                  id: item.id,
                }))}
                onChange={(e: any) => handleDriverChange(e)}
              />
            )}
          />
        </div>
      </div> */}
      <div className="catalog_item">
        <div>Машина:</div>
        <div style={{ width: "56.8%" }}>
          <input
            type="text"
            placeholder="1146"
            value={item?.item?.model?.name || 'Модель не указан ' + ' | ' + " Номер: " + item?.item?.number}
            disabled
          />
        </div>
      </div>
      <div className="catalog_item">
        <div>Водитель:</div>
        <div style={{ width: "56.8%" }}>
          <input
            type="text"
            placeholder="1146"
            value={user?.driver?.user?.last_name + ' ' + user?.driver?.user?.first_name}
            disabled
          />
        </div>
      </div>
      <div className="catalog_item">
        <div>Осталось количества:</div>
        <div style={{ width: "56.8%" }}>
          <input
            type="text"
            placeholder="1146"
            value={user?.extra_lifting_capacity}
            disabled
          />
        </div>
      </div>
      <div className="catalog_item">
        <div>Вес:</div>
        <div style={{ width: "56.8%" }}>
          <input
            type="text"
            placeholder="1599кг"
            disabled
            value={user?.taken_lifting_capacity}
          />
        </div>
      </div>
      <div className="catalog_item">
        <div>Местоположение груза:</div>
        <div className="truckBorders">
          <img
            src={
              item?.item?.type === "head"
                ? HeadOfTruck
                : item?.item?.type === "truck"
                ? SmallTruck
                : item?.item?.type === "trailer"
                ? BigTruck
                : item?.item?.type === "semitrailer"
                ? MediumTruck
                : ""
            }
            alt=""
          />
        </div>
      </div>
      {/* <div className="editCar">
        <button className="EditWayModal__content_left-carSmall">
          Поменять голову <img src={TruckSmallImg} alt="" />
        </button>
        <button className="EditWayModal__content_left-carSmall withBack">
          Перегрузить{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
          >
            <path
              d="M11.9173 3.66732H11.584L9.31773 0.834401C9.19279 0.678273 9.03433 0.552236 8.8541 0.465616C8.67386 0.378996 8.47645 0.334009 8.27648 0.333984L3.82003 0.333984C3.27482 0.333984 2.78461 0.665859 2.58211 1.17211L1.58398 3.7144C1.00982 3.86315 0.583984 4.38003 0.583984 5.00065L0.583984 7.33398C0.583984 7.51815 0.733151 7.66732 0.917318 7.66732H1.91732C1.91732 8.7719 2.81273 9.66732 3.91732 9.66732C5.0219 9.66732 5.91732 8.7719 5.91732 7.66732H8.58398C8.58398 8.7719 9.4794 9.66732 10.584 9.66732C11.6886 9.66732 12.584 8.7719 12.584 7.66732H13.584C13.7682 7.66732 13.9173 7.51815 13.9173 7.33398V5.66732C13.9173 4.56273 13.0219 3.66732 11.9173 3.66732ZM3.91732 8.66732C3.36586 8.66732 2.91732 8.21878 2.91732 7.66732C2.91732 7.11586 3.36586 6.66732 3.91732 6.66732C4.46878 6.66732 4.91732 7.11586 4.91732 7.66732C4.91732 8.21878 4.46878 8.66732 3.91732 8.66732ZM5.41732 3.66732H3.02003L3.82003 1.66732L5.41732 1.66732V3.66732ZM6.41732 3.66732V1.66732L8.27648 1.66732L9.87648 3.66732L6.41732 3.66732ZM10.584 8.66732C10.0325 8.66732 9.58398 8.21878 9.58398 7.66732C9.58398 7.11586 10.0325 6.66732 10.584 6.66732C11.1354 6.66732 11.584 7.11586 11.584 7.66732C11.584 8.21878 11.1354 8.66732 10.584 8.66732Z"
              fill="white"
            />
          </svg>
        </button>
      </div> */}
    </div>
  )
}

export default TruckWayInfo

import React from "react"
import { useForm, SubmitHandler } from "react-hook-form"

import Start from "../../assets/img/Start.png"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"

import { Link } from "react-router-dom"
import { recoveryPassword } from "../../redux/users"

type Inputs = {
  username: string
  password: string
}

const RecoverPswd = () => {
  const dispatch = useAppDispatch()
  const { users } = useAppSelector((state: any) => state)

  const { register, handleSubmit, reset } = useForm<Inputs>()
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    dispatch(recoveryPassword({ email: data?.username }))
    reset()
  }

  return (
    <div
      className="auth"
      style={{
        backgroundImage: `url(${Start})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}
    >
      <div
        className="form"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 style={{
            textAlign: 'center'
          }}>Восстановления пароля</h1>
          <br />
          <input
            {...register("username")}
            className={users?.error?.email ? "inputError" : "input"}
            type="text"
            placeholder="Ваш email"
          />
          
          <br />
          <div>
            {/* <h2>
              У меня нету
              <Link to="#">
                <u>аккаунта</u>
              </Link>
            </h2> */}
            <Link to={"/login"}>
              <h2>
                <u>Я вспомнил пароль</u>
              </h2>
            </Link>
          </div>
          {users.data?.data && (
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M20 10C20 15.5229 15.5229 20 10 20C4.47714 20 0 15.5229 0 10C0 4.47714 4.47714 0 10 0C15.5229 0 20 4.47714 20 10ZM8.84331 15.2949L16.2627 7.87556C16.5146 7.62363 16.5146 7.21512 16.2627 6.96319L15.3503 6.05081C15.0983 5.79883 14.6898 5.79883 14.4379 6.05081L8.3871 12.1015L5.56214 9.27657C5.3102 9.02464 4.90169 9.02464 4.64972 9.27657L3.73734 10.189C3.4854 10.4409 3.4854 10.8494 3.73734 11.1013L7.93089 15.2949C8.18286 15.5469 8.59133 15.5469 8.84331 15.2949Z"
                  fill="black"
                />
              </svg>
              {users.data.data?.detail + " " + "посмотрите email"}
            </p>
          )}
          <button>Отправить запрос</button>
        </form>
      </div>
    </div>
  )
}

export default RecoverPswd

import React, { useEffect, useState } from "react"

import AboutCardForm from "./AboutCardForm"
import AbutDeriverForm from "./AbutDeriverForm"
import AboutAnotherForm from "./AboutAnotherForm"
import SelectInput from "../../Forms/Selekted/New-React-selekt"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { addTruck } from "../../../redux/cargos/trucks/truckSlice"
import { error, log } from "console"
import { useParams } from "react-router-dom"
import { fetchProductById } from "../../../redux/products/productSlice"

interface HeadProps {
  title: string
  state: boolean
  setState: Function
  state2: boolean
}
interface CreateNewClient {
  setOnModal: Function
}

const options = [
  { id: 1, value: "option1", label: "Option 1" },
  { id: 2, value: "option2", label: "Option 2" },
  { id: 3, value: "option3", label: "Option 3" },
]
const optionsUsers = [
  { id: 1, value: "1", label: "1" },
  { id: 2, value: "2", label: "2" },
  { id: 3, value: "3", label: "3" },
]
const optionsInsurens = [
  { id: 1, value: true, label: "есть" },
  { id: 2, value: false, label: "нет" },
]

const optionsDocuments = [
  { id: 1, value: "таможенные документы", label: "таможенные документы" },
  { id: 2, value: "международные разрешени", label: "международные разрешени" },
]

const optiounsofVehicles = [
  { id: 1, value: "система GPS", label: "система GPS" },
  {
    id: 2,
    value: "холодильное оборудование",
    label: "холодильное оборудование",
  },
  { id: 3, value: "система безопасности", label: "система безопасности" },
]

const Form_head: React.FC<HeadProps> = ({ title, setState, state, state2 }) => {
  return (
    <div className="form_midle">
      <h2>{title}</h2>
      <div className={state2 === false ? "" : "none"}>
        <h2
          className={
            state === true ? "form_midle-iconON" : "form_midle-iconOff"
          }
          onClick={() => setState(!state)}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="bootstrap/Chevrons/chevron-up">
              <path
                id="Path (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
                fill="#1A1A1A"
              />
            </g>
          </svg>
        </h2>
      </div>
    </div>
  )
}

const EditTruck: React.FC<CreateNewClient> = ({ setOnModal }) => {
  const { trucks } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const [onPop, setOnPop] = useState(false)
  const [onCar, setOnCar] = useState(true)
  const [onAnother, setOnAnother] = useState(false)
  const [create, setCreate] = useState(true)

  const combineOptionValues = (options: any): string => {
    return options.map((option: any) => option.value).join(",")
  }

  const [type, setType] = useState<any>([]) // Поле "type"
  const [brand, setBrand] = useState<any>([]) // Поле "brand"
  const [model, setModel] = useState<any>([]) // Поле "model"
  const [year, setYear] = useState<any>() // Поле "year"
  const [registrationCountry, setRegistrationCountry] = useState<any>([]) // Поле "registration_country"
  const [number, setNumber] = useState<string>("") // Поле "number"
  const [loadCapacity, setLoadCapacity] = useState<string>("") // Поле "load_capacity"
  const [insurance, setInsurance] = useState<any>([]) // Поле "insurance"
  const [documents, setDocuments] = useState<any>([]) // Поле "insurance"
  const [vehicles, setVehicles] = useState<any>([]) // Пrs"
  const [avatar, setAvatar] = useState<File | null>(null)

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumber(event?.target?.value)
  }
  const handleLoadCapacityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLoadCapacity(event.target.value)
  }
  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setAvatar(event.target.files[0])
    }
  }
  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYear(event.target.value)
  }
  const last_nameResult = trucks.dataOfUser[0]?.user?.map(
    (item: any) => item.last_name,
  )
  const citizenshipResult = trucks.dataOfUser[0]?.user?.map(
    (item: any) => item.citizenship,
  )
  const DriverLicenseStartsResult = trucks.dataOfUser[1]?.another?.map(
    (item: any) => item.licence_date_begin,
  )
  const experienceResult = trucks.dataOfUser[1]?.another?.map(
    (item: any) => item.experience,
  )
  const experienceOfDriverResult = trucks.dataOfUser[1]?.another?.map(
    (item: any) => item.experience_driver_year,
  )
  const fine_infoResult = trucks.dataOfUser[1]?.another?.map(
    (item: any) => item.fine_info,
  )
  const intenational_licenceResult = trucks.dataOfUser[1]?.another?.map(
    (item: any) => item.intenational_licence,
  )
  const languagesResult = trucks.dataOfUser[1]?.another?.map(
    (item: any) => item.languages,
  )
  const DriverLicenseEndsResult = trucks.dataOfUser[1]?.another?.map(
    (item: any) => item.licence_date_end,
  )

  useEffect(() => {
    // dispatch(fetchProductById())
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const formData = new FormData()

    formData.append("last_name", last_nameResult.join(","))
    formData.append("citizenship", citizenshipResult.join())
    formData.append("type", combineOptionValues(type))
    formData.append("brand", combineOptionValues(brand))
    formData.append("model", combineOptionValues(model))
    formData.append("year", year)
    formData.append(
      "registration_country",
      combineOptionValues(registrationCountry),
    )
    formData.append("number", number)
    formData.append("load_capacity", loadCapacity)
    formData.append("insurance", combineOptionValues(insurance))
    formData.append("documents", combineOptionValues(documents))
    formData.append("vehicles", combineOptionValues(vehicles))
    formData.append("licence_date_begin", DriverLicenseStartsResult.join())
    formData.append("experience", experienceResult.join())
    formData.append("experience_driver_year", experienceOfDriverResult.join())
    formData.append("fine_info", fine_infoResult.join())
    formData.append("licence_date_end", DriverLicenseEndsResult.join())
    formData.append("intenational_licence", intenational_licenceResult.join())
    formData.append("languages", languagesResult.join())

    if (avatar) {
      formData.append("avatar", avatar)
    }
    // dispatch(editTruck(formData, id))
    setOnModal(false)
  }

  return (
    <div className="form" onClick={(e) => e.stopPropagation()}>
      <div className="form_head">
        <h1>Редактирования</h1>
        <svg
          onClick={() => setOnModal(false)}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Font Awesome Free/Regular/window-close">
            <path
              id="Vector"
              d="M20.125 3.25H3.875C2.83984 3.25 2 4.08984 2 5.125V18.875C2 19.9102 2.83984 20.75 3.875 20.75H20.125C21.1602 20.75 22 19.9102 22 18.875V5.125C22 4.08984 21.1602 3.25 20.125 3.25ZM20.125 18.6406C20.125 18.7695 20.0195 18.875 19.8906 18.875H4.10938C3.98047 18.875 3.875 18.7695 3.875 18.6406V5.35938C3.875 5.23047 3.98047 5.125 4.10938 5.125H19.8906C20.0195 5.125 20.125 5.23047 20.125 5.35938V18.6406ZM15.9258 9.60156L13.5273 12L15.9258 14.3984C16.1055 14.5781 16.1055 14.8711 15.9258 15.0547L15.0547 15.9258C14.875 16.1055 14.582 16.1055 14.3984 15.9258L12 13.5273L9.60156 15.9258C9.42188 16.1055 9.12891 16.1055 8.94531 15.9258L8.07422 15.0547C7.89453 14.875 7.89453 14.582 8.07422 14.3984L10.4727 12L8.07422 9.60156C7.89453 9.42188 7.89453 9.12891 8.07422 8.94531L8.94531 8.07422C9.125 7.89453 9.41797 7.89453 9.60156 8.07422L12 10.4727L14.3984 8.07422C14.5781 7.89453 14.8711 7.89453 15.0547 8.07422L15.9258 8.94531C16.1094 9.125 16.1094 9.41797 15.9258 9.60156V9.60156Z"
              fill="black"
            />
          </g>
        </svg>
      </div>
      <br />
      <div className="form_scroll">
        <Form_head
          title="Водитель"
          setState={setOnPop}
          state={onPop}
          state2={false}
        />
        <br />
        <div className={onPop === false ? "form_popOff" : "flex"}>
          {/* <div>
            <p style={{ whiteSpace: "nowrap" }}>Поменять водителя:</p>
          </div>

          <SelectInput
            error={trucks?.error}
            setState={setUser}
            state={user}
            placeholder=""
            options={optionsUsers}
          />

          <div>
            <button onClick={Create} className="AddDriverBtn2">
              создать_новго
            </button>
          </div> */}
        </div>

        <div className={onPop === false ? "form_popOff" : "form_popON"}>
          <AbutDeriverForm setCreate={setCreate} setOnPop={setOnPop} />
        </div>
        <br />
        <br />
        <Form_head
          title="Машина"
          setState={setOnCar}
          state={onCar}
          state2={false}
        />
        <br />
        <div className={onCar === false ? "form_popOff" : "form_popON"}>
          <div className="form_inputs">
            <div className="form_inputs-input">
              <div>
                <p>Фото:</p>
              </div>
              <div>
                <article className="form_inputs-input-upload">
                  {avatar ? (
                    <img
                      src={URL.createObjectURL(avatar)}
                      alt="Selected"
                      className="imageUpload"
                    />
                  ) : (
                    <img
                      src={trucks?.data?.avatar}
                      alt="Selected"
                      className="imageUpload"
                    />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleAvatarChange}
                  />
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Font Awesome Free/Solid/download">
                      <path
                        id="Vector"
                        d="M14.4165 2.66669L18.5832 2.66669C19.2759 2.66669 19.8332 3.22398 19.8332 3.91669V12.6667H24.4009C25.328 12.6667 25.7915 13.7865 25.1353 14.4427L17.2134 22.3698C16.8228 22.7604 16.1821 22.7604 15.7915 22.3698L7.85921 14.4427C7.20296 13.7865 7.6665 12.6667 8.59359 12.6667L13.1665 12.6667L13.1665 3.91669C13.1665 3.22398 13.7238 2.66669 14.4165 2.66669ZM29.8332 22.25V28.0834C29.8332 28.7761 29.2759 29.3334 28.5832 29.3334L4.4165 29.3334C3.7238 29.3334 3.1665 28.7761 3.1665 28.0834L3.1665 22.25C3.1665 21.5573 3.7238 21 4.4165 21H12.0571L14.6092 23.5521C15.6561 24.599 17.3436 24.599 18.3905 23.5521L20.9425 21H28.5832C29.2759 21 29.8332 21.5573 29.8332 22.25ZM23.3748 26.8334C23.3748 26.2604 22.9061 25.7917 22.3332 25.7917C21.7603 25.7917 21.2915 26.2604 21.2915 26.8334C21.2915 27.4063 21.7603 27.875 22.3332 27.875C22.9061 27.875 23.3748 27.4063 23.3748 26.8334ZM26.7082 26.8334C26.7082 26.2604 26.2394 25.7917 25.6665 25.7917C25.0936 25.7917 24.6248 26.2604 24.6248 26.8334C24.6248 27.4063 25.0936 27.875 25.6665 27.875C26.2394 27.875 26.7082 27.4063 26.7082 26.8334Z"
                        fill="#999999"
                      />
                    </g>
                  </svg>
                </article>
              </div>
            </div>
            <div className="form_inputs-input">
              <div style={{ width: "53%" }}></div>
              <div>
                {trucks?.error && trucks?.error?.avatar && (
                  <p
                    style={{
                      width: "100%",
                      color: "red",
                      marginBottom: "15px",
                    }}
                  >
                    {trucks?.error.avatar[0]}
                  </p>
                )}
              </div>
            </div>
            <div className="form_inputs-input">
              <div>
                <p>Тип транспортного средства:</p>
              </div>

              <SelectInput
                placeholder={trucks?.data?.type}
                setState={setType}
                state={type}
                error={trucks?.error?.type}
                options={options}
              />
            </div>
            <div className="form_inputs-input">
              <div style={{ width: "53%" }}></div>
              <div>
                {" "}
                {trucks?.error && trucks?.error?.type && (
                  <p
                    style={{
                      width: "100%",
                      color: "red",
                      marginBottom: "15px",
                    }}
                  >
                    {trucks?.error.type[0]}
                  </p>
                )}
              </div>
            </div>
            <div className="form_inputs-input">
              <div>
                <p>Марка:</p>
              </div>

              <SelectInput
                placeholder={trucks?.data?.brand}
                setState={setBrand}
                state={brand}
                error={trucks?.error?.brand}
                options={options}
              />
            </div>
            <div className="form_inputs-input">
              <div style={{ width: "53%" }}></div>
              <div>
                {" "}
                {trucks?.error && trucks?.error?.brand && (
                  <p
                    style={{
                      width: "100%",
                      color: "red",
                      marginBottom: "15px",
                    }}
                  >
                    {trucks?.error.brand[0]}
                  </p>
                )}
              </div>
            </div>
            <div className="form_inputs-input">
              <div>
                <p>Модель:</p>
              </div>

              <SelectInput
                placeholder={trucks?.data?.model}
                setState={setModel}
                state={model}
                error={trucks?.error?.model}
                options={options}
              />
            </div>
            <div className="form_inputs-input">
              <div style={{ width: "53%" }}></div>
              <div>
                {" "}
                {trucks?.error && trucks?.error?.model && (
                  <p
                    style={{
                      width: "100%",
                      color: "red",
                      marginBottom: "15px",
                    }}
                  >
                    {trucks?.error.model[0]}
                  </p>
                )}
              </div>
            </div>
            <div className="form_inputs-input">
              <div>
                <p>Год выпуска:</p>
              </div>
              <div>
                <input
                  className="form_inputs-input-text"
                  type="text"
                  onChange={handleYearChange}
                  defaultValue={trucks?.data?.year}
                />
              </div>
            </div>
            <div className="form_inputs-input">
              <div style={{ width: "53%" }}></div>
              <div>
                {" "}
                {trucks?.error && trucks?.error?.year && (
                  <p
                    style={{
                      width: "100%",
                      color: "red",
                      marginBottom: "15px",
                    }}
                  >
                    {trucks?.error.year[0]}
                  </p>
                )}
              </div>
            </div>
            <div className="form_inputs-input">
              <div>
                <p>В какой стране зарегестрирован:</p>
              </div>

              <SelectInput
                placeholder={trucks?.data?.registration_country}
                setState={setRegistrationCountry}
                state={registrationCountry}
                error={trucks?.error?.registration_country}
                options={options}
              />
            </div>
            <div className="form_inputs-input">
              <div style={{ width: "53%" }}></div>
              <div>
                {" "}
                {trucks?.error && trucks?.error?.registration_country && (
                  <p
                    style={{
                      width: "100%",
                      color: "red",
                      marginBottom: "15px",
                    }}
                  >
                    {trucks?.error.registration_country[0]}
                  </p>
                )}
              </div>
            </div>
            <div className="form_inputs-input">
              <div>
                <p>Номер:</p>
              </div>
              <div>
                <input
                  className="form_inputs-input-text"
                  type="text"
                  defaultValue={trucks?.data?.number}
                  onChange={handleNumberChange}
                />
              </div>
            </div>
            <div className="form_inputs-input">
              <div style={{ width: "53%" }}></div>
              <div>
                {" "}
                {trucks?.error && trucks?.error?.number && (
                  <p
                    style={{
                      width: "100%",
                      color: "red",
                      marginBottom: "15px",
                    }}
                  >
                    {trucks?.error.number[0]}
                  </p>
                )}
              </div>
            </div>
            <div className="form_inputs-input">
              <div>
                <p>Грузоподьемность:</p>
              </div>
              <div>
                <input
                  className="form_inputs-input-text"
                  type="text"
                  defaultValue={trucks?.data?.load_capacity}
                  onChange={handleLoadCapacityChange}
                />
              </div>
            </div>
            <div className="form_inputs-input">
              <div style={{ width: "53%" }}></div>
              <div>
                {" "}
                {trucks?.error && trucks?.error?.load_capacity && (
                  <p
                    style={{
                      width: "100%",
                      color: "red",
                      marginBottom: "15px",
                    }}
                  >
                    {trucks?.error.load_capacity[0]}
                  </p>
                )}
              </div>
            </div>
            <div className="form_inputs-input">
              <div>
                <p>Страховка:</p>
              </div>
              <SelectInput
                placeholder={trucks?.data?.insurance === true ? "Есть" : "Нет"}
                setState={setInsurance}
                state={insurance}
                error={trucks?.error?.insurance}
                options={optionsInsurens}
              />
            </div>
            <div className="form_inputs-input">
              <div style={{ width: "53%" }}></div>
              <div>
                {" "}
                {trucks?.error && trucks?.error?.insurance && (
                  <p
                    style={{
                      width: "100%",
                      color: "red",
                      marginBottom: "15px",
                    }}
                  >
                    {trucks?.error.insurance[0]}
                  </p>
                )}
              </div>
            </div>
            <div className="form_inputs-input">
              <div>
                <p>Документы и лицензи:</p>
              </div>
              <SelectInput
                placeholder=""
                setState={setDocuments}
                state={documents}
                error={trucks?.error?.documents}
                options={optionsDocuments}
              />
            </div>
            <div className="form_inputs-input">
              <div style={{ width: "53%" }}></div>
              <div>
                {" "}
                {trucks?.error && trucks?.error?.documents && (
                  <p
                    style={{
                      width: "100%",
                      color: "red",
                      marginBottom: "15px",
                    }}
                  >
                    {trucks?.error.documents[0]}
                  </p>
                )}
              </div>
            </div>
            <div className="form_inputs-input">
              <div>
                <p>
                  Техническое состояние и обслуживание транспортного средств:
                </p>
              </div>
              <SelectInput
                placeholder=""
                setState={setVehicles}
                state={vehicles}
                error={trucks?.error?.vehicles}
                options={optiounsofVehicles}
              />
            </div>
            <div className="form_inputs-input">
              <div style={{ width: "53%" }}></div>
              <div>
                {" "}
                {trucks?.error && trucks?.error?.vehicles && (
                  <p
                    style={{
                      width: "100%",
                      color: "red",
                      marginBottom: "15px",
                    }}
                  >
                    {trucks?.error.vehicles[0]}
                  </p>
                )}
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
        <br />
        <br />

        <Form_head
          title="Другое"
          setState={setOnAnother}
          state={onAnother}
          state2={false}
        />
        <br />
        <div className={onAnother === false ? "form_popOff" : "form_popON"}>
          <AboutAnotherForm setCreate={setCreate} setOnAnother={setOnAnother} />
        </div>
      </div>
      <div className="form_button">
        <button onClick={handleSubmit} className="TruckBtn">
          Добавить
        </button>
        <button onClick={() => setOnPop(false)} className="TruckBtn2">
          Отмена
        </button>
      </div>
    </div>
  )
}

export default EditTruck

import React from "react"
import { Link, useParams } from "react-router-dom"
import DeleteModal from "./Delete"
import Stock from '../../assets/img/Rectangle 413.png'


interface StockProps {

  StockNum?: string
  state?: boolean
  type?: string
  adress?: string
  count?: number
  image?: string
  countClient?: number
  id?: number | string
  item: any
  deleteStock: any
  dispatcher?: any
}

const StockCard: React.FC<StockProps> = ({ item, deleteStock, dispatcher }) => {
  const [exit, setExit] = React.useState(false)

  return (
    <div className="Stock">
      {/* {!dispatcher && (
        <div className="closed">
          <svg
            onClick={() => setExit(!exit)}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M16.7707 2.70834H3.229C2.36637 2.70834 1.6665 3.40821 1.6665 4.27084V15.7292C1.6665 16.5918 2.36637 17.2917 3.229 17.2917H16.7707C17.6333 17.2917 18.3332 16.5918 18.3332 15.7292V4.27084C18.3332 3.40821 17.6333 2.70834 16.7707 2.70834ZM16.7707 15.5339C16.7707 15.6413 16.6828 15.7292 16.5754 15.7292H3.42432C3.31689 15.7292 3.229 15.6413 3.229 15.5339V4.46616C3.229 4.35873 3.31689 4.27084 3.42432 4.27084H16.5754C16.6828 4.27084 16.7707 4.35873 16.7707 4.46616V15.5339ZM13.2713 8.00131L11.2726 10L13.2713 11.9987C13.4211 12.1484 13.4211 12.3926 13.2713 12.5456L12.5454 13.2715C12.3957 13.4212 12.1515 13.4212 11.9985 13.2715L9.99984 11.2728L8.00114 13.2715C7.8514 13.4212 7.60726 13.4212 7.45426 13.2715L6.72835 12.5456C6.57861 12.3958 6.57861 12.1517 6.72835 11.9987L8.72705 10L6.72835 8.00131C6.57861 7.85157 6.57861 7.60743 6.72835 7.45444L7.45426 6.72853C7.604 6.57879 7.84815 6.57879 8.00114 6.72853L9.99984 8.72722L11.9985 6.72853C12.1483 6.57879 12.3924 6.57879 12.5454 6.72853L13.2713 7.45444C13.4243 7.60418 13.4243 7.84832 13.2713 8.00131Z"
              fill="#828282"
            />
          </svg>
        </div>
      )} */}

      <div className="Stock_characteristic" style={{ height: '65%' }}>
        <div className="Stock_characteristic_inner">
          <h1>Склад {item.name}</h1>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px'
          }}>
            <h2>
              Cтатус:

              <span>
                {item?.is_available === true ? " Работает" : " Не работает"}
              </span>
            </h2>

            <p>
              Тип склада: <span>{item?.type?.map((it: any) => it.name)}</span>
            </p>
            <p>
              Адресс: <span>{item?.address}</span>
            </p>
          </div>
        </div>

        <div className="Stock_actions-image">
          <img style={{
            objectFit: 'cover'
          }} src={item?.image ? item?.image : 'https://promstellage.ru/images/stati/raspredelitelnyy-sklad-1.jpg'} alt="" />
        </div>


      </div>
      <div className="Stock_actions">
        <div className="Stock_characteristic-count" style={{ width: '50%' }}>
          <h3>
            Количество грузов для отбытия: <span>{item?.senders_cargos_count || '0'}</span>
          </h3>
          <div className="Stock_characteristic-count-line"></div>
          <h3>
            Клиенты: <span>{item?.clients_count || '0'}</span>
          </h3>
          <div className="Stock_characteristic-count-line"></div>
          <h3>
            Количество прибывщих грузов: <span>{item?.receivers_cargos_count || '0'}</span>
          </h3>
        </div>
        <div className="Stock_actions-btn">
          <Link to={`/stock/${item?.id}/clients`}>
            <button className="StockBtn">Клиенты</button>
          </Link>
          <Link to={`/stock/${item?.id}/goods`}>
            <button className="StockBtn">Товары</button>
          </Link>
        </div>
      </div>
      <DeleteModal
        deleteStock={deleteStock}
        exit={exit}
        setExit={setExit}
        id={item.id}
      />
    </div>
  )
}

export default StockCard

import React, { useEffect, useRef, useState } from "react"

import CarImage from "../../assets/img/Pin=Off.png"
import Terms from "../mapsCard/terms"
import LocationIcon from "../../assets/svg/location.svg"
import ItomIcon from "../../assets/svg/itemIcon.svg"
import DropArrowTop from "../../assets/svg/topArrow.svg"
import DropArrowDown from "../../assets/svg/downarrow.svg"
import { getCargoStatus } from "../../utils/functions"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { fetchCargo } from "../../redux/cargos/cargo"
import {
  addItemsLast,
  fetchTransportationsAttributes,
} from "../../redux/cargos/transportations-atributes"
import { setLoading } from "../../redux/loading"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import EditIcon from "../../assets/svg/editIcon.svg"
import { Modal } from "../Modal"
import { EditCargo } from "../Forms/GoodsEdit/EditGoodsForm"

const index = ({ item }: any) => {
  const { cargo, transportatAttrib, auth } = useAppSelector(
    (state: any) => state,
  )
  const dispatch = useAppDispatch()
  const [more, setMore] = React.useState<any>(false)
  const [onModal, setOnModal] = useState(false)

  const getCargo = () => {
    dispatch(fetchCargo(item?.id))
  }

  return (
    <div className="AllStockInfo">
      <div className="AllStockInfo__header">
        <div className="AllStockInfo__header_info">
          <div className="AllStockInfo__header_info-title">
            Груз № {item?.id}{" "}
          </div>

          <div className="SearchBox">
            <div className="AllStockInfo__header_info-id">ID: {item?.id}</div>
            <div
              className={
                item.status === "delivered" || item.status === "in_stock"
                  ? "AllStockInfo__header_info-status"
                  : item.status === "canceled"
                  ? "AllStockInfo__header_info-status canceled"
                  : "AllStockInfo__header_info-status waiting"
              }
            >
              {getCargoStatus(item.status)}
            </div>
          </div>
        </div>

        <div className="AllStockInfo__header_adress">
          <div className="AllStockInfo__header_adress-city">
            <div className="AllStockInfo__header_adress-city--title">
              <img src={LocationIcon} alt="" />
              <div className="city">
                {" "}
                {item?.sender?.city?.name
                  ? item?.sender?.city?.name
                  : cargo?.oneData?.sender?.city?.name}
              </div>
            </div>
            <div className="AllStockInfo__header_adress-city--line"></div>
            <div className="AllStockInfo__header_adress-city--title">
              <img src={LocationIcon} alt="" />
              <div className="city">
                {item?.receiver?.city?.name
                  ? item?.receiver?.city?.name
                  : cargo?.oneData?.receiver?.city?.name}
              </div>
            </div>
          </div>

          <div className="AllStockInfo__header_adress-city">
            <div className="AllStockInfo__header_adress-city--title">
              <img src={ItomIcon} alt="" />
              <div className="street">
                {" "}
                {item?.sender?.stock?.address
                  ? item?.sender?.stock?.address
                  : cargo?.oneData?.sender?.stock?.address}
              </div>
            </div>
            <div className="AllStockInfo__header_adress-city--line2"></div>
            <div className="AllStockInfo__header_adress-city--title">
              <img src={ItomIcon} alt="" />
              <div className="street">
                {item?.receiver?.stock?.address
                  ? item?.receiver?.stock?.address
                  : cargo?.oneData?.receiver?.stock?.address}
              </div>
            </div>
          </div>
        </div>
        {auth?.data?.role !== "dispatcher" && (
          <div
            className="CargoCard__top_btn"
            onClick={() => setOnModal(!onModal)}
          >
            <button>Редактировать</button>
          </div>
        )}
      </div>

      <div className="AllStockInfo__content">
        <div className="AllStockInfo__content_sides">
          <div className={"AllStockInfo__content_left"}>
            <div className="MapsCard__content_info">
              Бренд:
              <u>{item?.products?.map((item: any) => item?.brand?.name)}</u>
            </div>
            <div className="MapsCard__content_info">
              Названия товара:{" "}
              <u>{item?.products?.map((item: any) => item?.name)}</u>
            </div>
            <div className={more ? "" : "none"}>
              <div
                className="MapsCard__content_counts"
                style={{ alignItems: "center", padding: "10px 0" }}
              >
                <div className="MapsCard__content_counts-count">
                  Количесво:{" "}
                  <u>
                    {item?.products?.reduce(
                      (acc: any, item: any) => acc + item.quantity,
                      0,
                    )}
                  </u>
                </div>
                <div className="MapsCard__content_counts-border"></div>
                <div className="MapsCard__content_counts-placeCount">
                  Количество мест: <u>{item?.total_spaces}</u>
                </div>
              </div>
              <div className="MapsCard__content_order-top">
                <div className="MapsCard__content_order-totalCount">
                  Сума договора: <u>${cargo.oneData?.total_price}</u>
                </div>
                <div className="MapsCard__content_order-border"></div>
                <div className="MapsCard__content_order-totalCount">
                  Вес: <u>{cargo.oneData?.total_weight}кг</u>
                </div>
              </div>
              <div className={"AllStockInfo__content_left-message"}>
                {cargo?.oneData?.comment
                  ? cargo?.oneData?.comment
                  : "К этому грузу нет комментариев"}
              </div>
            </div>
          </div>
          <div className={more ? "AllStockInfo__content_right" : "none"}>
            <div className={"MapInfo__extraInfo"}>
              <div className="AllStockInfo__trucktitle">
                <div className="MapInfo__extraInfo_mainTitle">
                  Дополнительная информация
                </div>
              </div>
              <img
                className="MapInfo__extraInfo_carImg"
                src={CarImage}
                alt=""
              />
              <div
                className="AllStockInfo__content_right-term"
                // style={{ height: "calc(100% - 400px)" }}
              >
                {cargo?.oneData?.attributes?.map((item: any) => (
                  <Terms key={item?.id} item={item} />
                ))}
                {(cargo?.oneData?.attributes?.length === 0 ||
                  cargo?.oneData?.attributes === null) && (
                  <div style={{ textAlign: "center" }}>Пусто!</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="AllStockInfo__endbtn"
        onClick={() => {
          setMore(!more)
          getCargo()
        }}
      >
        Свернуть
        <img
          style={{ cursor: "pointer" }}
          src={!more ? DropArrowDown : DropArrowTop}
          alt=""
        />
      </div>
      <Modal
        title={"Редактировать груз"}
        modal={onModal}
        width={"630px"}
        setModal={setOnModal}
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "98%",
            }}
          >
            <div className="catalog">
              <EditCargo setModal={setOnModal} id={item?.id} type={item} />
            </div>
          </div>
        }
      />
    </div>
  )
}

export default index

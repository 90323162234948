import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../../api/api_config"
import { access_token } from "../../../services"
import { toast } from "react-toastify"
import { setLoading } from "../../loading"
import { setCategories } from "../cargo/index"

const initialState: any = {
  loading: false,
  data: [],
  oneData: {},
  error: false,
  reCheck: false,

  stock: false,
  stocksIntermediate: [],
  stockCar: false,
  stocksSenderArr: [],
}

const URL = "cargo/stocks"

const StocksSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    setRecheck: (state , {payload}) => {
      state.reCheck = !state.reCheck
    },
    clearAllStock: (state, {}) => {
      state.stock = false
      state.stocksIntermediate = []
      state.stockCar = false
      state.stocksSenderArr = []
    },
    getItems: (state, { payload }) => {
      state.data = payload
    },
    editAllStocksSenderArr: (state, { payload }) => {
      state.stocksSenderArr = payload
    },
    editStockItem: (state, action) => {
      console.log(action)
      const updatedResults = state.stock.cargos.map(
        (item: any, index: number) => {
          if (item.id === action?.payload?.id) {
            return {
              ...item,
              total_weight:
                Number(item.total_weight) - action.payload.total_weight,
            }
          }
          return item
        },
      )

      state.stock.cargos = updatedResults
    },
    getItem: (state, { payload }) => {
      state.oneData = payload
    },
    getSenderItem: (state, { payload }) => {
      state.stock = payload
    },
    editSenderItem: (state, { payload }) => {
      state.stock = payload
    },
    setStockCar: (state, { payload }) => {
      state.stockCar = payload
    },
    getSenderIntermediateItem: (state, { payload }) => {
      state.stocksIntermediate.push(payload)
    },
    getStocksSenderArr: (state, { payload }) => {
      state.stocksSenderArr.push(payload)
    },
    editSenderIntermediateItem: (state, action): any => {
      const updatedResults = state.stocksSenderArr.map(
        (item: any, index: number) => {
          if (index === action?.payload?.index) {
            return action?.payload
          }
          return item
        },
      )
      state.stocksSenderArr = updatedResults
    },

    editSenderIntermediateItem2: (state, { payload }): any => {
      const updatedResults = state.stocksSenderArr.map((item: any) => {
        if (item.id === payload?.id) {
          return payload
        }
        return item
      })
      state.stocksSenderArr = updatedResults
    },

    addItem(state, { payload }) {
      state.data.results.unshift(payload)
    },
    editItems: (state, { payload }) => {
      const updatedResults = state.data.results.map((item: any) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
      state.data.results = updatedResults
    },
    editItem: (state, { payload }) => {
      state.oneData = payload
    },
    removeItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },

    setError: (state, { payload }) => {
      state.error = payload
    },
    addItemsLast: (state, { payload }) => {
      state.data.results.push(...payload)
    },
  },
})

export const {
  setRecheck,
  getItems,
  editAllStocksSenderArr,
  getItem,
  setStockCar,
  setError,
  addItem,
  editItems,
  clearAllStock,
  removeItem,
  editSenderIntermediateItem,
  editSenderIntermediateItem2,
  editItem,
  addItemsLast,
  editSenderItem,
  getSenderIntermediateItem,
  getSenderItem,
  editStockItem,
  getStocksSenderArr,
} = StocksSlice.actions
export default StocksSlice.reducer

export const fetchStocks = (params?: any, AfterGet?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/`, {
        params: params,
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItems(response.data))
        AfterGet(response.data)
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const fetchStock = (id: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItem(response.data))
        dispatch(getStocksSenderArr(response.data))
        After(response.data)
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const addSenderStock = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getSenderItem(response.data))
        dispatch(getStocksSenderArr(response.data))
      })
      .catch((er) => {})
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const EditSenderStock = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(editSenderItem(response.data))
      })
      .catch((er) => {})
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const addSenderIntermediateStock = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getSenderIntermediateItem(response.data))
        dispatch(getStocksSenderArr(response.data))
      })
      .catch((er) => {})
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const editSenderIntermediateStock = (id: any, index: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(editSenderIntermediateItem({ ...response.data, index }))
      })
      .catch((er) => {})
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const addStock = (data: any, avatar: any, setResent?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/${URL}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        axios
          .patch(
            `${API_URL}api/v1/${URL}/${response.data.id}/`,
            {
              image: avatar,
            },
            {
              headers: {
                Authorization: `Token ${access_token}`,
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then((res: any) => {
            dispatch(addItem(res.data))
            setResent()
            dispatch(setLoading(false))
          })
          .catch((er: any) => {
            dispatch(setError(er.res?.data))
            dispatch(setLoading(false))
          })
      })
      .catch((er) => {
        dispatch(setError(er.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => {})
  }
}

export const editStock = (
  data: any,
  id: any,
  avatar: any,
  setOnModal?: any,
) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/${URL}/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        axios
          .patch(
            `${API_URL}api/v1/${URL}/${response.data.id}/`,
            {
              image: avatar,
            },
            {
              headers: {
                Authorization: `Token ${access_token}`,
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then((res: any) => {
            dispatch(editItems(res.data))
            dispatch(editItem(res.data))
          })
          .catch((er: any) => {
            dispatch(setError(er.res?.data))
            dispatch(setLoading(false))
          })
        setOnModal()
      })
      .catch((er: any) => {
        dispatch(setError(er.response?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const removeStock = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .delete(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(removeItem(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

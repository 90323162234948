import React, { useState } from "react"

import Location from "../../assets/svg/location.svg"
import Circle from "../../assets/svg/circle.svg"
import StockImg from "../../assets/svg/itemIcon.svg"
import DoubleCheckmark from "../../assets/svg/doublecheckArr.svg"
import CashStack from "../../assets/svg/cash.svg"
import CashStackGreen from "../../assets/svg/cash_green.svg"
import NewsTextEditor from "../../components/new/newsTextEditor"

import AllCargoInfoModl from "../../components/cargos/allInfoModal"
import { Modal } from "../Modal"
import axios from "axios"
import { access_token } from "../../services"
import { API_URL } from "../../api/api_config"
import { toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { editBalance } from "../../redux/balance-history"


const Payment = ({ item }: any) => {
  const [showDetail, setShowDetail] = useState(false)
  const [modal, setModal] = useState(false)
  const [content, setContent] = useState('')
  const dispatch = useAppDispatch()
  const {balanceHistory} = useAppSelector((state) => state)

  const LeaveComment = () => {
    axios.patch(`${API_URL}api/v1/auth/balance-histories/${item?.id}/`, {
      comment: content
    }, {
      headers: {
        Authorization: `Token ${access_token}`
      }
    }).then((res) => {
      toast.success('Коментарий успешео был добавлен')
      setModal(false)
      dispatch(editBalance(res.data))
    })
  }
  

  return (
    <div style={{ paddingTop: '10px' }}>
      <div className="ClientsInfo__content_center-addMoney">
        <img src={DoubleCheckmark} alt="" />
        <div>
          <img src={item?.type === 'cargo_payment' ? CashStack : CashStackGreen} alt="" />
          <div>{item?.type === 'cargo_payment' ? 'Оплата за груз' : 'Пополнения счета:'}<span style={{color: item?.type === 'cargo_payment' ? 'red' : '#83C251'}}>${item.value}</span></div>
        </div>
      </div>

      {item?.inner_payload?.image && <img style={{
        width: '100%',
        height: '160px',
        objectFit: 'cover'
      }} src={item?.inner_payload?.image} alt="" />}
      {
        item?.type === 'cargo_payment' && <>
          <div style={{
            padding: '0px 0px 10px 0px',
            display: 'flex',
            alignItems: 'center',
            gap: '40px',
          }}>
            <div onClick={() => setShowDetail(true)} style={{
              cursor: 'pointer',
              borderBottom: '1px solid black'
            }}>Номер груза: {item?.inner_payload?.sender?.cargo?.id} </div>
            <button onClick={() => setModal(true)} style={{ padding: '6px 12px', background: '#83C251', color: 'white', border: 'none', display: 'flex', alignItems: 'center', gap: '5px', borderRadius: '12px' }}>Коментарий <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path d="M11.9731 0.514282C12.0288 0.375001 11.9962 0.215919 11.8901 0.109846C11.784 0.00377242 11.6249 -0.0288804 11.4857 0.0268322L0.575333 4.39096C0.149742 4.5612 0.0955366 5.1417 0.482248 5.38779L4.2283 7.77164L5.37674 9.57634C5.48793 9.75107 5.71972 9.80257 5.89445 9.69138C6.06917 9.58019 6.12068 9.34841 6.00949 9.17368L4.97781 7.55247L10.5982 1.93204L9.17676 5.48574C9.09984 5.67803 9.19337 5.89627 9.38566 5.97319C9.57796 6.05011 9.7962 5.95658 9.87311 5.76428L11.9731 0.514282ZM10.0679 1.40171L4.44748 7.02214L1.19355 4.95145L10.0679 1.40171Z" fill="white" />
              <path d="M11.9999 9.375C11.9999 10.8247 10.8247 12 9.37494 12C7.92519 12 6.74994 10.8247 6.74994 9.375C6.74994 7.92525 7.92519 6.75 9.37494 6.75C10.8247 6.75 11.9999 7.92525 11.9999 9.375ZM9.37494 7.875C9.16783 7.875 8.99994 8.04289 8.99994 8.25V9H8.24994C8.04283 9 7.87494 9.16789 7.87494 9.375C7.87494 9.58211 8.04283 9.75 8.24994 9.75H8.99994V10.5C8.99994 10.7071 9.16783 10.875 9.37494 10.875C9.58205 10.875 9.74994 10.7071 9.74994 10.5V9.75H10.4999C10.707 9.75 10.8749 9.58211 10.8749 9.375C10.8749 9.16789 10.707 9 10.4999 9H9.74994V8.25C9.74994 8.04289 9.58205 7.875 9.37494 7.875Z" fill="white" />
            </svg></button>
          </div>
          <div className="ClientsInfo__content_center-adress">
            <div className="ClientsInfo__content_center-adress--city">
              <img src={Location} alt="" />
              {item?.inner_payload?.sender?.city?.country_name}
            </div>
            <div className="ClientsInfo__content_center-adress--line" style={{ width: '10px' }}></div>
            <div className="ClientsInfo__content_center-adress--city">
              <img src={Location} alt="" />
              {item?.inner_payload?.receiver?.city?.country_name}

            </div>
          </div>
          <div className="ClientsInfo__content_center-stocks">
            <div className="ClientsInfo__content_center-stocks--dispatching">
              <div className="stocks_img">
                <img src={Circle} alt="" />
                <div></div>
              </div>
              <div className="stocks_titles">
                <div className="stocks_titles-main">Скад отправления: <span style={{ color: 'black' }}>{item?.inner_payload?.sender?.stock?.name}</span></div>

                <div className="stocks_titles-stockstreet">
                  <img src={StockImg} alt="" />
                  {item?.inner_payload?.sender?.stock?.address}

                </div>
              </div>
            </div>
            <div className="ClientsInfo__content_center-stocks--resulting">
              <div className="stocks_img2">
                <img src={Circle} alt="" />
                <div></div>
              </div>
              <div className="stocks_titles">
                <div className="stocks_titles-main">Скад прибытия : <span style={{ color: 'black' }}>{item?.inner_payload?.receiver?.stock?.name}</span></div>
                <div className="stocks_titles-stockstreet">
                  <img src={StockImg} alt="" />
                  {item?.inner_payload?.receiver?.stock?.address}
                </div>
              </div>
            </div>
          </div>
        </>
      }

      {showDetail && (
        <div className="modal-background">
          <AllCargoInfoModl id={item?.inner_payload?.sender?.cargo?.id} setShowDetail={setShowDetail} />
        </div>
      )}

      <Modal title={'Написать коментарий'} padding={'15px'} height={'fit-content'} modal={modal} setModal={setModal} element={<>
        <div
          style={{
            width: "100%",
            maxWidth: "850px",
            height: 'calc(80vh - 100px)',
            position: 'relative'
          }}
        >
          <NewsTextEditor
            value={item?.comment}
            onChange={setContent}
            style={{
              paddingTop: '10px',
            }}
          />

          <div style={{
            position: 'absolute',
            left: '0',
            right: '0',
            bottom: '30px',
            display: 'flex',
            justifyContent: 'center'
          }}>
            <button onClick={LeaveComment} style={{
              background: '#83C251',
              color: 'white',
              padding: '12px 40px',
              border: 'none',
              borderRadius: '8px'
            }}>+ Оставить коментарий</button>
          </div>
        </div>
      </>} />
    </div>
  )
}

export default Payment

import { useState } from "react"
import { Link, NavLink } from "react-router-dom"
import HomeIcon from "../../assets/svg/homeIcon.svg"
import MapIcon from "../../assets/svg/mapIcon.svg"
import ClientIcon from "../../assets/svg/clientIcon.svg"
import TruckIcon from "../../assets/svg/truckIcon.svg"
import VarehouseIcon from "../../assets/svg/varehouseIcon.svg"
import StaffIcon from "../../assets/svg/staffIcon.svg"
import LogoPhoto from "../../assets/svg/logo.svg"
import LogoText from "../../assets/svg/logo2.svg"
import LeftArrow from "../../assets/svg/leftArrow.svg"
import { useAppSelector } from "../../redux/hooks"

interface Props {
  show: boolean
  setShow: any
}

const Index = ({ setShow, show }: Props) => {
  const { auth } = useAppSelector((state: any) => state)

  return (
    <div className={!show ? "SideBar" : "SideBar2"}>
      <div>
        <div className="sidebar_direct">
          <div>
            <Link to="/">
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  width: show ? "120%" : "100%",
                  padding: "32px 0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "13px",
                  }}
                >
                  <img src={LogoPhoto} alt="" />
                  {!show ? <img src={LogoText} alt="" /> : null}
                </div>
              </div>
            </Link>
            <button onClick={() => setShow(!show)} className={"SideBar_btn"}>
              <img
                style={{ rotate: show ? "-180deg" : "", transition: ".7s" }}
                src={LeftArrow}
                alt=""
              />
            </button>
            <div className="SideBar__inner">
              <NavLink to="/">
                <div className={"SideBar__inner_onhover"}>
                  <img src={HomeIcon} alt="" />
                  {show ? "" : "Аналитика"}
                </div>
              </NavLink>
              {(
                <NavLink to={"onways/on_way"}>
                  <div className={"SideBar__inner_onhover"}>
                    <img src={MapIcon} alt="" />

                    {show ? "" : "В пути"}
                  </div>
                </NavLink>
              )}
              <NavLink to="clients">
                <div className={"SideBar__inner_onhover"}>
                  <img src={ClientIcon} alt="" />

                  {show ? "" : "Клиенты"}
                </div>
              </NavLink>
              <NavLink to="trucks">
                <div className={"SideBar__inner_onhover"}>
                  <img src={TruckIcon} alt="" />

                  {show ? "" : "Грузовики"}
                </div>
              </NavLink>
              <NavLink to="/stock">
                <div className={"SideBar__inner_onhover"}>
                  <img src={VarehouseIcon} alt="" />

                  {show ? "" : "Склады"}
                </div>
              </NavLink>
              <NavLink to="/staff">
                <div className={"SideBar__inner_onhover"}>
                  <img src={StaffIcon} alt="" />

                  {show ? "" : "Персонал"}
                </div>
              </NavLink>
            </div>
          </div>
          <Link to="news">
            <div className="new_link">
              <div
                className={
                  show
                    ? "SideBar__inner_onhover new2"
                    : "SideBar__inner_onhover new"
                }
                style={{
                  whiteSpace: "initial",
                  display: show ? "block" : "",
                  textAlign: show ? "center" : "start",
                  paddingLeft: show ? "6px" : "",
                  paddingTop: show ? "6px" : "",
                  width: show ? "75%" : "",
                  borderTopRightRadius: show ? "10px" : "",
                  borderBottomRightRadius: show ? "10px" : "",
                  fontSize: show ? "10px" : "",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M6.99972 0.142822C7.47311 0.142822 7.85686 0.526578 7.85686 0.999965V6.14282H12.9997C13.4731 6.14282 13.8569 6.52658 13.8569 6.99996C13.8569 7.47335 13.4731 7.85711 12.9997 7.85711H7.85686V13C7.85686 13.4734 7.47311 13.8571 6.99972 13.8571C6.52633 13.8571 6.14258 13.4734 6.14258 13V7.85711H0.999721C0.526334 7.85711 0.142578 7.47335 0.142578 6.99996C0.142578 6.52658 0.526334 6.14282 0.999721 6.14282H6.14258V0.999965C6.14258 0.526578 6.52633 0.142822 6.99972 0.142822Z"
                    fill="white"
                  />
                </svg>
                Новости
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Index

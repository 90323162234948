import React from "react"
import CloseIcon from "../../../assets/svg/closeIcon.svg"
import { useAppDispatch } from "../../../redux/hooks"
import { removeStock } from "../../../redux/cargos/stock"

interface Props {
  exit: boolean
  setExit: any
  id?: any
  deleteStock?: any
}

const DeleteModal = ({ exit, setExit, id, deleteStock }: Props) => {
  const dispatch = useAppDispatch()

  const DeleteStock = () => {
    dispatch(removeStock(id))
    deleteStock(id)
  }

  return (
    <div className={exit ? "exitModal" : "none"} onClick={() => setExit(!exit)}>

      <div className="exitModal__content" onClick={(e) => e.stopPropagation()}>
        <img src={CloseIcon} alt="" onClick={() => setExit(!exit)} />
        <div className="exitModal__content_title">
          Вы действительно хотите удалить этот грузовик?
        </div>
        <div className="exitModal__content_btns">
          <button className="outline" onClick={() => setExit(!exit)}>
            Отмена
          </button>
          <button className="background" onClick={DeleteStock}>
            Удалить
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeleteModal

import React, { useEffect, useRef, useState } from "react"
import StockCard from "../components/stock/StockCard"
import GraphicSliderIcon from "../assets/svg/graficSlider.svg"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link, useParams } from "react-router-dom"
import { Modal } from "../components/Modal"
import { AddStock } from "../components/stock/Add"
import { addItemsLast, fetchStocks } from "../redux/cargos/stock"
import axios from "axios"
import { API_URL } from "../api/api_config"
import { access_token } from "../services"
import { setLoading } from "../redux/loading"
import { fetchStockTypes } from "../redux/cargos/stock-types"
import SelectFilter from "../components/selectComponent"
import PlusIcon from "../assets/svg/plusIcon.svg"

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    width: "150px",
    borderRadius: "6px",

    border: state.isFocused
      ? "1px solid rgba(157, 194, 81, 1)" // LC Green color on focus
      : "1px solid var(--lc-black-200, #CCC)",

    background: "var(--lc-white, #FFF)",
    padding: "0px 16px",
    boxShadow: state.isFocused ? "0 0 0 2px rgba(157, 194, 81, 0.3)" : "none", // LC Green color on focus
    "&:hover": {
      border: "1px solid rgba(157, 194, 81, 1)", // LC Green color on hover
    },

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? "rgba(157, 194, 81, 0.5)"
      : "transparent", // LC Green color on focus
    color: "var(--lc-black-800, #333)", // LC Black-800 color
  }),
}

const StockPages = () => {
  const dispatch = useAppDispatch()
  const { stocks, stockTypes, auth } = useAppSelector((state: any) => state)

  const [open, setOpen] = useState(true)
  const [onModal, setOnModal] = useState(false)

  const [type, setType] = useState<string>("")

  const [fetching, setFetching] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)

  const AfterGet = (data: any) => {
    setTotalCount(data?.count)
  }

  React.useEffect(() => {
    dispatch(
      fetchStocks(
        {
          limit: 24,
          offset: currentPage,
        },
        (data: any) => {
          setTotalCount(data.count)
        },
      ),
    )
    dispatch(fetchStockTypes())
  }, [])

  const deleteStock = (id: number) => {}

  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    if (
      target.scrollHeight - (target.scrollTop + target.clientHeight) < 1 &&
      stocks?.data?.results?.length < totalCount
    ) {
      dispatch(setLoading(true))
      axios
        .get(`${API_URL}api/v1/cargo/stocks/`, {
          params: {
            limit: 24,
            offset: currentPage + 1,
          },
          headers: {
            Authorization: `Token ${access_token} `,
          },
        })
        .then((response: any) => {
          setCurrentPage(currentPage + 1)
          dispatch(addItemsLast(response.data.results))

          dispatch(setLoading(false))
        })
        .catch((er) => {
          dispatch(setLoading(false))
        })
        .finally(() => {})
    } else {
    }
  }

  /////////////Search
  let timeID: any = 0

  const handleSearch = (event: any) => {
    if (timeID) {
      clearTimeout(timeID)
    }
    timeID = setTimeout(() => {
      dispatch(
        fetchStocks({
          search: event,
        }),
      )
    }, 500)
  }
  const handleButtonClick = (item: any) => {
    dispatch(
      fetchStocks({
        type: item.id,
      }),
    )
  }

  const scrollContainerRef: any = useRef()

  useEffect(() => {
    const handleWheel = (event: any) => {
      scrollContainerRef.current.scrollLeft += event.deltaY
      event.preventDefault()
    }

    const scrollContainer = scrollContainerRef.current
    scrollContainer.addEventListener("wheel", handleWheel)

    return () => {
      scrollContainer.removeEventListener("wheel", handleWheel)
    }
  }, [])

  return (
    <>
      <div
        className="spaceBetween"
        style={{
          flexWrap: "nowrap",
        }}
      >
        <div className="Clients__header" style={{width: '100%'}} ref={scrollContainerRef}>
          <div
            className="flex"
            style={{
              width: "100%",
            }}
          >
            {auth?.data?.role !== "dispatcher" && (
              <div>
                <Link to={"/stock/addgoodpage/"}>
                  <button className="stock_button">
                    <img src={PlusIcon} alt="" />
                    <div className="button_title"> Отправить груз</div>
                  </button>
                </Link>
              </div>
            )}

            <div className="SearchBox">
              <input
                onChange={(e) => handleSearch(e.target.value)}
                className="SearchBox__input"
                type="text"
                placeholder="Поиск"
              />
              <div className={open ? "SearchBox__btns" : "none"}>
                {
                  <SelectFilter
                    items={stockTypes?.data?.results?.map((item: any) => ({
                      value: item.name,
                      label: item.name,
                      id: item.id,
                    }))}
                    setSelectedItem={handleButtonClick}
                    title={""}
                  />
                }
              </div>
              <button className="SearchBox_btn" onClick={() => setOpen(!open)}>
              <img src={GraphicSliderIcon} alt="" />
            </button>
            </div>
            
            
          </div>
        </div>
        <div
          className="stock_flex"
          style={{
            display: "flex",
            alignItems: "center",
            width: "fit-content",
            gap: "32px",
          }}
        >
          {auth?.data?.role !== "dispatcher" && (
            <div onClick={() => setOnModal(!onModal)}>
              <button className="stock_button">
                <img src={PlusIcon} alt="" />
                <div className="button_title2">Добавить</div>
              </button>
            </div>
          )}
          <div className="title">Склады</div>
        </div>
      </div>

      <section
        className="stockWrap"
        style={{
          position: "relative",
          height: "100%",
        }}
      >
        <div
          className="stockWrap_list"
          ref={scrollableDivRef}
          onScroll={scrollHandler}
          style={{
            overflowY: "scroll",
            height: "calc(100% - 64px)",
          }}
        >
          {stocks?.data?.results?.map((item: any) => (
            <StockCard
              dispatcher={auth?.data?.role === "dispatcher" ? true : false}
              deleteStock={deleteStock}
              item={item}
              key={item.id}
            />
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: loading ? "60vh" : 'fit-content',
            width: "100%",
            position: "absolute",
            top: "0px",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {stocks?.data?.results?.length !== 0 ? "" : "Пусто!"}
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={"Создать новый склад"}
        modal={onModal}
        width={"630px"}
        setModal={setOnModal}
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div className="catalog">
              <AddStock setModal={setOnModal} />
            </div>
          </div>
        }
      />

       
    </>
  )
}

export default StockPages

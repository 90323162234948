import React, { useEffect, useState } from "react"
import SearchBox from "../../components/searchBox"
import { GreenAddBtn, GreenBackgroundBtn } from "../../components/btns"
import GraphicSliderIcon from "../../assets/svg/graficSlider.svg"
import StockInfo from "../../components/stockMoreComponent/StockInfo"
import WorkingMode from "../../components/stockMoreComponent/WorkingMode"
import { Link, NavLink, useParams } from "react-router-dom"
import EditIcon from "../../assets/svg/editIcon.svg"
import AllClient from "../../components/stockMoreComponent/allClient/index"

import { Modal } from "../../components/Modal"
import { EditStock } from "../../components/stock/Edit"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { fetchStock } from "../../redux/cargos/stock"
import { fetchStocksCargo } from "../../redux/cargos/inStock"
import { fetchStockTypes } from "../../redux/cargos/stock-types"
import { fetchProducktCategoryes } from "../../redux/cargos/produckt-categories"
import AllGooddsSection from "../../components/stockMoreComponent/allGoods/AllGoodsSection"
const stockTypes = [
  { id: 1, label: "Загрузка", name: "string", color: "string" },
  { id: 2, label: "Отгрузка", name: "string", color: "string" },
  { id: 3, label: "Перегрузка", name: "string", color: "string" },
  { id: 4, label: "Все", name: "string", color: "string" },
]
const StockMore = () => {
  const { stocks, auth, cargo } = useAppSelector((state: any) => state)

  const dispatch = useAppDispatch()
  const { id, type } = useParams()
  const [curentType, setCurentType] = useState<any>()
  const [more, setMore] = useState(false)
  const [client, setClient] = useState(type === "clients" ? false : true)

  const [onModal, setOnModal] = useState(false)

  useEffect(() => {
    dispatch(fetchStock(id))
    dispatch(fetchStockTypes())
    dispatch(fetchProducktCategoryes())
  }, [])

  return (
    <>
      <div className="Clients__header">
        <div
          className="flex"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Link to={`/stock/addgoodpage/`}>
              {auth?.data?.role !== "dispatcher" && (
                <GreenAddBtn title="Отправить груз" />
              )}
            </Link>
          </div>
          {client ? (
            <button
              className="GreenAddBtn"
              style={{ background: "#83C251" }}
              onClick={() => setClient(!client)}
            >
              <div>Клиенты</div>
            </button>
          ) : (
            <button
              className="GreenAddBtn"
              style={{ background: "#83C251" }}
              onClick={() => setClient(!client)}
            >
              <div>Товары</div>
            </button>
          )}
        </div>
      </div>
      <main className="StockMore" style={{ overflowY: "scroll" }}>
        <div
          style={more === true ? { width: "25%" } : {}}
          className="StockMore-left"
        >
          <div className="ClientsCard__inner_info-ids--btns">
            <Link to={`/stock`}>
              <GreenBackgroundBtn title="Назад" />
            </Link>
            {auth?.data?.role !== "dispatcher" && (
              <button
                onClick={() => setOnModal(!onModal)}
                className="btns__eitBtn"
              >
                <img src={EditIcon} alt="" />
              </button>
            )}
          </div>
          <div
            style={more === true ? { gap: "0" } : {}}
            className="StockMore-left-top"
          >
            <div
              className="StockMore-left-top-image"
              style={{ maxWidth: more ? "100%" : "" }}
            >
              <img
                src={
                  stocks?.oneData?.image
                    ? stocks?.oneData?.image
                    : "https://promstellage.ru/images/stati/raspredelitelnyy-sklad-1.jpg"
                }
                alt=""
              />
            </div>
            <div className="StockMore-left-top-info">
              <h1 style={more === true ? { display: "none" } : {}}>
                Склад №{stocks?.oneData?.id}
              </h1>
              <button
                className="btnForOpen"
                style={more === true ? { display: "none" } : {}}
                onClick={() => setMore(!more)}
              >
                <GreenBackgroundBtn title="Свернуть" />
              </button>
              <div style={more === true ? { display: "none" } : {}}>
                <h2>
                  Клиентов: <u className="u">{stocks?.oneData?.clients_count}</u>
                </h2>
                <h2>
                  ID: <u className="u">{stocks?.oneData?.id}</u>
                </h2>
              </div>
            </div>
          </div>
          <StockInfo stockData={stocks?.oneData} />
          {more && (
            <div onClick={() => setMore(!more)}>
              <GreenBackgroundBtn title="Развернуть" />
            </div>
          )}
        </div>
        <div
          style={more === true ? { width: "100%" } : {}}
          className="StockMore-right"
        >
          {!client ? <AllClient /> : <AllGooddsSection />}
          <br />
        </div>
        <Modal
          title={"Редактировать склад"}
          modal={onModal}
          width={"630px"}
          setModal={setOnModal}
          element={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "98%",
              }}
            >
              <div className="catalog">
                <EditStock
                  id={id}
                  setModal={setOnModal}
                  item={stocks.oneData}
                />
              </div>
            </div>
          }
        />
      </main>
    </>
  )
}

export default StockMore

import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { editStockItem, setRecheck } from '../../redux/cargos/stock'
import { Modal } from '../../components/Modal'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { fetchCities, fetchCitiesFrom, fetchCitiesTo } from '../../redux/cargos/cities'
import { fetchCountries } from '../../redux/cargos/countries'
import { fetchTrucks } from '../../redux/cargos/trucks'
import { addProduct, fetchProduct, fetchProducts, setError } from '../../redux/transports-products'
import { addItem, fetchProductForSend, fetchProductsSend } from '../../redux/products/productSlice'
import { ToastContainer, toast } from 'react-toastify'
import { addProductTransportation } from '../../redux/cargos/transportations'


export const StockClientItem = ({ editCargo, item, cargo, getCargo }: any) => {
    const dispatch = useAppDispatch()
    const { countries, cities, transportations, stocks, products } = useAppSelector((state) => state)
    const [download, setDownload] = useState(true)
    const [upload, setUpload] = useState(false)
    const [cancel, setCancel] = useState(false)
    const [modal, setModal] = useState(false)
    const [modalDownload, setModalDownload] = useState(false)
    const [list, setList] = useState(false)

    const { brand, weight, extra_quantity, } = item

    const [totalWeight, setTotalWeight] = useState(extra_quantity);
    const [productWeight, setProductWeight] = useState(extra_quantity)

    const { handleSubmit, register, control, setValue, reset } = useForm()

    const customStyless = {
        control: (provided: any, state: any) => ({
            ...provided,
            padding: '2px 5px',
            borderRadius: "10px",
            cursor: "pointer",
            marginRight: "1px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused
                ? "rgba(157, 194, 81, 0.5)"
                : "transparent",
            color: "var(--lc-black-800, #333)",
        }),
    }



    const [fromRoute, setFromRoute] = useState<any>(false)
    const [toRoute, setToRoute] = useState<any>(false)
    const [truck, setTruck] = useState<any>(false)
    const [zone, setZone] = useState<any>(false)

    const Download = (data: any) => {

        console.log(data);

        dispatch(addProduct({
            quantity: Number(data?.quantity),
            weight: data?.weight,
            zone: zone?.id,
            product: item?.id,
            cargo_transport: truck?.id,
            from_route: fromRoute?.id,
            to_route: toRoute?.id
        }, (data: any) => {
            dispatch(fetchProduct(data.id, (data: any) => {
                dispatch(addProductTransportation(data))
                toast.success('Товар успешно был добавлен')
                getCargo(true)
                dispatch(addItem(data))
                setDownload(false)
                setUpload(true)
                setModal(false)

                transportations?.oneData?.transports[0]?.transport_assembly?.map((i1: any) => {
                    if (i1?.inner_products?.find((i2: any) => i2?.product?.id === item.id)) {
                        setUpload(true)
                        setDownload(false)
                    }
                })

                transportations?.oneData?.transports[0]?.transport_assembly?.map((i1: any) => {
                    if (i1?.inner_products?.find((i2: any) => i2?.product?.id === item.id)) {
                        setUpload(true)
                        setDownload(false)
                    }
                })

                setProductWeight(extra_quantity)
            }))
            dispatch(setError(null))
        }))
    }

    const Upload = () => {
        setUpload(false)
        setCancel(true)
        setList(true)
    }

    const Cancel = () => {
        setDownload(false)
        setUpload(true)
        setCancel(false)
        setList(false)
    }

    const onSubmit = () => {
        setDownload(false)
        setUpload(true)
        setCancel(false)
        setList(false)
    }

    const getCitiesByCountryFrom = (id: number) => {
        dispatch(fetchCitiesFrom(id))
    }

    const getCitiesByCountryTo = (id: number) => {
        dispatch(fetchCitiesTo(id))
    }

    const getCitiesByCountry = (id: number) => {
        dispatch(fetchCities(id))
    }

    const handleWeightChange = (event: any) => {
        setProductWeight(parseInt(event.target.value, 10));
        // editCargo({total_weight: parseInt(event.target.value, 10)})
    };

    useEffect(() => {
        getCargo()
        setProductWeight(extra_quantity)
        const hasProduct = transportations?.oneData?.transports[0]?.transport_assembly?.some((i1: any) =>
            i1?.inner_products?.some((i2: any) => i2?.product?.id === item.id)
        );

        if (hasProduct) {
            setUpload(true);
            setDownload(false);
        } else {
            setUpload(false);
            setDownload(true);
        }


    }, [stocks?.reCheck, extra_quantity]);

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            width: '345px'
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused
                ? "rgba(157, 194, 81, 0.5)"
                : "transparent", // LC Green color on focus
            color: "var(--lc-black-800, #333)",
        }),
    }


    console.log(productWeight, item);


    return (
        <div>
            <div className='good_client_info_item'>
                <div>
                    <div className='good_client_info_item_titles' style={{ whiteSpace: 'nowrap' }}>Вещи: Бренд - {brand?.name} {productWeight} к-во</div>
                    {/* {upload && <button style={{ marginTop: '10px' }} onClick={Upload}>Догрузить</button>} */}
                    {cancel && <button style={{ marginTop: '10px' }} onClick={Cancel}>Отменить</button>}
                </div>
                <div className='good_client_info_item_btns' style={!download && upload && !cancel || !download && !upload && cancel ? { width: '-webkit-fill-available', paddingLeft: '20px' } : {}}>
                    <div className="good_actual_item_editables_range" style={{ width: !download && upload && !cancel || !download && !upload && cancel ? '100%' : '100%' }}>
                        <input min="0"
                            max={totalWeight}
                            step="1"
                            value={productWeight}
                            onChange={handleWeightChange} type="range" className="good_range" />
                        <div style={{ whiteSpace: 'nowrap' }}>{extra_quantity} к-во</div>
                    </div>

                    {<button className={extra_quantity === 0 ? 'good_client_info_item_btns_grey' : ''} onClick={extra_quantity === 0 ? (e: any) => {
                        e.stopPropagation()
                    } : () => {
                        if (!transportations?.oneData?.transports?.length) {
                            toast.error(
                                `Выберите машину`,
                                {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                },
                            )
                        } else {
                            setValue('quantity', productWeight)
                            setModal(true)
                        }
                    }}>Загрузить</button>}
                    {!upload && !download && <button onClick={() => {
                        setValue('quantity', productWeight)
                        setModal(true)
                        setModalDownload(true)
                    }}>Догрузить</button>}
                </div>
            </div>

            {/* {
                list &&
                <div className='good_client_info_item_clients'>
                    <div className='good_client_info_item_client'>
                        <div className='good_client_info_item_client_title'>10т Иван Иванов Иванов </div>
                        <div className="good_actual_item_editables_range">
                            <div>0т</div>
                            <input type="range" className="good_range" />
                            <div>10т</div>
                        </div>
                    </div>

                    <div className='good_client_info_item_client'>
                        <div className='good_client_info_item_client_title'>10т Иван Иванов Иванов </div>
                        <div className="good_actual_item_editables_range">
                            <div>0т</div>
                            <input type="range" className="good_range" />
                            <div>10т</div>
                        </div>
                    </div>
                </div>
            } */}

            <div className='good_client_info_item_linear'></div>

            {modal ? <Modal width={'600px'} height='fit-content' title={modalDownload ? 'Догрузка' : 'Загрузка'} modal={modal} setModal={setModal} element={
                <>
                    <div className='good_product'>
                        <div className="catalog_item">
                            <div>Количество: <u className="redStar">*</u></div>
                            <div style={{
                                width: "57.1%",
                            }}>

                                <input
                                    style={{ width: '90.5%' }}
                                    placeholder="Пусто"
                                    {...register("quantity", { required: false })}
                                    type="number"
                                />
                                {/* <div className="error">{users?.error?.first_name}</div> */}
                            </div>
                        </div>

                        <div className='error'>{products?.error?.quantity}</div>
                        <div className="catalog_item">
                            <div>Вес: <u className="redStar">*</u></div>
                            <div style={{
                                width: "57.1%",
                            }}>

                                <input
                                    style={{ width: '90.5%' }}
                                    placeholder="Пусто"
                                    {...register("weight", { required: false })}
                                    type="number"
                                />
                                {/* <div className="error">{users?.error?.first_name}</div> */}
                            </div>
                        </div>
                        <div className='error'>{products?.error?.weight}</div>
                        <div className="good_select_item2">
                            <div className="good_select_title">Прицеп: <u className="redStar">*</u></div>
                            <Select onChange={((e) => setTruck(e))} options={transportations?.oneData?.transports[0]?.transport_assembly
                                ?.filter((item: any) => item?.item?.type !== 'head')
                                .map((filteredItem: any) => ({
                                    label: filteredItem?.item?.number,
                                    value: filteredItem?.item?.number,
                                    id: filteredItem?.id,
                                    zones: filteredItem?.item?.zones,
                                }))} placeholder='Не выбрано' />
                        </div>
                        <div className='error'>{products?.error?.cargo_transport}</div>
                        <div className="good_select_item2">
                            <div className="good_select_title">Зона: <u className="redStar">*</u></div>
                            <Select styles={customStyles} isDisabled={!truck} onChange={((e) => setZone(e))} options={truck?.zones?.map((item: any, index: number) => ({
                                label: '№ ' + (index + 1) + ' | ' + item?.lifting_capacity + 'кг',
                                id: index + 1,
                            }))} placeholder='Не выбрано' />
                        </div>
                        <div className='error'>{products?.error?.zone}</div>

                        <div className="good_select_item2">
                            <div className="good_select_title">Маршрут от: <u className="redStar">*</u></div>
                            <Select onChange={((e) => setFromRoute(e))} options={transportations?.oneData?.routes?.filter((item2: any) => item2?.id !== transportations?.oneData?.routes[transportations?.oneData?.routes.length - 1]?.id)?.map((filteredItem: any) => (
                                {
                                    label: filteredItem?.stock?.name,
                                    value: filteredItem?.stock?.name,
                                    id: filteredItem?.id,
                                }))} placeholder='Не выбрано' />
                        </div>
                        <div className='error'>{products?.error?.from_route}</div>
                        <div className="good_select_item2">
                            <div className="good_select_title">Маршрут до: <u className="redStar">*</u></div>
                            <Select onChange={((e) => setToRoute(e))} options={transportations?.oneData?.routes?.filter((item2: any) => item2?.id !== transportations?.oneData?.routes[0]?.id)
                                .map((filteredItem: any) => ({
                                    label: filteredItem?.stock?.name,
                                    value: filteredItem?.stock?.name,
                                    id: filteredItem?.id,
                                }))} placeholder='Не выбрано' />
                        </div>
                        <div className='error'>{products?.error?.to_route}</div>
                        <div className='error'>{products?.error?.product ? 'Продукт:' + products?.error?.product : ''}</div>
                        <div
                            className="form_button"
                            style={{
                                paddingTop: "12px",
                            }}
                        >
                            <button style={{
                                color: '#83C251',
                                border: '1px solid #83C251',
                                background: 'white'
                            }} className="TruckBtn2" onClick={() => {
                                setModal(false)
                                dispatch(setError(false))
                            }}>
                                Отмена
                            </button>
                            <button
                                onClick={handleSubmit(Download)}
                                type="submit"
                                className="TruckBtn"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </>
            } /> : null}


        </div>
    )
}


import { createSlice } from "@reduxjs/toolkit"
import { NewsState } from "../types"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { toast } from "react-toastify"
import { setLoading } from "../loading"

const initialState: any = {
  data: [],
  error: false,
}

const reseipSlice = createSlice({
  name: "reseips",
  initialState,
  reducers: {
    loadDataSuccess: (state, { payload }) => {
      state.error = false
      state.data = payload
    },
    setItems: (state, { payload }) => {
      state.data = payload
    },
    addItem(state, { payload }) {
      state.data.push({ payload })
    },
    addItemsLast: (state, { payload }) => {
      state.data.results.push(...payload)
    },
    deleteItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const {
  setItems,
  setError,
  addItem,
  addItemsLast,
  deleteItem,
  loadDataSuccess,
} = reseipSlice.actions
export default reseipSlice.reducer

//fetchNews
export const fetchReseips = (params: any, AfterGet?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/cargo/receipts/`, {
        params: params,
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
        AfterGet(response.data)
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const addReciept = (data: any, SetFunction?: any) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/cargo/increase-balance/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        toast.success(response?.data?.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        SetFunction(data)
        dispatch(addItem(response.data))
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const confirmReciept = (id: any, setFunc?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/cargo/receipts/confirm/${id}/`, {
        headers: { Authorization: `Token ${access_token}` },
      })
      .then((response: any) => {
        setFunc()
        toast.success(response?.data?.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })

        dispatch(setLoading(false))
      })
      .catch((er) => {
        toast.error(er?.response?.data?.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        dispatch(setLoading(false))
      })
  }
}

export const cancelReciept = (id: any, setFunc?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/cargo/receipts/cancel/${id}/`, {
        headers: { Authorization: `Token ${access_token}` },
      })
      .then((response: any) => {
        setFunc()
        toast.success(response?.data?.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })

        dispatch(setLoading(false))
      })
      .catch((er) => {
        toast.error(er?.response?.data?.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        dispatch(setLoading(false))
      })
  }
}
import React, { useState, useEffect, useRef } from "react"
import send from "../../assets/svg/Vector 417 (Stroke).svg"
import { access_token, user } from "../../services"
import { useParams } from "react-router-dom"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { addMessage } from "../../redux/chats"

export interface Message {
  action: string
  message: string
  user: number
  chatId: any
}

const ChatComponent = ({
  reSoket,
  messages,
  setTyping,
  setOnline,
  // setMessages,
  scrollToBottom,
}: any) => {
  const [inputMessage, setInputMessage] = useState<string>("")
  const socketRef = useRef<WebSocket | null>(null)
  const { uuid } = useParams()
  const dispatch = useAppDispatch()
  const { chats } = useAppSelector((state) => state.chats)

  const queries = new URLSearchParams({
    Authorization: `Token ${access_token}`,
    mode: "dispatcher",
  }).toString()

  const ws: any = useRef<WebSocket | null>(null)
  const online_user_ws = useRef<WebSocket | null>(null)



  // Define handleMessageSubmit function outside of useEffect
  const handleMessageSubmit = () => {
    scrollToBottom();

    if (!inputMessage.trim() || !ws.current || ws.current.readyState !== WebSocket.OPEN) {
      return;
    }
    const messageData = {
      id: user.id,
      body: inputMessage,
      type: "send_message",
    };

    ws.current.send(JSON.stringify(messageData));
    setInputMessage("");
    ws.current.send(JSON.stringify({
      id: Number(localStorage.getItem('id')),
      typing: false,
      type: "typing",
      user_type: "dispatcher"
    }));
  };

  const handleKeyPress = (event: any) => {

    if (event.key === "Enter") {
      scrollToBottom();

      handleMessageSubmit();
    } else {
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        if (!inputMessage) {
          ws.current.send(JSON.stringify({
            id: Number(localStorage.getItem('id')),
            typing: false,
            type: "typing",
            user_type: "dispatcher"
          }));
        } else {
          ws.current.send(JSON.stringify({
            id: Number(localStorage.getItem('id')),
            typing: true,
            type: "typing",
            user_type: "dispatcher"
          }));
        }

      }
    }
  };

  const [messagesReceived, setMessagesReceived] = useState(false);

  // Dependency added to re-trigger effect when messagesReceived changes

  // When messages are received, set messagesReceived to true
  // Assuming you have a function to handle message reception


  // console.log(messages);

  useEffect(() => {
    ws.current = new WebSocket(
      `wss://back.lidercargo.net/chat-room/${uuid}/?${queries}`
    );
    online_user_ws.current = new WebSocket(
      `wss://back.lidercargo.net/online-user/?${queries}`
    );

    ws.current.onopen = () => {
      console.log("WebSocket connected");





    };

    ws.current.onmessage = (e: any) => {
      const data = e.data.toString();

      const message: any = JSON.parse(data);
      

      if (message.type === 'send_message') {

        dispatch(addMessage(message))

        if (message?.user_id !== Number(localStorage.getItem('id'))) {
          ws.current.send(JSON.stringify({ type: 'make_read_message', message_id: message.message_id, id: Number(localStorage.getItem('id')), is_read: true, ds: 1 }));
        }

      } else if (message.type === 'typing') {

        if (message.id !== Number(localStorage.getItem('id'))) {
          setTyping(message.typing);
        }

      }




    };

    online_user_ws.current.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.type === 'online') {
        if (data.user_id === Number(localStorage.getItem('id'))) {
          setOnline(data.is_online);
        }
      }
    };

    online_user_ws.current.onopen = () => {
      // console.log("Online WebSocket connected");

    };

    online_user_ws.current.onclose = () => {
      // console.log("Online WebSocket closed");
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
      if (online_user_ws.current) {
        online_user_ws.current.close();
      }
    };
  }, [uuid, messagesReceived]);

  const sendImg = (e: any) => {
    axios.post(`${API_URL}api/v1/chat/messages/upload-file/${uuid}/`, {
      id: Number(Number(localStorage.getItem('id'))),
      photo: e
    }, {
      headers: {
        Authorization: `Token ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {

    }).catch((er) => {
    })
  }

  const sendFile = (e: any) => {
    axios.post(`${API_URL}api/v1/chat/messages/upload-file/${uuid}/`, {
      id: Number(Number(localStorage.getItem('id'))),
      file: e
    }, {
      headers: {
        Authorization: `Token ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {

    }).catch((er) => {
    })
  }

  return (
    <>
      <div className="chat_main-rightChat-action-input" style={{
        alignItems: 'center',
        gap: '10px',
      }}>
        <input
          placeholder="Введите сообщение"
          value={inputMessage}
          onKeyDown={handleKeyPress}
          onChange={(e) => setInputMessage(e.target.value)}
        />
        <input onChange={(e: any) => sendFile(e.target.files[0])} type="file" id="filesend1" hidden />
        <label htmlFor="filesend1">
          <svg style={{
            cursor: 'pointer'
          }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 2.26946V6.4C14 6.96005 14 7.24008 14.109 7.45399C14.2049 7.64215 14.3578 7.79513 14.546 7.89101C14.7599 8 15.0399 8 15.6 8H19.7305M20 9.98822V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H12.0118C12.7455 2 13.1124 2 13.4577 2.08289C13.7638 2.15638 14.0564 2.27759 14.3249 2.44208C14.6276 2.6276 14.887 2.88703 15.4059 3.40589L18.5941 6.59411C19.113 7.11297 19.3724 7.3724 19.5579 7.67515C19.7224 7.94356 19.8436 8.2362 19.9171 8.5423C20 8.88757 20 9.25445 20 9.98822Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </label>
        <input onChange={(e: any) => sendImg(e.target.files[0])} type="file" id="filesend" hidden />
        <label htmlFor="filesend">
          <svg style={{
            cursor: 'pointer'
          }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 5.25581V16.5C17.5 19.5376 15.0376 22 12 22C8.96243 22 6.5 19.5376 6.5 16.5V5.66667C6.5 3.64162 8.14162 2 10.1667 2C12.1917 2 13.8333 3.64162 13.8333 5.66667V16.4457C13.8333 17.4583 13.0125 18.2791 12 18.2791C10.9875 18.2791 10.1667 17.4583 10.1667 16.4457V6.65116" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </label>




        <button
          onClick={handleMessageSubmit}
          className="chat_main-rightChat-action-input-send"
        >
          <div style={{ cursor: "pointer" }}>
            <img src={send} alt="" />
          </div>
        </button>
      </div>
    </>
  )
}

export default ChatComponent

import React, { useState } from "react"

import AddCatagoryForm from "./AddCategoryForm"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { deleteCategory } from "../../../redux/products/productSlice"

const AbuotAnoterGoods: React.FC = () => {
  const { produckt } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const [openAddCategory, setOpenCategory] = useState(false)

  const handleDeleteCategory = (id: any) => {
    dispatch(deleteCategory(id))
  }

  return (
    <div>
      <div className="form_inputs-input">
        <div>
          <p>Категории:</p>
        </div>
        <div className="addedItemOption" onClick={() => setOpenCategory(true)}>
          Добавить категорию
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM6.375 3.375C6.375 3.16789 6.20711 3 6 3C5.79289 3 5.625 3.16789 5.625 3.375V5.625H3.375C3.16789 5.625 3 5.79289 3 6C3 6.20711 3.16789 6.375 3.375 6.375H5.625V8.625C5.625 8.83211 5.79289 9 6 9C6.20711 9 6.375 8.83211 6.375 8.625V6.375H8.625C8.83211 6.375 9 6.20711 9 6C9 5.79289 8.83211 5.625 8.625 5.625H6.375V3.375Z"
              fill="white"
            />
          </svg>
        </div>
        <br />
      </div>
      <div
        key={null}
        className={
          produckt.category?.length % 2 === 0
            ? "CategoryListForm"
            : "CategoryListForm2"
        }
      >
        {produckt.category?.map((item: any) => (
          <div key={item.id} className="addedItemCategory">
            {item.category}
            <svg
              onClick={() => handleDeleteCategory(item.id)}
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
            >
              <path
                d="M6.75 11.25C3.85051 11.25 1.5 8.8995 1.5 6C1.5 3.10051 3.85051 0.75 6.75 0.75C9.6495 0.75 12 3.10051 12 6C12 8.8995 9.6495 11.25 6.75 11.25ZM6.75 12C10.0637 12 12.75 9.31371 12.75 6C12.75 2.68629 10.0637 0 6.75 0C3.43629 0 0.75 2.68629 0.75 6C0.75 9.31371 3.43629 12 6.75 12Z"
                fill="white"
              />
              <path
                d="M3.75 6C3.75 5.79289 3.91789 5.625 4.125 5.625H9.375C9.58211 5.625 9.75 5.79289 9.75 6C9.75 6.20711 9.58211 6.375 9.375 6.375H4.125C3.91789 6.375 3.75 6.20711 3.75 6Z"
                fill="white"
              />
            </svg>
          </div>
        ))}
      </div>
      <div></div>
      <br />
      <div className={openAddCategory === false ? "form_popOff" : "form_popON"}>
        <AddCatagoryForm setOpen={setOpenCategory} />
      </div>
    </div>
  )
}

export default AbuotAnoterGoods

import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../../api/api_config"
import { access_token } from "../../../services"
import { toast } from "react-toastify"
import { setLoading } from "../../loading"

const initialState: any = {
  loading: false,
  data: [],
  oneData: {},
  error: false,
}

const URL = "auth/notifications"

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    getItems: (state, { payload }) => {
      state.data = payload
    },
    getItem: (state, { payload }) => {
      state.oneData = payload
    },
    addItem(state, { payload }) {
      state.data.push({ payload })
    },
    addItemsLast: (state, { payload }) => {
      state.data.results.push(...payload)
    },
    editItems: (state, { payload }) => {
      const updatedResults = state.data.results.map((item: any) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
      state.data.results = updatedResults
    },
    editItem: (state, { payload }) => {
      state.oneData = payload
    },
    removeItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const {
  getItems,
  getItem,
  setError,
  addItem,
  editItems,
  removeItem,
  editItem,
  addItemsLast,
} = notificationsSlice.actions
export default notificationsSlice.reducer

export const fetchOwnNotifications = (params: any, AfterGet?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/own/`, {
        params: params,
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItems(response.data))
        AfterGet(response.data)
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => {})
  }
}

export const makeReadAsMana = (id: number, setFunc?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/make-read-as-manager/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        setFunc()
        dispatch(getItem(response.data))
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
      .finally(() => {})
  }
}

export const makeReadAndConfirmCargo = (id: number, setFunc?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/cargo/сonfirm-cargo-by-notification/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        setFunc()
        dispatch(getItem(response.data))
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
      .finally(() => {})
  }
}

export const makeRead = (id: number, setClose?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/make-read/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        setClose(false)
        dispatch(getItem(response.data))
        // toast.warn(`Увядомления успешно прочитано`, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // })
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
      .finally(() => {})
  }
}

export const fetchNotification = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItem(response.data))
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const addNotification = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/${URL}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(addItem(response.data))
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const editNotification = (id: any, data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/${URL}/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editItem(res.data))
        dispatch(editItems(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const removeNotification = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .delete(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(removeItem(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const confirmPayment = (id: number, setClose?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/cargo/receipts/confirm/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItem(response.data))
        setClose()
      })
      .catch((er) => {
        dispatch(setError(er.response?.data))
        dispatch(setLoading(false))
        toast.error(`${er.response?.data?.detail}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const rejectPayment = (id: number, setClose?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/cargo/receipts/cancel/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItem(response.data))
        setClose()
      })
      .catch((er) => {
        dispatch(setLoading(false))
        toast.error(`${er.response?.data?.detail}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

import React, { useEffect, useState } from "react"
import Location from "../../assets/svg/location.svg"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import NoImage from "../../assets/img/no-profile.jpeg"
import ConfirmCargo from "./Modal/confirmCargo"
import { ToastContainer } from "react-toastify"
import { fetchCities } from "../../redux/cargos/cities"
import { Modal } from "../Modal"
import { EditStock } from "../stock/Edit"
import { EditCargo } from "../Forms/GoodsEdit/EditGoodsForm"

const ReqestToNewGoods = ({ item }: any) => {
  const [onModal, setOnModal] = useState(false)

  return (
    <>
      {item?.inner_payload?.status === "waiting" ? (
        <div className="AddedNewUser">
          <div className="AddedNewUser_avatar">
            <img src={item?.image ? item?.image : NoImage} alt="" />
          </div>
          <div className="AddedNewUser_reqest">
            <div className="AddedNewUser_reqest-top">
              <article>
                <h1>Запрос на новый груз</h1>
                <div
                  style={{
                    color: "#1A1A1A",
                    fontSize: "12px",
                    marginTop: "6px",
                  }}
                >
                  {item?.inner_payload?.user.first_name +
                    " " +
                    item?.inner_payload?.user.last_name}
                </div>
                <div
                  style={{
                    color: "#1A1A1A",
                    fontSize: "12px",

                    marginTop: "6px",
                  }}
                >
                  ID: {item.inner_payload?.id}
                </div>
              </article>
              <div className="AddedNewUser_reqest-top-div">
                <div className="ClientsInfo__content_center-adress">
                  <div
                    className="ClientsInfo__content_center-adress--city"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <img src={Location} alt="" />
                    {item?.inner_payload?.sender?.city?.name}
                  </div>
                  <div className="ClientsInfo__content_center-adress--line"></div>
                  <div
                    className="ClientsInfo__content_center-adress--city"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <img src={Location} alt="" />
                    {item?.inner_payload?.receiver?.city?.name}
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div
                onClick={() => setOnModal(!onModal)}
                className="Green-bth"
                style={{ cursor: "pointer", width: "100%" }}
              >
                Смотреть детально
              </div>
            </div>
          </div>

          {/* {onModal && (
            <div
              className="modal-background"
              onClick={() => setOnModal(!onModal)}
            >
              <ConfirmCargo
                id={item?.inner_payload?.id}
                onModal={onModal}
                setOnModal={setOnModal}
                cargoAllow={cargoAllow}
              />
            </div>
          )} */}

          <Modal
            title={"Редактировать груз"}
            modal={onModal}
            width={"630px"}
            setModal={setOnModal}
            element={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  height: "98%",
                }}
              >
                <div className="catalog">
                  <EditCargo
                    setModal={setOnModal}
                    id={item?.inner_payload?.id}
                    type={item?.inner_payload}
                    status={item}
                  />
                </div>
              </div>
            }
          />
        </div>
      ) : null}
    </>
  )
}

export default ReqestToNewGoods

import React, { useEffect, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Select from "react-select"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { editTransportationsRoute } from "../../../redux/cargos/transportations"

type Props = {
  country: any
  city: any
  stock: any
  sending_day?: any
}

const ReadAndUpdate = ({ item, transport }: any) => {
  const { countries, cities, stocks } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const { register, handleSubmit, control } = useForm<any>()

  const editRoute = (e: any) => {
    dispatch(
      editTransportationsRoute(
        {
          stock: e?.id,
          transportation: transport?.transportation,
        },
        item?.id,
      ),
    )
  }

  let timeId: any = 0

  const handleChangeDate = (e: any) => {
    if (timeId) {
      clearTimeout(timeId)
    }
    timeId = setTimeout(() => {
      dispatch(
        editTransportationsRoute(
          {
            transportation: transport?.transportation,
            date: e,
          },
          item?.id,
        ),
      )
    }, 500)
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px",
      cursor: "pointer",
      background: "#fff",
      // marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent", // LC Green color on focus
      color: "var(--lc-black-800, #333)",
    }),
  }

  return (
    <div>
      <div className="EditWayModal__content_item">
        <div className="catalog_item">
          <div>Выбрать страну:</div>
          <div style={{ width: "52.7%", whiteSpace: "nowrap" }}>
            <Controller
              control={control}
              {...register("country", { required: false })}
              render={({ field }) => (
                <Select
                  styles={customStyless}
                  id="favouriteAvenger"
                  {...field}
                  placeholder={item?.stock?.city?.country_name}
                  // onMenuScrollToBottom={(e: any) => scrollHandler(e)}
                  defaultValue={item?.stock?.city?.country_name}
                  options={[]}
                />
              )}
            />
          </div>
        </div>
        <div className="catalog_item">
          <div>Выбрать город:</div>
          <div style={{ width: "42.7%", whiteSpace: "nowrap" }}>
            <Controller
              control={control}
              {...register("city", { required: false })}
              render={({ field }) => (
                <Select
                  styles={customStyless}
                  id="favouriteAvenger"
                  {...field}
                  placeholder={item?.stock?.city?.name}
                  // onMenuScrollToBottom={(e: any) => scrollHandler(e)}
                  defaultValue={item?.stock?.city?.name}
                  options={[]}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="EditWayModal__content_item">
        <div>Выбор склада отправки:</div>
        <div style={{ width: "58.7%" }}>
          <Controller
            control={control}
            {...register("stock", { required: false })}
            render={({ field }) => (
              <Select
                styles={customStyless}
                id="favouriteAvenger"
                {...field}
                placeholder={item?.stock?.name}
                // onMenuScrollToBottom={(e: any) => scrollHandler(e)}
                defaultValue={item?.stock?.id}
                options={stocks?.data?.results?.map((item: any) => ({
                  value: item?.name,
                  label: item?.name,
                  id: item?.id,
                }))}
                onChange={(e: any) => editRoute(e)}
              />
            )}
          />
        </div>
      </div>
      <div className="EditWayModal__content_item">
        <div>День отправки:</div>
        <div style={{ width: "58.7%" }}>
          <input
            {...register("sending_day", { required: false })}
            type="date"
            defaultValue={item?.date?.slice(0, 10)}
            onChange={(e: any) => handleChangeDate(e.target.value)}
          />
        </div>
      </div>
      <div className="wayline"></div>
    </div>
  )
}

export default ReadAndUpdate

import React, { FC, useState } from "react"

import DoubleCheckmark from "../../assets/svg/doublecheckArr.svg"
import CashStack from "../../assets/svg/greenwalletIcon.svg"

import CloseIcon from "../../assets/svg/closeIcon.svg"
import { getPaymentStatus } from "../../utils/functions"
import { useAppDispatch } from "../../redux/hooks"
import {
  cancelReciept,
  confirmReciept,
  fetchReseips,
} from "../../redux/receipts"
interface Props {
  id: number
  status: string
  value: any
  user: any
  image: string
  dispatcher?: any
}
const Index: FC<Props> = ({ status, id, user, value, image, dispatcher }) => {
  const dispatch = useAppDispatch()
  const [onModal, setOnModal] = useState(false)
  const [onCansel, setOnCansel] = useState(false)
  const [onImage, setOnImage] = useState(false)

  const setFunc = () => {
    dispatch(fetchReseips({}))
    setOnModal(false)
    setOnCansel(false)
  }

  const fetchConfirm = () => {
    dispatch(confirmReciept(id, setFunc))
  }
  const fetchCancel = async () => {
    dispatch(cancelReciept(id, setFunc))
  }

  return (
    <div
      className="HistoryCard"
      style={{
        width: "100%",
      }}
    >
      <div className="ClientsInfo__content_center-addMoney">
        <img src={DoubleCheckmark} alt="" />
        <div>
          <img src={CashStack} alt="" />
          <div>Пополнения счета: {value}</div>
        </div>
      </div>
      <div className="ClientsCard__inner_info-name">
        {user?.first_name + " "}
        {user?.last_name}
      </div>
      <div>
        <div className="HistoryCard_status">
          Статус: <u style={{color: status === 'confirmed' ? 'greed' :  status === 'waiting' ? 'orange' :  status === 'canceled' ? 'red' : ''}}>{getPaymentStatus(status)}</u>
        </div>
        <div className="HistoryCard_id">
          ID: <u>{id}</u>
        </div>
        <div className="HistoryCard_balance">
          Баланс: <u style={{color: Number(user?.client?.balance) >= 0 ? '#83C251' : Number(user?.client?.balance) <= 0 ? 'red' : ''}}>{ Number(user?.client?.balance) 
          < 0 ? '-' : ''}{user?.client?.balance ? user?.client?.balance : '0'}$</u>
        </div>
      </div>
      <img
        onClick={() => setOnImage(true)}
        className="HistoryCard_img"
        width={"100%"}
        src={image}
        alt=""
      />
      {!dispatcher && (
        <div className="HistoryCard_btns">
          <div
            onClick={() => setOnModal(true)}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <button style={{ width: "50%" }} className="payBtn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.85355 2.64645C4.04882 2.84171 4.04882 3.15829 3.85355 3.35355L1.20711 6L3.85355 8.64645C4.04882 8.84171 4.04882 9.15829 3.85355 9.35355C3.65829 9.54882 3.34171 9.54882 3.14645 9.35355L0.146447 6.35355C-0.0488155 6.15829 -0.0488155 5.84171 0.146447 5.64645L3.14645 2.64645C3.34171 2.45118 3.65829 2.45118 3.85355 2.64645Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.5 6C0.5 5.72386 0.723858 5.5 1 5.5L11.5 5.5C11.7761 5.5 12 5.72386 12 6C12 6.27614 11.7761 6.5 11.5 6.5L1 6.5C0.723858 6.5 0.5 6.27614 0.5 6Z"
                  fill="white"
                />
              </svg>
              <div>Потвердить</div>
            </button>
            <button
              className="button red "
              style={{
                padding: "9px 0px",
                width: "50%",
              }}
              onClick={() => setOnCansel(true)}
            >
              Отменить
            </button>
          </div>
        </div>
      )}
      {onModal && (
        <div className="modal-background" onClick={() => setOnModal(!onModal)}>
          <div className={onModal ? "exitModal" : "none"}>
            <div
              className="exitModal__content"
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src={CloseIcon}
                alt=""
                onClick={() => setOnModal(!onModal)}
              />
              <div className="exitModal__content_title">
                Вы действительно хотите потвердить?
              </div>
              <div className="exitModal__content_btns">
                <button className="button" onClick={() => setOnModal(!onModal)}>
                  Отмена
                </button>
                <button onClick={fetchConfirm} className="button red fill">
                  Потвердить
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {onCansel && (
        <div
          className="modal-background"
          onClick={() => setOnCansel(!onCansel)}
        >
          <div className={onCansel ? "exitModal" : "none"}>
            <div
              className="exitModal__content"
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src={CloseIcon}
                alt=""
                onClick={() => setOnCansel(!onCansel)}
              />
              <div className="exitModal__content_title">
                Вы действительно хотите Отменить?
              </div>
              <div className="exitModal__content_btns">
                <button
                  className="outline"
                  onClick={() => setOnCansel(!onCansel)}
                >
                  Отмена
                </button>
                <button
                  onClick={fetchCancel}
                  style={{ background: "#EB5757", border: "none" }}
                  className="background"
                >
                  Отменить
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {onImage && (
        <div className="modal-background" onClick={() => setOnImage(!onImage)}>
          <div className={onImage ? "exitModal" : "none"}>
            <img
              style={{
                position: "absolute",
                right: "17vw",
                top: "17vh",
                cursor: "pointer",
              }}
              src={CloseIcon}
              alt=""
              onClick={() => setOnImage(!onImage)}
            />
            <img
              style={{
                border: "2px solid #83C251",
                borderRadius: "16px",
                width: "70vw",
                height: "70vh",
                objectFit: "cover",
              }}
              src={image}
              alt=""
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Index

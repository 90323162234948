import React, { useState } from "react"

interface StarRatingProps {
  totalStars: number
  rating: any
  setRating: any
}

const StarAddRating: React.FC<StarRatingProps> = ({
  totalStars,
  rating,
  setRating,
}) => {
  const handleStarClick = (clickedStar: number) => {
    setRating(clickedStar)
  }

  return (
    <div>
      {[...Array(totalStars)].map((_, index) => (
        <span
          key={index}
          onClick={() => handleStarClick(index + 1)}
          style={{
            cursor: "pointer",
            color: index < rating ? "gold" : "gray",
            fontSize: "15px",
          }}
        >
          ★
        </span>
      ))}
    </div>
  )
}

export default StarAddRating

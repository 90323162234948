import { useState } from "react"
import PlusIcon from "../../assets/svg/plusIcon.svg"

interface NotificationBtnState {
  image: string
  active?: boolean
}

interface CategoriesBtnsState {
  title: string,
  red?: any
}

interface GreenAddBtnState {
  title: string
}

export const GreenAddBtn = ({ title }: GreenAddBtnState) => {
  return (
    <button className="GreenAddBtn">
      <img src={PlusIcon} alt="" />
      <div>{title}</div>
    </button>
  )
}

export const NotificationBtn = ({ image, active }: NotificationBtnState) => {
  return (
    <button style={{ position: "relative" }} className="NotificationBtn">
      <div  className={active ? "activeback" : "none"}>
        <div style={{
          width: '10px',
          height: '10px',
          padding: '0px',
          top: '6px',
          right: '6px'
        }} className={active ? "active" : "none"}></div>
      </div>
      <img src={image} alt="" />
    </button>
  )
}

export const GreenBackgroundBtn = ({ style, title, red }: any) => {
  return (
    <button
      className="GreenAddBtn"
      style={{ background: red && "#EB5757", ...style }}
    >
      <div>{title}</div>
    </button>
  )
}

export const GreenToBackBtn = ({ title }: CategoriesBtnsState) => {
  return (
    <button className="GreenToBackBtn">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.85355 2.64645C4.04882 2.84171 4.04882 3.15829 3.85355 3.35355L1.20711 6L3.85355 8.64645C4.04882 8.84171 4.04882 9.15829 3.85355 9.35355C3.65829 9.54882 3.34171 9.54882 3.14645 9.35355L0.146447 6.35355C-0.0488155 6.15829 -0.0488155 5.84171 0.146447 5.64645L3.14645 2.64645C3.34171 2.45118 3.65829 2.45118 3.85355 2.64645Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 6C0.5 5.72386 0.723858 5.5 1 5.5L11.5 5.5C11.7761 5.5 12 5.72386 12 6C12 6.27614 11.7761 6.5 11.5 6.5L1 6.5C0.723858 6.5 0.5 6.27614 0.5 6Z"
          fill="white"
        />
      </svg>
      <div>{title}</div>
    </button>
  )
}

export const WhiteBackGraundBtn = ({ title }: CategoriesBtnsState) => {
  return (
    <button className="WhiteBackGraundBtn">
      
      <div>{title}</div>
    </button>
  )
}
import React, { useEffect, useMemo, useRef, useState } from "react"
import backIcon from "../assets/svg/Back.svg"

import CloseIcon from "../assets/svg/closeIcon.svg"
import { Link, NavLink, useNavigate, useParams } from "react-router-dom"

import BackChat from "../assets/img/BackChat.png"
import ChatComponent from "../components/chatComponents/FormChat"

import { access_token, user } from "../services"
import axios, { AxiosResponse } from "axios"

import { API_URL } from "../api/api_config"
import { ToastContainer, toast } from "react-toastify"
import { useAppDispatch } from "../redux/hooks"
import { setLoading } from "../redux/loading"

const RoomPage = () => {
  const [reSoket, setReSocet] = useState(false)
  const { uuid } = useParams()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [state, setState] = useState(false)
  const [SocketMesege, setSocketMesege] = useState<any[]>([])
  const [offset, setOffset] = useState(1)
  const [CurentUser, setCurentUser] = useState<any>()
  const [isAsept, setIsAsept] = useState(false)

  const [onModal, setOnModal] = useState(false)

  const [messages, setMessages] = useState<any[]>([])
  const [error, setError] = useState<any>()
  const chatContainerRef = useRef<HTMLDivElement>(null)
  const [isRoomOpen, setRoomOpen] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}api/v1/chat/rooms/${uuid}`,
          {
            headers: {
              Authorization: `Token ${access_token}`,
            },
          },
        )
        setCurentUser(response.data)
        if (response?.data?.status === "active") {
          setRoomOpen(true)
        }
      } catch (error) {
        console.error("Произошла ошибка при выполнении GET-запроса:", error)
      }
    }

    fetchData()
  }, [uuid])

  const scrollToBottom = () => {
    const chatContainer = chatContainerRef.current
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight
    }
  }
  const fetchData = async () => {
    dispatch(setLoading(true))
    try {
      const response = await axios.get(
        `${API_URL}api/v1/chat/messages/room/${uuid}/?offset=${offset}`,
        {
          headers: {
            Authorization: `Token ${access_token} `,
          },
        },
      )
      const newData = response?.data?.results
      setMessages((prevData) => [...newData, ...prevData])
      setTimeout(() => {
        if (offset === 1) {
          scrollToBottom()
        }
      }, 100)
    } catch (error: any) {
      setError(error?.response?.data?.detail)
    } finally {
      dispatch(setLoading(false))
    }
  }
  const fetchNewData = async () => {
    dispatch(setLoading(true))
    try {
      const response = await axios.get(
        `${API_URL}api/v1/chat/messages/room/${uuid}/?offset=${offset}`,
        {
          headers: {
            Authorization: `Token ${access_token} `,
          },
        },
      )
      const newData = response?.data?.results
      setMessages(newData)
      setTimeout(() => {
        if (offset === 1) {
          scrollToBottom()
        }
      }, 100)
    } catch (error: any) {
      setError(error?.response?.data?.detail)
    } finally {
      dispatch(setLoading(false))
    }
  }

  //=======================Get Meseges by id==================================================
  useEffect(() => {
    fetchData()
    setSocketMesege([])
  }, [uuid, offset])

  //=========================================================================
  useEffect(() => {
    setError(null)
    setMessages([])
    setOffset(1)
  }, [uuid])

  //=========================================================================
  const [isArhiv, setIsArhiv] = useState(false)

  //========================================================================

  const [open, setOpen] = useState(true)
  const handleScroll = () => {
    const divElement = chatContainerRef.current

    if (divElement) {
      if (divElement.scrollTop === 0) {
        setOffset(offset + 1)
      }
    }
  }

  const HandleAsseptChat = async () => {
    try {
      const response = await axios
        .get(`${API_URL}api/v1/chat/rooms/accept-request/${uuid}`, {
          headers: {
            Authorization: `Token ${access_token}`,
          },
        })
        .then(() => {
          fetchNewData()
          toast.success("Вы успешно приняли запрос на переписку", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
          navigate(`/chat/none/active`)
          setRoomOpen(true)
          setIsAsept(true)
          setReSocet(!reSoket)
        })
    } catch (error) {
      console.error("Произошла ошибка при выполнении GET-запроса:", error)
    } finally {
      navigate(`/room/${uuid}`)
    }
  }

  const handleCloseRoom = async () => {
    try {
      const response = await axios
        .get(`${API_URL}api/v1/chat/rooms/close-room/${uuid}`, {
          headers: {
            Authorization: `Token ${access_token}`,
          },
        })
        .then((response) => {
          toast.success(response?.data?.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
          navigate(`/chat/none/active`)
          setOnModal(false)
          setReSocet(!reSoket)
        })
    } catch (error: any) {
      toast.error(error?.response?.data?.detail, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })

      setOnModal(false)
    }
  }
  const roomOut = async () => {
    try {
      const response = await axios
        .get(`${API_URL}api/v1/chat/rooms/refuse-room/${uuid}`, {
          headers: {
            Authorization: `Token ${access_token}`,
          },
        })
        .then((response) => {
          toast.success(response?.data?.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
          navigate(`/chat/none/active`)
          setOnModal(false)
          setReSocet(!reSoket)
        })
    } catch (error: any) {
      toast.error(error?.response?.data?.detail, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })

      setOnModal(false)
    }
  }

  const BackBtn = () => {
    return (
      <div style={{ color: "black" }} className="chat_main-leftBar-Back">
        <div>
          <img src={backIcon} alt="" />
        </div>
        <div onClick={() => navigate(-1)}>Вернутся</div>
      </div>
    )
  }

  console.log(messages);


  return (
    <main className="chat">
      {!uuid ? (
        <img style={{ margin: "0 auto" }} src={BackChat} alt="" />
      ) : (
        <>
          <div className="chat_main-rightChat">
            <div className="chat_main-rightChat-ContactInf">
              {/* <button className="button" onClick={() => setState(!state)}>
                  {" "}
                  <div>
                    <img src={backIcon} alt="" />
                  </div>{" "}
                </button> */}
              {uuid ? <BackBtn /> : ""}
              <div className="chat_main-rightChat-ContactInf-image">
                <img src={CurentUser?.user_detail?.avatar} alt="" />
              </div>
              <p>
                {CurentUser?.name} | {CurentUser?.user_detail?.first_name}
                {CurentUser?.user_detail?.last_name}
              </p>

              {isRoomOpen ? (
                <div
                  style={{ display: "flex", gap: "1rem" }}
                  onClick={() => setIsArhiv(true)}
                  className="chat_main-rightChat-ContactInf-folder"
                >
                  <button
                    onClick={() => setOnModal(true)}
                    className="GreenAddBtn Small"
                  >
                    Закрыть комнату
                  </button>
                  <button
                    onClick={() => roomOut()}
                    className="GreenAddBtn Small"
                  >
                    выйти из команаты
                  </button>
                </div>
              ) : null}
            </div>
            <br />

            <div className="chat_main-rightChat-action">
              <div
                className="chat_main-rightChat-action-canva"
                ref={chatContainerRef}
                onScroll={handleScroll}
              >
                {/* <ChatCanva /> */}
                {error ? (
                  <div className="asseptChat">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M0 5C0 3.61929 1.11929 2.5 2.5 2.5H17.5C18.8807 2.5 20 3.61929 20 5V15C20 16.3807 18.8807 17.5 17.5 17.5H2.5C1.11929 17.5 0 16.3807 0 15V5ZM2.5 3.75C1.80964 3.75 1.25 4.30964 1.25 5V5.27113L10 10.5211L18.75 5.27113V5C18.75 4.30964 18.1904 3.75 17.5 3.75H2.5ZM18.75 6.72887L12.8649 10.2599L18.75 13.8815V6.72887ZM18.7078 15.3233L11.6573 10.9845L10 11.9789L8.34272 10.9845L1.29221 15.3233C1.43468 15.8569 1.92144 16.25 2.5 16.25H17.5C18.0786 16.25 18.5653 15.8569 18.7078 15.3233ZM1.25 13.8815L7.1351 10.2599L1.25 6.72887V13.8815Z"
                          fill="#83C251"
                        />
                      </svg>
                    </div>
                    <h1
                      style={
                        isAsept === true
                          ? { display: "none" }
                          : { margin: "1rem auto" }
                      }
                    >
                      {error === "Неправильная страница"
                        ? "это все сообшения"
                        : error}{" "}
                    </h1>
                    {CurentUser?.status === "waiting" ? (
                      <button
                        onClick={() => HandleAsseptChat()}
                        style={
                          isAsept === true
                            ? { display: "none" }
                            : { margin: "1rem auto" }
                        }
                        className="GreenAddBtn Small"
                      >
                        Открыть чат чтобы написать сообщения.
                      </button>
                    ) : null}
                  </div>
                ) : null}
                {messages.map((item: any, idx) => (
                  <div className="mesegeBlock" key={item.uuid}>
                    <div
                      className=""
                      style={
                        item?.user === user?.id
                          ? { opacity: 0 }
                          : { opacity: 1 }
                      }
                    >
                      <div className="chatMessegeAvatar">
                        <div
                          style={
                            item?.user === user?.id
                              ? { opacity: 0 }
                              : { opacity: 1 }
                          }
                          className={
                            messages[idx]?.user === messages[idx - 1]?.user
                              ? "none"
                              : "flex"
                          }
                        >
                          <img src={CurentUser?.user_detail?.avatar} alt="" />
                          <p>{CurentUser?.user_detail?.first_name}</p>
                        </div>
                        <div
                          style={
                            item?.user === user?.id
                              ? { opacity: 1 }
                              : { opacity: 0 }
                          }
                          className={
                            messages[idx]?.user === messages[idx - 1]?.user
                              ? "none"
                              : "flex"
                          }
                        >
                          <p>{CurentUser?.user_detail?.first_name}</p>
                          <img src={CurentUser?.user_detail?.avatar} alt="" />
                        </div>
                      </div>
                      <div className="mesegeBlockR">
                        {" "}
                        <div className="mesegeBlockR-text">
                          {item.body}
                        </div>{" "}
                        <div></div>
                      </div>
                    </div>
                    <div
                      className=""
                      style={
                        item.user === user.id ? { opacity: 1 } : { opacity: 0 }
                      }
                    >
                      <div className="chatMessegeAvatar">
                        <div
                          style={
                            item?.user === user?.id
                              ? { opacity: 0 }
                              : { opacity: 1 }
                          }
                          className={
                            messages[idx]?.user === messages[idx - 1]?.user
                              ? "none"
                              : "flex"
                          }
                        >
                          <img
                            src={CurentUser?.dispatcher_detail?.avatar}
                            alt=""
                          />
                          <p>{CurentUser?.dispatcher_detail?.first_name}</p>
                        </div>
                        <div
                          style={
                            item?.user === user?.id
                              ? { opacity: 1 }
                              : { opacity: 0 }
                          }
                          className={
                            messages[idx]?.user === messages[idx - 1]?.user
                              ? "none"
                              : "flex"
                          }
                        >
                          <p>{CurentUser?.dispatcher_detail?.first_name}</p>
                          <img
                            src={CurentUser?.dispatcher_detail?.avatar}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="mesegeBlockR">
                        {" "}
                        <div></div>
                        <div className="mesegeBlockR-text">
                          {item?.body}
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                ))}
                {SocketMesege.map((item: any, idx) => (
                  <div className="mesegeBlock" key={item?.uuid}>
                    <div
                      className=""
                      style={
                        item?.user === user?.id
                          ? { opacity: 1 }
                          : { opacity: 0 }
                      }
                    >
                      <div className="chatMessegeAvatar">
                        <div
                          style={
                            item?.user === user?.id
                              ? { opacity: 0 }
                              : { opacity: 1 }
                          }
                          className={
                            SocketMesege[idx]?.user ===
                              SocketMesege[idx - 1]?.user
                              ? "none"
                              : "flex"
                          }
                        >
                          <img src={CurentUser?.user_detail?.avatar} alt="" />
                          <p>{CurentUser?.user_detail?.first_name}</p>
                        </div>
                        <div
                          style={
                            item?.user === user?.id
                              ? { opacity: 1 }
                              : { opacity: 0 }
                          }
                          className={
                            SocketMesege[idx]?.user ===
                              SocketMesege[idx - 1]?.user
                              ? "none"
                              : "flex"
                          }
                        >
                          <p>{CurentUser?.user_detail?.first_name}</p>
                          <img src={CurentUser?.user_detail?.avatar} alt="" />
                        </div>
                      </div>
                      <div className="mesegeBlockR">
                        {" "}
                        <div className="mesegeBlockR-text">
                          {item?.body}
                        </div>{" "}
                        <div></div>
                      </div>
                    </div>
                    <div
                      className=""
                      style={
                        item?.user === user?.id
                          ? { opacity: 0 }
                          : { opacity: 1 }
                      }
                    >
                      <div className="chatMessegeAvatar">
                        <div
                          style={
                            item?.user === user?.id
                              ? { opacity: 1 }
                              : { opacity: 0 }
                          }
                          className={
                            SocketMesege[idx]?.user ===
                              SocketMesege[idx - 1]?.user
                              ? "none"
                              : "flex"
                          }
                        >
                          <img
                            src={CurentUser?.dispatcher_detail?.avatar}
                            alt=""
                          />
                          <p>{CurentUser?.dispatcher_detail?.first_name}</p>
                        </div>
                        <div
                          style={
                            item?.user === user?.id
                              ? { opacity: 0 }
                              : { opacity: 1 }
                          }
                          className={
                            SocketMesege[idx]?.user ===
                              SocketMesege[idx - 1]?.user
                              ? "none"
                              : "flex"
                          }
                        >
                          <p>{CurentUser?.dispatcher_detail?.first_name}</p>
                          <img
                            src={CurentUser?.dispatcher_detail?.avatar}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="mesegeBlockR">
                        {" "}
                        <div></div>
                        <div className="mesegeBlockR-text">
                          {item?.body}
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <br />

              {isRoomOpen ? (
                <ChatComponent
                  reSoket={reSoket}
                  messages={SocketMesege}
                  setMessages={setSocketMesege}
                  scrollToBottom={scrollToBottom}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
      {/* <button onClick={scrollToBottom}>dsd</button> */}
      {onModal && (
        <div className="modal-background" onClick={() => setOnModal(!onModal)}>
          <div className={onModal ? "exitModal" : "none"}>
            <div
              className="exitModal__content"
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src={CloseIcon}
                alt=""
                onClick={() => setOnModal(!onModal)}
              />
              <div className="exitModal__content_title">
                Вы действительно хотите закрыть чат?
              </div>
              <div className="exitModal__content_btns">
                <button
                  className="outline"
                  onClick={() => setOnModal(!onModal)}
                >
                  Отмена
                </button>
                <button
                  onClick={handleCloseRoom}
                  style={{ background: "#EB5757", border: "none" }}
                  className="background"
                >
                  Закрыть
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


    </main>
  )
}

export default RoomPage

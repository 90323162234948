import { useEffect, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Select from "react-select"
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"

import {
  addTruckZone,
  clearTrucZone,
  deleteZone,
  editItem,
  editTruck,
  fetchTrucksById,
  setError,
  setSelectedZones,
  setZones,
} from "../../../../redux/cargos/trucks"
import { getInsurance, getTrucksType } from "../../../../utils/functions"
import { fetchAndSearchUsers } from "../../../../redux/users"
import { fetchCities } from "../../../../redux/cargos/cities"

import { fetchBrands } from "../../../../redux/brands"
import { fetchBrandModels } from "../../../../redux/brand-models"
import { fetchTransportLicenses } from "../../../../redux/cargos/transport-licenses"
import { fetchCountries } from "../../../../redux/cargos/countries"
import axios from "axios"
import { API_URL } from "../../../../api/api_config"
import { access_token } from "../../../../services"
import { fetchTransportTechConditions } from "../../../../redux/cargos/transport-tech-conditions"
import StarAddRating from "../../Add/ratingComponent"
import PlusIcon from "../../../../assets/svg/categoryPlusIcon.svg"
import MinusIcon from "../../../../assets/svg/minusCategoryIcon.svg"
import TruckZoneEdit from "../truckZones"

type Props = {
  type: any
  first_name: string
  last_name: string
  city: any
  passport: string
  released_year: number
  number: string
  lifting_capacity: any
  brand: object
  model: object
  registered_country: object
  insurance: any
  licenses: any
  tech_conditions: any
  user: any
}

const insuranseOption = [
  { id: 1, label: "Есть", value: "true" },
  { id: 2, label: "Нет", value: "false" },
]

type Props2 = {
  setModal: any
  id: any
  Edit?: any
  trucks?: any
}

type Props3 = {
  zone_capacity?: number
  zone_order?: any
}

export const EditTruck = ({ setModal, id, Edit }: Props2) => {
  const { brands, brandModels, countries, license, transConditions, trucks } =
    useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm()

  const [menu1, setMenu1] = useState(false)
  const [menu2, setMenu2] = useState(true)
  const [menu3, setMenu3] = useState(false)
  const [addZone, setAddZone] = useState(false)
  const [rating, setRating] = useState<any>()
  const [truckAvatar, setTruckAvatar] = useState()
  const [truckAvatarShow, setTruckAvatarShow] = useState<any>()
  const { image, brand, model } = trucks?.oneData

  useEffect(() => {
    dispatch(fetchCountries())
    dispatch(fetchBrands())
    dispatch(fetchBrandModels())
    dispatch(fetchTransportLicenses())
    dispatch(fetchTransportTechConditions())
    dispatch(fetchAndSearchUsers("driver"))
    dispatch(fetchCities(false))

    axios
      .get(`${API_URL}api/v1/cargo/transports/${trucks?.oneData?.id}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(editItem(response.data))
        dispatch(setZones(response?.data?.zones))
        setLisenseList(
          response.data?.licenses?.map((item: any) => ({
            value: item.name,
            label: item.name,
            id: item.id,
          })) || [],
        )
        setTechConditionsList(
          response.data?.tech_conditions?.map((item: any) => ({
            value: item.name,
            label: item.name,
            id: item.id,
          })) || [],
        )

        setValue("image", response?.data?.image)
        setValue(
          "insurance",
          response?.data?.insurance ? response?.data?.insurance : "Пусто",
        )
        setValue("lifting_capacity", response?.data?.lifting_capacity)
        setValue("rating", response?.data?.rating)
        setValue("released_year", response?.data?.released_year)
        setValue(
          "status",
          response?.data?.status ? response?.data?.status : "Пусто",
        )

        setValue("model", {
          value: response?.data?.model?.name,
          label: response?.data?.model?.name
            ? response?.data?.model?.name
            : "Пусто",
          id: response?.data?.model?.id,
        })
        setValue("brand", {
          value: response?.data?.brand?.name,
          label: response?.data?.brand?.name
            ? response?.data?.brand?.name
            : "Пусто",
          id: response?.data?.brand?.id,
        })

        setValue("registered_country", {
          value: response?.data?.registered_country?.name,
          label: response?.data?.registered_country?.name
            ? response?.data?.registered_country?.name
            : "Пусто",
          id: response?.data?.registered_country?.id,
        })
        setValue("type", response?.data?.type ? response?.data?.type : "Пусто")
        setRating(response.data.rating)
      })
  }, [])

  const [lisenseList, setLisenseList] = useState<any>([])
  const [techConditionsList, setTechConditionsList] = useState<any>([])

  const handleTruckImg = (e: any) => {
    setTruckAvatar(e.target.files[0])
    setTruckAvatarShow(URL.createObjectURL(e.target.files[0]))
  }

  let ins
  const onSubmit = (data: any) => {
    if (data.insurance?.value === "true") {
      ins = true
    } else {
      ins = false
    }
    dispatch(
      editTruck(
        {
          rating: rating,
          type: data.type?.value,
          brand: data.brand?.id,
          model: data.model?.id,
          released_year: data.released_year,
          registered_country: data.registered_country?.id,
          number: data.number,
          lifting_capacity: data.lifting_capacity,
          insurance: ins,
          licenses: lisenseList.map((item: any) => item.id),
          tech_conditions: techConditionsList.map((item: any) => item.id),
          user: data.user?.id,
          zones: trucks?.zones,
        },
        id,
        truckAvatar,
        () => {
          dispatch(fetchTrucksById(id))
          setModal(false)
          dispatch(clearTrucZone([]))
        },
      ),
    )
  }

  const handleDeleteZone = (id: any) => {
    dispatch(deleteZone(id))
  }

  const handleEditZone = (e: any) => {
    setMenu3(!menu3)
    dispatch(setSelectedZones(trucks.zones.find((item: any) => item.id === e)))
  }

  const addZones: SubmitHandler<Props3> = (data: any) => {
    dispatch(
      addTruckZone({
        lifting_capacity: +data.zone_capacity,
        order: data.zone_order?.id,
      }),
    )
    setAddZone(false)
    reset()
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px",
      cursor: "pointer",
      background: "#fff",
      marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent", // LC Green color on focus
      color: "var(--lc-black-800, #333)",
    }),
  }

  const truckTypesOptions = [
    { id: 1, label: "Стандарт", value: "truck" },
    { id: 2, label: "Тягач", value: "head" },
    { id: 3, label: "Прицеп", value: "trailer" },
    { id: 3, label: "Тандем", value: "semitrailer" },
  ]

  const zonesCount = [
    { value: 1, label: 1, id: 1 },
    { value: 2, label: 2, id: 2 },
    { value: 3, label: 3, id: 3 },
    { value: 4, label: 4, id: 4 },
    { value: 5, label: 5, id: 5 },
    { value: 6, label: 6, id: 6 },
    { value: 7, label: 7, id: 7 },
  ]

  return (
    <div className="truckScroll">
      <div className="catalog_list">
        <div>
          <div className="catalog_category_block">
            <div>Машина </div>
            <svg
              className={menu2 ? "" : "reverse"}
              onClick={() => setMenu2(!menu2)}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
                fill="#1A1A1A"
              />
            </svg>
          </div>
          <div className={menu2 ? "catalog_items" : "menunone"}>
            <div className="catalog_item">
              <div>Рейтинг:</div>
              <div style={{ width: "57%" }}>
                <StarAddRating
                  totalStars={5}
                  rating={rating}
                  setRating={setRating}
                />
              </div>
            </div>
            <div
              className="catalog_item"
              style={{ alignItems: "start", marginTop: "10px" }}
            >
              <div>
                Фото: 
              </div>
              <input
                onChange={(e) => handleTruckImg(e)}
                type="file"
                id="truckAvatarEdit"
                hidden
                accept="image/png, image/jpeg, image/webp, image/svg+xml"
              />
              <label
                htmlFor="truckAvatarEdit"
                style={{
                  width: "57%",
                  height: "184px",
                  border: "1px solid #CCC",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",

                  background: `url(${
                    truckAvatarShow ? truckAvatarShow : image
                  }) center no-repeat`,
                  objectFit: "cover",
                  backgroundSize: "cover",
                }}
              >
                {!truckAvatarShow && image ? null : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                  >
                    <path
                      d="M14.4167 2.66675L18.5834 2.66675C19.2761 2.66675 19.8334 3.22404 19.8334 3.91675V12.6667H24.4011C25.3282 12.6667 25.7917 13.7865 25.1355 14.4428L17.2136 22.3699C16.823 22.7605 16.1824 22.7605 15.7917 22.3699L7.85946 14.4428C7.20321 13.7865 7.66675 12.6667 8.59383 12.6667L13.1667 12.6667L13.1667 3.91675C13.1667 3.22404 13.724 2.66675 14.4167 2.66675ZM29.8334 22.2501V28.0834C29.8334 28.7761 29.2761 29.3334 28.5834 29.3334L4.41675 29.3334C3.72404 29.3334 3.16675 28.7761 3.16675 28.0834L3.16675 22.2501C3.16675 21.5574 3.72404 21.0001 4.41675 21.0001H12.0574L14.6095 23.5522C15.6563 24.599 17.3438 24.599 18.3907 23.5522L20.9428 21.0001H28.5834C29.2761 21.0001 29.8334 21.5574 29.8334 22.2501ZM23.3751 26.8334C23.3751 26.2605 22.9063 25.7917 22.3334 25.7917C21.7605 25.7917 21.2917 26.2605 21.2917 26.8334C21.2917 27.4063 21.7605 27.8751 22.3334 27.8751C22.9063 27.8751 23.3751 27.4063 23.3751 26.8334ZM26.7084 26.8334C26.7084 26.2605 26.2397 25.7917 25.6667 25.7917C25.0938 25.7917 24.6251 26.2605 24.6251 26.8334C24.6251 27.4063 25.0938 27.8751 25.6667 27.8751C26.2397 27.8751 26.7084 27.4063 26.7084 26.8334Z"
                      fill="#999999"
                    />
                  </svg>
                )}
                <div className="error">{errors && trucks.error?.image}</div>
              </label>
            </div>
            <div className="catalog_item">
              <div>
                Тип транспортного средства: 
              </div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <Controller
                  control={control}
                  {...register("type", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="type"
                      {...field}
                      placeholder={getTrucksType(trucks.oneData?.type)}
                      options={truckTypesOptions?.map((item: any) => ({
                        value: item.value,
                        label: item.label,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">{errors && trucks.error?.type}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                Марка: 
              </div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <Controller
                  control={control}
                  {...register("brand", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={brand?.name}
                      options={brands.data.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">{errors && trucks.error?.brand}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                Модель: 
              </div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <Controller
                  control={control}
                  {...register("model", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={model?.name}
                      options={brandModels.data.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">{errors && trucks.error?.model}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                Год выпуска: 
              </div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("released_year", { required: false })}
                  type="text"
                  style={{ color: "#808080" }}
                  defaultValue={trucks?.oneData?.released_year}
                />
                <div className="error">
                  {errors && trucks?.error?.released_year}
                </div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                В какой стране зарестрирована: 
              </div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <Controller
                  control={control}
                  {...register("registered_country", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={trucks?.oneData.registered_country?.name}
                      options={countries.data.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">
                  {errors && trucks?.error?.registered_country}
                </div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Номер: <u className="redStar">*</u></div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("number", { required: false })}
                  style={{ color: "#808080" }}
                  type="text"
                  defaultValue={trucks?.oneData?.number}
                />
                <div className="error">{errors && trucks?.error?.number}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                Страховка: 
              </div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <Controller
                  control={control}
                  {...register("insurance")}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      placeholder={getInsurance(trucks?.oneData?.insurance)}
                      {...field}
                      options={insuranseOption.map((item: any) => ({
                        value: item.value,
                        label: item.label,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">{errors && trucks.error?.insurance}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Документы и лицензи:</div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <Controller
                  control={control}
                  {...register("licenses", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={
                        lisenseList?.length !== 0
                          ? lisenseList?.map((item: any) => item?.label)
                          : "Пусто"
                      }
                      onChange={(e) => {
                        if (
                          !lisenseList.find((item: any) => item.id === e.id)
                        ) {
                          setLisenseList((languages: any) => [...languages, e])
                        }
                      }}
                      options={license.data.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">{errors && trucks.error?.licenses}</div>
              </div>
            </div>
            {lisenseList?.length !== 0 && (
              <div
                style={{
                  textAlign: "end",
                  display: "flex",
                  justifyContent: "end",
                  gap: "5px",
                }}
              >
                {lisenseList?.map((item: any) => (
                  <div
                    onClick={() => {
                      setLisenseList(
                        lisenseList.filter((tem: any) => tem.id !== item.id),
                      )
                    }}
                    style={{
                      padding: "6px 12px",
                      background: "#83C251",
                      fontSize: "12px",
                      color: "white",
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      gap: "4px",
                    }}
                  >
                    {item.value}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                ))}
              </div>
            )}
            <div className="catalog_item">
              <div>
                Техническое состояние и <br /> обслуживание транспортного <br />
                средств:
              </div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <Controller
                  control={control}
                  {...register("tech_conditions", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={
                        techConditionsList?.length !== 0
                          ? techConditionsList?.map((item: any) => item?.label)
                          : "Пусто"
                      }
                      options={transConditions.data.results?.map(
                        (item: any) => ({
                          value: item.name,
                          label: item.name,
                          id: item.id,
                        }),
                      )}
                      onChange={(e) => {
                        if (
                          !techConditionsList.find(
                            (item: any) => item.id === e.id,
                          )
                        ) {
                          setTechConditionsList((condition: any) => [
                            ...condition,
                            e,
                          ])
                        }
                      }}
                    />
                  )}
                />
                <div className="error">
                  {errors && trucks.error?.tech_conditions}
                </div>
              </div>
            </div>
            {techConditionsList?.length !== 0 && (
              <div
                style={{
                  textAlign: "end",
                  display: "flex",
                  justifyContent: "end",
                  gap: "5px",
                }}
              >
                {techConditionsList?.map((item: any) => (
                  <div
                    onClick={() => {
                      setTechConditionsList(
                        techConditionsList.filter(
                          (tem: any) => tem.id !== item.id,
                        ),
                      )
                    }}
                    style={{
                      padding: "6px 12px",
                      background: "#83C251",
                      fontSize: "12px",
                      color: "white",
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      gap: "4px",
                    }}
                  >
                    {item.value}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                ))}
              </div>
            )}

            <div style={{ marginTop: "12px" }}>
              <div className="catalog_item">
                <div>
                  Зоны: 
                </div>
                <div
                  style={{
                    width: "56.9%",
                    display: "flex",
                    gap: "12px",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    className="addZoneBtn"
                    onClick={() => setAddZone(!addZone)}
                  >
                    Добавить зону <img src={PlusIcon} alt="" />
                  </button>
                </div>
              </div>
              <div
                key={null}
                className={
                  trucks.zones?.length % 2 === 0
                    ? "categoryWidth1"
                    : "categoryWidth"
                }
              >
                {trucks.zones?.map((item: any) => (
                  <div
                    key={item.id}
                    className="addedItemCategory"
                    onClick={() => {
                      setMenu3(!menu3)
                      handleEditZone(item.id)
                    }}
                  >
                    {item.order}
                    <img
                      onClick={(e) => {
                        e.stopPropagation()
                        handleDeleteZone(item.id)
                      }}
                      src={MinusIcon}
                      alt=""
                    />
                  </div>
                ))}
              </div>
            </div>
            {addZone ? (
              <div className="catalog_items">
                <div className="catalog_item">
                  <div>
                    Грузоподъемность <u className="redStar">*</u>
                  </div>
                  <div style={{ width: "56.9%" }}>
                    <input
                      {...register("zone_capacity", {
                        required: true,
                      })}
                      placeholder="Грузоподъемность"
                      type="number"
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                    <div className="error">
                      {trucks?.error?.zones?.lifting_capacity}
                    </div>
                  </div>
                </div>
                <div className="catalog_item">
                  <div>
                    Номер зоны: <u className="redStar">*</u>
                  </div>
                  <div style={{ width: "56.9%" }}>
                    <Controller
                      control={control}
                      {...register("zone_order", { required: false })}
                      render={({ field }) => (
                        <Select
                          styles={customStyless}
                          id="favouriteAvenger"
                          {...field}
                          placeholder="oryadok 1"
                          options={zonesCount?.map((item: any) => ({
                            value: item.value,
                            label: item.label,
                            id: item.id,
                          }))}
                        />
                      )}
                    />
                    <div className="error">
                      {trucks?.error?.zones?.lifting_capacity}
                    </div>
                  </div>
                </div>
                <div className="form_button" style={{ width: "100%" }}>
                  <div style={{ width: "50%" }}>
                    <button
                      className="TruckBtn2"
                      onClick={() => setMenu3(false)}
                    >
                      Отмена
                    </button>
                  </div>
                  <div style={{ width: "50%" }}>
                    <button
                      className="Green-bth"
                      onClick={handleSubmit(addZones)}
                    >
                      Добавить зону
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M9.08333 4.87565V1.33398L3.875 1.33398C3.52865 1.33398 3.25 1.61263 3.25 1.95898L3.25 14.0423C3.25 14.3887 3.52865 14.6673 3.875 14.6673L12.625 14.6673C12.9714 14.6673 13.25 14.3887 13.25 14.0423L13.25 5.50065H9.70833C9.36458 5.50065 9.08333 5.2194 9.08333 4.87565ZM10.7807 10.5009L9.08333 10.5009L9.08333 12.5842C9.08333 12.8145 8.89687 13.0009 8.66667 13.0009H7.83333C7.60313 13.0009 7.41667 12.8145 7.41667 12.5842L7.41667 10.5009H5.71927C5.3474 10.5009 5.16172 10.0507 5.42578 9.78841L7.93672 7.29622C8.1099 7.12409 8.38958 7.12409 8.56276 7.29622L11.0737 9.78841C11.338 10.0507 11.1526 10.5009 10.7807 10.5009ZM13.0677 4.06836L10.5182 1.51628C10.401 1.39909 10.2422 1.33398 10.0755 1.33398H9.91667V4.66732L13.25 4.66732V4.50846C13.25 4.3444 13.1849 4.18555 13.0677 4.06836Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {menu3 ? <TruckZoneEdit setMenu3={setMenu3} /> : null}
          </div>
        </div>
      </div>

      <div
        className="form_button"
        style={{
          paddingTop: "10px",
          position: "absolute",
          left: "20px",
          right: "20px",
          bottom: "20px",
        }}
      >
        <button
          onClick={() => {
            setModal(false)
            dispatch(setError(false))
            dispatch(clearTrucZone([]))
          }}
          className="TruckBtn2"
        >
          Отмена
        </button>

        <button onClick={handleSubmit(onSubmit)} className="TruckBtn">
          Сохранить
        </button>
      </div>
    </div>
  )
}

const StockInfo = ({ stockData }: any) => {
  return (
    <div className="ClientsInfo__content_left-info">
      <div className="ClientsInfo__content_left-info--inner">
        <div>
          Название склада: <u>{stockData?.name || "Не указано!"}</u>
        </div>
        <div>
          Физический адрес склада:<u>{stockData?.address || "Не указано!"}</u>
        </div>
        <div>
          Площадь склада:<u>{stockData?.volume || "Не указано!"}</u>
        </div>
        <div>
          Типы складских помещений:
          <u>
            {stockData?.type?.length > 0
              ? stockData?.type.map((item: any) => item.name + "  ")
              : "Не указано!"}
          </u>
        </div>
        {/* <div>
          Условия хранения: <u>{stockData?.storage_conditions}</u>
        </div> */}
        <div>
          Доступность и безопасность склада:{" "}
          <u>{stockData?.is_available ? "есть" : "нет"}</u>
        </div>
        <div>
          Оборудование на складе:{" "}
          <u>
            {stockData?.equipment?.length > 0
              ? stockData?.equipment?.map((item: any) => item.name + " ")
              : "Не указано!"}
          </u>
        </div>
        <div>
          Услуги, предоставляемые на склад:
          <u>
            {stockData?.services?.length > 0
              ? stockData?.services?.map((item: any) => item.name + " ")
              : "Не указано!"}
          </u>
        </div>
      </div>
    </div>
  )
}

export default StockInfo

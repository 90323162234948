import React, { FC, useEffect, useState } from "react"
import Select from "react-select"
import { Controller, useForm } from "react-hook-form"

import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { fetchCountries } from "../../redux/cargos/countries"
import { fetchTrucks } from "../../redux/cargos/trucks"
import { fetchCities } from "../../redux/cargos/cities"

import TruckWayInfo from "./truckWayInfo/index"
import MapImage from "../../assets/img/map.png"
import LocationIcon from "../../assets/svg/location.svg"
import UpIcon from "../../assets/svg/upArrow.svg"
import CloseIcon from "../../assets/svg/closeIcon.svg"
import { ActualItem } from "../../pages/AddGoodsPage/ActualItem"
import { AdditionalItem } from "../../pages/AddGoodsPage/AdditionalItem"
import {
  editTransportations,
  getTransportation,
} from "../../redux/cargos/transportations"
import { editAllStocksSenderArr, fetchStocks } from "../../redux/cargos/stock"
import Assemblies from "../../pages/AddGoodsPage/Assemblies"
import axios from "axios"
import { setLoading } from "../../redux/loading"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import {
  addItemsLast as addItemsLastUsers,
  fetchAllDrivers,
} from "../../redux/users"
import { GreenBackgroundBtn } from "../btns"
import ReadAndUpdate from "./productWayInfo/readAndUpdate"
import AddProductWayInfo from "./productWayInfo/Add"
import { DownloadModal } from "../../pages/AddGoodsPage/DownloadModal"
import { editRoute } from "../../redux/cargos/cargo-routes"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"

interface Props {
  country: any
  select: any
  city: any
  arriving_date?: any
}

const options = [
  { id: 1, value: "odddption1", label: "Optionddd1" },
  { id: 2, value: "opddtion2", label: "Optiond 2" },
  { id: 3, value: "opdddtion3", label: "Option d3" },
]

const EditWayModal: FC<any> = ({ setEditWay, item }) => {
  const { transportations } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  const [menu1, setMenu1] = useState(true)
  const [menu2, setMenu2] = useState(true)
  const [addRoute, setAddRoute] = useState(false)

  const [currentPageUsers, setCurrentPageUsers] = useState(0)
  const [download, setDownload] = useState(false)
  const params = useParams()

  const { register, control } = useForm<Props>()

  useEffect(() => {
    dispatch(fetchCountries({}))
    dispatch(fetchTrucks({}))
    dispatch(fetchCities({}))
    dispatch(fetchStocks())
    dispatch(fetchAllDrivers({}))
    dispatch(
      getTransportation(item?.transportation, (data: any) => {
        dispatch(
          editAllStocksSenderArr(
            data?.routes?.map((item: any) => ({
              ...item?.stock,
              routeId: item?.id,
            })),
          ),
        )
      }),
    )
  }, [])

  const scrollHandlerUsers = (e: any) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/auth/users/`, {
        params: {
          roles: "driver",
          limit: 24,
          offset: currentPageUsers,
        },
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        setCurrentPageUsers(currentPageUsers + 1)
        dispatch(addItemsLastUsers(response.data.results))

        dispatch(setLoading(false))
      })
      .catch((er) => {})
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const setFunction = () => {
    dispatch(
      getTransportation(item?.transportation, (data: any) => {
        dispatch(
          editAllStocksSenderArr(
            data?.routes?.map((item: any) => ({
              ...item?.stock,
              routeId: item?.id,
            })),
          ),
        )
      }),
    )
  }

  let arrDayId: any = 0
  const handleArrivinDay = (e: any) => {
    if (arrDayId) {
      clearTimeout(arrDayId)
    }
    arrDayId = setTimeout(() => {
      dispatch(
        editTransportations(
          {
            arriving_date: e,
          },
          item?.transportation,
          setFunction,
        ),
      )
    }, 500)
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px",
      cursor: "pointer",
      background: "#fff",
      // marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent", // LC Green color on focus
      color: "var(--lc-black-800, #333)",
    }),
  }

  const sortedArray =
    transportations?.oneData?.transports?.[0]?.transport_assembly
      ?.slice()
      ?.sort((a: any, b: any) => {
        if (a?.item?.type === "head" || a?.item?.type === "truck") return -1
        if (b?.item?.type === "head" || a?.item?.type === "truck") return 1
        return 0
      })

  const handleOptionClick = (e: any) => {
    const lastOptionValue =
      transportations?.oneData?.routes[
        transportations?.oneData?.routes?.length - 1
      ]?.stock?.id

    editRout(e)
    if (e?.id === lastOptionValue) {
      setDownload(!download)
    }
  }

  const editRout = (e: any) => {
    axios
      .post(
        `${API_URL}api/v1/cargo/transportations/general-transports/change-location/${transportations?.oneData?.transports[0]?.id}/`,
        {
          route: e?.routeId,
        },
        {
          headers: {
            Authorization: `Token ${access_token}`,
          },
        },
      )
      .then((response: any) => {
        setFunction()
        if (
          transportations?.oneData?.routes[
            transportations?.oneData?.routes?.length - 1
          ]?.stock?.id !== e?.id
        ) {
          toast.success("Грузы успешно прибыли")
        }
        // dispatch(editItem(response.data))
      })
  }

  return (
    <div onClick={(e) => e.stopPropagation()} className="EditWayModal">
      <div className="EditWayModal__content">
        <div className="EditWayModal__content_left">
          <div className="EditWayModal_title">
            Редактировать Путь <div></div>
          </div>
          <div className="EditWayModal__content_left-inner">
            {/* {transportations?.oneData?.routes?.length > 3 ? null : (
              <div className="catalog_item">
                <div>Через город склада:</div>
                <div style={{ width: "57%" }}>
                  <Controller
                    control={control}
                    {...register("country", { required: false })}
                    render={({ field }) => (
                      <Select
                        styles={customStyless}
                        id="favouriteAvenger"
                        {...field}
                        // placeholder={"Выберите город склада"}
                        placeholder={
                          transportations?.oneData?.routes?.[1]?.stock?.city
                            ?.name || "Выберите город склада"
                        }
                        // onMenuScrollToBottom={(e: any) => scrollHandler(e)}
                        options={transportations?.oneData?.routes?.map(
                          (item: any) => ({
                            value: item.stock?.city?.name,
                            label: item.stock?.city?.name,
                            id: item.stock?.city?.id,
                          }),
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            )} */}
            <div className="catalog_item">
              <div>Текущее местоположение:</div>
              <div style={{ width: "57%" }}>
                <Controller
                  control={control}
                  {...register("country", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      onChange={(e: any) => handleOptionClick(e)}
                      // placeholder={"Выберите город склада"}
                      placeholder={
                        transportations?.oneData?.transports?.[0]
                          ?.current_location?.stock?.city?.name
                      }
                      // onMenuScrollToBottom={(e: any) => scrollHandler(e)}
                      options={transportations?.oneData?.routes?.map(
                        (item: any) => ({
                          value: item.stock?.city?.name,
                          label: item.stock?.city?.name,
                          id: item.stock?.id,
                          routeId: item?.id,
                        }),
                      )}
                    />
                  )}
                />
              </div>
            </div>
            <div
              className="EditWayModal__content_left-map"
              style={{ backgroundImage: `url(${MapImage})` }}
            >
              <div className="good_download_title">Карта временно</div>
              <div className="good_download_title">недоступна</div>
            </div>
            <div
              className="TrucksMore__content_right_content-way"
              style={{
                margin: "0",
                padding: "20px 12px",
                borderRadius: "0px",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              {transportations?.oneData?.routes?.map(
                (item: any, index: number) =>
                  !transportations?.oneData?.routes[index + 1] ? (
                    <>
                      <div
                        className="good_download_info_locations_title"
                        style={{ width: "fit-content%" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="12"
                          viewBox="0 0 13 12"
                          fill="none"
                        >
                          <path
                            d="M9.62433 6.70482C9.23131 7.50113 8.69934 8.29508 8.15432 9.00753C7.61099 9.71776 7.06582 10.3326 6.65576 10.7704C6.60127 10.8285 6.54924 10.8835 6.5 10.9351C6.45076 10.8835 6.39873 10.8285 6.34424 10.7704C5.93418 10.3326 5.38901 9.71776 4.84568 9.00753C4.30066 8.29508 3.76869 7.50113 3.37567 6.70482C2.9786 5.90031 2.75 5.14643 2.75 4.5C2.75 2.42893 4.42893 0.75 6.5 0.75C8.57107 0.75 10.25 2.42893 10.25 4.5C10.25 5.14643 10.0214 5.90031 9.62433 6.70482ZM6.5 12C6.5 12 11 7.73528 11 4.5C11 2.01472 8.98528 0 6.5 0C4.01472 0 2 2.01472 2 4.5C2 7.73528 6.5 12 6.5 12Z"
                            fill="#83C251"
                          />
                          <path
                            d="M6.5 6C5.67157 6 5 5.32843 5 4.5C5 3.67157 5.67157 3 6.5 3C7.32843 3 8 3.67157 8 4.5C8 5.32843 7.32843 6 6.5 6ZM6.5 6.75C7.74264 6.75 8.75 5.74264 8.75 4.5C8.75 3.25736 7.74264 2.25 6.5 2.25C5.25736 2.25 4.25 3.25736 4.25 4.5C4.25 5.74264 5.25736 6.75 6.5 6.75Z"
                            fill="#83C251"
                          />
                        </svg>
                        <div>{item?.stock?.city?.name}</div>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div
                        className="good_download_info_locations_title"
                        style={{ width: "100%" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="12"
                          viewBox="0 0 13 12"
                          fill="none"
                        >
                          <path
                            d="M9.62433 6.70482C9.23131 7.50113 8.69934 8.29508 8.15432 9.00753C7.61099 9.71776 7.06582 10.3326 6.65576 10.7704C6.60127 10.8285 6.54924 10.8835 6.5 10.9351C6.45076 10.8835 6.39873 10.8285 6.34424 10.7704C5.93418 10.3326 5.38901 9.71776 4.84568 9.00753C4.30066 8.29508 3.76869 7.50113 3.37567 6.70482C2.9786 5.90031 2.75 5.14643 2.75 4.5C2.75 2.42893 4.42893 0.75 6.5 0.75C8.57107 0.75 10.25 2.42893 10.25 4.5C10.25 5.14643 10.0214 5.90031 9.62433 6.70482ZM6.5 12C6.5 12 11 7.73528 11 4.5C11 2.01472 8.98528 0 6.5 0C4.01472 0 2 2.01472 2 4.5C2 7.73528 6.5 12 6.5 12Z"
                            fill="#83C251"
                          />
                          <path
                            d="M6.5 6C5.67157 6 5 5.32843 5 4.5C5 3.67157 5.67157 3 6.5 3C7.32843 3 8 3.67157 8 4.5C8 5.32843 7.32843 6 6.5 6ZM6.5 6.75C7.74264 6.75 8.75 5.74264 8.75 4.5C8.75 3.25736 7.74264 2.25 6.5 2.25C5.25736 2.25 4.25 3.25736 4.25 4.5C4.25 5.74264 5.25736 6.75 6.5 6.75Z"
                            fill="#83C251"
                          />
                        </svg>
                        <div>{item?.stock?.city?.name}</div>
                        <div className="good_download_info_locations_linear"></div>
                      </div>
                    </>
                  ),
              )}
            </div>
            <div
              className="catalog_category_block"
              onClick={() => setMenu1(!menu1)}
            >
              <div>Ваши машины</div>
              <img
                className={menu1 ? "reReverse" : "reverse"}
                src={UpIcon}
                alt=""
              />
            </div>
            <div className={menu1 ? "" : "menunone"}>
              {sortedArray?.map((trans: any) => (
                <TruckWayInfo key={item.id} item={trans} user={item} />
              ))}
            </div>
            <div
              className="catalog_category_block"
              onClick={() => setMenu2(!menu2)}
            >
              <div>Путь товаров</div>
              <img
                className={menu2 ? "reReverse" : "reverse"}
                src={UpIcon}
                alt=""
              />
            </div>
            <div className={menu2 ? "" : "menunone"}>
              {transportations?.oneData?.routes?.map((route: any) => (
                <ReadAndUpdate key={route.key} item={route} transport={item} />
              ))}
              {addRoute && (
                <AddProductWayInfo item={item} setModal={setAddRoute} />
              )}
              {params.status === "delivered" ? null : (
                <>
                  <button
                    className="addStock"
                    onClick={() => setAddRoute(!addRoute)}
                  >
                    Добавить еще склад
                  </button>
                  <div className="wayline"></div>
                </>
              )}

              <div className="EditWayModal__content_item">
                <div>День получения:</div>
                <div style={{ width: "58.7%" }}>
                  <input
                    onChange={(e: any) => handleArrivinDay(e?.target?.value)}
                    defaultValue={transportations?.oneData?.arriving_date?.slice(
                      0,
                      10,
                    )}
                    type="date"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="EditWayModal__content_right">
          <img
            className="closeIcon"
            onClick={() => setEditWay(false)}
            src={CloseIcon}
            alt=""
          />
          <div className="EditWayModal__content_right-inner">
            {transportations?.oneData?.transports?.map((item: any) => (
              <div style={{ width: "100%" }}>
                <Assemblies data={item?.transport_assembly} />
              </div>
            ))}
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div className="btn" onClick={() => setDownload(!download)}>
              <GreenBackgroundBtn
                title={params?.status === "delivered" ? "История" : params?.status === 'planning' ? 'В путь' : "Завершить"}
              />
            </div>
          </div>
        </div>
      </div>
      {download && (
        <DownloadModal
          finish={params?.status === "planning" ? false : true}
          setEditWay={setEditWay}
          modal={download}
          setModal={setDownload}
        />
      )}
    </div>
  )
}

export default EditWayModal

import { useEffect, useState } from "react"
import { useAppSelector } from "../../../redux/hooks"
import { Modal } from "../../Modal"
import { EditCargo } from "../../Forms/GoodsEdit/EditGoodsForm"
import { fetchCargo } from "../../../redux/cargos/cargo"
import { formatPhoneNumber } from "../../../utils/functions"

interface Props {
  item: any
  dispatcher?: any
}

const StockMoreClientCard = ({ item, dispatcher }: Props) => {
  const { cargo } = useAppSelector((state: any) => state)
  const [state, setState] = useState(true)
  const [modal, setModal] = useState(false)

  return (
    <>
      <div className="StockMoreClientCard">
        <header>
          <div className="StockMoreClientCard-col">
            <h1>
              {item?.user?.first_name
                ? item?.user?.first_name + " " + item?.user?.last_name
                : cargo?.oneData?.user?.first_name +
                  " " +
                  cargo?.oneData?.user?.last_name}
              :<u>{item?.id}</u>
            </h1>
          </div>
          <article className="StockMoreClientCard-edit StockMoreClientCard-col">
            <h2>{item?.sender?.arriving_day}</h2>
            {!dispatcher && (
              <button onClick={() => setModal(true)}>Редактировать</button>
            )}
          </article>
        </header>
        <div className="StockMoreClientCard-way">
          <h1>
            Найменование:
            <u style={{ whiteSpace: "nowrap" }}>{item?.total_weight} позиции</u>
          </h1>
          <div>
            <h1 style={{ whiteSpace: "nowrap" }}>{item?.sender?.city?.name}</h1>
            <div className="line"></div>
            <h1 style={{ whiteSpace: "nowrap" }}>
              {item?.receiver?.city?.name}
            </h1>
          </div>
        </div>
        <br />
        <section className="StockMoreClientCard-section">
          <div className="StockMoreClientCard-section-blok">
            <div className="StockMoreClientCard-section-blok-contact">
              <h1>Отправитель:</h1>
              <h2>{item?.sender?.name}</h2>
            </div>
            <div className="StockMoreClientCard-section-blok-contact">
              <h1>Телефон:</h1>
              <h2>{formatPhoneNumber(item?.sender?.phone)}</h2>
            </div>
          </div>
          <div className="StockMoreClientCard-section-line"></div>
          <div className="StockMoreClientCard-section-blok">
            <div className="StockMoreClientCard-section-blok-contact">
              <h1>Получатель:</h1>
              <h2>{item?.receiver?.name} </h2>
            </div>

            <div className="StockMoreClientCard-section-blok-contact">
              <h1>Телефон:</h1>
              <h2>{formatPhoneNumber(item?.receiver?.phone)}</h2>
            </div>
          </div>
        </section>
        <div className="StockMoreClientCard-goodinfo">
          <h1>
            Общие места: <u>{item?.total_spaces}</u>
          </h1>
          <div></div>
          <h1 className="StockMoreClientCard-goodinfo-border">
            Вес: <u>{item?.total_weight}</u>
          </h1>
        </div>
        <div
          style={
            state === true
              ? { height: "1px", overflow: "hidden", transition: "0.3s" }
              : { height: "", transition: "0.3s", overflow: "hidden" }
          }
          className="StockMoreClientCard-cards"
        >
          {item?.products?.map((i: any) => (
            <div key={i?.id} className="StockMoreClientCard-cards-card">
              <div className="StockMoreClientCard-cards-card-top">
                <h1>{i?.name}</h1>
                <div className="StockMoreClientCard-cards-card-top-line"></div>
                <h1>{i?.brand?.name}</h1>
              </div>
              <div className="StockMoreClientCard-cards-card-bottom">
                <div className="StockMoreClientCard-cards-card-bottom-top">
                  <h1>{i?.category.name}</h1>
                  <div className="StockMoreClientCard-cards-card-bottom-top-line"></div>
                  <h2>
                    Мест:
                    <u>{item?.total_spaces || cargo?.oneData?.total_spaces}</u>
                  </h2>
                </div>
                <div className="StockMoreClientCard-cards-card-bottom-top">
                  <h2>
                    Кол: <u>{i?.quantity}</u>
                  </h2>
                  <div className="StockMoreClientCard-cards-card-bottom-top-line"></div>
                  <h2>
                    Вес:<u>{i?.weight}</u>
                  </h2>
                </div>
              </div>
            </div>
          ))}
        </div>
        <br />
        <div
          className="StockMoreClientCard-goodinfo"
          style={{ padding: "0 2rem" }}
        >
          <h1>
            Дорожные расходы: <u>{item?.coefficient_delivery}</u>
          </h1>
          <div></div>
          <h1 className="StockMoreClientCard-goodinfo-border">
            Цена: <u>{item?.total_price}</u>
          </h1>
        </div>
        <article>
          <h1>{/* Вес умноженный на цену <u>286$</u> */}</h1>
        </article>
        <footer style={{ cursor: "pointer" }} onClick={() => setState(!state)}>
          <h1>
            Свернуть
            <svg
              style={
                state === true
                  ? { transform: "rotate(0.5turn)", transition: "0.2s" }
                  : {}
              }
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
                fill="#1A1A1A"
              />
            </svg>
          </h1>
        </footer>
      </div>
      <Modal
        title={"Редактировать груз"}
        modal={modal}
        width={"630px"}
        setModal={setModal}
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "98%",
            }}
          >
            <div className="catalog">
              <EditCargo setModal={setModal} id={item?.id} type={item} />
            </div>
          </div>
        }
      />
    </>
  )
}

export default StockMoreClientCard

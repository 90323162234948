import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { fetchproductCategories } from "../../../redux/cargos/product-categories"
import { fetchTransportAssignments } from "../../../redux/cargos/transport-assigments"
import {
  fetchCities,
  fetchCitiesFrom,
  fetchCitiesTo,
  getItems,
  getItemsFrom,
  getItemsTo,
} from "../../../redux/cargos/cities"
import { fetchCountries } from "../../../redux/cargos/countries"
import { fetchTariffs } from "../../../redux/cargos/tariffs"
import {
  editUser,
  fetchUserById,
  setError,
  setItemsById,
  userEditByIdDataId,
  userEditByIdDataIdAvatar,
} from "../../../redux/users"
import Avatar from "../../../assets/img/no-profile.jpeg"
import axios from "axios"
import { API_URL } from "../../../api/api_config"
import { access_token } from "../../../services"
import { fetchLanguages } from "../../../redux/cargos/languages"
import { getCurrentDate, getMinDate } from "../../clients/Add"

type Props = {
  setModal: any
  user_id: number
}
export const EditStaff = ({ setModal, user_id }: Props) => {
  const {
    users,
    productCategories,
    transAssignments,
    cities,
    countries,
    tariffs,
    languages,
    education,
  } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm()

  const [menu1, setMenu1] = useState(true)
  const [menu2, setMenu2] = useState(true)
  const [avatar, setAvatar] = useState<any>()
  const [avatarShow, setAvatarShow] = useState<any>()

  const handleAvatar = (e: any) => {
    dispatch(
      userEditByIdDataIdAvatar(
        {
          avatar: e.target.files[0],
        },
        users?.dataId?.id,
      ),
    )
    setAvatar(e.target.files[0])
    setAvatarShow(URL.createObjectURL(e.target.files[0]))
  }

  const setFunc = () => {
    setModal(false)
    reset()
  }

  const getCitiesByCountryFrom = (id: number) => {
    dispatch(fetchCitiesFrom(id))
  }

  const getCitiesByCountryTo = (id: number) => {
    dispatch(fetchCitiesTo(id))
  }

  const getCitiesByCountry = (id: number) => {
    dispatch(fetchCities(id))
  }

  useEffect(() => {
    dispatch(fetchproductCategories())
    dispatch(fetchTransportAssignments())
    dispatch(fetchCountries())
    dispatch(fetchTariffs())
    dispatch(fetchLanguages())

    dispatch(getItemsFrom([]))
    dispatch(getItemsTo([]))
    dispatch(getItems([]))

    dispatch(setError(false))

    axios
      .get(`${API_URL}api/v1/auth/users/${user_id}`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(setItemsById(response.data))
        setLanguagesList(
          response?.data?.staff?.languages?.map((item: any) => ({
            value: item.name,
            label: item.name,
            id: item.id,
          })) || [],
        )
        setValue("is_confirmed", response?.data?.is_confirmed)
        setValue("first_name", response?.data?.first_name)
        setValue("last_name", response?.data?.last_name)
        setValue("email", response?.data?.email)
        setValue("phone", response?.data?.phone)
        setValue("passport", response?.data?.staff?.passport)
        setValue("date_of_birth", response?.data?.staff?.date_of_birth || null)

        setValue("experience", response?.data?.staff?.experience)
        setValue(
          "experience_as_truck_driver",
          response?.data?.driver?.experience_as_truck_driver,
        )
        setValue(
          "license_external_transportation",
          response?.data?.driver?.license_external_transportation,
        )
        setValue(
          "info_criminal_record_or_fines",
          response?.data?.driver?.info_criminal_record_or_fines,
        )

        setValue("license_country", {
          value: response?.data?.driver?.license_country?.name,
          label: response?.data?.driver?.license_country?.name
            ? response?.data?.driver?.license_country?.name
            : "Страна лицензии",
          id: response?.data?.driver?.license_country?.id,
        })
        setValue("education", {
          value: response?.data?.driver?.education?.name,
          label: response?.data?.driver?.education?.name
            ? response?.data?.driver?.education?.name
            : "Образование",
          id: response?.data?.driver?.education?.id,
        })

        setValue("date_license", response?.data?.driver?.date_license)
        setValue("validity_license", response?.data?.driver?.validity_license)

        setValue("address", response.data.staff.address)
        setValue("city", response.data.staff.city)
        setValue("uuid", response?.data?.uuid)

        setValue("country", {
          value: response?.data?.staff?.city?.country_name,
          label: response?.data?.staff?.city?.country_name
            ? response?.data?.staff?.city?.country_name
            : "Страна",
          id: response?.data?.staff?.city?.country,
        })
        setValue("citizen", {
          value: response?.data?.staff?.city?.country_name,
          label: response?.data?.staff?.city?.country_name
            ? response?.data?.staff?.city?.country_name
            : "Гражданство",
          id: response?.data?.staff?.city?.country,
        })
        setValue("city", {
          value: response?.data?.staff?.city?.name,
          label: response?.data?.staff?.city?.name
            ? response?.data?.staff?.city?.name
            : "Город",
          id: response?.data?.staff?.city?.id,
        })
        getCitiesByCountry(response?.data?.staff?.country)
      })
  }, [])

  const [languagesList, setLanguagesList] = useState<any>([])

  const onSubmit = (data: any) => {
    dispatch(
      userEditByIdDataId(
        {
          staff: {
            passport: data?.passport,
            date_of_birth: data?.date_of_birth,
            address: data?.address,
            experience: data?.experience,
            citizen: data?.citizen?.id,
            city: data?.city?.id,
            languages: languagesList?.map((item: any) => item.id),
          },
          driver:
            users?.dataId?.role === "driver"
              ? {
                  date_license: data?.date_license,
                  validity_license: data?.validity_license,
                  experience_as_truck_driver: data?.experience_as_truck_driver,
                  license_external_transportation:
                    data?.license_external_transportation,
                  info_criminal_record_or_fines:
                    data?.info_criminal_record_or_fines,
                  license_country: data?.license_country?.id,
                  education: data?.education?.id,
                  transport: data?.transport,
                }
              : null,
          client: null,
          client_info: null,
          password:
            users?.dataId?.role === "driver" ? "254668kg" : data?.password,
          is_superuser: false,
          first_name: data?.first_name ? data?.first_name : null,
          last_name: data?.last_name ? data?.last_name : null,
          is_staff: false,
          is_active: true,
          phone: data?.phone,
          email: data?.email,
          role: users?.dataId?.role,
          is_confirmed: data?.is_confirmed,
          uuid: data?.uuid,
        },
        users?.dataId?.id,
        setFunc,
      ),
    )
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      padding: "2px 5px",
      borderRadius: "10px",
      cursor: "pointer",
      marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent",
      color: "var(--lc-black-800, #333)",
    }),
  }

  const Cancel = () => {
    setModal(false)
  }

  return (
    <>
      <div className={menu1 ? "catalog_list" : ""}>
        <div
          className={"catalog_category_block"}
          onClick={() => setMenu1(!menu1)}
        >
          <div>Основные</div>
          <svg
            className={menu1 ? "reverse" : ""}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
              fill="#1A1A1A"
            />
          </svg>
        </div>

        <div
          className={menu1 ? "catalog_items" : "none"}
          style={{
            paddingBottom: "20px",
          }}
        >
          <div className="catalog_item">
            <div>Статус:</div>
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                width: "56.7%",
              }}
            >
              <input
                className="custom-checkbox"
                placeholder="Пусто"
                {...register("is_confirmed", { required: false })}
                style={{
                  width: "15px",
                  cursor: "pointer",
                  borderRadius: "0px",
                  padding: "9px",
                  borderColor: "#83C251",
                }}
                type="checkbox"
                id="status_label"
              />
              <label
                style={{
                  cursor: "pointer",
                }}
                htmlFor="status_label"
              >
                Подтвержденный
              </label>
            </div>
          </div>
          <div
            className="catalog_item"
            style={{ alignItems: "start", marginTop: "10px" }}
          >
            <div>Фото:</div>
            <input
              onChange={(e) => handleAvatar(e)}
              type="file"
              id="avatar"
              hidden
              accept="image/png, image/jpeg, image/webp, image/svg+xml"
            />

            <label
              htmlFor="avatar"
              style={{
                width: "56.7%",
                height: "184px",
                border: "1px solid #CCC",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                objectFit: "cover",
              }}
            >
              <img
                src={
                  users?.dataId?.avatar
                    ? users?.dataId?.avatar
                    : avatarShow
                    ? avatarShow
                    : Avatar
                }
                style={{
                  width: "100%",
                  height: "184px",
                  cursor: "pointer",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
                alt=""
              />
            </label>
          </div>
          <div className="catalog_item">
            <div>
              UID: <u className="redStar">*</u>
            </div>
            <div
              style={{
                width: "57.1%",
              }}
            >
              <input
                placeholder="Пусто"
                {...register("uuid", { required: false })}
                type="text"
              />
              <div className="error">{users?.error?.uuid}</div>
            </div>
          </div>
          <div className="catalog_item">
            <div>
              Имя: <u className="redStar">*</u>
            </div>
            <div
              style={{
                width: "57.1%",
              }}
            >
              <input
                placeholder="Пусто"
                {...register("first_name", { required: false })}
                type="text"
              />
              <div className="error">{users?.error?.first_name}</div>
            </div>
          </div>
          <div className="catalog_item">
            <div>
              Фамилия: <u className="redStar">*</u>
            </div>
            <div
              style={{
                width: "57.1%",
              }}
            >
              <input
                placeholder="Пусто"
                {...register("last_name", { required: false })}
                type="text"
              />
              <div className="error">{users?.error?.last_name}</div>
            </div>
          </div>
          <div className="catalog_item">
            <div>Email:</div>
            <div
              style={{
                width: "57.1%",
              }}
            >
              <input
                placeholder="Пусто"
                {...register("email", { required: false })}
                type="text"
              />
              <div className="error">{users?.error?.email}</div>
            </div>
          </div>
          <div className="catalog_item">
            <div>
              Номер телефона: <u className="redStar">*</u>
            </div>
            <div
              style={{
                width: "57.1%",
              }}
            >
              <input
                placeholder="Пусто"
                {...register("phone", { required: false })}
                type="text"
              />
              <div className="error">{users?.error?.phone}</div>
            </div>
          </div>

          {
            <div className="catalog_item">
              <div>Адрес:</div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("address", { required: false })}
                  type="text"
                />
                <div className="error">{users?.error?.staff?.address}</div>
              </div>
            </div>
          }

          {
            <div
              className="catalog_item"
              style={{
                alignItems: "start",
              }}
            >
              <div>Город:</div>
              <div
                style={{
                  width: "57%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <Controller
                  control={control}
                  {...register("country", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      onChange={(e) => getCitiesByCountry(e.id)}
                      placeholder="Страна"
                      options={countries?.data?.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <Controller
                  control={control}
                  {...register("city", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder="Город"
                      isDisabled={cities?.data?.length !== 0 ? false : true}
                      options={cities?.data?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">{users?.error?.staff?.city}</div>
              </div>
            </div>
          }
        </div>

        {users?.dataId?.role === "driver" ? (
          <div>
            <div
              className="catalog_category_block"
              onClick={() => setMenu2(!menu2)}
            >
              <div>Другие driver</div>
              <svg
                className={menu2 ? "reverse" : ""}
                // onClick={() => setMenu2(!menu2)}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
                  fill="#1A1A1A"
                />
              </svg>
            </div>
            <div className={menu2 ? "catalog_items" : "none"}>
              <div className="catalog_item">
                <div>Дата лицензии:</div>
                <div
                  style={{
                    width: "57.1%",
                  }}
                >
                  <input
                    placeholder="0к.м"
                    onWheel={(e) => e.currentTarget.blur()}
                    {...register("date_license", { required: false })}
                    type="date"
                  />
                  <div className="error">
                    {users?.error?.driver?.date_license}
                  </div>
                </div>
              </div>

              <div className="catalog_item">
                <div>Срок лицензии:</div>
                <div
                  style={{
                    width: "57.1%",
                  }}
                >
                  <input
                    placeholder="0к.м"
                    onWheel={(e) => e.currentTarget.blur()}
                    {...register("validity_license", { required: false })}
                    type="date"
                  />
                  <div className="error">
                    {users?.error?.driver?.validity_license}
                  </div>
                </div>
              </div>

              <div className="catalog_item">
                <div>Опыт работы как водитель:</div>
                <div
                  style={{
                    width: "57.1%",
                  }}
                >
                  <input
                    placeholder="Пусто"
                    {...register("experience_as_truck_driver", {
                      required: false,
                    })}
                    type="text"
                  />
                  <div className="error">
                    {users?.error?.driver?.experience_as_truck_driver}
                  </div>
                </div>
              </div>

              <div className="catalog_item">
                <div>Лицензия на внешние перевозки:</div>
                <div
                  style={{
                    width: "57.1%",
                  }}
                >
                  <div
                    // onClick={() => setChoosedWorker(worker)}
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      padding: "10px 0px",
                    }}
                  >
                    <input
                      className="custom-checkbox"
                      {...register("license_external_transportation")}
                      style={{
                        width: "15px",
                        cursor: "pointer",
                        borderRadius: "15px",
                        padding: "9px",
                        borderColor: "#83C251",
                      }}
                      id="licence_driver"
                      type="checkbox"
                    />

                    <label
                      htmlFor="licence_driver"
                      style={{ cursor: "pointer" }}
                    >
                      Лицензия
                    </label>
                  </div>
                  <div className="error">
                    {users?.error?.driver?.license_external_transportation}
                  </div>
                </div>
              </div>
              <div className="catalog_item">
                <div>Информация о судимости или штрафах: </div>
                <div
                  style={{
                    width: "57.1%",
                  }}
                >
                  <div
                    // onClick={() => setChoosedWorker(worker)}
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      padding: "10px 0px",
                    }}
                  >
                    <input
                      className="custom-checkbox"
                      {...register("info_criminal_record_or_fines")}
                      style={{
                        width: "15px",
                        cursor: "pointer",
                        borderRadius: "15px",
                        padding: "9px",
                        borderColor: "#83C251",
                      }}
                      id="licence_driver2"
                      type="checkbox"
                    />

                    <label
                      htmlFor="licence_driver2"
                      style={{ cursor: "pointer" }}
                    >
                      Штраф
                    </label>
                  </div>
                  <div className="error">
                    {users?.error?.driver?.info_criminal_record_or_fines}
                  </div>
                </div>
              </div>

              <div
                className="catalog_item"
                style={{
                  alignItems: "start",
                }}
              >
                <div>Страна лицензии:</div>
                <div
                  style={{
                    width: "57%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <Controller
                    control={control}
                    {...register("license_country", { required: false })}
                    render={({ field }) => (
                      <Select
                        styles={customStyless}
                        id="favouriteAvenger"
                        {...field}
                        placeholder="Страна"
                        options={countries?.data?.results?.map((item: any) => ({
                          value: item.name,
                          label: item.name,
                          id: item.id,
                        }))}
                      />
                    )}
                  />
                  <div className="error">
                    {users?.error?.driver?.license_country}
                  </div>
                </div>
              </div>

              <div
                className="catalog_item"
                style={{
                  alignItems: "start",
                }}
              >
                <div>Образование:</div>
                <div
                  style={{
                    width: "57%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <Controller
                    control={control}
                    {...register("education", { required: false })}
                    render={({ field }) => (
                      <Select
                        styles={customStyless}
                        id="favouriteAvenger"
                        {...field}
                        placeholder="Образование"
                        options={education?.data?.map((item: any) => ({
                          value: item.name,
                          label: item.name,
                          id: item.id,
                        }))}
                      />
                    )}
                  />
                  <div className="error">{users?.error?.driver?.education}</div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div>
          <div
            className="catalog_category_block"
            onClick={() => setMenu2(!menu2)}
          >
            <div>Другие</div>
            <svg
              className={menu2 ? "reverse" : ""}
              // onClick={() => setMenu2(!menu2)}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
                fill="#1A1A1A"
              />
            </svg>
          </div>
          <div className={menu2 ? "catalog_items" : "none"}>
            <div
              className="catalog_item"
              style={{
                alignItems: "center",
              }}
            >
              <div>Гражданство:</div>
              <div
                style={{
                  width: "57%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <Controller
                  control={control}
                  {...register("citizen", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder="Страна"
                      options={countries?.data?.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">{users?.error?.staff?.citizen}</div>
              </div>
            </div>

            <div
              className="catalog_item"
              style={{
                alignItems: "center",
              }}
            >
              <div>Знание языков:</div>
              <div
                style={{
                  width: "57%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <Controller
                  control={control}
                  {...register("languages", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder="Страна"
                      onChange={(e) => {
                        if (
                          !languagesList.find((item: any) => item.id === e.id)
                        ) {
                          setLanguagesList((languages: any) => [
                            ...languages,
                            e,
                          ])
                        }
                      }}
                      options={languages?.data?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">{users?.error?.staff?.languages}</div>
              </div>
            </div>

            {languagesList?.length !== 0 && (
              <div
                style={{
                  textAlign: "end",
                  display: "flex",
                  justifyContent: "end",
                  gap: "5px",
                }}
              >
                {languagesList?.map((item: any) => (
                  <div
                    onClick={() => {
                      setLanguagesList(
                        languagesList.filter((tem: any) => tem.id !== item.id),
                      )
                    }}
                    style={{
                      padding: "6px 12px",
                      background: "#83C251",
                      fontSize: "12px",
                      color: "white",
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      gap: "4px",
                    }}
                  >
                    {item.value}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                ))}
              </div>
            )}

            <div className="catalog_item">
              <div>Паспорт:</div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("passport", { required: false })}
                  type="text"
                />
                <div className="error">{users?.error?.staff?.passport}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Дата рождения:</div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="0к.м"
                  {...register("date_of_birth", { required: false })}
                  type="date"
                  min={getMinDate()}
                  max={getCurrentDate()}
                />
                <div className="error">
                  {users?.error?.staff?.date_of_birth}
                </div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Опыт работы:</div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("experience", { required: false })}
                  type="text"
                />
                <div className="error">{users?.error?.staff?.experience}</div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="form_button"
          style={{
            paddingTop: "20px",
            position: "absolute",
            bottom: "20px",
            left: "20px",
            right: "20px",
          }}
        >
          <button className="TruckBtn2" onClick={Cancel}>
            Отмена
          </button>
          <button
            onClick={handleSubmit(onSubmit)}
            type="submit"
            className="TruckBtn"
          >
            Сохранить
          </button>
        </div>
      </div>
    </>
  )
}

import React from "react"
import { Outlet } from "react-router-dom"
import POPShipment from "../components/shipment/Modal/POPShipment"

interface Props {
  show: boolean
}

const Outlets = ({ show }: Props) => {
  return (
    <div className={!show ? "Outlet" : "Outlet adaptive"}>
      <div className="Outlet_inner">
        <Outlet />
      </div>
    </div>
  )
}

export default Outlets

import React from "react"
import Header from "../components/header"
import SideBar from "../components/sideBar"
import Outlets from "./outlet"

const MainLayout = () => {
  const [show, setShow] = React.useState(false)
  return (
    <>
      <Header show={show} />
      <div>
        <SideBar show={show} setShow={setShow} />
        <div className="wrapper">
          <Outlets show={show} />
        </div>
      </div>
    </>
  )
}

export default MainLayout

import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { RootState } from "../../redux/store"
import { fetchNewsById } from "../../redux/news"
import ReactQuill from "react-quill"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { GreenBackgroundBtn } from "../../components/btns"

const NewsMorePage = () => {
  const { id } = useParams()
  const navigate: any = useNavigate()

  const [news, setNews] = useState<any>()

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    axios
      .get(`${API_URL}api/v1/news/${id}`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then(
        (response: any) => {
          setNews(response.data)
          setLoading(false)
        },
        (error: any) => {
          console.log(error)
          setLoading(false)
        },
      )
      .finally(() => setLoading(false))
  }, [])

  if (news === undefined || news.length === 0) {
    return (
      <div></div>
    )
  }

  console.log(news.content);
  

  return (
    <>
      <div onClick={() => navigate(-1)}>
        <GreenBackgroundBtn title="Назад" />
      </div>
      <div className="NewsMorePage">

        <>

          {" "}
          <h1>{news.title}</h1>
          <br />
          <div className="NewsMorePage-image">
            <div className="NewsMorePage-image-date" style={{ color: 'white', padding: '2px 8px' }}>{news?.released_date?.toString().slice(8, 10)}.{news?.released_date?.toString().slice(5, 7)}.{news?.released_date?.toString().slice(0, 4)}</div>
            <img src={news.image} alt="" />
          </div>
          <br />
          <ReactQuill
            value={news.content}
            readOnly={true}
            theme="snow"
            modules={{ toolbar: false }}
          />
        </>


        <br />
        <br />
        <Link to={"/news"}>
          <button>Вернутся</button>
        </Link>
      </div>
    </>
  )
}

export default NewsMorePage

import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import SearchBox from "../../components/searchBox"
import { GreenAddBtn, GreenBackgroundBtn } from "../../components/btns"
import GraphicSliderIcon from "../../assets/svg/graficSlider.svg"
import AddGoodsForm from "../../components/Forms/Goods/AddGoodsForm"
import EditIcon from "../../assets/svg/editIcon.svg"
import DeleteIcon from "../../assets/svg/deleteIcon.svg"
import TruckImg from "../../assets/img/Rectangle 413.png"
import TruckImage from "../../assets/svg/Track.svg"
import TruckLayoutImg from "../../assets/img/Track.png"
import Terms from "../../components/mapsCard/terms"
import ApproveIcon from "../../assets/svg/approveIcon.svg"
import LocationIcon from "../../assets/svg/location.svg"
import CollapseImg from "../../assets/svg/topArrow.svg"
import ZonaImg from "../../assets/svg/downarrow.svg"
import ZonaImg2 from "../../assets/svg/topArrow.svg"
import CargoCard from "../../components/cargos/index"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { addItemsLast, fetchTrucksById } from "../../redux/cargos/trucks"
import { Modal } from "../../components/Modal"
import { AddTruck } from "../../components/trucks/Add"
import { EditTruck } from "../../components/trucks/Edit/Good"
import { fetchCargos } from "../../redux/cargos/cargo"
import {
  formatPhoneNumber,
  getInsurance,
  getTruckStatus,
} from "../../utils/functions"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { setLoading } from "../../redux/loading"
import { fetchDriver } from "../../redux/users"
import { fetchTransportationsAttributes } from "../../redux/cargos/transportations-atributes"
import { Good_stock_zone } from "../AddGoodsPage/Good_stock_zone"
import { Zone } from "../AddGoodsPage/Zone"
import { AddCargo } from "../../components/cargos/Add"

const index = () => {
  const { cargo, trucks, auth, transportatAttrib } = useAppSelector(
    (state: any) => state,
  )
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const navigate = useNavigate()

  const [onModal, setOnModal] = useState(false)
  const [onModal2, setOnModal2] = useState(false)
  const [active, setActive] = useState(true)
  const [zones, setZones] = useState(false)
  const [rating, setRating] = useState<any>()
  let totalStars = 5

  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    dispatch(fetchTrucksById(id))
    setRating(trucks?.oneData?.rating)
    dispatch(
      fetchCargos({
        limit: 24,
        offset: currentPage,
      }),
    )
  }, [])

  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    if (
      target.scrollHeight - (target.scrollTop + target.clientHeight) < 1 &&
      trucks?.oneData?.results?.length < totalCount
    ) {
      dispatch(setLoading(true))

      axios
        .get(`${API_URL}api/v1/auth/cargo/`, {
          params: {
            limit: 24,
            offset: currentPage + 1,
          },
          headers: {
            Authorization: `Token ${access_token} `,
          },
        })
        .then((response: any) => {
          setCurrentPage(currentPage + 1)
          dispatch(addItemsLast(response.data.results))
        })
        .catch((er) => {})
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }

  const [currentPageAtr, setCurrentpageAtr] = useState(1)
  const [totalCountAtr, setTotalCountAtr] = useState<any>()

  const AfterGet = (data: any) => {
    setTotalCountAtr(data.count)
  }

  useEffect(() => {
    dispatch(
      fetchTransportationsAttributes(
        {
          limit: 24,
          offset: currentPageAtr,
        },
        AfterGet,
      ),
    )
  }, [])

  const scrollableDivRefAtr = useRef<HTMLDivElement | null>(null)

  const scrollHandlerAtr = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    if (
      target.scrollHeight - (target.scrollTop + target.clientHeight) < 1 &&
      transportatAttrib?.data?.results?.length < totalCountAtr
    ) {
      dispatch(setLoading(true))
      axios
        .get(`${API_URL}api/v1/cargo/transportations/attributes`, {
          params: {
            limit: 24,
            offset: currentPageAtr + 1,
            // checked: false,
          },
          headers: {
            Authorization: `Token ${access_token} `,
          },
        })
        .then((response: any) => {
          dispatch(addItemsLast(response.data.results))
        })
        .catch((er) => {
          dispatch(setLoading(false))
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }

  return (
    <section
      className="ClientsInfo"
      style={{
        height: "100%",
      }}
    >
      <div className="spaceBetween">
        <div className="Trucks__header">
          <div className="flex" style={{ gap: "15px" }}>
            <div onClick={() => setOnModal(!onModal)}>
              {auth?.data?.role !== "dispatcher" && (
                <GreenAddBtn title="Добавить груз" />
              )}
            </div>
          </div>
        </div>
        <div className="Clients__header_btns">
          <div className="title" style={{ paddingLeft: "0px" }}>
            Грузовики
          </div>
        </div>
      </div>
      <div
        className="TrucksMore__content"
        style={{
          borderColor: "#83C251",
          margin: "0",
          justifyContent: "normal",
          gap: "40px",
        }}
      >
        <div className="TrucksMore__content_inner">
          <div
            className={
              trucks.oneData?.status === "waiting"
                ? "TrucksMore__content_left left2"
                : "TrucksMore__content_left"
            }
          >
            <div
              className="ClientsInfo__content_left"
              style={{ height: "100%" }}
            >
              <div className="ClientsInfo__content_left_btns">
                <div onClick={() => navigate(-1)}>
                  <GreenBackgroundBtn title="Назад" />
                </div>
                {auth?.data?.role !== "dispatcher" && (
                  <button
                    onClick={() => setOnModal2(!onModal2)}
                    className="ClientsInfo__content_left_btns-editBtn"
                  >
                    <img src={EditIcon} alt="" />
                  </button>
                )}

                <div onClick={() => setActive(!active)}>
                  <GreenBackgroundBtn
                    title={!active ? "Розвернуть" : "Свернуть"}
                  />
                </div>
              </div>
              <div>
                <div className="TrucksMore__content_left-info-titles">
                  <div>
                    {trucks?.oneData?.driver?.user?.first_name +
                      " " +
                      trucks?.oneData?.driver?.user?.last_name}
                  </div>
                  <div>
                    <div>{trucks?.oneData?.number}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex">
                  <div className="ClientsCard__inner_info-ids--titlesStatus">
                    Статус:
                    <u
                      className={
                        trucks?.oneData?.status === "on_way" ? "" : "waiting"
                      }
                    >
                      {trucks?.oneData?.status
                        ? getTruckStatus(trucks?.oneData?.status)
                        : "status"}
                    </u>
                  </div>
                  <div
                    className="ClientsCard__inner_info-ids--titlesStatus"
                    style={{ display: "flex", gap: "5px" }}
                  >
                    Рейтинг:
                    <div>
                      {[...Array(totalStars)]?.map((_, index) => (
                        <span
                          key={index}
                          style={{
                            cursor: "pointer",
                            fontSize: "16px",
                            color:
                              trucks?.oneData?.rating <= index
                                ? "grey"
                                : "gold",
                          }}
                        >
                          ★
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="ClientsCard__inner_info-ids--titlesId">
                    ID: <u>{trucks?.oneData?.id}</u>
                  </div>
                  {trucks?.oneData?.trailer ? (
                    <div className="ClientsCard__inner_info-ids--titlesBalance">
                      Номер прицепа: <u>1</u>
                    </div>
                  ) : null}
                </div>
                <div className={active ? "flex" : "none"}>
                  <div>
                    <div className="ClientsCard__inner_info-ids--titlesId">
                      Вместимость фуры:
                      <u>{trucks?.oneData?.lifting_capacity} к.м.</u>
                    </div>
                    <div className="border"></div>
                    <div className="ClientsCard__inner_info-ids--titlesId">
                      Зарегистрирован:{" "}
                      <u>{trucks?.oneData?.created_at?.slice(0, 10)}</u>
                    </div>
                  </div>
                  {/* <div>
                    <div className="ClientsCard__inner_info-ids--titlesId">
                      Сейчас свободно: <u>000000 к.м.</u>
                    </div>
                    <div className="border"></div>
                    <div className="ClientsCard__inner_info-ids--titlesId">
                      Последняя доставка: <u>0000000000</u>
                    </div>
                  </div> */}
                </div>
              </div>
              <div
                className={active ? "ClientsInfo__content_left-info" : "none"}
                style={{ height: "calc(100% - 337px)" }}
              >
                <div className="ClientsInfo__content_left-info--inner">
                  <div>
                    Город:
                    <u>
                      {trucks.oneData?.driver?.user?.staff?.city?.name ||
                        "Не указано "}
                    </u>
                  </div>
                  <div>
                    Адрес:{" "}
                    <u>
                      {" "}
                      {trucks?.oneData?.driver?.user?.staff?.address ||
                        "Не указано "}{" "}
                    </u>
                  </div>
                  <div>
                    Номер телефона:{" "}
                    <u>
                      {formatPhoneNumber(
                        trucks?.oneData?.driver?.user?.phone,
                      ) || "Не указано "}
                    </u>
                  </div>
                  <div>
                    Языки:
                    <u>
                      {trucks?.oneData?.driver?.user?.staff?.languages
                        ?.map((item: any) => item.name)
                        ?.join("  ") || "Не указано "}
                    </u>
                  </div>
                  <div>
                    Электронная почта водителя:{" "}
                    <u>{trucks.oneData?.driver?.user?.email || "Не указано "}</u>
                  </div>
                  <div>
                    Дата рождения:{" "}
                    <u>{trucks.oneData?.driver?.user?.staff?.date_of_birth || "Не указано "}</u>
                  </div>
                  <div>
                    Заграничный пасспорт:{" "}
                    <u>{trucks.oneData?.driver?.user?.staff?.passport || "Не указано "}</u>
                  </div>
                  <div>
                    Гражданство:{" "}
                    <u>{trucks.oneData?.driver?.user?.staff?.citizen?.name || "Не указано "}</u>
                  </div>
                  <div>
                    Опыт работы водителем грузового автомобиля:{" "}
                    <u>{trucks.oneData?.driver?.experience_as_truck_driver || "Не указано "}</u>
                  </div>
                  <div>
                    Судимость:
                    <u>
                      {getInsurance(
                        trucks.oneData?.driver?.info_criminal_record_or_fines,
                      ) || "Не указано "}
                    </u>
                  </div>
                </div>
              </div>
              {trucks.oneData?.current_transportation === null ? null : (
                <div
                  className={!active ? "TrucksMore__content_center" : "none"}
                >
                  <div className="TrucksMore__content_center-title">
                    Дополнительная информация:
                  </div>

                  <div className="TrucksMore__content_center-aboutTruck">
                    <div>
                      Общий количество: <u>2452</u>
                    </div>
                    <div>
                      Общее место: <u>4352</u>
                    </div>
                    <div>
                      Общий сумма: <u>$252</u>
                    </div>
                  </div>
                  <div className="good_indicator">
                    <div className="good_indicator_titles">
                      <div className="good_indicator_title">Общий объем:</div>
                      <div className="good_indicator_mini_title">
                        {trucks?.oneData?.volume}
                      </div>
                    </div>
                    <div className="good_indicator_items">
                      <div className="good_indicator_grey good_indicator_position"></div>
                      <div className="good_indicator_green good_indicator_position">
                        <div className="good_indicator_green_title">
                          <div className="good_indicator_green_number good_indicator_position_green">
                            25т
                          </div>
                        </div>
                      </div>
                      <div className="good_indicator_orange good_indicator_position"></div>
                    </div>
                  </div>

                  <div
                    onScroll={scrollHandlerAtr}
                    ref={scrollableDivRefAtr}
                    className="TrucksMore__content_center-terms"
                    style={{ height: "calc(100% - 400px)" }}
                  >
                    {transportatAttrib?.data?.results?.map((item: any) => (
                      <Terms key={item?.id} item={item} />
                    ))}
                  </div>
                  {/* <div className="btn" onClick={() => setEditWay(!editWay)}>
                    {auth?.data?.role !== "dispatcher" && (
                      <GreenBackgroundBtn title="Перегрузить" />
                    )}
                  </div> */}
                </div>
              )}
            </div>
          </div>
          {trucks.oneData?.current_transportation === null ? null : (
            <>
              <div
                className={active ? "TrucksMore__content_center" : "none"}
                style={{ width: "100%", maxWidth: "360px" }}
              >
                <div className="TrucksMore__content_center_inner">
                  <div className="TrucksMore__content_center-title">
                    Фото грузовика
                  </div>

                  <img
                    className="TrucksMore__content_center-truckLayoutImg"
                    src={
                      trucks?.oneData?.image ? trucks?.oneData?.image : TruckImg
                    }
                    alt=""
                  />
                  <div className="good_select_zone">
                    <div className="good_select_zone_items_inner">
                      <Good_stock_zone
                        type={trucks?.oneData?.type}
                        elem={
                          <div
                            style={{
                              position: "absolute",
                              display: "flex",
                              justifyContent: "space-between",
                              right:
                                trucks?.oneData?.type === "truck"
                                  ? "62px"
                                  : trucks?.oneData?.type === "semitrailer"
                                  ? "14px"
                                  : trucks?.oneData?.type === "trailer"
                                  ? "0"
                                  : "0",
                              top: "-20px",
                            }}
                          >
                            {trucks?.oneData?.type !== "head" &&
                              trucks?.oneData?.zones?.map((item: any) => (
                                <Zone number={item?.order} item={item} />
                              ))}
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div
            className={
              active
                ? "TrucksMore__content_right right2"
                : "TrucksMore__content_right"
            }
          >
            <div className="TrucksMore__content_right_content">
              <div className="TrucksMore__content_right_content-title">
                Грузы
              </div>
              {trucks?.oneData?.status === "on_way" ? (
                <div
                  className={
                    active ? "TrucksMore__content_right_content-way" : "none"
                  }
                >
                  <div className="AllStockInfo__header_adress-city--title">
                    <img src={LocationIcon} alt="" />
                    <div className="city"> Кыргыстан</div>
                  </div>
                  <div className="wayLine"></div>
                  <div className="AllStockInfo__header_adress-city--title">
                    <img src={LocationIcon} alt="" />
                    <div className="city">Казакстан</div>
                  </div>
                  <div className="wayLine"></div>
                  <div className="AllStockInfo__header_adress-city--title">
                    <img src={LocationIcon} alt="" />
                    <div className="city">Россия</div>
                  </div>
                </div>
              ) : null}

              <div className="TrucksMore__content_right_content-collapse">
                Свернуть <img src={CollapseImg} alt="" />
              </div>
              <div className="TrucksMore__content_right_content-zones">
                <div>
                  <div className="TrucksMore__content_right_content-zones--firstZone">
                    <div className="zoneTitle">Зона 1</div>
                    <div
                      className="zoneWeight"
                      onClick={() => setZones(!zones)}
                    >
                      621кг
                      <img src={!zones ? ZonaImg : ZonaImg2} alt="" />
                    </div>
                  </div>

                  <div className={!zones ? "zone_flex" : "none"}>
                    <div
                      className="zone_flex-inner"
                      ref={scrollableDivRef}
                      onScroll={scrollHandler}
                    >
                      {/* {trucks?.oneData?.current_transportation?.cargo_general_transport?.products?.map(
                          (item: any) => (
                            <CargoCard
                              dispatcher={
                                auth?.data?.role === "dispatcher" ? true : false
                              }
                              key={item.id}
                              item={item}
                            />
                          ),
                        )}

                        {trucks?.oneData?.current_transportation
                          ?.cargo_general_transport?.products?.length === 0 && (
                          <div className="error">В грузовике нет груза</div>
                        )} */}
                      <div style={{ textAlign: "center" }}>
                        В грузовике нет груза
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        width={"630px"}
        modal={onModal}
        title={"Добавить новый груз"}
        setModal={setOnModal}
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div className="catalog">
              <AddCargo setModal={setOnModal} modal={onModal} />
            </div>
          </div>
        }
      />

      <Modal
        title={"Редактирования грузовика"}
        modal={onModal2}
        width={"630px"}
        setModal={setOnModal2}
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "98%",
            }}
          >
            <div className="catalog">
              <EditTruck setModal={setOnModal2} id={id} />
            </div>
          </div>
        }
      />
    </section>
  )
}

export default index

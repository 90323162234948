import React, { useEffect, useRef, useState } from "react"
import axios from "axios"

import PaymentConfirmation from "../PaymentConfirmation"
import ShipAddedNewUser from "../AddedNewUser"
import ReqestToNewGoods from "../ReqestToNewGoods"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { fetchUserById } from "../../../redux/users"
import { fetchCities } from "../../../redux/cargos/cities"
import { access_token } from "../../../services"
import { API_URL } from "../../../api/api_config"
import {
  addItemsLast,
  fetchOwnNotifications,
  makeRead,
  makeReadAsMana,
} from "../../../redux/auth/notifications"
import PaymentConfirmationShow from "./paymentImage/paymentShow"
import { editCargoStatus } from "../../../redux/cargos/cargo"
import { setLoading } from "../../../redux/loading"
import { Link, Navigate, useNavigate } from "react-router-dom"

const POPShipment = ({ setIsHovered, isHovered }: any) => {
  const { notifications, auth } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [active, setActive] = useState(false)

  const AfterGet = (data: any) => {
    setTotalCount(data?.count)
  }

  useEffect(() => {
    dispatch(
      fetchOwnNotifications(
        {
          limit: 12,
          offset: currentPage,
          is_read: active,
        },
        AfterGet,
      ),
    )
  }, [])

  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    if (
      target.scrollHeight - (target.scrollTop + target.clientHeight) < 1 &&
      notifications?.data?.results?.length < totalCount
    ) {
      dispatch(setLoading(true))
      axios
        .get(`${API_URL}api/v1/auth/notifications/own/`, {
          params: {
            limit: 24,
            offset: currentPage + 1,
            is_read: active,
          },
          headers: {
            Authorization: `Token ${access_token} `,
          },
        })

        .then((response: any) => {
          dispatch(addItemsLast(response.data.results))
        })

        .catch((er) => {})
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }

  const cargoAllow = (id: any, setOnModal: any) => {
    const setFunc = () => {
      setOnModal(false)
      window.location.reload()
    }
    dispatch(editCargoStatus({ status: "in_stock" }, id, setFunc))
  }

  const makeReadAsManager = (item: any) => {
    if (auth.data?.role === "manager") {
      dispatch(makeReadAsMana(item, setIsHovered))
    } else {
      dispatch(makeRead(item, setIsHovered))
    }
  }

  const navigate = useNavigate()

  return (
    <div
      onClick={() => navigate("notifications")}
      className="POPShipment"
      style={{
        height: "70%",
        maxWidth: "700px",
        width: "100%",
      }}
    >
      <div
        className="POPShipment_scroll"
        style={{}}
        onScroll={scrollHandler}
        ref={scrollableDivRef}
        onClick={(e) => e.stopPropagation()}
      >
        {notifications?.data?.results?.map((item: any) => (
          <>
            {item.type === "new_cargo" && (
              <>
                <ReqestToNewGoods
                  setIsHovered={setIsHovered}
                  cargoAllow={cargoAllow}
                  key={item.id}
                  item={item}
                />
              </>
            )}
            {item.type === "new_user" && (
              <>
                <ShipAddedNewUser
                  onMouseOver={(id: number) => {}}
                  setIsHovered={setIsHovered}
                  item={item}
                  key={item.id}
                  id={item?.id}
                />
              </>
            )}
            {item.type === "new_receipt" &&
              item.inner_payload?.status === "waiting" && (
                <>
                  <PaymentConfirmation
                    isHovered={isHovered}
                    item={item}
                    key={item.id}
                  />
                </>
              )}
          </>
        ))}

        {notifications?.data?.results?.length === 0 && (
          <div style={{ textAlign: "center" }}>Пусто</div>
        )}
      </div>
    </div>
  )
}

export default POPShipment

import React from 'react'
import { useAppSelector } from '../../redux/hooks';
import { Good_stock_zone } from './Good_stock_zone';
import Select from 'react-select'
import AssemblyItem from './AssemblyItem';

const Assemblies = ({ data }: any) => {

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            padding: '2px 5px',
            borderRadius: "10px",
            cursor: "pointer",
            marginRight: "1px",
            width: '100% - 135px', // Width set to 100%

        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused
                ? "rgba(157, 194, 81, 0.5)"
                : "transparent",
            color: "var(--lc-black-800, #333)",
        }),
    }
    const { trucks } = useAppSelector((state) => state)
    const sortedArray = data.slice().sort((a: any, b: any) => {
        if (a?.item?.type === 'head' || a?.item?.type === 'truck') return -1;
        if (b?.item?.type === 'head' || a?.item?.type === 'truck') return 1;
        return 0;
    });
    
    return (
        <div>
            {sortedArray?.map((item: any) => <AssemblyItem item={item} truck={item}/>)}
        </div>
    )
}

export default Assemblies

import React, { useEffect, useRef, useState } from "react"
import axios from "axios"

import { GreenAddBtn } from "../../components/btns"
import ClientsCard from "../../components/clients/clientsCard"
import PaymentIcon from "../../assets/svg/paymentIcon.svg"
import GraphicSliderIcon from "../../assets/svg/graficSlider.svg"
import { Link } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { API_URL } from "../../api/api_config"
import { AddClient } from "../../components/clients/Add"
import { Modal } from "../../components/Modal"
import { access_token } from "../../services"
import { AddCargo } from "../../components/cargos/Add"
import {
  addItemsLast,
  editUserById,
  fetchAllUsers,
  userDelete,
  userEditById,
} from "../../redux/users"
import { setLoading } from "../../redux/loading"
import PlusIcon from "../../assets/svg/plusIcon.svg"
import { setCategories } from "../../redux/cargos/cargo"

const Index = () => {
  const { users, countries, auth } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const [open, setOpen] = React.useState(true)
  const [modal, setModal] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [active, setActive] = useState(true)

  const AfterGet = (data: any) => {
    setTotalCount(data?.count)
  }

  useEffect(() => {
    dispatch(
      fetchAllUsers(
        {
          limit: 24,
          offset: currentPage,
          roles: "client",
          is_active: true,
          is_confirmed: active,
        },
        AfterGet,
      ),
    )
  }, [active])

  const editUserByIdF = (data: any, id: any, setModal: any) => {
    const AfterEdit = () => {
      setModal(false)
    }
    dispatch(userEditById(data, id, AfterEdit))
  }

  const DeleteUser = (id: any, setExit: any) => {
    const AfterDelete = () => {
      setExit(false)
    }
    dispatch(userDelete(id, AfterDelete))
  }

  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    if (
      target.scrollHeight - (target.scrollTop + target.clientHeight) < 1 &&
      users?.data?.results?.length < totalCount
    ) {
      dispatch(setLoading(true))
      axios
        .get(`${API_URL}api/v1/auth/users/`, {
          params: {
            limit: 24,
            offset: currentPage + 1,
            roles: "client",
            is_active: true,
            is_confirmed: active,
          },
          headers: {
            Authorization: `Token ${access_token} `,
          },
        })
        .then((response: any) => {
          setCurrentPage(currentPage + 1)
          dispatch(addItemsLast(response.data.results))
        })
        .catch((er) => { })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }

  // Динамическа пагинация закончилась

  let timeID: any = 0

  const handleSearch = (event: any) => {
    setCurrentPage(1)
    if (timeID) {
      clearTimeout(timeID)
    }
    timeID = setTimeout(() => {
      dispatch(
        fetchAllUsers(
          {
            limit: 24,
            offset: currentPage,
            roles: "client",
            search: event,
            is_active: true,
            is_confirmed: active,
          },
          AfterGet,
        ),
      )
    }, 500)
  }
  const setScrollPosition = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = 0
    }
  }

  const activeFilter = () => {
    setScrollPosition()
    setCurrentPage(1)
    setActive(!active)
  }

  const scrollContainerRef: any = useRef()

  useEffect(() => {
    const handleWheel = (event: any) => {
      scrollContainerRef.current.scrollLeft += event.deltaY
      event.preventDefault()
    }

    const scrollContainer = scrollContainerRef.current
    scrollContainer.addEventListener("wheel", handleWheel)

    return () => {
      scrollContainer.removeEventListener("wheel", handleWheel)
    }
  }, [])

  const [onGoodsModal, setOnGoodsModal] = useState(false)

  console.log(auth)

  return (
    <div className="Clients">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "20px",
          gap: "32px",
        }}
      >
        <div
          className="Clients__header"
          ref={scrollContainerRef}
          style={{
            width: "100%",
          }}
        >
          <div className="flex">
            {auth?.data?.role !== "dispatcher" && (
              <div onClick={() => {
                setOnGoodsModal(!onGoodsModal)
                dispatch(editUserById({}))
                dispatch(setCategories([]))
              }}>
                <button className="stock_button">
                  <img src={PlusIcon} alt="" />
                  <div className="button_title"> Добавить груз</div>
                </button>
              </div>
            )}

            <input
              className="SearchBox__input"
              type="text"
              placeholder="Поиск"
              onChange={(e) => handleSearch(e.target.value)}
            />

            {open ? (
              <>
                <div className="Clients__header_check">
                  <div>
                    <input
                      onClick={activeFilter}
                      type="checkbox"
                      className="custom-checkbox"
                      id="my-label1"
                    />
                    <label htmlFor="my-label1" className="custom-label">
                      Не подтвержденный
                    </label>
                  </div>
                </div>
              </>
            ) : null}
            <button className="SearchBox_btn" onClick={() => setOpen(!open)}>
              <img src={GraphicSliderIcon} alt="" />
            </button>
          </div>

          <div className="Clients__header_btns">
            <Link to="/clients/payment-history">
              <button className="stock_button">
                <img src={PaymentIcon} alt="" />
                <div className="button_title">Оплата</div>
              </button>
            </Link>
            {auth?.data?.role !== "dispatcher" && (<div onClick={() => setModal(true)}>
              <button className="stock_button">
                <img src={PlusIcon} alt="" />
                <div className="button_title2">Добавить</div>
              </button>
            </div>)}
          </div>
        </div>
        <div className="title" style={{ marginTop: "8px" }}>
          Клиенты
        </div>
      </div>
      {/* пагинация тут  */}
      <div className="client_height">
        <div
          className="Clients__cards"
          ref={scrollableDivRef}
          onScroll={scrollHandler}
        >
          {users?.data?.results?.map((item: any) => (
            <ClientsCard
              dispatcher={auth?.data?.role === "dispatcher" ? true : false}
              key={item.id}
              item={item}
              userEdit={editUserByIdF}
              DeleteUser={DeleteUser}
            />
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: "absolute",
            top: "0",
            bottom: "auto",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {users?.data?.results?.length !== 0 ? "" : "Пусто!"}
            </div>
          </div>
        </div>
      </div>
      <Modal
        width={"630px"}
        modal={modal}
        title={"Создать нового клиента"}
        setModal={setModal}
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "98%",
            }}
          >
            <div className="catalog">
              <AddClient active={active} staff={false} setModal={setModal} />
            </div>
          </div>
        }
      />
      <Modal
        width={"630px"}
        modal={onGoodsModal}
        title={"Добавить новый груз"}
        setModal={setOnGoodsModal}
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div className="catalog">
              <AddCargo setModal={setOnGoodsModal} modal={onGoodsModal} />
            </div>
          </div>
        }
      />
    </div>
  )
}

export default Index

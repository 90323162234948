import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
// import { editUserOfTruck } from "../../../redux/trucks"
interface AddDriverFormProps {
  setOnPop: Function
  setCreate: Function
}

type Props = {
  last_name: string
  number: string
  citizenship: string
  passport: number
  city: string
  address: string
  phone_number: number
  driver_licence_country: string
}

const AbutDeriverForm: React.FC<AddDriverFormProps> = ({
  setOnPop,
  setCreate,
}) => {
  const { trucks } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  const { users, number } = trucks?.data

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Props>()
  const onSubmit: SubmitHandler<Props> = (data) => {
    // dispatch(
    //   editUserOfTruck({
    //     last_name: data.last_name,
    //     number: data.number,
    //     citizenship: data.citizenship,
    //     passport: data.passport,
    //     city: data.city,
    //     address: data.address,
    //     phone_number: data.phone_number,
    //     driver_licence_country: data.driver_licence_country,
    //   }),
    // )
    setOnPop(false)
    setCreate(true)
    reset()
    console.log("users", trucks?.dataOfUser)
  }

  return (
    <div className="form_inputs">
      {/* <div className="form_inputs-input">
        <div>
          <span>Рейтинг:</span>
        </div>
        <div>
          <input
            className="form_inputs-input-checkbox"
            type="checkbox"
            name=""
            id=""
          />
        </div>
      </div> */}
      {/* <br /> */}
      {/* <div className="form_inputs-input">
        <div>
          <p>Фото:</p>
        </div>
        <div>
          <article className="form_inputs-input-upload">
            <input type="file" name="" id="" />
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Font Awesome Free/Solid/download">
                <path
                  id="Vector"
                  d="M14.4165 2.66669L18.5832 2.66669C19.2759 2.66669 19.8332 3.22398 19.8332 3.91669V12.6667H24.4009C25.328 12.6667 25.7915 13.7865 25.1353 14.4427L17.2134 22.3698C16.8228 22.7604 16.1821 22.7604 15.7915 22.3698L7.85921 14.4427C7.20296 13.7865 7.6665 12.6667 8.59359 12.6667L13.1665 12.6667L13.1665 3.91669C13.1665 3.22398 13.7238 2.66669 14.4165 2.66669ZM29.8332 22.25V28.0834C29.8332 28.7761 29.2759 29.3334 28.5832 29.3334L4.4165 29.3334C3.7238 29.3334 3.1665 28.7761 3.1665 28.0834L3.1665 22.25C3.1665 21.5573 3.7238 21 4.4165 21H12.0571L14.6092 23.5521C15.6561 24.599 17.3436 24.599 18.3905 23.5521L20.9425 21H28.5832C29.2759 21 29.8332 21.5573 29.8332 22.25ZM23.3748 26.8334C23.3748 26.2604 22.9061 25.7917 22.3332 25.7917C21.7603 25.7917 21.2915 26.2604 21.2915 26.8334C21.2915 27.4063 21.7603 27.875 22.3332 27.875C22.9061 27.875 23.3748 27.4063 23.3748 26.8334ZM26.7082 26.8334C26.7082 26.2604 26.2394 25.7917 25.6665 25.7917C25.0936 25.7917 24.6248 26.2604 24.6248 26.8334C24.6248 27.4063 25.0936 27.875 25.6665 27.875C26.2394 27.875 26.7082 27.4063 26.7082 26.8334Z"
                  fill="#999999"
                />
              </g>
            </svg>
          </article>
        </div>
      </div> */}
      <div className="form_inputs-input">
        <div>
          <p>ФИО: <u className="redStar">*</u></p>
        </div>
        <div>
          <input
            defaultValue={users?.last_name}
            className="form_inputs-input-text"
            type="text"
            {...register("last_name")}
          />
        </div>
      </div>

      <div className="form_inputs-input">
        <div>
          <p>Номер машины: <u className="redStar">*</u></p>
        </div>
        <div>
          <input
            defaultValue={number}
            className="form_inputs-input-text"
            type="text"
            {...register("number")}
          />
        </div>
      </div>
      {/* <div className="form_inputs-input">
        <div>
          <p>ID:</p>
        </div>
        <div>
          <input className="form_inputs-input-text" type="text" name="" id="" />
        </div>
      </div> */}
      {/* <div className="form_inputs-input">
        <div>
          <p>Дата рождения:</p>
        </div>
        <div>
          <input className="form_inputs-input-text" type="date" name="" id="" />
        </div>
      </div> */}
      <div className="form_inputs-input">
        <div>
          <p>Гражданство: <u className="redStar">*</u></p>
        </div>
        <div>
          <input
            className="form_inputs-input-text"
            type="text"
            defaultValue={users?.citizenship}
            {...register("citizenship")}
          />
        </div>
      </div>
      <div className="form_inputs-input">
        <div>
          <p>Номер паспорта: <u className="redStar">*</u></p>
        </div>
        <div>
          <input
            className="form_inputs-input-text"
            type="number"
            onWheel={(e) => e.currentTarget.blur()}
            defaultValue={users?.passport}
            {...register("passport")}
          />
        </div>
      </div>
      <div className="form_inputs-input">
        <div>
          <p>Город: <u className="redStar">*</u></p>
        </div>
        <div>
          <input
            className="form_inputs-input-text"
            defaultValue={users?.city}
            {...register("city")}
          />
        </div>
      </div>
      <div className="form_inputs-input">
        <div>
          <p>Адрес: <u className="redStar">*</u></p>
        </div>
        <div>
          <input
            className="form_inputs-input-text"
            defaultValue={users?.address}
            {...register("address")}
          />
        </div>
      </div>
      <div className="form_inputs-input">
        <div>
          <p>Номер телефона: <u className="redStar">*</u></p>
        </div>
        <div>
          <input
            className="form_inputs-input-text"
            type="text"
            defaultValue={users?.phone_number}
            {...register("phone_number")}
          />
        </div>
      </div>
      <div className="form_inputs-input">
        <div>
          <p>Страна выдачи прав: <u className="redStar">*</u></p>
        </div>
        <div>
          <input
            className="form_inputs-input-text"
            type="text"
            defaultValue={users?.driver_licence_country}
            {...register("driver_licence_country")}
          />
        </div>
      </div>
      <br />
      {errors && <div className="error">{trucks.error?.detail}</div>}
      <div className="form_button">
        <button onClick={handleSubmit(onSubmit)} className="TruckBtn">
          Сохранить
        </button>
        <button onClick={() => setOnPop(false)} className="TruckBtn2">
          Отмена
        </button>
      </div>
      <br />
    </div>
  )
}

export default AbutDeriverForm

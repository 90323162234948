import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import { useForm } from "react-hook-form"
import {
  editTransportationsAttribute,
  fetchTransportationsAttributes,
} from "../../../../redux/cargos/transportations-atributes"

const BetweenRouteEdit = ({ setOpen, item }: any) => {
  const { transportatAttrib } = useAppSelector((state) => state)
  const dispatch = useAppDispatch()
  const { register, handleSubmit, reset } = useForm<any>()

  const setFunc = () => {
    dispatch(fetchTransportationsAttributes({ limit: 24, offset: 1 }))
    setOpen(false)
    reset()
  }

  const onSubmit = (data: any) => {
    dispatch(
      editTransportationsAttribute(
        {
          name: data.name,
          date: data?.date,
        },
        item?.id,
        setFunc,
      ),
    )
  }

  return (
    <div className="catalog_items">
      <div className="catalog_item">
        <div>Описание:</div>
        <div style={{ width: "67.6%" }}>
          <input
            {...register("name", { required: false })}
            defaultValue={item?.name}
            type="text"
            placeholder="Пусто"
          />
          <div className="error">{transportatAttrib?.error?.name}</div>
        </div>
      </div>
      <div className="catalog_item">
        <div>Дата:</div>
        <div style={{ width: "67.6%" }}>
          <input
            {...register("date", { required: false })}
            defaultValue={item?.date}
            type="date"
          />
          <div className="error">{transportatAttrib?.error?.date}</div>
        </div>
      </div>
      <div className="error">{transportatAttrib?.error?.detail}</div>
      <div
        className="form_button"
        style={{ width: "100%", marginBottom: "10px" }}
      >
        <div style={{ width: "50%" }}>
          <button className="TruckBtn2" onClick={() => setOpen(false)}>
            Отмена
          </button>
        </div>
        <div style={{ width: "50%" }}>
          <button className="Green-bth" onClick={handleSubmit(onSubmit)}>
            Изменить
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                d="M9.08333 4.87565V1.33398L3.875 1.33398C3.52865 1.33398 3.25 1.61263 3.25 1.95898L3.25 14.0423C3.25 14.3887 3.52865 14.6673 3.875 14.6673L12.625 14.6673C12.9714 14.6673 13.25 14.3887 13.25 14.0423L13.25 5.50065H9.70833C9.36458 5.50065 9.08333 5.2194 9.08333 4.87565ZM10.7807 10.5009L9.08333 10.5009L9.08333 12.5842C9.08333 12.8145 8.89687 13.0009 8.66667 13.0009H7.83333C7.60313 13.0009 7.41667 12.8145 7.41667 12.5842L7.41667 10.5009H5.71927C5.3474 10.5009 5.16172 10.0507 5.42578 9.78841L7.93672 7.29622C8.1099 7.12409 8.38958 7.12409 8.56276 7.29622L11.0737 9.78841C11.338 10.0507 11.1526 10.5009 10.7807 10.5009ZM13.0677 4.06836L10.5182 1.51628C10.401 1.39909 10.2422 1.33398 10.0755 1.33398H9.91667V4.66732L13.25 4.66732V4.50846C13.25 4.3444 13.1849 4.18555 13.0677 4.06836Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export default BetweenRouteEdit

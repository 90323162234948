import React, { useEffect } from "react"

import { GreenBackgroundBtn } from "../../btns"
import StarRating from "../../trucks/starRating"
import LocationIcon from "../../../assets/svg/location.svg"
import CargoCategory from "./cargoCategory"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { fetchCargo } from "../../../redux/cargos/cargo"

type Props = {
  id: number
  setShowDetail?: any
}

const item = {
  category: "Детс.Пижама",
  brand: "Бренд",
  package: "Мешок",
  quantity: 20,
  place: 2,
  weight: 10.2,
}

const AllCargoInfoModl = ({ id, setShowDetail }: Props) => {
  const dispatch = useAppDispatch()
  const { cargo } = useAppSelector((state) => state)

  useEffect(() => {
    dispatch(fetchCargo(id))
  }, [])

  console.log(cargo?.oneData);


  return (
    <div className="AllCargoInfoModal">
      <svg
        onClick={() => setShowDetail(false)}
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          cursor: "pointer",
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M15.0938 2.4375H2.90625C2.12988 2.4375 1.5 3.06738 1.5 3.84375V14.1562C1.5 14.9326 2.12988 15.5625 2.90625 15.5625H15.0938C15.8701 15.5625 16.5 14.9326 16.5 14.1562V3.84375C16.5 3.06738 15.8701 2.4375 15.0938 2.4375ZM15.0938 13.9805C15.0938 14.0771 15.0146 14.1562 14.918 14.1562H3.08203C2.98535 14.1562 2.90625 14.0771 2.90625 13.9805V4.01953C2.90625 3.92285 2.98535 3.84375 3.08203 3.84375H14.918C15.0146 3.84375 15.0938 3.92285 15.0938 4.01953V13.9805ZM11.9443 7.20117L10.1455 9L11.9443 10.7988C12.0791 10.9336 12.0791 11.1533 11.9443 11.291L11.291 11.9443C11.1562 12.0791 10.9365 12.0791 10.7988 11.9443L9 10.1455L7.20117 11.9443C7.06641 12.0791 6.84668 12.0791 6.70898 11.9443L6.05566 11.291C5.9209 11.1562 5.9209 10.9365 6.05566 10.7988L7.85449 9L6.05566 7.20117C5.9209 7.06641 5.9209 6.84668 6.05566 6.70898L6.70898 6.05566C6.84375 5.9209 7.06348 5.9209 7.20117 6.05566L9 7.85449L10.7988 6.05566C10.9336 5.9209 11.1533 5.9209 11.291 6.05566L11.9443 6.70898C12.082 6.84375 12.082 7.06348 11.9443 7.20117Z"
          fill="black"
        />
      </svg>
      <div className="AllCargoInfoModal__top">
        <div>{cargo?.oneData?.user?.first_name} {cargo?.oneData?.user?.last_name}:</div>
        <u>{cargo?.oneData?.user?.uuid}</u>
      </div>
      <div className="AllCargoInfoModal__aboutTruck">
        <div className="AllCargoInfoModal__aboutTruck_driver">
          <div>{cargo?.oneData?.user?.first_name} {cargo?.oneData?.user?.last_name}</div>
          {/* <GreenBackgroundBtn title="Смотреть" /> */}
        </div>
        <div className="AllCargoInfoModal__aboutTruck_truck">
          <div style={{ paddingBottom: '10px' }}>
            <div className="AllCargoInfoModal__aboutTruck_truck-subtitle">
              Статус: <u>Доставлен</u>
            </div>
            <div className="AllCargoInfoModal__aboutTruck_truck-subtitle2">
              ID: <u>{cargo?.oneData?.id}</u>
            </div>
          </div>
          <div>
            {/* <div className="AllCargoInfoModal__aboutTruck_truck-subtitle">
              Рейтинг: <StarRating initialRating={3} />
            </div> */}
          </div>
        </div>
        {/* <div className="AllCargoInfoModal__aboutTruck_route">
          <div
            className="TrucksMore__content_right_content-way"
            style={{ border: "none", boxShadow: "none" }}
          >
            <div className="AllStockInfo__header_adress-city--title">
              <img src={LocationIcon} alt="" />
              <div className="city"> Кыргыстан</div>
            </div>
            <div className="wayLine"></div>
            <div className="AllStockInfo__header_adress-city--title">
              <img src={LocationIcon} alt="" />
              <div className="city">Казакстан</div>
            </div>
            <div className="wayLine"></div>
            <div className="AllStockInfo__header_adress-city--title">
              <img src={LocationIcon} alt="" />
              <div className="city">Россия</div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="AllCargoInfoModal__aboutCargo">
        <div className="AllCargoInfoModal__aboutCargo_title">
          Категория: {cargo?.oneData?.products?.length} <u>позиции</u>
        </div>
        <div className="AllCargoInfoModal__aboutCargo_owners">
          <div className="AllCargoInfoModal__aboutCargo_owners-sender">
            <div>
              Отправитель: <u>{cargo?.oneData?.sender?.name}</u>
            </div>
            <div>
              Телефон: <u>{cargo?.oneData?.sender?.phone}</u>
            </div>
          </div>
          <div className="line"></div>
          <div className="AllCargoInfoModal__aboutCargo_owners-sender">
            <div>
              Получатель: <u>{cargo?.oneData?.receiver?.name}</u>
            </div>
            <div>
              Телефон: <u>{cargo?.oneData?.receiver?.phone}</u>
            </div>
          </div>
        </div>
        <div className="AllCargoInfoModal__aboutCargo_total">
          <div className="AllCargoInfoModal__aboutCargo_owners-sender">
            <div>
              Общие места: <u>{cargo?.oneData?.total_spaces}</u>
            </div>
          </div>

          <div className="AllCargoInfoModal__aboutCargo_owners-sender">
            <div>
              <div className="line2"></div>
              Вес: <u>{cargo?.oneData?.total_weight}кг</u>
            </div>
          </div>
        </div>
        <div className="AllCargoInfoModal__aboutCargo_categories">
          <div className="AllCargoInfoModal__aboutCargo_categories-inner">
            {
              cargo?.oneData?.products?.map((item: any) => <CargoCategory item={item} />)
            }
            {
              cargo?.oneData?.products?.length === 0 && <div style={{ display: 'flex', justifyContent: 'center' }}>Пусто!</div>
            }
          </div>
        </div>
        <div
          className="AllCargoInfoModal__aboutCargo_owners"
          style={{
            justifyContent: "space-evenly",
            width: "95%",
            border: "none",
            
          }}
        >
          <div className="AllCargoInfoModal__aboutCargo_owners-sender">
            <div>
              <u>Дорожные расходы: ${cargo?.oneData?.coefficient_delivery}</u>
            </div>
          </div>
          <div className="line2"></div>
          <div className="AllCargoInfoModal__aboutCargo_owners-sender">
            <div>
              <u>Цена: ${cargo?.oneData?.total_coefficient}</u>
            </div>
          </div>
        </div>
      </div>
      <div className="AllCargoInfoModal__bottom" style={{ paddingTop: '20px', textAlign: 'center' }}>
        Общая стоимость <u>${cargo?.oneData?.total_price}</u>
      </div>
    </div>
  )
}

export default AllCargoInfoModl

import React, { useState } from "react"
import Select from "react-select"
import AllGoodsSectionTree from "./AllGoodsSectionTree"

import { useAppDispatch, useAppSelector } from "../../../redux/hooks"

const AllGooddsSection = () => {
  const { stocks, stockTypes, producktCategories } = useAppSelector(
    (state) => state,
  )
  const percentage =
    ((stocks?.oneData?.volume - stocks?.oneData?.available_volume) /
      stocks?.oneData?.volume) *
    100

  console.log(producktCategories)

  return (
    <div className="allClient">
      <header>
        <div></div>
        <div>
          <h1>Все товары</h1>
        </div>
        <div></div>
      </header>
      <main
        style={{
          height: "100%",
        }}
      >
        <br />
        <div className="good_indicator" style={{ padding: "0px", gap: "22px" }}>
          <div className="good_indicator_titles">
            <div className="good_indicator_title">Общий вес:</div>
            <div className="good_indicator_mini_title">
              {stocks?.oneData?.volume}кг.
            </div>
          </div>
          <div className="good_indicator_items">
            <div className="good_indicator_grey good_indicator_position"></div>
            <div
              style={{
                width: `${
                  percentage ? (percentage > 100 ? 100 : percentage) : "0"
                }%`,
                background: `${
                  stocks?.oneData?.volume - stocks?.oneData?.available_volume >
                  stocks?.oneData?.volume
                    ? "red"
                    : ""
                }`,
              }}
              className="good_indicator_green good_indicator_position"
            >
              <div className="good_indicator_green_title">
                <div
                  className="good_indicator_green_number good_indicator_position_green"
                  style={
                    percentage < 8
                      ? {
                          right: `-30px`,
                          position: "unset",
                          marginTop: "-23px",
                          color: `${
                            stocks?.oneData?.volume -
                              stocks?.oneData?.available_volume >
                            stocks?.oneData?.volume
                              ? "red"
                              : ""
                          }`,
                        }
                      : percentage < 92
                      ? {
                          right: `30px`,
                          position: "unset",
                          marginTop: "-23px",
                          display: "flex",
                          justifyContent: "end",
                        }
                      : {
                          right: `-20px`,
                          color: `${
                            stocks?.oneData?.volume -
                              stocks?.oneData?.available_volume >
                            stocks?.oneData?.volume
                              ? "red"
                              : ""
                          }`,
                        }
                  }
                >
                  {stocks?.oneData?.volume - stocks?.oneData?.available_volume}
                  кг.
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <section
          style={{
            overflowY: "scroll",
            height: "calc(100% - 100px)",
          }}
          className="scroll_none allClient-list"
        >
          <h1 className="allClient-Overallvolume-title">Категории товара:</h1>
          <div>
            <div className="allGoodsMap">
              {producktCategories?.data?.results?.map((cargo: any) => (
                <AllGoodsSectionTree cargo={cargo} />
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default AllGooddsSection

import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../../api/api_config"
import { access_token } from "../../../services"
import { toast } from "react-toastify"

const initialState: any = {
  loading: false,
  data: [],
  oneData: {},
  error: false,
}

const URL = "transport-types"

const transportTypesSlice = createSlice({
  name: "transport-types",
  initialState,
  reducers: {
    getItems: (state, { payload }) => {
      state.data = payload
    },
    getItem: (state, { payload }) => {
      state.oneData = payload
    },
    addItem(state, { payload }) {
      state.data.push({ payload })
    },
    editItems: (state, { payload }) => {
      const updatedResults = state.data.results.map((item: any) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
      state.data.results = updatedResults
    },
    editItem: (state, { payload }) => {
      state.oneData = payload
    },
    removeItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    setLoading(state, { payload }) {
      state.loading = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const { getItems, getItem, setError, addItem, editItems, removeItem, setLoading, editItem } =
  transportTypesSlice.actions
export default transportTypesSlice.reducer

export const fetchTransportTypes = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItems(response.data))
      })
      .catch((er) => {
         
      })
      .finally(() => {
        dispatch(setLoading(true))
      })
  }
}


export const fetchTransportType = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItem(response.data))
      })
      .catch((er) => {
         
      })
      .finally(() => {
        dispatch(setLoading(true))
      })
  }
}

export const addTransportType = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/${URL}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(addItem(response.data))
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
      .finally(() => {
        dispatch(setLoading(true))
      })
  }
}

export const editTransportType = (data: any, id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/${URL}/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editItems(res.data))
        dispatch(editItem(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(true))
      })
  }
}

export const removeTransportType = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .delete(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(removeItem(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(true))
      })
  }
}

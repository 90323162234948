import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"

import ApproveIcon from "../../assets/svg/approveIcon.svg"
import GreenApproveIcon from "../../assets/svg/greenApproveIcon.svg"
import {
  editTransportationsAttribute,
  fetchTransportationsAttributes,
  makeCheckedTransportationsAttribute,
  removeTransportationsAttribute,
  setError,
} from "../../redux/cargos/transportations-atributes/index"
import BetweenRouteEdit from "../../pages/inways/betweenMainRoutes/Edit"

interface Props {
  title?: string
  adress?: string
  date?: string
  item?: any
}

const MapInfo__extraInfo_term = ({ item }: Props) => {
  const { transportatAttrib } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  const [editPoint, setEditPoint] = useState(false)

  const setFunc = () => {
    dispatch(
      fetchTransportationsAttributes({
        limit: 24,
        offset: 1,
        cargo_general_transport: item?.cargo_general_transport,
      }),
    )
  }

  const handleClick = () => {
    dispatch(makeCheckedTransportationsAttribute(item?.id, setFunc))
  }

  const handleDelete = () => {
    dispatch(removeTransportationsAttribute(item?.id, setFunc))
  }

  return (
    <>
      <div className="MapInfo__extraInfo_term-titles">
        <div className="MapInfo__extraInfo_term-titles--approve">
          <img
            onClick={handleClick}
            src={item?.checked ? GreenApproveIcon : ApproveIcon}
            alt=""
          />
          <div
            style={
              item?.checked
                ? {
                    transition: "border-color 0.2s ease",
                    background: "#83C251",
                    borderColor: "#83C251",
                  }
                : {}
            }
            className="approve_line"
          ></div>
        </div>
        <div
          className="MapInfo__extraInfo_term-titles--points"
          style={{ width: "100%" }}
        >
          <div className="points__title">Нахождения на складе {item?.name}</div>
          <div className="points__adress">{item?.name}</div>
          <div className="flex">
            <div className="points__time">{item?.date} </div>
            <div className="flex" style={{ justifyContent: "end" }}>
              <button
                className="addZoneBtn"
                onClick={() => setEditPoint(!editPoint)}
              >
                Редактировать
              </button>
              <button className="addZoneBtn edit" onClick={handleDelete}>
                Удалить
              </button>
            </div>
          </div>
        </div>
      </div>
      {editPoint && <BetweenRouteEdit item={item} setOpen={setEditPoint} />}
    </>
  )
}

export default MapInfo__extraInfo_term

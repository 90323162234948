import React, { useState } from 'react'
import Select from 'react-select'
import { useAppSelector } from '../../redux/hooks'
import AllCargoInfoModl from '../../components/cargos/allInfoModal'

export const ActualItem = ({ item, truck, removeItem }: any) => {
    const { transportations } = useAppSelector((state) => state)
    const [totalWeight, setTotalWeight] = useState(item?.quantity);
    const [productWeight, setProductWeight] = useState(item?.quantity)
    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            padding: '2px 5px',
            borderRadius: "10px",
            cursor: "pointer",
            marginRight: "1px",
            width: '100% - 135px', // Width set to 100%

        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused
                ? "rgba(157, 194, 81, 0.5)"
                : "transparent",
            color: "var(--lc-black-800, #333)",
        }),
    }



    const handleWeightChange = (event: any) => {
        setProductWeight(parseInt(event.target.value, 10));
        // editCargo({total_weight: parseInt(event.target.value, 10)})
    };

    const [showDetail, setShowDetail] = useState(false)

    return (
        <div>
            <div className="good_actual_item">
                <div className="good_actual_item_titles">
                    <div onClick={() => setShowDetail(true)} className="good_actual_item_name" style={{ textDecoration: 'underline', cursor: 'pointer' }}>{item?.product?.cargo?.user?.first_name || 'Не указан'}</div>
                    <div className="good_actual_item_desc_title">
                        <div className="good_actual_item_desc">{item?.product?.category?.name || 'Не указан'}:</div>
                        <div>{item?.weight}кг</div>
                    </div>
                    <div className="good_actual_item_desc_title">
                        <div className="good_actual_item_desc">Место:</div>
                        <div>{item?.from_route?.stock?.city?.name || 'Не указан'} - {item?.to_route?.stock?.city?.name || 'Не указан'}</div>
                    </div>
                </div>
                <div className="good_actual_item_editables">
                    <div className="good_actual_item_desc_title" style={{ gap: '16px' }}>
                        <div className="good_actual_item_desc">Зона:</div>
                        <div><Select isDisabled={true} styles={customStyles} placeholder={item?.zone ? item?.zone : '1'} options={truck?.item?.zones?.map((item: any) => ({
                            value: item?.order,
                            label: item?.order,
                            id: item?.id,
                        }))} /></div>
                    </div>
                    <div className="good_actual_item_editables_range">
                        <div style={{ whiteSpace: 'nowrap' }}>{productWeight} к-во</div>
                        <input min="0"
                            max={totalWeight}
                            step="1"
                            value={productWeight}
                            onChange={handleWeightChange}
                            type="range" className="good_range" />
                        <div style={{ whiteSpace: 'nowrap' }}>{item?.quantity} к-во</div>
                    </div>
                    <button onClick={() => removeItem(item)}>Удалить</button>
                </div>
                <div className="good_actual_item_linear"></div>
            </div>

            {showDetail && (
                <div className="modal-background">
                    <AllCargoInfoModl id={item?.product?.cargo?.id} setShowDetail={setShowDetail} />
                </div>
            )}
        </div>
    )
}


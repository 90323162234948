import jwt_decode from "jwt-decode"

export const access_tokenn = localStorage.getItem("access_token")
export const access_token = access_tokenn?.replace(/"/g, "")
export const refresh_token = localStorage.getItem("refresh_token")
export const userProfile: any = localStorage.getItem("user")
export const fbToken = localStorage.getItem("fcm_token")
  ? JSON.parse(localStorage.getItem("fcm_token") || "")
  : undefined
export const draftNews = localStorage.getItem("newsDrafts")
  ? JSON.parse(localStorage.getItem("newsDrafts") || "")
  : undefined
export let user: any

if (userProfile) {
  user = JSON.parse(userProfile)
} else {
  console.log("User data not found in localStorage")
}

let decoded: any

export async function decodedjwt() {
  const decodedjwt = await jwt_decode(access_token as any)
  decoded = decodedjwt // Сохраняем результат в переменную
  return decodedjwt
}

export { decoded }

import React, { useState } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { Link } from "react-router-dom"

import Start from "../../assets/img/Start.png"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { SignIn } from "../../redux/auth/authSlice"
import ShowIcon from "../../assets/svg/showEyeIcon.svg"
import HideIcon from "../../assets/svg/hideIcon.svg"

type Inputs = {
  email: any
  password: string
}

const AuthPage = () => {
  const dispatch = useAppDispatch()
  const { auth } = useAppSelector((state: any) => state)
  const [showPswd, setShowPswd] = useState(false)

  const { register, handleSubmit, setValue } = useForm<Inputs>()
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    dispatch(
      SignIn({
        phone: data.email,
        password: data.password,
      }),
    )
  }
  return (
    <div
      className="auth"
      style={{
        backgroundImage: `url(${Start})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div
        className="form"
        style={{
          display: "block",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Вход</h1>
          <br />
          <div style={{ display: "block" }}>
            <PhoneInput
              {...register("email")}
              country={"kg"}
              onChange={(value, country, e, formattedValue) => {
                setValue("email", formattedValue)
              }}
            />
            <div className="error">{auth?.error?.phone}</div>
          </div>
          <div style={{ display: "block", position: "relative" }}>
            <input
              className={auth?.error ? "inputError" : "input"}
              {...register("password")}
              type={showPswd ? "text" : "password"}
              placeholder="Пароль"
            />
            <img
              onClick={() => setShowPswd(!showPswd)}
              style={{
                position: "absolute",
                right: "20px",
                top: "19px",
                cursor: "pointer",
              }}
              src={showPswd ? ShowIcon : HideIcon}
              alt=""
            />
            <div className="error">{auth?.error?.password}</div>
          </div>
          <div style={auth?.error ? { marginTop: "5px" } : {}}>
            <Link to={"/auth/reset_password_send_message"}>
              <h2>
                <u>Забыли пароль</u>
              </h2>
            </Link>
          </div>
          <br />
          {auth?.error && <span className="error">{auth?.error?.login}</span>}
          <button type="submit">Войти</button>
        </form>
      </div>
    </div>
  )
}

export default AuthPage

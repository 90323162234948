import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../../api/api_config"
import { access_token } from "../../../services"
import { setLoading } from "../../loading"

const initialState: any = {
  loading: false,
  data: [],
  oneData: null,
  error: false,

  truck: false,
  cargo: false,
  route: null,
}

const URL = "cargo/transportations"

const TransportationsSlice = createSlice({
  name: "transportations",
  initialState,
  reducers: {
    addTransportationTruck: (state, { payload }) => {
      state.truck = payload
    },
    addCargoTransportation: (state, { payload }) => {
      state.cargo = payload
    },
    addRoute: (state, { payload }) => {
      state.route = payload
    },
    clearAll: (state, { }) => {
      state.oneData = null
      state.data = []
    },
    getItems: (state, { payload }) => {
      state.data = payload
    },
    getItem: (state, { payload }) => {
      state.oneData = { ...payload }
    },
    addItem(state, { payload }) {
      state.oneData = { payload }
    },
    editItems: (state, { payload }) => {
      const updatedResults = state.data.results.map((item: any) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
      state.data.results = updatedResults
    },
    editRouteItems: (state, { payload }) => {
      const updatedResults = state.oneData.routes.map((item: any) => {
        if (item.stock.id === payload.id) {
          return { ...item, stock: payload }
        }
        return item
      })
      state.oneData.routes = updatedResults
    },
    editRouteItems2: (state, { payload }) => {
      const updatedResults = state.oneData.routes.map((item: any) => {
        if (item.id === payload.routeId) {
          return { ...item, stock: payload }
        }
        return item
      })
      state.oneData.routes = updatedResults
    },
    editItem: (state, { payload }) => {
      state.oneData = payload
    },
    removeItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
    addProductTransportation: (state, { payload }) => {
      state?.oneData?.transports[0]?.transport_assembly?.map((item: any) => {
        if (item?.id === payload?.cargo_transport) {
          return item.inner_products?.push(payload)
        }
        return item
      })
    },
    removeProductTransportation: (state, { payload }) => {
      const updatedTransportAssembly =
        state?.oneData?.transports[0]?.transport_assembly?.map((item: any) => {
          if (item?.id === payload?.truckId) {
            return {
              ...item,
              inner_products: item.inner_products?.filter(
                (innerItem: any) => innerItem.id !== payload.id,
              ),
              // taken_quantity: payload?.taken_quantity
            }
          } else {
            return item
          }
        })

      state.oneData.transports[0].transport_assembly = updatedTransportAssembly
    },
    editTransportationTransport: (state, { payload }) => {
      state.oneData.transports[0].transport_assembly =
        state.oneData.transports[0].transport_assembly.map((item: any) => {
          if (item.id === payload.id) {
            return { ...item, item: payload.item }
          } else {
            return item
          }
        })
    },
    editTransportationTransportCargo: (state, { payload }) => {
      state.oneData.transports[0].transport_assembly =
        state.oneData.transports[0].transport_assembly.map((item: any) => {
          item?.inner_products?.map((item: any) => {
            if (item.product.cargo.id === payload.id) {
              return payload
            } else {
              return item
            }
          })
        })
    },
    editTransportationDriver: (state, { payload }) => {
      state.oneData.transports[0].driver = payload
    },
  },
})

export const {
  editTransportationTransportCargo,
  editTransportationDriver,
  editTransportationTransport,
  removeProductTransportation,
  addProductTransportation,
  editRouteItems,
  editRouteItems2,
  addTransportationTruck,
  addCargoTransportation,
  clearAll,
  getItems,
  getItem,
  setError,
  addItem,
  editItems,
  removeItem,
  editItem,
  addRoute,
} = TransportationsSlice.actions

export default TransportationsSlice.reducer

export const fetchStockTypes = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItems(response.data))
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
      .finally(() => { })
  }
}

export const getTransportation = (id: number, After?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    dispatch(setError(false))
    axios
      .get(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        After(response?.data)
        dispatch(getItem(response?.data))

        dispatch(setLoading(false))
      })
      .catch((er) => { })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const addTransportations = (data: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/${URL}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        After(response.data)
        dispatch(addItem(response.data))
        dispatch(setError(false))
      })
      .catch((er) => {
        dispatch(setError(er.response?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const editTransportations = (data: any, id: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/${URL}/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        After()
        dispatch(editItem(response?.data))
        dispatch(editItems(response?.data))
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setError(er.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => { })
  }
}

export const removeStockSchedule = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .delete(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(removeItem(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//////////////-----------Routes------------///////////

export const addTransportationsRoute = (data: any, setFunc?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/${URL}/routes/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        setFunc()
        dispatch(addRoute(response.data))
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const editTransportationsRoute = (data: any, id: any, setFunc?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/${URL}/routes/${id}/`, data, {
        headers: { Authorization: `Token ${access_token}` },
      })
      .then((response: any) => {
        setFunc()
        dispatch(editRouteItems2(response?.data))
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

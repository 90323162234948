import { useEffect, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Select from "react-select"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"

import {
  addTruck,
  addTruckZone,
  clearTrucZone,
  deleteZone,
  fetchTrucks,
  setError,
} from "../../../redux/cargos/trucks"
import { getInsuranceById } from "../../../utils/functions"
import { fetchBrands } from "../../../redux/brands"
import { fetchBrandModels } from "../../../redux/brand-models"
import { fetchCountries } from "../../../redux/cargos/countries"
import { fetchTransportLicenses } from "../../../redux/cargos/transport-licenses"
import { fetchTransportTechConditions } from "../../../redux/cargos/transport-tech-conditions"
import { addDriversLast, fetchAllDrivers } from "../../../redux/users"
import { fetchCities } from "../../../redux/cargos/cities"

import { fetchEducations } from "../../../redux/cargos/educations"
import { fetchLanguages } from "../../../redux/cargos/languages"
import StarAddRating from "./ratingComponent"
import DropDownImg from "../../../assets/svg/downarrow.svg"
import PlusIcon from "../../../assets/svg/categoryPlusIcon.svg"
import MinusIcon from "../../../assets/svg/minusCategoryIcon.svg"
import AddNewDriv from "./addNewDriver"
import { useParams } from "react-router-dom"

type Props = {
  type: any
  first_name: string
  last_name: string
  city: any
  passport: string
  released_year: number
  number: string
  lifting_capacity: any
  brand: object
  model: object
  registered_country: object
  insurance: any
  licenses: any
  tech_conditions: any
  user: any
  zones: any
  zone_capacity: number
  zone_order: any
  driver_license: any
  validity_license: any
  driver_experience: string
  experience_as_truck_driver: string
  license_external_transportation: any
  info_criminal_record_or_fines: any
  license_country: any
  education: any
  transport: any
  email: string
  phone: string
  date_license: any
  date_of_birth: any
  address: string
  experience: string
  citizen: any
  languages: any
}

const insuranseOption = [
  { id: 1, label: "Есть", value: "Есть" },
  { id: 2, label: "Нет", value: "Нет" },
]

export const AddTruck = ({ setOnModal, setTotalCount, page }: any) => {
  const {
    users,
    brands,
    brandModels,
    countries,
    license,
    transConditions,
    trucks,
  } = useAppSelector((state: any) => state)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<Props>()
  const dispatch = useAppDispatch()
  const params = useParams()

  const [menu1, setMenu1] = useState(false)
  const [menu2, setMenu2] = useState(true)
  const [menu3, setMenu3] = useState(true)
  const [truckAvatar, setTruckAvatar] = useState()
  const [truckAvatarShow, setTruckAvatarShow] = useState<any>()
  const [rating, setRating] = useState(1)
  const [type, setType] = useState<any>()
  const [model, setModel] = useState<any>()
  const [brand, setBrand] = useState<any>()
  const [registeredCountry, setRegistredCountry] = useState<any>()
  const [insurance, setInsurance] = useState<any>()
  const [driver, setDriver] = useState<any>()

  const [documentList, setDocumentList] = useState<any>([])
  const [techConditionList, setTechCondeitionList] = useState<any>([])
  const [currentDrivers, setCurrentDrivers] = useState<any>(1)

  const AfterDriversGetFunc = (data?: any) => {
    setCurrentDrivers(currentDrivers + 1)
    dispatch(addDriversLast(data?.results))
  }

  const handleTruckImg = (e: any) => {
    setTruckAvatar(e.target.files[0])
    setTruckAvatarShow(URL.createObjectURL(e.target.files[0]))
  }

  const AfterGet = (data: any) => {
    setTotalCount(data?.count)
  }

  useEffect(() => {
    dispatch(fetchBrands())
    dispatch(fetchBrandModels())
    dispatch(fetchCountries())
    dispatch(fetchTransportLicenses())
    dispatch(fetchCities(false))
    dispatch(fetchTransportTechConditions())
    dispatch(fetchEducations())
    dispatch(fetchLanguages())
    dispatch(fetchAllDrivers({ limit: 12, offset: currentDrivers }))
  }, [])

  const onSubmit = (data: any) => {
    dispatch(
      addTruck(
        {
          zones: trucks.zones.length === 0 ? [] : trucks.zones,
          rating: rating,
          type: type?.value || null,
          brand: brand?.id || null,
          model: model?.id || null,
          released_year: data.released_year || null,
          registered_country: registeredCountry?.id || null,
          number: data.number || null,
          lifting_capacity: data.lifting_capacity,
          insurance: getInsuranceById(insurance?.id) || false,
          licenses: documentList.map((item: any) => item?.id),
          tech_conditions:
            techConditionList?.map((item: any) => item?.id) || null,
          driver: driver?.id,
        },
        truckAvatar,
        () => {
          setOnModal(false)
          dispatch(clearTrucZone([]))
          console.log("suuuhb")

          if (!params?.id) {
            dispatch(
              fetchTrucks(
                {
                  limit: 24,
                  offset: page,
                },
                AfterGet,
              ),
            )
          }
        },
      ),
    )
  }

  const addZones: SubmitHandler<Props> = (data: any) => {
    if (+data?.zone_capacity && data?.zone_order?.id) {
      dispatch(
        addTruckZone({
          lifting_capacity: +data.zone_capacity,
          order: data.zone_order?.id,
        }),
      )
      setMenu3(false)
      reset()
    }
  }

  const handleDeleteZone = (id: any) => {
    dispatch(deleteZone(id))
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px",
      cursor: "pointer",
      background: "#fff",
      marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent", // LC Green color on focus
      color: "var(--lc-black-800, #333)",
    }),
  }

  const truckTypesOptions = [
    { id: 1, label: "Стандарт", value: "truck" },
    { id: 2, label: "Тягач", value: "head" },
    { id: 3, label: "Прицеп", value: "trailer" },
    { id: 3, label: "Тандем", value: "semitrailer" },
  ]

  const zonesCount = [
    { value: 1, label: 1, id: 1 },
    { value: 2, label: 2, id: 2 },
    { value: 3, label: 3, id: 3 },
    { value: 4, label: 4, id: 4 },
    { value: 5, label: 5, id: 5 },
    { value: 6, label: 6, id: 6 },
    { value: 7, label: 7, id: 7 },
  ]

  const usersScrollHandler = (e: any) => {
    dispatch(
      fetchAllDrivers(
        {
          limit: 12,
          offset: currentDrivers + 1,
        },
        AfterDriversGetFunc,
      ),
    )
  }

  return (
    <>
      <div className="truckScroll">
        <div className="catalog_list">
          <div className="catalog_category_block">
            <div>Водитель</div>
          </div>
          <div className="catalog_item">
            <div>
              Выбрать водителя <u className="redStar">*</u>
            </div>
            <div style={{ width: "45%" }}>
              <Controller
                control={control}
                {...register("user", { required: false })}
                render={({ field }) => (
                  <Select
                    onMenuScrollToBottom={(e: any) => usersScrollHandler(e)}
                    styles={customStyless}
                    {...field}
                    placeholder="Пусто"
                    options={users.status.results?.map((item: any) => ({
                      value: item.user?.first_name + " " + item.user?.last_name,
                      label: item.user?.first_name + " " + item.user?.last_name,
                      id: item.id,
                    }))}
                    onChange={(e: any) => setDriver(e)}
                  />
                )}
              />
              <div className="error">{trucks.error?.driver}</div>
            </div>
            <button
              className="catalog__green_btn"
              onClick={() => setMenu1(!menu1)}
            >
              Создать нового
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M13.8062 2.81445L2.6951 2.81445C2.08167 2.81445 1.58398 3.31214 1.58398 3.92556L1.58398 12.0737C1.58398 12.6871 2.08167 13.1848 2.6951 13.1848L13.8062 13.1848C14.4196 13.1848 14.9173 12.6871 14.9173 12.0737L14.9173 3.92556C14.9173 3.31214 14.4196 2.81445 13.8062 2.81445ZM13.8062 12.0737L2.6951 12.0737L2.6951 3.92556L13.8062 3.92556L13.8062 12.0737ZM6.3988 7.99964C7.21593 7.99964 7.88028 7.33529 7.88028 6.51816C7.88028 5.70103 7.21593 5.03668 6.3988 5.03668C5.58167 5.03668 4.91732 5.70103 4.91732 6.51816C4.91732 7.33529 5.58167 7.99964 6.3988 7.99964ZM4.32473 10.9626H8.47287C8.75991 10.9626 8.99139 10.7635 8.99139 10.5182V10.0737C8.99139 9.3376 8.29463 8.74038 7.43584 8.74038C7.18584 8.74038 7.00297 8.92556 6.3988 8.92556C5.77611 8.92556 5.62565 8.74038 5.36176 8.74038C4.50297 8.74038 3.80621 9.3376 3.80621 10.0737V10.5182C3.80621 10.7635 4.03769 10.9626 4.32473 10.9626ZM9.91732 9.48112L12.5099 9.48112C12.6118 9.48112 12.6951 9.39779 12.6951 9.29593V8.92556C12.6951 8.82371 12.6118 8.74038 12.5099 8.74038L9.91732 8.74038C9.81547 8.74038 9.73213 8.82371 9.73213 8.92556V9.29593C9.73213 9.39779 9.81547 9.48112 9.91732 9.48112ZM9.91732 7.99964L12.5099 7.99964C12.6118 7.99964 12.6951 7.91631 12.6951 7.81445V7.44408C12.6951 7.34223 12.6118 7.2589 12.5099 7.2589L9.91732 7.2589C9.81547 7.2589 9.73213 7.34223 9.73213 7.44408V7.81445C9.73213 7.91631 9.81547 7.99964 9.91732 7.99964ZM9.91732 6.51816L12.5099 6.51816C12.6118 6.51816 12.6951 6.43482 12.6951 6.33297V5.9626C12.6951 5.86075 12.6118 5.77742 12.5099 5.77742L9.91732 5.77742C9.81547 5.77742 9.73213 5.86075 9.73213 5.9626V6.33297C9.73213 6.43482 9.81547 6.51816 9.91732 6.51816Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <AddNewDriv menu1={menu1} setMenu1={setMenu1} />
          <br />
          <div>
            <div
              onClick={() => setMenu2(!menu2)}
              className="catalog_category_block"
            >
              <div>Машина</div>
              <img
                className={!menu2 ? "" : "reverse"}
                src={DropDownImg}
                alt=""
              />
            </div>
            <div className={menu2 ? "catalog_items" : "menunone"}>
              <div className="catalog_item">
                <div>Рейтинг:</div>
                <div style={{ width: "56.9%" }}>
                  <StarAddRating
                    totalStars={5}
                    rating={rating}
                    setRating={setRating}
                  />
                </div>
              </div>
              <div
                className="catalog_item"
                style={{ alignItems: "start", marginTop: "10px" }}
              >
                <div>Фото:</div>
                <input
                  onChange={(e) => handleTruckImg(e)}
                  type="file"
                  id="truckAvatar"
                  hidden
                  accept="image/png, image/jpeg, image/webp, image/svg+xml"
                />
                <label
                  htmlFor="truckAvatar"
                  style={{
                    width: "56.9%",
                    height: "184px",
                    border: "1px solid #CCC",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    background: `url(${
                      truckAvatarShow ? truckAvatarShow : null
                    }) center no-repeat`,
                    objectFit: "cover",
                    backgroundSize: "cover",
                  }}
                >
                  {truckAvatarShow ? null : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                    >
                      <path
                        d="M14.4167 2.66675L18.5834 2.66675C19.2761 2.66675 19.8334 3.22404 19.8334 3.91675V12.6667H24.4011C25.3282 12.6667 25.7917 13.7865 25.1355 14.4428L17.2136 22.3699C16.823 22.7605 16.1824 22.7605 15.7917 22.3699L7.85946 14.4428C7.20321 13.7865 7.66675 12.6667 8.59383 12.6667L13.1667 12.6667L13.1667 3.91675C13.1667 3.22404 13.724 2.66675 14.4167 2.66675ZM29.8334 22.2501V28.0834C29.8334 28.7761 29.2761 29.3334 28.5834 29.3334L4.41675 29.3334C3.72404 29.3334 3.16675 28.7761 3.16675 28.0834L3.16675 22.2501C3.16675 21.5574 3.72404 21.0001 4.41675 21.0001H12.0574L14.6095 23.5522C15.6563 24.599 17.3438 24.599 18.3907 23.5522L20.9428 21.0001H28.5834C29.2761 21.0001 29.8334 21.5574 29.8334 22.2501ZM23.3751 26.8334C23.3751 26.2605 22.9063 25.7917 22.3334 25.7917C21.7605 25.7917 21.2917 26.2605 21.2917 26.8334C21.2917 27.4063 21.7605 27.8751 22.3334 27.8751C22.9063 27.8751 23.3751 27.4063 23.3751 26.8334ZM26.7084 26.8334C26.7084 26.2605 26.2397 25.7917 25.6667 25.7917C25.0938 25.7917 24.6251 26.2605 24.6251 26.8334C24.6251 27.4063 25.0938 27.8751 25.6667 27.8751C26.2397 27.8751 26.7084 27.4063 26.7084 26.8334Z"
                        fill="#999999"
                      />
                    </svg>
                  )}
                </label>
              </div>
              <div className="error">{trucks.error?.image}</div>
              <div className="catalog_item">
                <div>
                  Тип транспортного средства: <u className="redStar">*</u>
                </div>
                <div
                  style={{
                    width: "56.9%",
                  }}
                >
                  <Controller
                    control={control}
                    {...register("type", { required: false })}
                    render={({ field }) => (
                      <Select
                        styles={customStyless}
                        {...field}
                        placeholder="Пусто"
                        options={truckTypesOptions?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                          id: item.id,
                        }))}
                        onChange={(e: any) => setType(e)}
                      />
                    )}
                  />
                  <div className="error">{trucks?.error?.type}</div>
                </div>
              </div>
              <div className="catalog_item">
                <div>
                  Номер: <u className="redStar">*</u>
                </div>
                <div
                  style={{
                    width: "56.9%",
                  }}
                >
                  <input
                    placeholder="Пусто"
                    {...register("number", { required: false })}
                    type="text"
                  />
                  <div className="error">{trucks?.error?.number}</div>
                </div>
              </div>
              <div className="catalog_item">
                <div>Марка:</div>
                <div
                  style={{
                    width: "56.9%",
                  }}
                >
                  <Controller
                    control={control}
                    {...register("brand", { required: false })}
                    render={({ field }) => (
                      <Select
                        styles={customStyless}
                        {...field}
                        placeholder="Пусто"
                        options={brands.data.results?.map((item: any) => ({
                          value: item.name,
                          label: item.name,
                          id: item.id,
                        }))}
                        onChange={(e: any) => setBrand(e)}
                      />
                    )}
                  />
                  <div className="error">{trucks.error?.brand}</div>
                </div>
              </div>
              <div className="catalog_item">
                <div>Модель:</div>
                <div
                  style={{
                    width: "56.9%",
                  }}
                >
                  <Controller
                    control={control}
                    {...register("model", { required: false })}
                    render={({ field }) => (
                      <Select
                        styles={customStyless}
                        {...field}
                        placeholder="Пусто"
                        options={brandModels.data.results?.map((item: any) => ({
                          value: item.name,
                          label: item.name,
                          id: item.id,
                        }))}
                        onChange={(e: any) => setModel(e)}
                      />
                    )}
                  />
                  <div className="error">{trucks.error?.model}</div>
                </div>
              </div>
              <div className="catalog_item">
                <div>Год выпуска:</div>
                <div
                  style={{
                    width: "56.9%",
                  }}
                >
                  <input
                    placeholder="Пусто"
                    {...register("released_year", { required: false })}
                    type="text"
                  />
                  <div className="error">{trucks?.error?.released_year}</div>
                </div>
              </div>
              <div className="catalog_item">
                <div>В какой стране зарестрирована:</div>
                <div
                  style={{
                    width: "56.9%",
                  }}
                >
                  <Controller
                    control={control}
                    {...register("registered_country", { required: false })}
                    render={({ field }) => (
                      <Select
                        styles={customStyless}
                        {...field}
                        placeholder="Пусто"
                        options={countries.data.results?.map((item: any) => ({
                          value: item.name,
                          label: item.name,
                          id: item.id,
                        }))}
                        onChange={(e: any) => setRegistredCountry(e)}
                      />
                    )}
                  />
                  <div className="error">
                    {trucks?.error?.registered_country}
                  </div>
                </div>
              </div>

              <div className="catalog_item">
                <div>Страховка:</div>
                <div
                  style={{
                    width: "56.9%",
                  }}
                >
                  <Controller
                    control={control}
                    {...register("insurance")}
                    render={({ field }) => (
                      <Select
                        styles={customStyless}
                        {...field}
                        placeholder="Пусто"
                        options={insuranseOption.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                          id: item.id,
                        }))}
                        onChange={(e: any) => setInsurance(e)}
                      />
                    )}
                  />
                  <div className="error">{trucks?.error?.insurance}</div>
                </div>
              </div>
              <div className="catalog_item">
                <div>Документы и лицензи:</div>
                <div
                  style={{
                    width: "56.9%",
                  }}
                >
                  <Controller
                    control={control}
                    {...register("licenses", { required: false })}
                    render={({ field }) => (
                      <Select
                        styles={customStyless}
                        {...field}
                        placeholder="Пусто"
                        onChange={(e) => {
                          if (
                            !documentList.find((item: any) => item.id === e.id)
                          ) {
                            setDocumentList((lisen: any) => [...lisen, e])
                          }
                        }}
                        options={license.data.results?.map((item: any) => ({
                          value: item.name,
                          label: item.name,
                          id: item.id,
                        }))}
                      />
                    )}
                  />
                  <div className="error">{trucks?.error?.licenses}</div>
                </div>
              </div>
              {documentList?.length !== 0 && (
                <div
                  style={{
                    textAlign: "end",
                    display: "flex",
                    justifyContent: "end",
                    gap: "5px",
                  }}
                >
                  {documentList?.map((item: any) => (
                    <div
                      onClick={() => {
                        setDocumentList(
                          documentList.filter((tem: any) => tem.id !== item.id),
                        )
                      }}
                      style={{
                        padding: "6px 12px",
                        background: "#83C251",
                        fontSize: "12px",
                        color: "white",
                        borderRadius: "12px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        gap: "4px",
                      }}
                    >
                      {item.value}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  ))}
                </div>
              )}
              <div className="catalog_item">
                <div>
                  Техническое состояние и <br /> обслуживание транспортного
                  <br />
                  средств:
                </div>
                <div
                  style={{
                    width: "56.9%",
                  }}
                >
                  <Controller
                    control={control}
                    {...register("tech_conditions", { required: false })}
                    render={({ field }) => (
                      <Select
                        styles={customStyless}
                        {...field}
                        placeholder="Пусто"
                        onChange={(e) => {
                          if (
                            !techConditionList.find(
                              (item: any) => item.id === e.id,
                            )
                          ) {
                            setTechCondeitionList((conditions: any) => [
                              ...conditions,
                              e,
                            ])
                          }
                        }}
                        options={transConditions.data.results?.map(
                          (item: any) => ({
                            value: item.name,
                            label: item.name,
                            id: item.id,
                          }),
                        )}
                      />
                    )}
                  />
                  <div className="error">{trucks?.error?.tech_conditions}</div>
                </div>
              </div>
              {techConditionList?.length !== 0 && (
                <div
                  style={{
                    textAlign: "end",
                    display: "flex",
                    justifyContent: "end",
                    gap: "5px",
                  }}
                >
                  {techConditionList?.map((item: any) => (
                    <div
                      onClick={() => {
                        setTechCondeitionList(
                          techConditionList.filter(
                            (tem: any) => tem.id !== item.id,
                          ),
                        )
                      }}
                      style={{
                        padding: "6px 12px",
                        background: "#83C251",
                        fontSize: "12px",
                        color: "white",
                        borderRadius: "12px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        gap: "4px",
                      }}
                    >
                      {item.value}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {type?.value !== "head" && (
              <div style={{ marginTop: "12px" }}>
                <div className="catalog_item">
                  <div>
                    Зоны: <u className="redStar">*</u>
                  </div>
                  <div
                    style={{
                      width: "56.9%",
                      display: "flex",
                      gap: "12px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      className="addZoneBtn"
                      onClick={() => setMenu3(!menu3)}
                    >
                      Добавить зону <img src={PlusIcon} alt="" />
                    </button>
                  </div>
                </div>
                <div
                  key={null}
                  className={
                    trucks.zones?.length % 2 === 0
                      ? "categoryWidth1"
                      : "categoryWidth"
                  }
                >
                  {trucks.zones?.map((item: any) => (
                    <div
                      key={item.id}
                      className="addedItemCategory"
                      // onClick={() => {
                      //   setEditCategory(!editCategory)
                      //   setUpdatingCatId(item.id)
                      // }}
                    >
                      {item.order}
                      <img
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteZone(item.id)
                        }}
                        src={MinusIcon}
                        alt=""
                      />
                    </div>
                  ))}
                </div>
                <div className="error">{trucks?.error?.order}</div>
              </div>
            )}

            {menu3 && type?.value !== "head" ? (
              <div className="catalog_items">
                <div className="catalog_item">
                  <div>
                    Грузоподъемность: <u className="redStar">*</u>
                  </div>
                  <div style={{ width: "56.9%" }}>
                    <input
                      {...register("zone_capacity", {
                        required: true,
                      })}
                      placeholder="Грузоподъемность"
                      type="number"
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                    {errors?.zone_capacity && (
                      <span className="error">
                        Это поле не может быть пустым
                      </span>
                    )}
                    <div className="error">
                      {trucks?.error?.zones?.[0]?.lifting_capacity}
                    </div>
                  </div>
                </div>
                <div className="catalog_item">
                  <div>
                    Номер зоны: <u className="redStar">*</u>
                  </div>
                  <div style={{ width: "56.9%" }}>
                    <Controller
                      control={control}
                      {...register("zone_order", { required: true })}
                      render={({ field }) => (
                        <Select
                          styles={customStyless}
                          {...field}
                          placeholder="Номер 1"
                          options={zonesCount?.map((item: any) => ({
                            value: item.value,
                            label: item.label,
                            id: item.id,
                          }))}
                        />
                      )}
                    />
                    {errors?.zone_order && (
                      <span className="error">
                        Это поле не может быть пустым
                      </span>
                    )}
                  </div>
                </div>
                <div className="form_button" style={{ width: "100%" }}>
                  <div style={{ width: "50%" }}>
                    <button
                      className="TruckBtn2"
                      onClick={() => setMenu3(false)}
                    >
                      Отмена
                    </button>
                  </div>
                  <div style={{ width: "50%" }}>
                    <button
                      className="Green-bth"
                      onClick={handleSubmit(addZones)}
                    >
                      Добавить зону
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M9.08333 4.87565V1.33398L3.875 1.33398C3.52865 1.33398 3.25 1.61263 3.25 1.95898L3.25 14.0423C3.25 14.3887 3.52865 14.6673 3.875 14.6673L12.625 14.6673C12.9714 14.6673 13.25 14.3887 13.25 14.0423L13.25 5.50065H9.70833C9.36458 5.50065 9.08333 5.2194 9.08333 4.87565ZM10.7807 10.5009L9.08333 10.5009L9.08333 12.5842C9.08333 12.8145 8.89687 13.0009 8.66667 13.0009H7.83333C7.60313 13.0009 7.41667 12.8145 7.41667 12.5842L7.41667 10.5009H5.71927C5.3474 10.5009 5.16172 10.0507 5.42578 9.78841L7.93672 7.29622C8.1099 7.12409 8.38958 7.12409 8.56276 7.29622L11.0737 9.78841C11.338 10.0507 11.1526 10.5009 10.7807 10.5009ZM13.0677 4.06836L10.5182 1.51628C10.401 1.39909 10.2422 1.33398 10.0755 1.33398H9.91667V4.66732L13.25 4.66732V4.50846C13.25 4.3444 13.1849 4.18555 13.0677 4.06836Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div
        className="form_button"
        style={{
          paddingTop: "30px",
          position: "absolute",
          left: "20px",
          right: "20px",
          bottom: "20px",
        }}
      >
        <button
          onClick={() => {
            setOnModal(false)
            dispatch(setError(false))
          }}
          className="TruckBtn2"
        >
          Отмена
        </button>

        <button onClick={handleSubmit(onSubmit)} className="TruckBtn">
          Сохранить
        </button>
      </div>
    </>
  )
}

import React, { useEffect } from "react"
import SearchBox from "../components/searchBox/index"
import { useAppDispatch, useAppSelector } from "../redux/hooks"

import GraphicSliderIcon from "../assets/svg/graficSlider.svg"
import { GreenAddBtn } from "../components/btns"
import FirstImg from "../assets/img/Frame 377.png"
import SecondImg from "../assets/img/Frame 381.png"
import ThirdImg from "../assets/img/Frame 378.png"
import FourthImg from "../assets/img/Frame 379.png"
import FifthImg from "../assets/img/Frame 380.png"

const HomePage = () => {
  const { balance } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState<any>()

  console.log(balance)

  return (
    <div className="Homepage">
      <div className="not_allowed">Аналитика временно недоступна</div>
      <div className="Maps_notdefined">
        <div className="Homepage__header">
          <div className="SearchBox">
            <GreenAddBtn title="Добавить груз" />
            <SearchBox
              title="По коду"
              title2="Номер трека"
              title3="По складу"
              title4="По городу"
              open={open}
              setValue={setValue}
            />
            <button className="SearchBox_btn" onClick={() => setOpen(!open)}>
              <img src={GraphicSliderIcon} alt="" />
            </button>
          </div>
          <div className="title">Аналитика</div>
        </div>
        <div className="sides">
          <div className="flex">
            <div className="left">
              <div>
                <img src={FirstImg} alt="" />
              </div>
              <img src={SecondImg} alt="" />
            </div>
            <div className="right">
              <img src={ThirdImg} alt="" />
              <div className="flex">
                <img className="right_right" src={FourthImg} alt="" />
                <img src={FifthImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage

import React, { useState } from "react"
import { Link } from "react-router-dom"

import { GreenBackgroundBtn } from "../btns"
import CloseIcon from "../../assets/svg/closeIcon.svg"
import EditIcon from "../../assets/svg/editIcon.svg"
import { ClientState } from "../../data-test"
import Avatar from "../../assets/img/no-profile.jpeg"
import EditTruck from "../../components/clients/UserEdit/EditTruck"
import { Modal } from "../Modal"
import { Menu1 } from "./Menu1"
import { Menu2 } from "./Menu2"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { editUser, userEdit } from "../../redux/users"
import { EditClient } from "./Edit"
import { ResetPassword } from "./ResetPassword"

type Props = {
  item: any
  userEdit: any
  DeleteUser?: any
  dispatcher?: any
  ArchiveUser?: any
}

const ClientsCard = ({
  item,
  userEdit,
  DeleteUser,
  dispatcher,
  ArchiveUser,
}: Props) => {
  const [modal, setModal] = useState(false)
  const [passActive, setPassActive] = useState<any>(null)

  const sendFunc = (data: any, id: number) => {
    userEdit(data, id, setModal)
  }
  const [editModal, setEditModal] = useState(false)
  const [exit, setExit] = useState(false)
  const { users } = useAppSelector((state: any) => state)

  return (
    <>
      <div className="ClientsCard">
        <div
          style={{
            padding: "20px",
          }}
        >
          {!dispatcher && (
            <svg
              onClick={() => setExit(true)}
              className="ClientsCard_x"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M16.7707 2.70834H3.229C2.36637 2.70834 1.6665 3.40821 1.6665 4.27084V15.7292C1.6665 16.5918 2.36637 17.2917 3.229 17.2917H16.7707C17.6333 17.2917 18.3332 16.5918 18.3332 15.7292V4.27084C18.3332 3.40821 17.6333 2.70834 16.7707 2.70834ZM16.7707 15.5339C16.7707 15.6413 16.6828 15.7292 16.5754 15.7292H3.42432C3.31689 15.7292 3.229 15.6413 3.229 15.5339V4.46615C3.229 4.35873 3.31689 4.27084 3.42432 4.27084H16.5754C16.6828 4.27084 16.7707 4.35873 16.7707 4.46615V15.5339ZM13.2713 8.0013L11.2726 10L13.2713 11.9987C13.4211 12.1484 13.4211 12.3926 13.2713 12.5456L12.5454 13.2715C12.3957 13.4212 12.1515 13.4212 11.9985 13.2715L9.99984 11.2728L8.00114 13.2715C7.8514 13.4212 7.60726 13.4212 7.45426 13.2715L6.72835 12.5456C6.57861 12.3958 6.57861 12.1517 6.72835 11.9987L8.72705 10L6.72835 8.0013C6.57861 7.85156 6.57861 7.60742 6.72835 7.45443L7.45426 6.72852C7.604 6.57878 7.84815 6.57878 8.00114 6.72852L9.99984 8.72722L11.9985 6.72852C12.1483 6.57878 12.3924 6.57878 12.5454 6.72852L13.2713 7.45443C13.4243 7.60417 13.4243 7.84831 13.2713 8.0013Z"
                fill="#828282"
              />
            </svg>
          )}

          <div className="ClientsCard__inner">
            <div className="ClientsCard__inner_image">
              <img
                style={{ objectFit: "cover" }}
                src={item?.avatar ? item?.avatar : Avatar}
                alt=""
              />
            </div>
            <div
              className="ClientsCard__inner_info"
              style={{
                width: "100%",
              }}
            >
              <div className="ClientsCard__inner_info-name">
                {item?.first_name} {item?.last_name}
              </div>
              <div
                className="ClientsCard__inner_info-ids"
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div
                    className="ClientsCard__inner_info-ids--titlesStatus"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      fontWeight: "400",
                    }}
                  >
                    Статус:
                    {item?.is_confirmed ? (
                      <b style={{ fontWeight: "400", color: "#83C251" }}>
                        Потвержденный
                      </b>
                    ) : (
                      <b style={{ fontWeight: "400", color: "#c25151" }}>
                        Не потвержденный
                      </b>
                    )}
                  </div>
                  <div className="ClientsCard__inner_info-ids--titlesId">
                    ID: <b>{item?.id}</b>
                  </div>
                  <div className="ClientsCard__inner_info-ids--titlesBalance">
                    Баланс:{" "}
                    <span
                      style={{
                        color: item?.client?.balance
                          ?.toString()
                          ?.startsWith("-")
                          ? "red"
                          : "#83C251",
                      }}
                    >
                      ${item?.client?.balance}
                    </span>
                  </div>
                </div>
                <div className="ClientsCard__inner_info-ids--btns">
                  <Link to={`/clients/history/${item.id}`}>
                    <GreenBackgroundBtn title="История" />
                  </Link>

                  {!dispatcher && (
                    <button
                      onClick={() => setModal(true)}
                      className="btns__eitBtn"
                    >
                      <img src={EditIcon} alt="" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              columnGap: "30px",
            }}
          >
            <div className="ClientsCard__bottom">
              <div className="ClientsCard__bottom-count">
                Последняя оплата:{" "}
                <b>
                  {item?.last_receipt?.slice(0, 10)
                    ? item?.last_receipt?.slice(0, 10)
                    : "Оплаты не было"}
                </b>
              </div>
              <div className="ClientsCard__bottom-border"></div>
              <div className="ClientsCard__bottom-placeCount">
                Тариф:
                <b>
                  {item?.client?.tariff?.name
                    ? item?.client?.tariff?.name
                    : "Нет тарифа"}
                </b>
              </div>
            </div>
            <div className="ClientsCard__bottom">
              <div className="ClientsCard__bottom-count">
                Последний заказ:
                <b>
                  {item?.last_order?.slice(0, 10)
                    ? item?.last_order?.slice(0, 10) +
                      " " +
                      item?.last_order?.toString()?.slice(11, 16)
                    : "Нет заказов"}
                </b>
              </div>
              <div className="ClientsCard__bottom-border"></div>
              <div className="ClientsCard__bottom-placeCount">
                Объем:
                <b>
                  {item?.client_info?.volume
                    ? item?.client_info?.volume
                    : "0.00"}
                  кг
                </b>
              </div>
            </div>
          </div>

          {editModal && (
            <div
              className="modal-background"
              onClick={() => setEditModal(!editModal)}
            >
              <EditTruck setOnModal={editUser} />
            </div>
          )}
        </div>

        <Modal
          title={"Редактировать клиента"}
          width={"633px"}
          modal={modal}
          setModal={setModal}
          element={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "98%",
              }}
            >
              <div className="catalog">
                <EditClient
                  user_id={item.id}
                  setModal={setModal}
                  setPassActive={setPassActive}
                />
              </div>
            </div>
          }
        />
        <Modal
          modal={passActive}
          setModal={setPassActive}
          hideX={true}
          height={"fit-content"}
          element={<ResetPassword setPassActive={setPassActive} />}
        />
        <div
          className={exit ? "exitModal" : "none"}
          onClick={() => setExit(!exit)}
        >
          <div
            className="exitModal__content"
            onClick={(e) => e.stopPropagation()}
          >
            <img src={CloseIcon} alt="" onClick={() => setExit(!exit)} />
            <div className="exitModal__content_title">
              Вы действительно хотите удалить этого клиента?
            </div>
            <div className="exitModal__content_btns">
              <button className="outline" onClick={() => setExit(!exit)}>
                Отмена
              </button>
              <button
                className="background"
                onClick={() => DeleteUser(item.id, setExit)}
              >
                Удалить
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientsCard

import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { fetchproductCategories } from "../../../redux/cargos/product-categories"
import { fetchTransportAssignments } from "../../../redux/cargos/transport-assigments"
import {
  fetchCities,
  fetchCitiesFrom,
  fetchCitiesTo,
  getItems,
  getItemsFrom,
  getItemsTo,
} from "../../../redux/cargos/cities"
import { fetchCountries } from "../../../redux/cargos/countries"
import { fetchTariffs } from "../../../redux/cargos/tariffs"
import {
  editUser,
  fetchUserById,
  setError,
  setItemsById,
  userEditByIdDataId,
  userEditByIdDataIdAvatar,
} from "../../../redux/users"
import Avatar from "../../../assets/img/no-profile.jpeg"
import axios from "axios"
import { API_URL } from "../../../api/api_config"
import { access_token } from "../../../services"
import { ResetPassword } from "../ResetPassword"

type Props = {
  setModal: any
  user_id: number
  setPassActive?: any
}
export const EditClient = ({ setModal, user_id, setPassActive }: Props) => {
  const {
    users,
    productCategories,
    transAssignments,
    cities,
    countries,
    tariffs,
  } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm()

  const [menu1, setMenu1] = useState(true)
  const [menu2, setMenu2] = useState(true)
  const [avatar, setAvatar] = useState<any>()
  const [avatarShow, setAvatarShow] = useState<any>()

  const [categoriesList, setCategoriesList] = useState<any>([])
  const [assigmentsList, setAssigmentsList] = useState<any>([])

  const handleAvatar = (e: any) => {
    dispatch(
      userEditByIdDataIdAvatar(
        {
          avatar: e.target.files[0],
        },
        users?.dataId?.id,
      ),
    )
    setAvatar(e.target.files[0])
    setAvatarShow(URL.createObjectURL(e.target.files[0]))
  }

  const setFunc = () => {
    dispatch(fetchUserById(user_id))
    setModal(false)
    reset()
  }

  const getCitiesByCountryFrom = (data: any) => {
    dispatch(fetchCitiesFrom(data?.id))
  }

  const getCitiesByCountryTo = (id: number) => {
    dispatch(fetchCitiesTo(id))
  }

  const getCitiesByCountry = (id: number) => {
    dispatch(fetchCities(id))
  }

  useEffect(() => {
    dispatch(fetchproductCategories())
    dispatch(fetchTransportAssignments())
    dispatch(fetchCountries())
    dispatch(fetchTariffs())

    dispatch(getItemsFrom([]))
    dispatch(getItemsTo([]))
    dispatch(getItems([]))

    dispatch(setError(false))

    axios
      .get(`${API_URL}api/v1/auth/users/${user_id}`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        setValue("balance", response.data.client.balance)
        setValue("address", response.data.client.address)
        setValue("orders_count", response.data.client.orders_count)
        setValue("city", response.data.client.city)
        dispatch(setItemsById(response.data))
        setCategoriesList(
          response?.data?.client_info?.product_categories?.map((item: any) => ({
            value: item.name,
            label: item.name,
            id: item.id,
          })) || [],
        )
        setAssigmentsList(
          response?.data?.client_info?.transportation_assignments?.map(
            (item: any) => ({
              value: item.name,
              label: item.name,
              id: item.id,
            }),
          ) || [],
        )
        setValue("uuid", response?.data?.uuid)
        setValue("is_confirmed", response?.data?.is_confirmed)
        setValue("first_name", response?.data?.first_name)
        setValue("last_name", response?.data?.last_name)
        setValue("email", response?.data?.email)
        setValue("phone", response?.data?.phone)

        setValue("volume", response?.data?.client_info?.volume)
        setValue("daily", response?.data?.client_info?.daily)
        setValue("weekly", response?.data?.client_info?.weekly)
        setValue("monthly", response?.data?.client_info?.monthly)

        // setValue(
        //   "country_from_from",
        //   response?.data?.client_info?.city_from
        //     ? {
        //         value: response?.data?.client_info?.city_from?.country_name,
        //         label: response?.data?.client_info?.city_from?.country_name,
        //         id: response?.data?.client_info?.city_from?.country,
        //       }
        //     : "",
        // )
        setValue("city_from", {
          value: response?.data?.client_info?.city_from?.name,
          label: response?.data?.client_info?.city_from?.name
            ? response?.data?.client_info?.city_from?.name
            : "Пусто",
          id: response?.data?.client_info?.city_from?.id,
        })

        setValue("city_to", {
          value: response?.data?.client_info?.city_to?.name,
          label: response?.data?.client_info?.city_to?.name
            ? response?.data?.client_info?.city_to?.name
            : "Пусто",
          id: response?.data?.client_info?.city_to?.id,
        }),
          response?.data?.client?.city !== null &&
            setValue("city", {
              value: response?.data?.client?.city?.name,
              label: response?.data?.client?.city?.name,
              id: response?.data?.client?.city?.id,
            }),
          setValue("company_size", {
            value: response.data.client_info?.company_size,
            label:
              response.data.client_info?.company_size === "small"
                ? "Малая"
                : "" || response.data.client_info?.company_size === "medium"
                ? "Средняя"
                : "" || response.data.client_info?.company_size === "big"
                ? "Большая"
                : "Пусто",
          })
        setValue("tariff", {
          value: response.data.client.tariff.name,
          label: response.data.client.tariff.name,
        })

        getCitiesByCountryFrom(response?.data?.client_info?.city_from?.country)
        getCitiesByCountryTo(response?.data?.client_info?.city_to?.country)
        getCitiesByCountry(response?.data?.client?.city?.country)
      })
  }, [])

  console.log(users)

  const onSubmit = (data: any) => {
    dispatch(
      userEditByIdDataId(
        {
          staff: null,
          driver: null,
          client: {
            balance: data?.balance ? data?.balance : 0,
            address: data?.address,
            orders_count: data?.orders_count ? data?.orders_count : 0,
            city: data?.city?.id,

            tariff: data?.tariff?.id ? data?.tariff?.id : null,
          },
          client_info: {
            volume: data?.volume ? data?.volume : 0,
            daily: data?.daily ? data?.daily : 0,
            weekly: data?.weekly ? data?.weekly : 0,
            monthly: data?.monthly ? data?.monthly : 0,
            company_size: data?.company_size?.value
              ? data?.company_size?.value
              : null,
            city_from: data?.city_from?.id ? data?.city_from?.id : null,
            city_to: data?.city_to?.id ? data?.city_to?.id : null,
            product_categories:
              categoriesList.length !== 0
                ? categoriesList?.map((item: any) => item.id)
                : [],
            transportation_assignments:
              assigmentsList.length !== 0
                ? assigmentsList?.map((item: any) => item.id)
                : [],
          },
          password: data?.password,
          is_superuser: false,
          first_name: data?.first_name ? data?.first_name : null,
          last_name: data?.last_name ? data?.last_name : null,
          is_staff: false,
          is_active: true,
          phone: data?.phone,
          email: data?.email,
          uuid: data?.uuid,
          role: "client",
          is_confirmed: data?.is_confirmed,
        },
        users?.dataId?.id,
        setFunc,
      ),
    )
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      padding: "2px 5px",
      borderRadius: "10px",
      cursor: "pointer",
      marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent",
      color: "var(--lc-black-800, #333)",
    }),
  }

  const Cancel = () => {
    setModal(false)
  }

  const companySize = [
    { id: 1, label: "Малая", value: "small" },
    { id: 2, label: "Средняя", value: "medium" },
    { id: 3, label: "Большая", value: "big" },
  ]

  return (
    <>
      <div className={menu1 ? "catalog_list" : ""}>
        <div
          className={"catalog_category_block"}
          onClick={() => setMenu1(!menu1)}
        >
          <div>Основные</div>
          <svg
            className={menu1 ? "reverse" : ""}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
              fill="#1A1A1A"
            />
          </svg>
        </div>
        <div
          className={menu1 ? "catalog_items" : "none"}
          style={{
            paddingBottom: "20px",
          }}
        >
          <div className="catalog_item">
            <div>Статус:</div>
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                width: "56.7%",
              }}
            >
              <input
                className="custom-checkbox"
                placeholder="Пусто"
                {...register("is_confirmed", { required: false })}
                style={{
                  width: "15px",
                  cursor: "pointer",
                  borderRadius: "0px",
                  padding: "9px",
                  borderColor: "#83C251",
                }}
                type="checkbox"
                id="status_label"
              />
              <label
                style={{
                  cursor: "pointer",
                }}
                htmlFor="status_label"
              >
                Подтвержденный
              </label>
            </div>
          </div>
          <div
            className="catalog_item"
            style={{ alignItems: "start", marginTop: "10px" }}
          >
            <div>Фото:</div>
            <input
              onChange={(e) => handleAvatar(e)}
              type="file"
              id="avatar"
              hidden
              accept="image/png, image/jpeg, image/webp, image/svg+xml"
            />

            <label
              htmlFor="avatar"
              style={{
                width: "56.7%",
                height: "184px",
                border: "1px solid #CCC",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                objectFit: "cover",
              }}
            >
              <img
                src={
                  users?.dataId?.avatar
                    ? users?.dataId?.avatar
                    : avatarShow
                    ? avatarShow
                    : Avatar
                }
                style={{
                  width: "100%",
                  height: "184px",
                  cursor: "pointer",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
                alt=""
              />
            </label>
          </div>
          <div className="catalog_item">
            <div>
              UID: <u className="redStar">*</u>
            </div>
            <div
              style={{
                width: "57.1%",
              }}
            >
              <input
                placeholder="Пусто"
                {...register("uuid", { required: false })}
                type="text"
              />
              <div className="error">{users?.error?.uuid}</div>
            </div>
          </div>
          <div className="catalog_item">
            <div>
              Имя: <u className="redStar">*</u>
            </div>
            <div
              style={{
                width: "57.1%",
              }}
            >
              <input
                placeholder="Пусто"
                {...register("first_name", { required: false })}
                type="text"
              />
              <div className="error">{users?.error?.first_name}</div>
            </div>
          </div>
          <div className="catalog_item">
            <div>
              Фамилия: <u className="redStar">*</u>
            </div>
            <div
              style={{
                width: "57.1%",
              }}
            >
              <input
                placeholder="Пусто"
                {...register("last_name", { required: false })}
                type="text"
              />
              <div className="error">{users?.error?.last_name}</div>
            </div>
          </div>
          <div className="catalog_item">
            <div>Email:</div>
            <div
              style={{
                width: "57.1%",
              }}
            >
              <input
                placeholder="Пусто"
                {...register("email", { required: false })}
                type="text"
              />
              <div className="error">{users?.error?.email}</div>
            </div>
          </div>
          <div className="catalog_item">
            <div>
              Номер телефона: <u className="redStar">*</u>
            </div>
            <div
              style={{
                width: "57.1%",
              }}
            >
              <input
                placeholder="Пусто"
                {...register("phone", { required: false })}
                type="text"
              />
              <div className="error">{users?.error?.phone}</div>
            </div>
          </div>

          <div className="catalog_item">
            <div>Пароль:</div>
            <div
              style={{
                width: "57.1%",
              }}
            >
              <button
                style={{
                  width: "100%",
                  padding: "9px 0px",
                  border: "none",
                  borderRadius: "4px",
                  background: "#83C251",
                  color: "#ffffff",
                }}
                onClick={() => setPassActive(true)}
              >
                Изменить пароль
              </button>
              <div className="error">{users?.error?.phone}</div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="catalog_category_block"
            onClick={() => setMenu2(!menu2)}
          >
            <div>Другие</div>
            <svg
              className={menu2 ? "reverse" : ""}
              // onClick={() => setMenu2(!menu2)}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
                fill="#1A1A1A"
              />
            </svg>
          </div>
          <div className={menu2 ? "catalog_items" : "none"}>
            <div className="catalog_item">
              <div>Объем перевозок клиента:</div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="0к.м"
                  {...register("volume", { required: false })}
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                />
                <div className="error">{users?.error?.client_info?.volume}</div>
              </div>
            </div>

            <div className="catalog_item">
              <div>Ежедневный:</div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="0к.м"
                  {...register("daily", { required: false })}
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                />
                <div className="error">{users?.error?.client_info?.daily}</div>
              </div>
            </div>

            <div className="catalog_item">
              <div>Еженедельный:</div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="0к.м"
                  {...register("weekly", { required: false })}
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                />
                <div className="error">{users?.error?.client_info?.weekly}</div>
              </div>
            </div>

            <div className="catalog_item">
              <div>Ежемесячный:</div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="0к.м"
                  {...register("monthly", { required: false })}
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                />
                <div className="error">
                  {users?.error?.client_info?.monthly}
                </div>
              </div>
            </div>

            <div
              className="catalog_item"
              style={{
                alignItems: "start",
              }}
            >
              <div>Маршруты перевозок от:</div>
              <div
                style={{
                  width: "57%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <Controller
                  control={control}
                  {...register("country_from_from", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger2"
                      {...field}
                      placeholder={"Страна"}
                      value={users?.dataId?.client_info?.city_from?.country}
                      options={countries?.data?.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                      onChange={(e: any) => {
                        field?.onChange(e)
                        getCitiesByCountryFrom(e)
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  {...register("city_from", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger2"
                      {...field}
                      placeholder="Город"
                      isDisabled={
                        cities?.data_from?.length !== 0 ? false : true
                      }
                      options={cities?.data_from?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">
                  {users?.error?.client_info?.city_from}
                </div>
              </div>
            </div>

            <div
              className="catalog_item"
              style={{
                alignItems: "start",
              }}
            >
              <div>Маршруты перевозок до:</div>
              <div
                style={{
                  width: "57%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <Controller
                  control={control}
                  {...register("country_from_to", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={"Страна"}
                      value={users?.users?.client_info?.city_to?.country}
                      options={countries?.data?.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                      onChange={(e) => {
                        field?.onChange(e)
                        getCitiesByCountryTo(e.id)
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  {...register("city_to", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder="Город"
                      isDisabled={cities?.data_to?.length !== 0 ? false : true}
                      options={cities?.data_to?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">
                  {users?.error?.client_info?.city_to}
                </div>
              </div>
            </div>

            <div className="catalog_item">
              <div>Размер компании клиента:</div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <Controller
                  control={control}
                  {...register("company_size", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder="Пусто"
                      options={companySize?.map((item: any) => ({
                        value: item.value,
                        label: item.label,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">
                  {users?.error?.client_info?.company_size}
                </div>
              </div>
            </div>
            <div className="catalog_item">
              <div style={{ display: "flex", flexWrap: "wrap", width: "32%" }}>
                <div>Предпочитаемые виды товаров:</div>
              </div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <Controller
                  control={control}
                  {...register("product_categories", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      onChange={(e) => {
                        if (
                          !categoriesList.find((item: any) => item.id === e.id)
                        ) {
                          setCategoriesList((categories: any) => [
                            ...categories,
                            e,
                          ])
                        }
                      }}
                      placeholder="Выбрать"
                      options={productCategories?.data?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">
                  {users?.error?.client_info?.product_categories}
                </div>
              </div>
            </div>
            {categoriesList?.length !== 0 && (
              <div
                style={{
                  textAlign: "end",
                  display: "flex",
                  justifyContent: "end",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {categoriesList?.map((item: any) => (
                  <div
                    onClick={() => {
                      setCategoriesList(
                        categoriesList.filter((tem: any) => tem.id !== item.id),
                      )
                    }}
                    style={{
                      padding: "6px 12px",
                      background: "#83C251",
                      fontSize: "12px",
                      color: "white",
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      gap: "4px",
                    }}
                  >
                    {item.value}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                ))}
              </div>
            )}
            <div className="catalog_item">
              <div>Требования к перевозкам:</div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <Controller
                  control={control}
                  {...register("transportation_assignments", {
                    required: false,
                  })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder="Выбрать"
                      onChange={(e) => {
                        if (
                          !assigmentsList.find((item: any) => item.id === e.id)
                        ) {
                          setAssigmentsList((assigment: any) => [
                            ...assigment,
                            e,
                          ])
                        }
                      }}
                      options={transAssignments?.data?.results?.map(
                        (item: any) => ({
                          value: item.name,
                          label: item.name,
                          id: item.id,
                        }),
                      )}
                    />
                  )}
                />
                <div className="error">
                  {users?.error?.client_info?.transportation_assignments}
                </div>
              </div>
            </div>
            {assigmentsList?.length !== 0 && (
              <div
                style={{
                  textAlign: "end",
                  display: "flex",
                  justifyContent: "end",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {assigmentsList?.map((item: any) => (
                  <div
                    onClick={() => {
                      setAssigmentsList(
                        assigmentsList.filter((tem: any) => tem.id !== item.id),
                      )
                    }}
                    style={{
                      padding: "6px 12px",
                      background: "#83C251",
                      fontSize: "12px",
                      color: "white",
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      gap: "4px",
                    }}
                  >
                    {item.value}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                ))}
              </div>
            )}
            <div
              className="catalog_item"
              style={{
                alignItems: "start",
              }}
            >
              <div>Город:</div>
              <div
                style={{
                  width: "57%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <Controller
                  control={control}
                  {...register("country", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={
                        users?.dataId?.client?.city?.country_name
                          ? users?.dataId?.client?.city?.country_name
                          : "Страна"
                      }
                      value={
                        field.value || users?.dataId?.client?.city?.country_name
                      }
                      options={countries?.data?.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                      onChange={(e) => {
                        field?.onChange(e)
                        getCitiesByCountry(e.id)
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  {...register("city", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder="Город"
                      isDisabled={cities?.data?.length !== 0 ? false : true}
                      options={cities?.data?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
              </div>
            </div>
            <div className="catalog_item">
              <div>Адрес:</div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("address", { required: false })}
                  type="text"
                />
                <div className="error">{users?.error?.client?.address}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Тариф:</div>
              <div
                style={{
                  width: "57%",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Controller
                  control={control}
                  {...register("tariff", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder="Пусто"
                      options={tariffs?.data?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
              </div>
            </div>
            <div className="catalog_item">
              <div>Баланс:</div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("balance", { required: false })}
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                  disabled
                />
                <div className="error">{users?.error?.client?.balance}</div>
              </div>
            </div>
            {/* <div className="catalog_item">
                <div>Имя: <u className="redStar">*</u></div>
                <div style={{
                  width: "57.1%",
                }}>

                  <input
                    placeholder="Пусто"
                    {...register("first_name", { required: false })}
                    type="text"
                  />
                  <div className="error">{users?.error?.first_name}</div>
                </div>
              </div> */}

            <div className="catalog_item">
              <div>Количество заказов:</div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("orders_count", { required: false })}
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                  // disabled
                />
                <div className="error">
                  {users?.error?.client?.orders_count}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="form_button"
          style={{
            paddingTop: "20px",
            position: "absolute",
            bottom: "20px",
            left: "20px",
            right: "20px",
          }}
        >
          <button className="TruckBtn2" onClick={Cancel}>
            Отмена
          </button>
          <button
            onClick={handleSubmit(onSubmit)}
            type="submit"
            className="TruckBtn"
          >
            Сохранить
          </button>
        </div>
      </div>
    </>
  )
}

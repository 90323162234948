import React, { useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Select from "react-select"
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import { addUser, fetchAllDrivers } from "../../../../redux/users"
import { getInsuranceById } from "../../../../utils/functions"

const insuranseOption = [
  { id: 1, label: "Есть", value: "Есть" },
  { id: 2, label: "Нет", value: "Нет" },
]

const AddNewDriv = ({ menu1, setMenu1 }: any) => {
  const { users, countries, cities, education, languages } = useAppSelector(
    (state: any) => state,
  )
  const dispatch = useAppDispatch()

  const { register, handleSubmit, control, reset } = useForm<any>()
  const [avatar, setAvatar] = useState<any>()
  const [avatarShow, setAvatarShow] = useState<any>()
  const [languagesList, setLanguagesList] = useState<any>([])

  const setNewDriver = () => {
    dispatch(fetchAllDrivers({}))
    setMenu1(false)
    reset()
  }

  const addDriver: SubmitHandler<any> = (data: any) => {
    if (menu1 === true) {
      dispatch(
        addUser(
          {
            staff: {
              passport: data?.passport,
              date_of_birth: data?.date_of_birth ? data?.date_of_birth : null,
              address: data?.address,
              experience: data?.experience ? data?.experience : null,
              citizen: data?.citizen?.id,
              city: data?.city?.id,
              languages: languagesList?.map((item: any) => item.id),
            },
            driver: {
              date_license: data?.date_license ? data?.date_license : null,
              validity_license: data?.validity_license
                ? data?.validity_license
                : null,
              experience: data?.driver_experience
                ? data?.driver_experience
                : null,
              experience_as_truck_driver: data?.experience_as_truck_driver
                ? data?.experience_as_truck_driver
                : null,
              license_external_transportation: getInsuranceById(
                data?.license_external_transportation?.id,
              ),
              info_criminal_record_or_fines: getInsuranceById(
                data?.info_criminal_record_or_fines?.id,
              ),
              license_country: data?.license_country?.id,
              education: data?.education?.id,
              transport: null,
            },
            client: null,
            client_info: null,
            password: "1234",
            first_name: data?.first_name ? data?.first_name : null,
            last_name: data?.last_name ? data?.last_name : null,
            is_active: true,
            phone: data?.phone,
            email: data?.email,
            role: "driver",
            is_confirmed: true,
          },
          avatar,
          setNewDriver,
        ),
      )
    }
  }

  const handleAvatar = (e: any) => {
    setAvatar(e.target.files[0])
    setAvatarShow(URL.createObjectURL(e.target.files[0]))
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px",
      cursor: "pointer",
      background: "#fff",
      marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent", // LC Green color on focus
      color: "var(--lc-black-800, #333)",
    }),
  }

  return (
    <div className={menu1 ? "catalog_items" : "menunone"}>
      <div
        className="catalog_item"
        style={{ alignItems: "start", marginTop: "10px" }}
      >
        <div>Фото:</div>
        <input
          onChange={(e) => handleAvatar(e)}
          type="file"
          id="avatar"
          hidden
          accept="image/png, image/jpeg, image/webp, image/svg+xml"
        />
        <label
          htmlFor="avatar"
          style={{
            width: "56.9%",
            height: "184px",
            border: "1px solid #CCC",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            // marginRight: "10px",
            background: `url(${
              avatarShow ? avatarShow : null
            }) center no-repeat`,
            objectFit: "cover",
            backgroundSize: "cover",
          }}
        >
          {avatarShow ? null : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
            >
              <path
                d="M14.4167 2.66675L18.5834 2.66675C19.2761 2.66675 19.8334 3.22404 19.8334 3.91675V12.6667H24.4011C25.3282 12.6667 25.7917 13.7865 25.1355 14.4428L17.2136 22.3699C16.823 22.7605 16.1824 22.7605 15.7917 22.3699L7.85946 14.4428C7.20321 13.7865 7.66675 12.6667 8.59383 12.6667L13.1667 12.6667L13.1667 3.91675C13.1667 3.22404 13.724 2.66675 14.4167 2.66675ZM29.8334 22.2501V28.0834C29.8334 28.7761 29.2761 29.3334 28.5834 29.3334L4.41675 29.3334C3.72404 29.3334 3.16675 28.7761 3.16675 28.0834L3.16675 22.2501C3.16675 21.5574 3.72404 21.0001 4.41675 21.0001H12.0574L14.6095 23.5522C15.6563 24.599 17.3438 24.599 18.3907 23.5522L20.9428 21.0001H28.5834C29.2761 21.0001 29.8334 21.5574 29.8334 22.2501ZM23.3751 26.8334C23.3751 26.2605 22.9063 25.7917 22.3334 25.7917C21.7605 25.7917 21.2917 26.2605 21.2917 26.8334C21.2917 27.4063 21.7605 27.8751 22.3334 27.8751C22.9063 27.8751 23.3751 27.4063 23.3751 26.8334ZM26.7084 26.8334C26.7084 26.2605 26.2397 25.7917 25.6667 25.7917C25.0938 25.7917 24.6251 26.2605 24.6251 26.8334C24.6251 27.4063 25.0938 27.8751 25.6667 27.8751C26.2397 27.8751 26.7084 27.4063 26.7084 26.8334Z"
                fill="#999999"
              />
            </svg>
          )}
        </label>
      </div>
      <div className="catalog_item">
        <div>
          Фамилия: <u className="redStar">*</u>
        </div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <input
            placeholder="Пусто"
            {...register("last_name", { required: false })}
            type="text"
          />
          <div className="error">{users?.error?.last_name}</div>
        </div>
      </div>
      <div className="catalog_item">
        <div>
          Имя: <u className="redStar">*</u>
        </div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <input
            placeholder="Пусто"
            {...register("first_name", { required: false })}
            type="text"
          />
          <div className="error">{users?.error?.first_name}</div>
        </div>
      </div>
      <div className="catalog_item">
        <div>
          Номер телефона: <u className="redStar">*</u>
        </div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <input
            placeholder="Пусто"
            {...register("phone", { required: false })}
            type="text"
          />
          <div className="error">{users?.error?.phone}</div>
        </div>
      </div>
      <div className="catalog_item">
        <div>Эл.почта водителя:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <input
            placeholder="Пусто"
            {...register("email", { required: false })}
            type="text"
          />
          <div className="error">{users?.error?.email}</div>
        </div>
      </div>

      <div className="catalog_item">
        <div>Пасспорт водителя:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <input
            placeholder="Пусто"
            {...register("passport", { required: false })}
            type="text"
          />
          <div className="error">{users?.error?.staff?.passport}</div>
        </div>
      </div>

      <div className="catalog_item">
        <div>Дата рождения водителя:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <input
            placeholder="Пусто"
            {...register("date_of_birth", { required: false })}
            type="date"
          />
          <div className="error">{users?.error?.staff?.date_of_birth}</div>
        </div>
      </div>

      <div className="catalog_item">
        <div>Адрес водителя:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <input
            placeholder="Пусто"
            {...register("address", { required: false })}
            type="text"
          />
          <div className="error">{users?.error?.staff?.address}</div>
        </div>
      </div>

      <div className="catalog_item">
        <div>Опыт водителя:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <input
            placeholder="Пусто"
            {...register("experience", { required: false })}
            type="text"
          />
          <div className="error">{users?.error?.staff?.experience}</div>
        </div>
      </div>

      <div className="catalog_item">
        <div>Гражданство водителя:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <Controller
            {...register("citizen")}
            control={control}
            render={({ field }) => (
              <Select
                styles={customStyless}
                {...field}
                placeholder="Пусто"
                options={countries.data?.results?.map((item: any) => ({
                  value: item.name,
                  label: item.name,
                  id: item.id,
                }))}
              />
            )}
          />
          <div className="error">{users?.error?.staff?.citizen}</div>
        </div>
      </div>

      <div className="catalog_item">
        <div>Город:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <Controller
            {...register("city")}
            control={control}
            render={({ field }) => (
              <Select
                styles={customStyless}
                {...field}
                placeholder="Пусто"
                options={cities.data?.map((item: any) => ({
                  value: item.name,
                  label: item.name,
                  id: item.id,
                }))}
              />
            )}
          />
          <div className="error">{users?.error?.staff?.city}</div>
        </div>
      </div>

      <div className="catalog_item">
        <div>Языки</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <Controller
            control={control}
            {...register("languages", { required: false })}
            render={({ field }) => (
              <Select
                styles={customStyless}
                {...field}
                placeholder="Пусто"
                onChange={(e) => {
                  if (!languagesList.find((item: any) => item.id === e.id)) {
                    setLanguagesList((languages: any) => [...languages, e])
                  }
                }}
                options={languages.data?.map((item: any) => ({
                  value: item.name,
                  label: item.name,
                  id: item.id,
                }))}
              />
            )}
          />
          <div className="error">{users?.error?.staff?.languages}</div>
        </div>
      </div>
      {languagesList?.length !== 0 && (
        <div
          style={{
            textAlign: "end",
            display: "flex",
            justifyContent: "end",
            gap: "5px",
          }}
        >
          {languagesList?.map((item: any) => (
            <div
              onClick={() => {
                setLanguagesList(
                  languagesList.filter((tem: any) => tem.id !== item.id),
                )
              }}
              style={{
                padding: "6px 12px",
                background: "#83C251",
                fontSize: "12px",
                color: "white",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                gap: "4px",
              }}
            >
              {item.value}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                  fill="white"
                />
              </svg>
            </div>
          ))}
        </div>
      )}

      <div className="catalog_item">
        <div>Страна выдачи прав:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <Controller
            {...register("license_country")}
            control={control}
            render={({ field }) => (
              <Select
                styles={customStyless}
                {...field}
                placeholder="Пусто"
                options={countries.data?.results?.map((item: any) => ({
                  value: item.name,
                  label: item.name,
                  id: item.id,
                }))}
              />
            )}
          />
          <div className="error">{users?.error?.driver?.license_country}</div>
        </div>
      </div>
      <div className="catalog_item">
        <div>Дата выдачи прав:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <input
            placeholder="Пусто"
            {...register("date_license", { required: false })}
            type="date"
          />
          <div className="error">{users?.error?.driver?.date_license}</div>
        </div>
      </div>
      <div className="catalog_item">
        <div>Оброзования:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <Controller
            control={control}
            {...register("education", { required: false })}
            render={({ field }) => (
              <Select
                styles={customStyless}
                {...field}
                placeholder="Пусто"
                options={education?.data?.map((item: any) => ({
                  value: item?.name,
                  label: item?.name,
                  id: item?.id,
                }))}
              />
            )}
          />
          <div className="error">{users?.error?.driver?.education}</div>
        </div>
      </div>
      <div className="catalog_item">
        <div>Срок действия прав:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <input
            placeholder="Пусто"
            {...register("validity_license", { required: false })}
            type="date"
          />
          <div className="error">{users?.error?.driver?.validity_license}</div>
        </div>
      </div>

      <div className="catalog_item">
        <div>Опыт в вождении:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <input
            placeholder="Пусто"
            {...register("driver_experience", { required: false })}
            type="number"
          />
          <div className="error">{users?.error?.driver?.experience}</div>
        </div>
      </div>

      <div className="catalog_item">
        <div>Опыт в вождении грузовиков:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <input
            placeholder="Пусто"
            {...register("experience_as_truck_driver", {
              required: false,
            })}
            type="number"
          />
          <div className="error">
            {users?.error?.driver?.experience_as_truck_driver}
          </div>
        </div>
      </div>

      <div className="catalog_item">
        <div>Лицензия на внешние перевозки:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <Controller
            control={control}
            {...register("license_external_transportation", {
              required: false,
            })}
            render={({ field }) => (
              <Select
                styles={customStyless}
                {...field}
                placeholder="Пусто"
                options={insuranseOption?.map((item: any) => ({
                  value: item?.value,
                  label: item?.label,
                  id: item?.id,
                }))}
              />
            )}
          />
          <div className="error">
            {users?.error?.driver?.license_external_transportation}
          </div>
        </div>
      </div>

      <div className="catalog_item">
        <div>Судимость и штрафы:</div>
        <div
          style={{
            width: "56.9%",
          }}
        >
          <Controller
            control={control}
            {...register("info_criminal_record_or_fines", {
              required: false,
            })}
            render={({ field }) => (
              <Select
                styles={customStyless}
                {...field}
                placeholder="Пусто"
                options={insuranseOption?.map((item: any) => ({
                  value: item?.value,
                  label: item?.label,
                  id: item?.id,
                }))}
              />
            )}
          />
          <div className="error">
            {users?.error?.driver?.info_criminal_record_or_fines}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "98.3%",
        }}
      >
        <button className="addDriverbtn" onClick={handleSubmit(addDriver)}>
          Добавить водителя
        </button>
      </div>
    </div>
  )
}

export default AddNewDriv

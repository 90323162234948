import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../../api/api_config"
import { access_token } from "../../../services"
import { toast } from "react-toastify"
import { setLoading } from "../../loading"

const initialState: any = {
  data: [],
  error: false,
}

const NewsCategoriesSlice = createSlice({
  name: "news-categories",
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.error = false
      state.data = payload
    },
    addItem(state, { payload }) {
      state.data.push(payload[0])
    },
    editNews: (state, { payload }) => {
      state.data = payload
    },
    deleteItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const {
  setItems,
  setError,
  addItem,
  editNews,
  deleteItem,
} = NewsCategoriesSlice.actions
export default NewsCategoriesSlice.reducer

//fetchNews
export const fetchNewsCategories = (params?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/news-categories/`, {
        params: {...params, use_pagination: false},
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const fetchNewsCategory = (id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .get(`${API_URL}news-categories/${id}`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
      })
      .catch((er) => {})
  }
}

//addNews
export const addNewsCategories = (data = {},) => {
  return async (dispatch: Dispatch) => {
    setLoading(true)
    axios
      .post(`${API_URL}api/v1/news-categories/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(addItem(response.data))
        toast.success("✅ Пост успешно добавлен", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
      .finally(() => setLoading(false))
  }
}

// edidBrand
export const EditNewsCategories = (
  data = {},
  id: any,
  setLoading: Function,
  navigate: any,
) => {
  return async (dispatch: Dispatch) => {
    setLoading(true)
    axios
      .patch(`${API_URL}news-categories/${id}`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editNews(res.data))
        navigate(`/news/${id}`)
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => setLoading(false))
  }
}

// edidBrand
export const EditAllNewsCategories = (data = {}, id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .put(`${API_URL}news-categories/${id}`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editNews(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
  }
}

//deleteNews
export const deleteNewsCategories = (id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .delete(`${API_URL}api/v1/news-categories/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(deleteItem(id))
        toast.success("Пост успешно удален", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
  }
}

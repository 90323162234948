import { useState } from "react"
import ProfileShow from "./fetchProfile"

const Index = () => {
  const [isEdit, setIsEdit] = useState(false)

  return (
    <div
      className="profile"
      style={{
        height: "100%",
      }}
    >
      <ProfileShow setIsEdit={setIsEdit} isEdit={isEdit} />
    </div>
  )
}

export default Index

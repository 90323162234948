import React, { useState } from "react"

import Download_img from "../../assets/img/download_map.png"
import No_truck from "../../assets/img/no-truck-download.png"
import { Good_stock_zone } from "./Good_stock_zone"
import { Zone } from "./Zone"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { editTransportations } from "../../redux/cargos/transportations"
import TruckImg from "../../assets/img/Rectangle 413.png"

import TruckModel1 from "../../assets/svg/TruckModel1.svg"
import TruckModel2 from "../../assets/svg/TruckModel2.svg"
import TruckModel3 from "../../assets/svg/TruckModel3.svg"

import { toast } from "react-toastify"
import { DownloadModalItem } from "./DownloadModalItem"
import { API_URL } from "../../api/api_config"
import axios from "axios"
import { access_token } from "../../services"
import { useNavigate, useParams } from "react-router-dom"
import { fetchGeneralTransports } from "../../redux/cargos/general-transports"

export const DownloadModal = ({ finish, setEditWay, modal, setModal }: any) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [infoOpener, setInfoOpener] = useState(true)
  const [senderOpener, setSenderOpener] = useState(true)
  const [getterOpener, setGetteOpener] = useState(true)

  const { transportations } = useAppSelector((state) => state)
  console.log(transportations?.oneData?.status)

  const EditTransportation = () => {
    axios
      .get(
        `${API_URL}api/v1/cargo/transportations/${
          transportations?.oneData?.status === "on_way"
            ? "finish-transportation"
            : "make-on-way-transportation"
        }/${transportations?.oneData?.id}/`,
        {
          headers: {
            Authorization: `Token ${access_token}`,
          },
        },
      )
      .then((res: any) => {
        if (transportations?.oneData?.status === "planning") {
          navigate("/onways/on_way/")
        }
        toast.success("Успешно отправлен")
        setModal(false)
        setEditWay(false)
        dispatch(
          fetchGeneralTransports(
            {
              limit: 24,
              offset: 1,
              transportation__status: "on_way",
            },
            (data: any) => {
              navigate("/onways/on_way")
            },
          ),
        )
      })
      .catch((error) => {
        toast.error(`${error?.response?.data?.detail}`)
      })
  }

  const editRout = (e: any) => {
    axios
      .post(
        `${API_URL}api/v1/cargo/transportations/general-transports/change-location/${transportations?.oneData?.transports[0]?.id}/`,
        {
          route: e?.routeId,
        },
        {
          headers: {
            Authorization: `Token ${access_token}`,
          },
        },
      )
      .then((response: any) => {
        // setFunction()
        toast.success("Грузы успешно прибыли")
        // dispatch(editItem(response.data))
      })
  }

  const Finish = () => {
    axios
      .get(
        `${API_URL}api/v1/cargo/transportations/${
          transportations?.oneData?.status === "on_way"
            ? "finish-transportation"
            : "make-on-way-transportation"
        }/${transportations?.oneData?.id}/`,
        {
          headers: {
            Authorization: `Token ${access_token}`,
          },
        },
      )
      .then((res) => {
        toast.success("Успешно завершён")
        setModal(false)
        setEditWay(false)
        dispatch(
          fetchGeneralTransports(
            {
              limit: 24,
              offset: 1,
              transportation__status: "delivered",
            },
            (data: any) => {
              navigate("/onways/delivered/")
            },
          ),
        )
      })
      .catch((error) => {
        toast.error(`${error?.response?.data?.detail}`)
      })
  }

  return (
    <div className={modal ? "modal" : "modal_none"}>
      <div className="good_download">
        <div
          className="good_download_map"
          style={{ backgroundImage: `url(${Download_img})` }}
        >
          <div className="good_download_title">Карта временно</div>
          <div className="good_download_title">недоступна</div>
        </div>

        <div className="good_download_info">
          <div className="good_download_info_btns">
            {transportations?.oneData?.routes?.map((item: any) => (
              <button>Склад :{item?.stock?.name}</button>
            ))}
          </div>
          <div className="good_download_info_locations" style={{ gap: "0px" }}>
            {transportations?.oneData?.routes?.map((item: any, index: number) =>
              !transportations?.oneData?.routes[index + 1] ? (
                <>
                  <div
                    className="good_download_info_locations_title"
                    style={{ width: "fit-content%" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                    >
                      <path
                        d="M9.62433 6.70482C9.23131 7.50113 8.69934 8.29508 8.15432 9.00753C7.61099 9.71776 7.06582 10.3326 6.65576 10.7704C6.60127 10.8285 6.54924 10.8835 6.5 10.9351C6.45076 10.8835 6.39873 10.8285 6.34424 10.7704C5.93418 10.3326 5.38901 9.71776 4.84568 9.00753C4.30066 8.29508 3.76869 7.50113 3.37567 6.70482C2.9786 5.90031 2.75 5.14643 2.75 4.5C2.75 2.42893 4.42893 0.75 6.5 0.75C8.57107 0.75 10.25 2.42893 10.25 4.5C10.25 5.14643 10.0214 5.90031 9.62433 6.70482ZM6.5 12C6.5 12 11 7.73528 11 4.5C11 2.01472 8.98528 0 6.5 0C4.01472 0 2 2.01472 2 4.5C2 7.73528 6.5 12 6.5 12Z"
                        fill="#83C251"
                      />
                      <path
                        d="M6.5 6C5.67157 6 5 5.32843 5 4.5C5 3.67157 5.67157 3 6.5 3C7.32843 3 8 3.67157 8 4.5C8 5.32843 7.32843 6 6.5 6ZM6.5 6.75C7.74264 6.75 8.75 5.74264 8.75 4.5C8.75 3.25736 7.74264 2.25 6.5 2.25C5.25736 2.25 4.25 3.25736 4.25 4.5C4.25 5.74264 5.25736 6.75 6.5 6.75Z"
                        fill="#83C251"
                      />
                    </svg>
                    <div>{item?.stock?.city?.name}</div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="good_download_info_locations_title"
                    style={{ width: "100%" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                    >
                      <path
                        d="M9.62433 6.70482C9.23131 7.50113 8.69934 8.29508 8.15432 9.00753C7.61099 9.71776 7.06582 10.3326 6.65576 10.7704C6.60127 10.8285 6.54924 10.8835 6.5 10.9351C6.45076 10.8835 6.39873 10.8285 6.34424 10.7704C5.93418 10.3326 5.38901 9.71776 4.84568 9.00753C4.30066 8.29508 3.76869 7.50113 3.37567 6.70482C2.9786 5.90031 2.75 5.14643 2.75 4.5C2.75 2.42893 4.42893 0.75 6.5 0.75C8.57107 0.75 10.25 2.42893 10.25 4.5C10.25 5.14643 10.0214 5.90031 9.62433 6.70482ZM6.5 12C6.5 12 11 7.73528 11 4.5C11 2.01472 8.98528 0 6.5 0C4.01472 0 2 2.01472 2 4.5C2 7.73528 6.5 12 6.5 12Z"
                        fill="#83C251"
                      />
                      <path
                        d="M6.5 6C5.67157 6 5 5.32843 5 4.5C5 3.67157 5.67157 3 6.5 3C7.32843 3 8 3.67157 8 4.5C8 5.32843 7.32843 6 6.5 6ZM6.5 6.75C7.74264 6.75 8.75 5.74264 8.75 4.5C8.75 3.25736 7.74264 2.25 6.5 2.25C5.25736 2.25 4.25 3.25736 4.25 4.5C4.25 5.74264 5.25736 6.75 6.5 6.75Z"
                        fill="#83C251"
                      />
                    </svg>
                    <div>{item?.stock?.city?.name}</div>

                    <div className="good_download_info_locations_linear"></div>
                  </div>
                </>
              ),
            )}
          </div>
          <div className="good_download_info_truck">
            <div>
              <div
                className="good_opener"
                style={{ padding: "0px 0px 12px 0px" }}
                onClick={() => setInfoOpener(!infoOpener)}
              >
                <div className="good_opener_title">Общие данные</div>
                {infoOpener ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.73484 3.98483C5.88128 3.83839 6.11872 3.83839 6.26516 3.98483L10.7652 8.48484C10.9116 8.63128 10.9116 8.86872 10.7652 9.01516C10.6187 9.16161 10.3813 9.16161 10.2348 9.01516L6 4.78033L1.76517 9.01516C1.61872 9.16161 1.38128 9.16161 1.23483 9.01516C1.08839 8.86872 1.08839 8.63128 1.23483 8.48484L5.73484 3.98483Z"
                      fill="#1A1A1A"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.23483 3.98483C1.38128 3.83839 1.61872 3.83839 1.76517 3.98483L6 8.21967L10.2348 3.98483C10.3813 3.83839 10.6187 3.83839 10.7652 3.98483C10.9116 4.13128 10.9116 4.36872 10.7652 4.51517L6.26516 9.01516C6.11872 9.16161 5.88128 9.16161 5.73484 9.01516L1.23483 4.51517C1.08839 4.36872 1.08839 4.13128 1.23483 3.98483Z"
                      fill="#1A1A1A"
                    />
                  </svg>
                )}
              </div>

              {infoOpener && (
                <>
                  <div className="good_download_truck">
                    <div>Фото грузовика</div>

                    <div
                      style={{
                        paddingTop: "10px",
                      }}
                      className="good_download_truck_photos"
                    >
                      <div className="">
                        <div>
                          {transportations?.oneData?.transports &&
                            transportations?.oneData?.transports[0]
                              ?.transport_assembly && (
                              <img
                                style={{
                                  width: "179px",
                                  height: "98px",
                                  objectFit: "cover",
                                  borderRadius: "8px",
                                  border: "1px solid var(--LC-Green, #83c251)",
                                }}
                                src={
                                  transportations?.oneData?.transports[0]?.transport_assembly.find(
                                    (item: any) =>
                                      item?.item?.type === "truck" ||
                                      item?.item?.type === "head",
                                  )?.item?.image || TruckImg
                                }
                                alt=""
                              />
                            )}
                        </div>
                      </div>
                      {/* <div className='good_download_truck_photos_layout'>
                                                <div>Макет грузовика</div>
                                                <div className="good_select_zone">
                                                    <div className="good_select_zone_items_inner">
                                                        <Good_stock_zone elem={
                                                            <div className='good_select_zones'>
                                                                <Zone number={1} />
                                                                <Zone number={2} />
                                                                <Zone number={3} />
                                                                <Zone number={4} />
                                                            </div>
                                                        } />
                                                    </div>
                                                </div>
                                            </div> */}
                      {transportations?.oneData?.transports &&
                      transportations?.oneData?.transports[0]?.transport_assembly.find(
                        (item: any) => item?.item?.type === "head",
                      ) &&
                      transportations?.oneData?.transports[0]?.transport_assembly.find(
                        (item: any) => item?.item?.type === "trailer",
                      ) ? (
                        <div
                          className="truckmodel"
                          style={{
                            padding: "0px 20px",
                            flexDirection: "column",
                          }}
                        >
                          <img src={TruckModel1} alt="" />
                        </div>
                      ) : null}
                      {transportations?.oneData?.transports &&
                      transportations?.oneData?.transports[0]?.transport_assembly.find(
                        (item: any) => item?.item?.type === "truck",
                      ) ? (
                        <div
                          style={{
                            flexDirection: "column",
                          }}
                          className="truckmodel"
                        >
                          <img src={TruckModel2} alt="" />
                        </div>
                      ) : null}
                      {transportations?.oneData?.transports &&
                      transportations?.oneData?.transports[0]?.transport_assembly.find(
                        (item: any) => item?.item?.type === "head",
                      ) &&
                      transportations?.oneData?.transports[0]?.transport_assembly.find(
                        (item: any) => item?.item?.type === "semi_trailer",
                      ) ? (
                        <div
                          className="truckmodel"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <img width={"90%"} src={TruckModel3} alt="" />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="good_download_humans">
              {/* <div className='good_download_human_item'>
                                <div className="good_opener" style={{ padding: '0px 0px 12px 0px' }} onClick={() => setSenderOpener(!senderOpener)}>
                                    <div className="good_opener_title">Оправитель</div>
                                    {
                                        senderOpener ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.73484 3.98483C5.88128 3.83839 6.11872 3.83839 6.26516 3.98483L10.7652 8.48484C10.9116 8.63128 10.9116 8.86872 10.7652 9.01516C10.6187 9.16161 10.3813 9.16161 10.2348 9.01516L6 4.78033L1.76517 9.01516C1.61872 9.16161 1.38128 9.16161 1.23483 9.01516C1.08839 8.86872 1.08839 8.63128 1.23483 8.48484L5.73484 3.98483Z" fill="#1A1A1A" />
                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.23483 3.98483C1.38128 3.83839 1.61872 3.83839 1.76517 3.98483L6 8.21967L10.2348 3.98483C10.3813 3.83839 10.6187 3.83839 10.7652 3.98483C10.9116 4.13128 10.9116 4.36872 10.7652 4.51517L6.26516 9.01516C6.11872 9.16161 5.88128 9.16161 5.73484 9.01516L1.23483 4.51517C1.08839 4.36872 1.08839 4.13128 1.23483 3.98483Z" fill="#1A1A1A" />
                                        </svg>
                                    }
                                </div>

                                {
                                    senderOpener && <>
                                        <div className='good_download_human_item_infos'>
                                            <div className=''>
                                                <div className='good_download_human_item_title'>Отправителя:</div>
                                                <div className='good_download_human_item_text'>Иван Иванов Иванов</div>
                                            </div>

                                            <div className=''>
                                                <div className='good_download_human_item_title'>Номер телефона:</div>
                                                <div className='good_download_human_item_text'>Иван Иванов Иванов</div>
                                            </div>

                                            <div className=''>
                                                <div className='good_download_human_item_title'>Получатель на складе:</div>
                                                <div className='good_download_human_item_text'>Иван Иванов Иванов</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>

                            <div className='good_download_human_item'>
                                <div className="good_opener" style={{ padding: '0px 0px 12px 0px' }} onClick={() => setGetteOpener(!getterOpener)}>
                                    <div className="good_opener_title">Получатель</div>
                                    {
                                        getterOpener ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.73484 3.98483C5.88128 3.83839 6.11872 3.83839 6.26516 3.98483L10.7652 8.48484C10.9116 8.63128 10.9116 8.86872 10.7652 9.01516C10.6187 9.16161 10.3813 9.16161 10.2348 9.01516L6 4.78033L1.76517 9.01516C1.61872 9.16161 1.38128 9.16161 1.23483 9.01516C1.08839 8.86872 1.08839 8.63128 1.23483 8.48484L5.73484 3.98483Z" fill="#1A1A1A" />
                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.23483 3.98483C1.38128 3.83839 1.61872 3.83839 1.76517 3.98483L6 8.21967L10.2348 3.98483C10.3813 3.83839 10.6187 3.83839 10.7652 3.98483C10.9116 4.13128 10.9116 4.36872 10.7652 4.51517L6.26516 9.01516C6.11872 9.16161 5.88128 9.16161 5.73484 9.01516L1.23483 4.51517C1.08839 4.36872 1.08839 4.13128 1.23483 3.98483Z" fill="#1A1A1A" />
                                        </svg>
                                    }
                                </div>

                                {
                                    getterOpener && <>
                                        <div className='good_download_human_item_infos'>
                                            <div className=''>
                                                <div className='good_download_human_item_title'>Отправителя:</div>
                                                <div className='good_download_human_item_text'>Иван Иванов Иванов</div>
                                            </div>

                                            <div className=''>
                                                <div className='good_download_human_item_title'>Номер телефона:</div>
                                                <div className='good_download_human_item_text'>Иван Иванов Иванов</div>
                                            </div>

                                            <div className=''>
                                                <div className='good_download_human_item_title'>Получатель на складе:</div>
                                                <div className='good_download_human_item_text'>Иван Иванов Иванов</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div> */}

              <div className="good_download_products">
                {transportations?.oneData?.transports &&
                  transportations?.oneData?.transports[0]?.transport_assembly?.map(
                    (item: any) =>
                      item?.inner_products?.map((i: any) => (
                        <DownloadModalItem i={i} />
                      )),
                  )}
              </div>
            </div>
          </div>
        </div>

        <div className="good_download_btns">
          <button
            onClick={() => setModal(false)}
            className="good_download_cancel_btn"
            style={
              params?.status === "delivered"
                ? { width: "100%" }
                : { width: "50%" }
            }
          >
            Отмена
          </button>
          {params?.status === "delivered" ? null : (
            <button onClick={finish === true ? Finish : EditTransportation}>
              {finish === true ? "Завершить" : "Сохранить"}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../../api/api_config"
import { access_token } from "../../../services"
import { setLoading } from "../../loading"

const initialState: any = {
  loading: false,
  data: [],
  oneData: {},
  error: false,
}

const URL = "cargo/receivers"

const cargoRecieversSlice = createSlice({
  name: "cargoRecievers",
  initialState,
  reducers: {
    getItems: (state, { payload }) => {
      state.data = payload
    },
    getItem: (state, { payload }) => {
      state.oneData = payload
    },
    addItem(state, { payload }) {
      state.data.push({ payload })
    },
    editItems: (state, { payload }) => {
      const updatedResults = state.data.results.map((item: any) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
      state.data.results = updatedResults
    },
    editItem: (state, { payload }) => {
      state.oneData = payload
    },
    removeItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const {
  getItems,
  getItem,
  setError,
  addItem,
  editItems,
  removeItem,
  editItem,
} = cargoRecieversSlice.actions
export default cargoRecieversSlice.reducer

export const fetchCargoRecievers = (use_pagination?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/`, {
        params: {
          use_pagination: true,
        },
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItems(response.data))
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const fetchCargoReciever = (id: number, After: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/auth/users/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        After(response.data)
        dispatch(getItem(response.data))
      })
      .catch((er) => {
         dispatch(setError(er?.response?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const addCargoReciever = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/${URL}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(addItem(response.data))
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const editCargoReciever = (data: any, id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/${URL}/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editItems(res.data))
        dispatch(editItem(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const removeCargoReciever = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .delete(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(removeItem(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import {
  fetchProductBrands,
  fetchProductForSend,
} from "../../../redux/products/productSlice"
import { editCategory } from "../../../redux/cargos/cargo"
import { getProductsType } from "../../../utils/functions"

// Определение типа объекта товара
export type Product = {
  id: number
  name: string
  brand: any
  category: any
  package_type: any
  quantity: number
  place: string
  discount: number
  price_coefficient: number
  space: any
}

type ProductFormProps = {
  setOpen: Function
  updatingCatId?: any
  choosedCatId?: any
}

const EditCatagoryForm: React.FC<ProductFormProps> = ({
  setOpen,
  updatingCatId,
}) => {
  const { produckt, cargo } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<Product>()

  const onSubmit: SubmitHandler<Product> = (data) => {
    dispatch(
      editCategory({
        name: data.name,
        brand: data.brand,
        category: data.category,
        package_type: data.package_type?.value,
        quantity: data.quantity,
        weight: data.place,
        discount: data.discount,
        coefficient: data.price_coefficient,
        space: data.space,
        id: updatingCatId,
      }),
    )
    reset()
    setOpen(false)
  }

  const filtredItems = cargo?.category?.find(
    (item: any) => item?.id === updatingCatId,
  )

  useEffect(() => {
    setValue("name", filtredItems?.name)
    setValue("quantity", filtredItems?.quantity)
    setValue("space", filtredItems?.space)
    setValue("place", filtredItems?.weight)
    setValue("discount", filtredItems?.discount)
    setValue("price_coefficient", filtredItems?.coefficient)
    setValue("category", {
      value: filtredItems?.category?.value,
      label: filtredItems?.category?.label,
      id: filtredItems?.category?.id,
    })
    setValue("brand", {
      value: filtredItems?.brand?.value,
      label: filtredItems?.brand?.label,
      id: filtredItems?.brand?.id,
    })
    setValue("package_type", {
      value: filtredItems?.package_type,
      label: getProductsType(filtredItems?.package_type),
    })

    dispatch(fetchProductForSend())
    dispatch(fetchProductBrands())
  }, [])

  const options = [
    { id: 1, label: "Пакет", value: "plastic_bag" },
    { id: 2, label: "Мешок", value: "sack" },
    { id: 3, label: "Коробка", value: "box" },
  ]

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px",
      cursor: "pointer",
      background: "#fff",
      marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent", // LC Green color on focus
      color: "var(--lc-black-800, #333)",
    }),
  }

  return (
    <div
      className="catalog_items"
      style={{ overflowX: "hidden" }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="catalog_item">
        <div>
          <p>Описания товара:</p>
        </div>
        <div style={{ width: "57%" }}>
          <input
            type="text"
            {...register("name", { required: false })}
            placeholder="Пусто"
            // defaultValue={
            //   cargo.selectedCategory?.name ? cargo.selectedCategory?.name : null
            // }
          />
        </div>
      </div>
      <div className="catalog_item">
        <div>
          <p>Бренд:</p>
        </div>
        <div style={{ width: "57%" }}>
          <Controller
            control={control}
            {...register("brand", { required: false })}
            render={({ field }) => (
              <Select
                isDisabled={false}
                styles={customStyless}
                id="favouriteAvenger"
                {...field}
                placeholder={filtredItems?.brand?.label}
                options={produckt?.brand?.results?.map((item: any) => ({
                  value: item.name,
                  label: item.name,
                  id: item.id,
                }))}
              />
            )}
          />
        </div>
      </div>
      <div className="catalog_item">
        <div>
          <p>Выбор категории товара:</p>
        </div>
        <div style={{ width: "57%" }}>
          <Controller
            control={control}
            {...register("category", { required: false })}
            render={({ field }) => (
              <Select
                isDisabled={false}
                styles={customStyless}
                id="favouriteAvenger"
                {...field}
                placeholder={filtredItems?.category?.label}
                options={produckt?.stockSendGoods?.map((item: any) => ({
                  value: item.name,
                  label: item.name,
                  id: item.id,
                }))}
              />
            )}
          />
        </div>
      </div>
      <div className="catalog_item">
        <div>
          <p>Мешок или пакет:</p>
        </div>
        <div style={{ width: "57%" }}>
          <Controller
            control={control}
            {...register("package_type", { required: false })}
            render={({ field }) => (
              <Select
                isDisabled={false}
                styles={customStyless}
                id="favouriteAvenger"
                {...field}
                // placeholder={getProductsType(
                //   cargo.selectedCategory?.package_type,
                // )}
                // defaultValue={cargo.selectedCategory?.package_type}
                options={options.map((item: any) => ({
                  value: item.value,
                  label: item.label,
                  id: item.id,
                }))}
              />
            )}
          />
        </div>
      </div>
      <div className="catalog_item">
        <div>
          <p>Количество:</p>
        </div>
        <div style={{ width: "57%" }}>
          <input
            className="form_inputs-input-text"
            type="number"
            onWheel={(e) => e.currentTarget.blur()}
            {...register("quantity", { required: false })}
            // defaultValue={cargo.selectedCategory?.quantity}
            placeholder="Пусто"
          />
        </div>
      </div>
      <div className="catalog_item">
        <div>
          <p>
            Мест: <u className="redStar">*</u>
          </p>
        </div>
        <div style={{ width: "58.7%" }}>
          <input
            className="form_inputs-input-text"
            type="number"
            onWheel={(e) => e.currentTarget.blur()}
            {...register("space", { required: true })}
            placeholder="Пусто"
          />
        </div>
      </div>
      <div className="catalog_item">
        <div>
          <p>Вес:</p>
        </div>
        <div style={{ width: "57%" }}>
          <input
            className="form_inputs-input-text"
            type="number"
            onWheel={(e) => e.currentTarget.blur()}
            {...register("place", { required: false })}
            placeholder="Пусто"
            // defaultValue={cargo.selectedCategory?.weight}
          />
        </div>
      </div>
      <div className="catalog_item">
        <div>
          <p>Скидка:</p>
        </div>
        <div style={{ width: "57%" }}>
          <input
            className="form_inputs-input-text"
            type="number"
            onWheel={(e) => e.currentTarget.blur()}
            {...register("discount", { required: false })}
            placeholder="Пусто"
            // defaultValue={cargo.selectedCategory?.discount}
          />
        </div>
      </div>
      <div className="catalog_item">
        <div>
          <p>Коэффициент цены:</p>
        </div>
        <div style={{ width: "57%" }}>
          <input
            className="form_inputs-input-text"
            type="number"
            onWheel={(e) => e.currentTarget.blur()}
            {...register("price_coefficient", { required: false })}
            placeholder="Пусто"
            // defaultValue={cargo?.selectedCategory?.coefficient}
          />
        </div>
      </div>
      <br />
      <div className="form_button" style={{ width: "100%" }}>
        <div style={{ width: "50%" }}>
          <button className="TruckBtn2" onClick={() => setOpen(false)}>
            Отмена
          </button>
        </div>
        <div style={{ width: "50%" }}>
          <button
            disabled={
              errors.category &&
              errors.package_type &&
              errors.brand &&
              errors.price_coefficient
                ? true
                : false
            }
            className="Green-bth"
            onClick={handleSubmit(onSubmit)}
          >
            {"Редактировать категорию"}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                d="M9.08333 4.87565V1.33398L3.875 1.33398C3.52865 1.33398 3.25 1.61263 3.25 1.95898L3.25 14.0423C3.25 14.3887 3.52865 14.6673 3.875 14.6673L12.625 14.6673C12.9714 14.6673 13.25 14.3887 13.25 14.0423L13.25 5.50065H9.70833C9.36458 5.50065 9.08333 5.2194 9.08333 4.87565ZM10.7807 10.5009L9.08333 10.5009L9.08333 12.5842C9.08333 12.8145 8.89687 13.0009 8.66667 13.0009H7.83333C7.60313 13.0009 7.41667 12.8145 7.41667 12.5842L7.41667 10.5009H5.71927C5.3474 10.5009 5.16172 10.0507 5.42578 9.78841L7.93672 7.29622C8.1099 7.12409 8.38958 7.12409 8.56276 7.29622L11.0737 9.78841C11.338 10.0507 11.1526 10.5009 10.7807 10.5009ZM13.0677 4.06836L10.5182 1.51628C10.401 1.39909 10.2422 1.33398 10.0755 1.33398H9.91667V4.66732L13.25 4.66732V4.50846C13.25 4.3444 13.1849 4.18555 13.0677 4.06836Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditCatagoryForm

import { useEffect, useRef, useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { access_token } from "../../services"
import { API_URL } from "../../api/api_config"

import GraphicSliderIcon from "../../assets/svg/graficSlider.svg"
import {
  addItemsLast,
  fetchOwnNotifications,
  makeRead,
  makeReadAsMana,
} from "../../redux/auth/notifications"
import ShowNewUser from "../../components/shipment/showNewUser"
import PaymentConfirmationShow from "../../components/shipment/Modal/paymentImage/paymentShow"
import CargoShow from "../../components/shipment/Modal/confirmCargo/CargoShow"
import { userEditById } from "../../redux/users"
import { GreenBackgroundBtn } from "../../components/btns"
import { setLoading } from "../../redux/loading"
import { fetchProfile } from "../../redux/auth/authSlice"

const Shipment = () => {
  const { notifications, auth } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  // Динамическа пагинация началась
  const [open, setOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)

  const navigate = useNavigate()

  const AfterGet = (data: any) => {
    setTotalCount(data?.count)
  }

  useEffect(() => {
    dispatch(
      fetchOwnNotifications(
        {
          limit: 24,
          offset: currentPage,
          is_read: false,
        },
        AfterGet,
      ),
    )
  }, [])

  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    if (
      target.scrollHeight - (target.scrollTop + target.clientHeight) < 1 &&
      notifications?.data?.results?.length < totalCount
    ) {
      dispatch(setLoading(true))
      axios
        .get(`${API_URL}api/v1/auth/notifications/own/`, {
          params: {
            limit: 24,
            offset: currentPage + 1,
            is_read: false,
          },
          headers: {
            Authorization: `Token ${access_token} `,
          },
        })
        .then((response: any) => {
          dispatch(addItemsLast(response.data.results))
          dispatch(setLoading(false))
        })
        .catch((er) => {})
        .finally(() => {})
    }
  }

  let notiId: any = 0
  const makeReadAsManager = (id: any, userId?: any) => {
    if (notiId) {
      clearTimeout(notiId)
    }
    notiId = setTimeout(() => {
      if (auth.data?.role === "manager") {
        dispatch(
          makeReadAsMana(id, () => {
            dispatch(
              fetchOwnNotifications(
                {
                  limit: 24,
                  offset: currentPage,
                  is_read: false,
                },
                AfterGet,
              ),
            )
          }),
        )
        dispatch(userEditById({ is_confirmed: true }, userId))
        dispatch(fetchProfile())
      } else {
        dispatch(makeRead(id))
        dispatch(fetchProfile())
      }
    }, 3000)
  }

  let timeId: any = 0
  const handleSearch = (e: any) => {
    setCurrentPage(1)
    if (timeId) {
      clearTimeout(timeId)
    }
    timeId = setTimeout(() => {
      dispatch(
        fetchOwnNotifications(
          {
            limit: 24,
            offset: currentPage,
            is_read: false,
            search: e,
          },
          AfterGet,
        ),
      )
    }, 500)
  }

  return (
    <div className="Clients" style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="flex">
            <div onClick={() => navigate(-1)}>
              <GreenBackgroundBtn title={"Назад"} />
            </div>
            <input
              className="SearchBox__input"
              type="text"
              placeholder="Поиск"
              onChange={(e) => handleSearch(e.target.value)}
            />

            <button className="SearchBox_btn" onClick={() => setOpen(!open)}>
              <img src={GraphicSliderIcon} alt="" />
            </button>
          </div>
          <div className="title" style={{ marginTop: "8px" }}>
            Уведомления
          </div>
        </div>
      </div>
      <div
        className="client_height"
        style={{ height: "calc(100% - 38px)", overflowY: "scroll" }}
        ref={scrollableDivRef} //1
        onScroll={scrollHandler}
      >
        <div
          className="Client_cards"
          style={{
            // display: "flex",
            gap: "20px",
            alignItems: "center",
            flexWrap: "wrap",
            width: "99%",
          }}
        >
          {notifications?.data?.results?.length !== 0 ? (
            notifications?.data?.results?.map((item: any) => (
              <>
                {item.type === "new_user" ? (
                  <>
                    <ShowNewUser
                      onMouseOver={(id: number) => {
                        makeReadAsManager(id, item?.inner_payload?.id)
                      }}
                      key={item.id}
                      item={item}
                      id={item?.id}
                    />
                  </>
                ) : null}
                {item.type === "new_cargo" ? (
                  <>
                    <CargoShow item={item} key={item.id} />
                  </>
                ) : null}
                {item.type === "new_receipt" && (
                  <>
                    <PaymentConfirmationShow item={item} key={item.id} />
                  </>
                )}
              </>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Ваши уведомления загружаются, подождите!
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Shipment

import React, { useState } from "react"

import ButonsSvg from "../../assets/svg/infoIcon.svg"
import Avatar from "../../assets/img/no-profile.jpeg"
import { getRoleName } from "../../utils/functions"
import { Modal } from "../Modal"
import AdminPOPinfo from "../StaffPopInfo/AdminPOPinfo"
import { AddClient } from "../clients/Add"
import { Menu1 } from "../clients/Menu1"
import { Menu2 } from "../clients/Menu2"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { userEdit } from "../../redux/users"
import CloseIcon from "../../assets/svg/closeIcon.svg"
import { EditClient } from "../clients/Edit"
import { EditStaff } from "./Edit"

type Props = {
  item: any
  dispatcher?: any
  DeleteUser?: any
}
const StaffCard = ({ item, dispatcher, DeleteUser }: any) => {
  const [modal, setModal] = React.useState(false)
  const [modal2, setModal2] = React.useState(false)
  const [modal1, setModal1] = React.useState(false)
  const [modalEdit, setModalEdit] = useState(false)

  const dispatch = useAppDispatch()
  const { users } = useAppSelector((state: any) => state)

  const editUserById = (data: any, id: any) => {
    dispatch(userEdit(data, id, setModal1, true))
  }
  // const DeleteUser = (id: any, setExit: any) => {
  //     const AfterDelete = () => {
  //       setExit(false)
  //     }
  //     dispatch(userDelete(id, AfterDelete))
  //   }

  return (
    <div>
      <article className="staff">
        <div className="staff_both">
          <div className="staff_image">
            <img src={item.avatar ? item.avatar : Avatar} alt="" />
          </div>
          <div className="staff_info">
            <div className="staff_info-name">
              <h1>
                {item.uuid} | {item.first_name + " " + item.last_name}
              </h1>
              <button onClick={() => setModal(!modal)}>
                <img src={ButonsSvg} alt="" />
              </button>
            </div>
            <div className="staff_info-personal">
              <h2>
                Роль <span>{getRoleName(item.role)}</span>
              </h2>
              <p>
                ID: <span>{item.id ? item.id : "ID"}</span>
              </p>
              <div className="Stock_characteristic-count">
                <h3>
                  Дата регистрации:{" "}
                  <span>
                    {item?.date_joined?.slice(0, 10)
                      ? item?.date_joined?.slice(0, 10)
                      : item?.staff?.created_at?.slice(0, 10)}
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </article>

      <Modal
        modal={modal}
        setModal={setModal}
        hideX={true}
        height={"fit-content"}
        element={
          <AdminPOPinfo
            setModalEdit={setModalEdit}
            dispatcher={dispatcher ? true : false}
            item={item}
            setModal={setModal2}
            setModal1={setModal}
          />
        }
      />

      <Modal
        title={"Редактировать сотрудника"}
        width={"633px"}
        modal={modal2}
        setModal={setModal2}
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "98%",
            }}
          >
            <div className="catalog">
              <EditStaff user_id={item.id} setModal={setModal2} />
            </div>
          </div>
        }
      />

      <div
        style={{ zIndex: "100000" }}
        className={modalEdit ? "exitModal" : "none"}
        onClick={() => setModalEdit(!modalEdit)}
      >
        <div
          className="exitModal__content"
          onClick={(e) => e.stopPropagation()}
        >
          <img
            src={CloseIcon}
            alt=""
            onClick={() => setModalEdit(!modalEdit)}
          />
          <div className="exitModal__content_title">
            Вы действительно хотите удалить сотрудника?
          </div>
          <div className="exitModal__content_btns">
            <button
              className="outline"
              onClick={() => setModalEdit(!modalEdit)}
            >
              Отмена
            </button>
            <button
              className="background"
              onClick={() => DeleteUser(item.id, setModalEdit)}
            >
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StaffCard

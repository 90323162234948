import axios from "axios"
import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { toast } from "react-toastify"

interface NewsCardProps {
  onModal: Function
  setCurentPost: Function
  dispatcher?: any
  item?: any
}

const NewsCard: React.FC<NewsCardProps> = ({
  onModal,
  setCurentPost,
  dispatcher,
  item,
}) => {
  const navigate = useNavigate()

  function formatDateString(dateString: string) {
    const date = new Date(dateString)
    const options = { year: "numeric", month: "long", day: "numeric" }
    return date.toLocaleDateString(undefined, options as any)
  }

  const updateIsPublished = async () => {
    try {
      // Замените URL на адрес вашего сервера
      const url = `${API_URL}api/v1/news/${item?.id}/` // Замените на ваш реальный URL
      const response = await axios
        .patch(
          url,
          { is_published: true },
          {
            headers: {
              Authorization: `Token ${access_token}`,
            },
          },
        )
        .then(() => {
          navigate(`/news/${item?.id}`),
            toast.success("✅ Пост успешно опубликован", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
        })
    } catch (error) {
      console.error("Произошла ошибка:", error)
    }
  }

  return (
    <div className="NewsCard">
      <div>
        <div className="NewsCard_image">
          <div
            className="NewsCard_image-date"
            style={
              item?.is_published === true
                ? { background: "#83C251" }
                : { background: "#EB5757" }
            }
          >
            {item?.is_published === true
              ? `${item?.released_date
                  ?.toString()
                  .slice(8, 10)}.${item?.released_date
                  ?.toString()
                  .slice(5, 7)}.${item?.released_date?.toString().slice(0, 4)}`
              : "Черновик"}
          </div>
          <img src={item?.image} alt="" />
        </div>
        <div className="NewsCard-title">
          <h1>{item?.title}</h1>
          <div>{item?.description}</div>
          <br />
        </div>
      </div>

      <div className="NewsCard-p">
        <div className="NewsCard_more">
          <button
            onClick={() => navigate(`/news/${item?.id}`)}
            style={
              item?.is_published === false
                ? { background: "#EB5757", border: "none" }
                : {}
            }
            className="NewsCard_more-bth"
          >
            Смотреть детально
          </button>

          {!dispatcher && (
            <Link
              to={`/news/edit/${item?.id}`}
              className="NewsCard_more-icon"
              style={{ padding: "12px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M13.3075 12.5734L14.2335 11.6475C14.3782 11.5028 14.6299 11.6041 14.6299 11.8124V16.0197C14.6299 16.7865 14.0078 17.4086 13.241 17.4086H3.05558C2.28878 17.4086 1.66666 16.7865 1.66666 16.0197V5.83429C1.66666 5.06749 2.28878 4.44537 3.05558 4.44537H10.9695C11.175 4.44537 11.2791 4.69422 11.1345 4.84179L10.2085 5.76774C10.1651 5.81114 10.1072 5.83429 10.0436 5.83429H3.05558V16.0197H13.241V12.7355C13.241 12.6747 13.2641 12.6168 13.3075 12.5734ZM17.8389 6.73419L10.2403 14.3327L7.62454 14.6221C6.86642 14.706 6.22115 14.0665 6.30507 13.3026L6.59443 10.6868L14.193 3.08828C14.8556 2.42565 15.9262 2.42565 16.586 3.08828L17.836 4.33831C18.4986 5.00094 18.4986 6.07446 17.8389 6.73419ZM14.98 7.62831L13.2989 5.94714L7.92258 11.3263L7.71135 13.2158L9.60086 13.0046L14.98 7.62831ZM16.8551 5.32213L15.605 4.0721C15.4864 3.95346 15.2925 3.95346 15.1768 4.0721L14.2827 4.96622L15.9638 6.64739L16.858 5.75327C16.9737 5.63174 16.9737 5.44076 16.8551 5.32213Z"
                  fill="white"
                />
              </svg>
            </Link>
          )}

          {!dispatcher && (
            <button
              onClick={() => {
                onModal(true), setCurentPost(item?.id)
              }}
              style={{ background: "#EB5757", padding: "9px 12px" }}
              className="NewsCard_more-icon"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M16.7708 2.70837H3.22916C2.36653 2.70837 1.66666 3.40824 1.66666 4.27087V15.7292C1.66666 16.5918 2.36653 17.2917 3.22916 17.2917H16.7708C17.6335 17.2917 18.3333 16.5918 18.3333 15.7292V4.27087C18.3333 3.40824 17.6335 2.70837 16.7708 2.70837ZM16.7708 15.5339C16.7708 15.6413 16.6829 15.7292 16.5755 15.7292H3.42447C3.31705 15.7292 3.22916 15.6413 3.22916 15.5339V4.46619C3.22916 4.35876 3.31705 4.27087 3.42447 4.27087H16.5755C16.6829 4.27087 16.7708 4.35876 16.7708 4.46619V15.5339ZM13.2715 8.00134L11.2728 10L13.2715 11.9987C13.4212 12.1485 13.4212 12.3926 13.2715 12.5456L12.5456 13.2715C12.3958 13.4213 12.1517 13.4213 11.9987 13.2715L9.99999 11.2728L8.00129 13.2715C7.85155 13.4213 7.60741 13.4213 7.45442 13.2715L6.72851 12.5456C6.57877 12.3959 6.57877 12.1517 6.72851 11.9987L8.7272 10L6.72851 8.00134C6.57877 7.8516 6.57877 7.60746 6.72851 7.45447L7.45442 6.72856C7.60416 6.57882 7.8483 6.57882 8.00129 6.72856L9.99999 8.72725L11.9987 6.72856C12.1484 6.57882 12.3926 6.57882 12.5456 6.72856L13.2715 7.45447C13.4245 7.60421 13.4245 7.84835 13.2715 8.00134Z"
                  fill="white"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default NewsCard

import { useState } from "react"
import { Link } from "react-router-dom"

import User from "../../assets/svg/userIcon.svg"
import TruckImg from "../../assets/img/Rectangle 413.png"

import DeleteModal from "./Delete/index"
import EditIcon from "../../assets/svg/editIcon.svg"
import DriverPOPinfo from "../StaffPopInfo/DriverPOPinfo"
import { formatPhoneNumber, getTruckStatus } from "../../utils/functions"
import StarRating from "./starRating"
import TruckModel1 from "../../assets/svg/theLongestTruck.svg"
import TruckModel2 from "../../assets/svg/TruckModel2.svg"
import TruckModel3 from "../../assets/svg/theSemiTrailer.svg"
import TruckHead from "../../assets/svg/theHeadOfTruck.svg"

interface Props {
  dispatcher?: any
  item: any
}

const TruckCard = ({ item, dispatcher }: Props) => {
  const [exit, setExit] = useState(false)
  const [infoModal, setInfoModal] = useState(false)


  return (
    <>
      <div
        className={
          item?.status === "waiting" ? "TruckCard onhover" : "TruckCard"
        }
      >
        <div className="TruckCard__inner">
          {!dispatcher && (
            <div className="closed">
              <svg
                onClick={() => setExit(!exit)}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M16.7707 2.70834H3.229C2.36637 2.70834 1.6665 3.40821 1.6665 4.27084V15.7292C1.6665 16.5918 2.36637 17.2917 3.229 17.2917H16.7707C17.6333 17.2917 18.3332 16.5918 18.3332 15.7292V4.27084C18.3332 3.40821 17.6333 2.70834 16.7707 2.70834ZM16.7707 15.5339C16.7707 15.6413 16.6828 15.7292 16.5754 15.7292H3.42432C3.31689 15.7292 3.229 15.6413 3.229 15.5339V4.46616C3.229 4.35873 3.31689 4.27084 3.42432 4.27084H16.5754C16.6828 4.27084 16.7707 4.35873 16.7707 4.46616V15.5339ZM13.2713 8.00131L11.2726 10L13.2713 11.9987C13.4211 12.1484 13.4211 12.3926 13.2713 12.5456L12.5454 13.2715C12.3957 13.4212 12.1515 13.4212 11.9985 13.2715L9.99984 11.2728L8.00114 13.2715C7.8514 13.4212 7.60726 13.4212 7.45426 13.2715L6.72835 12.5456C6.57861 12.3958 6.57861 12.1517 6.72835 11.9987L8.72705 10L6.72835 8.00131C6.57861 7.85157 6.57861 7.60743 6.72835 7.45444L7.45426 6.72853C7.604 6.57879 7.84815 6.57879 8.00114 6.72853L9.99984 8.72722L11.9985 6.72853C12.1483 6.57879 12.3924 6.57879 12.5454 6.72853L13.2713 7.45444C13.4243 7.60418 13.4243 7.84832 13.2713 8.00131Z"
                  fill="#828282"
                />
              </svg>
            </div>
          )}
          <div className="TruckCard__inner_content">
            <div className="TruckCard__inner_content-titles">
              <div className="TruckCard__inner_content-titles--maintitle">
                <div style={{ whiteSpace: "nowrap" }}>
                  {item?.driver?.user?.first_name +
                    " " +
                    item?.driver?.user?.last_name}
                </div>
                <button onClick={() => setInfoModal(!infoModal)}>
                  <img src={User ? User : TruckImg} alt="" />
                </button>
              </div>
              <div className="TruckCard__inner_content-titles--subtitle">
                <p className="TruckCard__inner_content-titles--subtitle_status">
                  <p style={{ display: "flex", gap: "5px" }}>
                    Статус:
                    <u
                      style={
                        item?.status === "on_way"
                          ? { color: "#83C251" }
                          : { color: "#F2994A" }
                      }
                    >
                      {getTruckStatus(item?.status)}
                    </u>
                  </p>
                  <p style={{ display: "flex", gap: "5px" }}>
                    Рейтинг: <StarRating initialRating={item.rating} />
                  </p>
                </p>
                <div>
                  ID: <u>{item?.id}</u>
                </div>
                <div>
                  Номер телефона:{" "}
                  <u>{formatPhoneNumber(item?.driver?.user?.phone)}</u>
                </div>
                <div>
                  Номер машины: <u>{item?.number}</u>
                </div>
              </div>
            </div>
            <div
              className="TruckCard__inner_content-image"
              style={
                item.image === null ? { padding: "5px" } : { padding: "0" }
              }
            >
              <img
                style={{
                  height: "100px",
                  width: "100%",
                  // objectFit: "cover",
                  borderRadius: "8px",
                }}
                src={
                  item.image
                    ? item.image
                    : item?.type === "head"
                    ? TruckHead
                    : item?.type === "truck"
                    ? TruckModel2
                    : item.type === "trailer"
                    ? TruckModel1
                    : TruckModel3
                }
                alt=""
              />
            </div>
          </div>

          <div className="TruckCard__bottom">
            {item?.status == "on_way" ? (
              <div className="TruckCard__bottom_info">
                {item?.current_transportation?.cargo_general_transport?.transportation?.routes?.map(
                  (item: any, index: number) => (
                    <>
                      {0 === index ? null : (
                        <div className="TruckCard__bottom_info-line"></div>
                      )}
                      <div className="TruckCard__bottom_info-number">
                        {item?.stock?.city?.name}
                      </div>
                    </>
                  ),
                )}
              </div>
            ) : (
              <div></div>
            )}

            <Link to={`edit/${item.id}`}>
              <button
                className={
                  item?.status === "waiting"
                    ? "waiting"
                    : "ClientsInfo__content_left_btns-editBtn"
                }
              >
                <img src={EditIcon ? EditIcon : TruckImg} alt="" />
              </button>
            </Link>
          </div>
        </div>
      </div>
      <DeleteModal exit={exit} setExit={setExit} id={item.id} />

      {infoModal && (
        <div className="modal" onClick={() => setInfoModal(!infoModal)}>
          <DriverPOPinfo
            item={item}
            active={infoModal}
            setActive={setInfoModal}
          />
        </div>
      )}
    </>
  )
}

export default TruckCard

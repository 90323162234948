import React from "react"
import { GreenBackgroundBtn } from "../btns"
import EditIcon from "../../assets/svg/editIcon.svg"
import NoImage from "../../assets/img/no-profile.jpeg"
import {
  getInsurance,
  getRoleName,
  getTruckStatus,
  getTrucksType,
} from "../../utils/functions"
interface AdminPOPinfoProps {
  item: any
  active: boolean
  setActive: any
}
const DriverPOPinfo: React.FC<AdminPOPinfoProps> = ({
  active,
  setActive,
  item,
}) => {
  return active ? (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{
        background: "#fff",
        borderRadius: "10px",
        padding: "1rem",
        width: "100%",
        maxWidth: "500px",
      }}
    >
      <div className="POP_head">
        <div></div>
        <div onClick={() => setActive(!active)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Font Awesome Free/Regular/window-close">
              <path
                id="Vector"
                d="M20.125 3.25H3.875C2.83984 3.25 2 4.08984 2 5.125V18.875C2 19.9102 2.83984 20.75 3.875 20.75H20.125C21.1602 20.75 22 19.9102 22 18.875V5.125C22 4.08984 21.1602 3.25 20.125 3.25ZM20.125 18.6406C20.125 18.7695 20.0195 18.875 19.8906 18.875H4.10938C3.98047 18.875 3.875 18.7695 3.875 18.6406V5.35938C3.875 5.23047 3.98047 5.125 4.10938 5.125H19.8906C20.0195 5.125 20.125 5.23047 20.125 5.35938V18.6406ZM15.9258 9.60156L13.5273 12L15.9258 14.3984C16.1055 14.5781 16.1055 14.8711 15.9258 15.0547L15.0547 15.9258C14.875 16.1055 14.582 16.1055 14.3984 15.9258L12 13.5273L9.60156 15.9258C9.42188 16.1055 9.12891 16.1055 8.94531 15.9258L8.07422 15.0547C7.89453 14.875 7.89453 14.582 8.07422 14.3984L10.4727 12L8.07422 9.60156C7.89453 9.42188 7.89453 9.12891 8.07422 8.94531L8.94531 8.07422C9.125 7.89453 9.41797 7.89453 9.60156 8.07422L12 10.4727L14.3984 8.07422C14.5781 7.89453 14.8711 7.89453 15.0547 8.07422L15.9258 8.94531C16.1094 9.125 16.1094 9.41797 15.9258 9.60156V9.60156Z"
                fill="black"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="POP_Info">
        <div className="staff_image">
          <img src={item?.image ? item?.image : NoImage} alt="" />
        </div>
        <div className="staff_info">
          <div className="staff_info-name">
            <h1>
              {item?.driver?.user?.last_name +
                " " +
                item?.driver?.user?.first_name || "Не указано"}
            </h1>
          </div>
          <div className="staff_info-personal">
            <h2>
              Статус:
              <span
                style={
                  item?.status === "on_way"
                    ? { color: "#83C251", marginLeft: "5px" }
                    : { color: "#F2994A", marginLeft: "5px" }
                }
              >
                {getTruckStatus(item?.status)}
              </span>
            </h2>
            <p style={{ marginTop: "4px" }}>
              ID: <span>{item?.id}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="POP_bottom">
        <div className="ClientsInfo__content_left-info--inner">
          <div>
            <div className={item?.role === "driver" ? "Photo" : "displayNone"}>
              <div className="Photo_title">
                <div>Фото грузовика</div>
              </div>
              <div className="Photo_image">
                <img
                  className=""
                  style={{
                    maxWidth: "200px",
                    maxHeight: "100px",
                  }}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2TDZ8YAW1Rlsz0S4txihQzjQ7yMatTsJHYKurZZBW_Aa4-nf5la_xbsV1JHa7EqIVETA&usqp=CAU"
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* <div>
              Тариф: <u>Обычный</u>
            </div>
            <div>
              Город: <u>Бишкек</u>
            </div>
            <div>
              Адрес: <u>ул. Такаячтобызапонить </u>
            </div> */}
          <div>
            Номер телефона:
            <u>
              {item.driver?.user?.phone
                ? item.driver?.user?.phone
                : "Номер не указан!"}
            </u>
          </div>
          <div>
            Номер машины: <u>{item?.number ? item?.number : "Не указано"}</u>
          </div>
          <div>
            Год выпуска машины:{" "}
            <u>{item?.released_year ? `${item?.released_year}г` : "Не указано"}</u>
          </div>

          <div>
            Грузоподёмность: <u>{item?.lifting_capacity ? `${item?.lifting_capacity}кг` : "Не указано"}</u>
          </div>
          <div>
            Модель машины: <u>{item?.model?.name || "Не указано"}</u>
          </div>
          <div>
            Тип машины: <u>{getTrucksType(item?.type) || "Не указано"}</u>
          </div>
          <div>
            Техническое состояние машины:
            <u>
              {item?.tech_conditions?.length > 0
                ? item?.tech_conditions?.map((item: any) => item.name)
                : "Не указано"}
            </u>
          </div>
          <div>
            Зарегистрированная страна:{" "}
            <u>{item?.registered_country?.name || "Не указано"}</u>
          </div>
          <div>
            Лицензии на машину:
            <u>
              {item?.licenses?.length > 0
                ? item?.licenses?.map((item: any) => item.name)
                : "Не указано"}
            </u>
          </div>
          <div>
            Страховка машины:{" "}
            <u>{getInsurance(item?.insurance) || "Не указано"}</u>
          </div>
          <div>
            Бренд машины:
            <u>{item?.brand?.name || "Не указано"}</u>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default DriverPOPinfo

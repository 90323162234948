import React, { useState } from "react"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import svg from "../../assets/svg/cont.svg"
import NoIcon from "../../assets/svg/noIcon.svg"
import NoIconGreen from "../../assets/svg/noIcongreen.svg"
import { Modal } from "../Modal"
import { useAppDispatch } from "../../redux/hooks"
import { setMessages } from "../../redux/chats"

interface ContactProps {
  item: any
}

const ConactListItem: React.FC<ContactProps> = ({ item }) => {
  const { uuid, status } = useParams()

  const [open, setOpen] = useState(false)
  const [modal, setModal] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  return (
    <>
      <div
        className={uuid === item?.uuid ? "contactActive" : "contact"}
        onClick={() => {
          dispatch(setMessages([]))
          navigate(`/chat/${item?.uuid}/${status}`)
        }}
        style={{
          position: "relative",
          cursor: "pointer",
        }}
      >
        <div className="contact-person">
          <div>
            <div>
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  objectFit: "cover",
                  borderRadius: "30px",
                }}
                src={
                  item.user?.avatar ||
                  (item.uuid === uuid ? NoIcon : NoIconGreen)
                }
                alt=""
              />
            </div>
          </div>
          <div className="contact__info">
            <h1>{item.user?.first_name}</h1>
            <h1>{item.user?.last_name}</h1>
            <h1 style={{
              position: 'absolute',
              top: '8px',
              left: '30px',
            }}>{item.user.online ? <div style={{
              width: '10px',
              height: '10px',
              borderRadius: '100%',

              background:  item.uuid === uuid ? 'white' : '#83c251',

            }}>

            </div> : ''}</h1>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <div
            onClick={() => setModal(true)}
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M7.99967 1.33333C4.31803 1.33333 1.33301 4.31943 1.33301 8C1.33301 11.6827 4.31803 14.6667 7.99967 14.6667C11.6813 14.6667 14.6663 11.6827 14.6663 8C14.6663 4.31943 11.6813 1.33333 7.99967 1.33333ZM7.99967 4.29032C8.62322 4.29032 9.12871 4.7958 9.12871 5.41935C9.12871 6.0429 8.62322 6.54838 7.99967 6.54838C7.37613 6.54838 6.87064 6.0429 6.87064 5.41935C6.87064 4.7958 7.37613 4.29032 7.99967 4.29032ZM9.50505 11.1183C9.50505 11.2964 9.36062 11.4409 9.18247 11.4409H6.81688C6.63873 11.4409 6.4943 11.2964 6.4943 11.1183V10.4731C6.4943 10.295 6.63873 10.1505 6.81688 10.1505H7.13946L7.13946 8.4301H6.81688C6.63873 8.4301 6.4943 8.28567 6.4943 8.10752V7.46236C6.4943 7.28422 6.63873 7.13978 6.81688 7.13978H8.53731C8.71545 7.13978 8.85989 7.28422 8.85989 7.46236V10.1505H9.18247C9.36062 10.1505 9.50505 10.295 9.50505 10.4731V11.1183Z"
                fill={item.uuid !== uuid ? `#808080` : `white`}
              />
            </svg>
          </div>

          {status === 'active' &&
            <div
              onClick={() => setModal(true)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: 'center',
                position: "relative",
                width: '20px',
                height: '20px',
                fontSize: '13px',
                background: item.uuid !== uuid ? `#83c251` : `white`,
                borderRadius: '100%',
                color: item.uuid === uuid ? `black` : `white`

              }}
            >
              {item?.unread_messages || 0}
            </div>
          }
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.64645 14.3536C4.45118 14.1583 4.45118 13.8417 4.64645 13.6464L10.2929 8L4.64645 2.35355C4.45118 2.15829 4.45118 1.84171 4.64645 1.64645C4.84171 1.45118 5.15829 1.45118 5.35355 1.64645L11.3536 7.64645C11.5488 7.84171 11.5488 8.15829 11.3536 8.35355L5.35355 14.3536C5.15829 14.5488 4.84171 14.5488 4.64645 14.3536Z"
              fill={item.uuid !== uuid ? `#808080` : `white`}
            />
          </svg>
        </div>
      </div>
      {modal && (
        <div className="modal-background">
          <div className="chat_forComment">
            {item?.name}
            <div
              onClick={() => setModal(false)}
              className="chat_forComment_close"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.125 3.25H3.875C2.83984 3.25 2 4.08984 2 5.125V18.875C2 19.9102 2.83984 20.75 3.875 20.75H20.125C21.1602 20.75 22 19.9102 22 18.875V5.125C22 4.08984 21.1602 3.25 20.125 3.25ZM20.125 18.6406C20.125 18.7695 20.0195 18.875 19.8906 18.875H4.10938C3.98047 18.875 3.875 18.7695 3.875 18.6406V5.35938C3.875 5.23047 3.98047 5.125 4.10938 5.125H19.8906C20.0195 5.125 20.125 5.23047 20.125 5.35938V18.6406ZM15.9258 9.60156L13.5273 12L15.9258 14.3984C16.1055 14.5781 16.1055 14.8711 15.9258 15.0547L15.0547 15.9258C14.875 16.1055 14.582 16.1055 14.3984 15.9258L12 13.5273L9.60156 15.9258C9.42188 16.1055 9.12891 16.1055 8.94531 15.9258L8.07422 15.0547C7.89453 14.875 7.89453 14.582 8.07422 14.3984L10.4727 12L8.07422 9.60156C7.89453 9.42188 7.89453 9.12891 8.07422 8.94531L8.94531 8.07422C9.125 7.89453 9.41797 7.89453 9.60156 8.07422L12 10.4727L14.3984 8.07422C14.5781 7.89453 14.8711 7.89453 15.0547 8.07422L15.9258 8.94531C16.1094 9.125 16.1094 9.41797 15.9258 9.60156Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ConactListItem

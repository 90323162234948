import React, { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import TruckCard from "../../components/trucks/TruckCard"
import GraphicSliderIcon from "../../assets/svg/graficSlider.svg"
import {
  fetchTrucks,
  addItemsLast,
  addTruck,
  removeTruck,
} from "../../redux/cargos/trucks"
import { API_URL } from "../../api/api_config"
import axios from "axios"
import { access_token } from "../../services"
import { Modal } from "../../components/Modal"
import { AddTruck } from "../../components/trucks/Add"
import { AddCargo } from "../../components/cargos/Add"
import { setLoading } from "../../redux/loading"
import { getInsuranceById } from "../../utils/functions"
import PlusIcon from "../../assets/svg/plusIcon.svg"

type Inputs = {
  username: string
  password: string
}

const Index = () => {
  const { trucks, auth } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  const [open, setOpen] = React.useState(true)
  const [onModal, setOnModal] = useState(false)
  const [onGoodsModal, setOnGoodsModal] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [allItems, setAllItems] = useState<any>("")
  const [onWay, setOnWay] = useState<any>(false)
  const [waiting, setWaiting] = useState(false)

  const AfterGet = (data: any) => {
    setTotalCount(data?.count)
  }

  useEffect(() => {
    dispatch(
      fetchTrucks(
        {
          limit: 24,
          offset: currentPage,
          status:
            onWay === true
              ? "on_way"
              : allItems || waiting === true
              ? "waiting"
              : allItems,
        },
        AfterGet,
      ),
    )
  }, [onWay, waiting])

  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    if (
      target.scrollHeight - (target.scrollTop + target.clientHeight) < 1 &&
      trucks?.data?.results?.length < totalCount
    ) {
      dispatch(setLoading(true))
      axios
        .get(`${API_URL}api/v1/cargo/transports/`, {
          params: {
            limit: 24,
            offset: currentPage + 1,
            status:
              onWay === true
                ? "on_way"
                : allItems || waiting === true
                ? "waiting"
                : allItems,
          },
          headers: {
            Authorization: `Token ${access_token} `,
          },
        })
        .then((response: any) => {
          dispatch(addItemsLast(response.data.results))
        })
        .catch((er) => {
          dispatch(setLoading(false))
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }
  // Динамическа пагинация закончилась

  let timeID: any = 0

  const handleSearch = (event: any) => {
    setCurrentPage(1)
    if (timeID) {
      clearTimeout(timeID)
    }
    timeID = setTimeout(() => {
      dispatch(
        fetchTrucks(
          {
            limit: 24,
            offset: currentPage,
            search: event,
            status:
              onWay === true
                ? "on_way"
                : allItems || waiting === true
                ? "waiting"
                : allItems,
          },
          AfterGet,
        ),
      )
    }, 500)
  }
  const setScrollPosition = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = 0
    }
  }

  // Handle the first checkbox click
  const handleCheckbox1Click = () => {
    setScrollPosition()
    setCurrentPage(1)
    setOnWay(!onWay)
    setWaiting(false)
  }

  // Handle the second checkbox click
  const handleCheckbox2Click = () => {
    setScrollPosition()
    setCurrentPage(1)
    setOnWay(false)
    setWaiting(!waiting)
  }

  return (
    <div className="Trucks">
      <div className="spaceBetween" style={{ flexWrap: "nowrap" }}>
        <div className="Trucks__header" style={{ width: "100%" }}>
          <div className="flex" style={{ gap: "15px" }}>
            <div onClick={() => setOnGoodsModal(!onGoodsModal)}>
              {auth?.data?.role !== "dispatcher" && (
                <button className="stock_button">
                  <img src={PlusIcon} alt="" />
                  <div className="button_title"> Добавить груз</div>
                </button>
              )}
            </div>

            <div className="SearchBox">
              <input
                className="SearchBox__input"
                type="text"
                placeholder="Поиск"
                onChange={(e) => {
                  handleSearch(e.target.value)
                }}
              />
              {open ? (
                <>
                  {/* <div style={{ whiteSpace: "nowrap" }}>Количество к.м.</div>
                  <div className="flex">
                    <div className="SearchBox">
                      <div>От</div>
                      <input
                        className="Trucks_filter"
                        type="number"
                        onWheel={(e) => e.currentTarget.blur()}
                        defaultValue={100}
                      />
                    </div>
                    <div className="SearchBox">
                      <div>До</div>
                      <input
                        className="Trucks_filter"
                        type="number"
                        onWheel={(e) => e.currentTarget.blur()}
                        defaultValue={100000}
                      />
                    </div>
                  </div> */}

                  <div className="Trucks__header_check">
                    <label
                      className="label__for_truck"
                      style={{
                        whiteSpace: "nowrap",
                        width: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={onWay}
                        onChange={handleCheckbox1Click}
                        className="customfor_truck"
                      />
                      В пути
                    </label>

                    <label
                      className="label__for_truck"
                      style={{
                        whiteSpace: "nowrap",
                        width: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={waiting}
                        onChange={handleCheckbox2Click}
                        className="customfor_truck"
                      />
                      В ожидании
                    </label>
                  </div>
                </>
              ) : null}

              <button
                style={{
                  marginLeft: "5px",
                }}
                className="SearchBox_btn"
                onClick={() => setOpen(!open)}
              >
                <img src={GraphicSliderIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <div onClick={() => setOnModal(!onModal)}>
            {auth?.data?.role !== "dispatcher" && (
              <button className="stock_button">
                <img src={PlusIcon} alt="" />
                <div className="button_title2">Добавить</div>
              </button>
            )}
          </div>
          <div className="Clients__header_btns">
            <div className="title" style={{ paddingLeft: "0px" }}>
              Грузовики
            </div>
          </div>
        </div>
      </div>
      {trucks?.data?.results?.length === 0 && (
        <div style={{ display: "flex", justifyContent: "center" }} className="">
          Пусто
        </div>
      )}

      <div className="Trucks__height">
        <div
          className="Trucks__cards"
          ref={scrollableDivRef}
          onScroll={scrollHandler}
        >
          {trucks?.data?.results !== undefined &&
            trucks?.data?.results?.map((item: any) => (
              <TruckCard
                dispatcher={auth?.data?.role === "dispatcher" ? true : false}
                key={item.id}
                item={item}
              />
            ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: trucks?.loading ? "10vh" : "fit-content",
            width: "100%",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {trucks?.data?.results?.length !== 0 ? "" : "Пусто!"}
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={"Добавить новый грузовик"}
        modal={onModal}
        width={"630px"}
        height={"80vh"}
        setModal={setOnModal}
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div className="catalog" style={{ margin: 0, marginTop: "20px" }}>
              <AddTruck
                setOnModal={setOnModal}
                setTotalCount={setTotalCount}
                page={currentPage}
              />
            </div>
          </div>
        }
      />
      <Modal
        width={"630px"}
        modal={onGoodsModal}
        title={"Добавить новый груз"}
        setModal={setOnGoodsModal}
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div className="catalog">
              <AddCargo setModal={setOnGoodsModal} modal={onGoodsModal} />
            </div>
          </div>
        }
      />
    </div>
  )
}

export default Index

import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../../api/api_config"
import { access_token } from "../../../services"
import { toast } from "react-toastify"
import { setLoading } from "../../loading"

const initialState: any = {
  loading: false,
  data: [],
  oneData: {},
  calcData: {},
  category: [],
  error: false,
  selectedCategory: false,
}

const URL = "cargo"

const cargoSlice = createSlice({
  name: "cargo",
  initialState,
  reducers: {
    setSelectedCategory: (state, { payload }) => {
      state.selectedCategory = payload
    },
    getItems: (state, { payload }) => {
      state.data = payload
    },
    getItem: (state, { payload }) => {
      state.oneData = payload
    },
    addItem(state, { payload }) {
      state.data.push({ payload })
    },
    setCategories: (state, { payload }) => {
      state.category = payload
    },
    addCategory: (state, { payload }) => {
      state.category.push(payload)
    },
    setCategory: (state, { payload }) => {
      state.category = []
    },
    editCategory: (state, { payload }) => {
      state.selectedCategory = payload

      const updatetResults = state.category.map((item: any) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
      state.category = updatetResults
    },
    calcPrice(state, { payload }) {
      state.calcData = payload
    },
    editItems: (state, { payload }) => {
      const updatedResults = state.data.results.map((item: any) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
      state.data.results = updatedResults
    },
    editItem: (state, { payload }) => {
      state.oneData = payload
    },
    removeItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    deleteCategory(state, { payload }) {
      state.category = state.category.filter((item: any) => item.id !== payload)
    },
    addItemsLast: (state, { payload }) => {
      state.data.results.push(...payload)
    },
    clearCalculatedItems: (state, { payload }) => {
      state.calcData = payload
    },
    clearCategories: (state, {payload}) => {
      state.category = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const {
  clearCalculatedItems,
  setSelectedCategory,
  getItems,
  getItem,
  setError,
  addItem,
  addCategory,
  editItems,
  removeItem,
  addItemsLast,
  editItem,
  calcPrice,
  setCategories,
  deleteCategory,
  editCategory,
  setCategory,
  clearCategories,
} = cargoSlice.actions
export default cargoSlice.reducer

export const fetchCargos = (params: any, SetFunction?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/`, {
        params: params,
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItems(response.data))
        SetFunction(response.data)
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const fetchCargo = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItem(response.data))
        if (response.data?.products?.length !== 0) {
          dispatch(setCategories(response.data?.products))
        }
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const addCargo = (data: any, setFunc: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/${URL}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        setFunc()
        dispatch(addItem(response.data))
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const CalcData = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/${URL}/calculate-cargo/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(calcPrice(response.data))
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const editCargo = (data: any, id: any, setFunc?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/cargo/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        setFunc()
        dispatch(editItem(res.data))
        dispatch(editItems(res.data))
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const editResiverCargo = (data: any, id: any, setFunction: Function) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/cargo/receivers/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editItem(res.data))
        dispatch(editItems(res.data))
      })
      .catch((er) => {
        dispatch(setError(er?.res?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
        setFunction(false)
      })
  }
}

export const editCargoStatus = (data: any, id: any, setRes?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/cargo/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editItem(res.data))
        setRes()
        toast.success("Груз успешно подтвержден!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        dispatch(editItems(res.data))

        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setError(er?.res?.data))
      })
      .finally(() => {})
  }
}

export const removeCargo = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .delete(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(removeItem(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

import React, { useEffect, useState } from "react"
import Location from "../../../../assets/svg/location.svg"
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import NoImage from "../../../../assets/img/no-profile.jpeg"
import ConfirmCargo from "../.././Modal/confirmCargo"
import { ToastContainer } from "react-toastify"
import { fetchCities } from "../../../../redux/cargos/cities"
import LocationIcon from "../../../../assets/svg/location.svg"
import { getPaymentStatus } from "../../../../utils/functions"
import { Modal } from "../../../Modal"
import { EditCargo } from "../../../Forms/GoodsEdit/EditGoodsForm"

const CargoShow = ({ item }: any) => {
  const { cities } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  const [onModal, setOnModal] = useState(false)
  const [cargoModal, setCargoModal] = useState(false)

  const sendingCity = cities.data.map((city: any) =>
    city.id === item?.inner_payload?.sender?.city ? city?.name : null,
  )
  const recievingCity = cities.data.map((city: any) =>
    city.id === item?.inner_payload?.receiver.city ? city?.name : null,
  )

  return (
    <>
      {item?.inner_payload?.status === "waiting" ? (
        <div
          className="AddedNewUser"
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginBottom: "12px",
          }}
        >
          <div className="AddedNewUser_avatar">
            <img src={item?.image ? item?.image : NoImage} alt="" />
          </div>
          <div className="CargoShow__titles">
            <div style={{ width: "45%", marginTop: "8px" }}>
              <div
                className="PaymentNotiCard__leftSide_about"
                style={{ flexWrap: "nowrap" }}
              >
                <div className="PaymentNotiCard__leftSide_about-client">
                  <div className="PaymentNotiCard__leftSide_about-client--top">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "30px",
                      }}
                    >
                      <div>
                        <div className="PaymentNotiCard__leftSide_about-client--top_title">
                          Запрос на новый груз
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            color: "#1A1A1A",
                            fontWeight: "500",
                            marginTop: "4px",
                          }}
                        >
                          ID:
                          <u>{item?.inner_payload?.id}</u>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="PaymentNotiCard__leftSide_about-way"
                  style={{ width: "auto" }}
                >
                  <div className="PaymentNotiCard__leftSide_about-way-flex">
                    <div className="PaymentNotiCard__leftSide_about-way--country">
                      <img src={LocationIcon} alt="" />
                      {item?.inner_payload?.sender.city?.name}
                    </div>
                    <br />
                    <div className="PaymentNotiCard__leftSide_about-way--line"></div>
                    <div className="PaymentNotiCard__leftSide_about-way--country">
                      <img src={LocationIcon} alt="" />
                      {item?.inner_payload?.receiver.city?.name}
                    </div>
                  </div>
                  <div className="PaymentNotiCard__leftSide_about-way-flex">
                    <div className="PaymentNotiCard__leftSide_about-way--country">
                      <img src={LocationIcon} alt="" />
                      {item?.inner_payload?.sender.stock?.address}
                    </div>
                    <br />
                    <div className="PaymentNotiCard__leftSide_about-way--line"></div>
                    <div className="PaymentNotiCard__leftSide_about-way--country">
                      <img src={LocationIcon} alt="" />
                      {item?.inner_payload?.receiver.stock?.address}
                    </div>
                  </div>
                </div>
              </div>
              <div className="PaymentNotiCard__leftSide_btns">
                <button
                  className="red-bth-with-white-back"
                  onClick={() => setCargoModal(!cargoModal)}
                >
                  Смотреть детально
                </button>
                <button
                  className="Green-bth"
                  onClick={() => setOnModal(!onModal)}
                >
                  Потвердить
                </button>
              </div>
            </div>
            <div className="CargoShow__titles_center">
              <div
                className="MapsCard__content_counts"
                style={{ marginBottom: "10px" }}
              >
                <div className="MapsCard__content_counts-count">
                  Бренд:{" "}
                  <b>
                    {item?.inner_payload?.products
                      ?.map((brand: any) => brand?.brand?.name)
                      ?.join(" ")}
                  </b>
                </div>
                <div></div>
                <div className="MapsCard__content_counts-placeCount"></div>
              </div>
              <div
                className="MapsCard__content_counts"
                style={{ marginBottom: "10px" }}
              >
                <div className="MapsCard__content_counts-count">
                  Описания товара:{" "}
                  <b>
                    {item?.inner_payload?.products
                      ?.map((brand: any) => brand?.name)
                      ?.join(" ")}
                  </b>
                </div>
                <div></div>
                <div className="MapsCard__content_counts-placeCount"></div>
              </div>
              <div
                className="MapsCard__content_counts"
                style={{ border: "none" }}
              >
                <div className="MapsCard__content_counts-count">
                  Количесво:{" "}
                  <b>
                    {item?.inner_payload?.products?.map(
                      (brand: any) => brand?.quantity,
                    )}
                  </b>
                </div>
                <div
                  className="MapsCard__content_counts-border"
                  style={{ margin: "0 15px" }}
                ></div>
                <div className="MapsCard__content_counts-placeCount">
                  Количество мест: <b>{12}</b>
                </div>
              </div>
            </div>
            <div className="CargoShow__titles_right">
              <div
                className="MapsCard__content_counts"
                style={{ marginBottom: "10px" }}
              >
                <div className="MapsCard__content_counts-count">
                  Выбор склада отправки:{" "}
                  <b>{item?.inner_payload?.sender?.stock?.name}</b>
                </div>
                <div></div>
                <div className="MapsCard__content_counts-placeCount"></div>
              </div>
              <div
                className="MapsCard__content_counts"
                style={{ marginBottom: "10px" }}
              >
                <div className="MapsCard__content_counts-count">
                  Выбор склада получения:{" "}
                  <b>{item?.inner_payload?.receiver.stock?.name}</b>
                </div>
                <div></div>
                <div className="MapsCard__content_counts-placeCount"></div>
              </div>
              <div
                className="MapsCard__content_counts"
                style={{ border: "none" }}
              >
                <div className="MapsCard__content_counts-count">
                  Общая стоимость: <b>{item?.inner_payload?.total_price}</b>
                </div>
                <div
                  className="MapsCard__content_counts-border"
                  style={{ margin: "0 15px" }}
                ></div>
                <div className="MapsCard__content_counts-placeCount">
                  Вес: <b>{item?.inner_payload?.total_weight}</b>
                </div>
              </div>
            </div>
          </div>

          {onModal && (
            <div
              className="modal-background"
              onClick={() => setOnModal(!onModal)}
            >
              <ConfirmCargo
                id={item?.id}
                onModal={onModal}
                setOnModal={setOnModal}
              />
            </div>
          )}

          <Modal
            title={"Редактировать груз"}
            modal={cargoModal}
            width={"630px"}
            setModal={setCargoModal}
            element={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  height: "98%",
                }}
              >
                <div className="catalog">
                  <EditCargo
                    setModal={setCargoModal}
                    id={item?.inner_payload?.id}
                    type={item?.inner_payload}
                  />
                </div>
              </div>
            }
          />
        </div>
      ) : null}
    </>
  )
}

export default CargoShow

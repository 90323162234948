import React, { useState } from "react"

import CloseIcon from "../../../assets/svg/closeIcon.svg"
import { SubmitHandler, useForm } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { changePassword, setError, userEditByIdDataId } from "../../../redux/users"
import { toast } from "react-toastify"

export const ResetPassword = ({ setPassActive }: any) => {
    const { users } = useAppSelector((state: any) => state)
    const dispatch = useAppDispatch()

    const [password, setPassword] = useState<any>(null)
    const [confirmPassword, setConfirmPassword] = useState<any>(null)
    const [errors, setErrors] = useState<any>(null)

    const {
        register,
        handleSubmit,
    } = useForm()

    const onSubmit = (data: any) => {
        dispatch(userEditByIdDataId({
            password: data?.password
        }, users?.dataId?.id, () => {
            toast.success('Пароль успешно изменен')
            Cancel()
        }))
    }

    const Cancel = () => {
        dispatch(setError(false))
        setErrors(null)
        setPassActive(false)
    }

    return (
        <div
            className={"exitModal"}
        >
            <div className="exitModal__content" onClick={(e) => e.stopPropagation()}>
                <img src={CloseIcon} alt="" onClick={Cancel} />
                <div className="exitModal__content_title">Изменение пароля</div>
                <input
                    style={{
                        width: '370px'
                    }}
                    type="text"
                    placeholder="Введите пароль"
                    {...register("password", { required: false })}
                />
                {users?.error?.password && <div className="error">{users?.error?.password}</div>}

                <div className="exitModal__content_btns">
                    <button className="outline" onClick={Cancel}>
                        Отмена
                    </button>
                    <button className="background" onClick={handleSubmit(onSubmit)}>
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    )
}


import React, { useState } from "react"
import { formatPhoneNumber, getPaymentStatus } from "../../utils/functions"
import LocationIcon from "../../assets/svg/location.svg"
import PaymentImage from "../../assets/img/Rectangle 252.png"
import PaymentImageCard from "./Modal/paymentImage"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
  confirmPayment,
  makeRead,
  makeReadAsMana,
  rejectPayment,
} from "../../redux/auth/notifications"
import AllCargoInfoModl from "../cargos/allInfoModal"
import { fetchProfile } from "../../redux/auth/authSlice"

const PaymentConfirmation = ({ item, isHovered }: any) => {
  const { auth } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  const [image, setImage] = React.useState(false)
  const [showDetail, setShowDetail] = useState(false)

  const setNavigate = () => {
    window.location.replace(`/clients/history/${item?.inner_payload?.user?.id}`)
    if (auth?.data?.role === "manager") {
      dispatch(makeReadAsMana(item?.id))
    } else {
      dispatch(makeRead(item?.id))
    }
  }

  const confirmCheck = () => {
    dispatch(confirmPayment(item?.inner_payload?.id, setNavigate))
        dispatch(fetchProfile())
  }

  const rejectCheck = () => {
    dispatch(rejectPayment(item?.inner_payload?.id, setNavigate))
    if (auth?.data?.role === "manager") {
      dispatch(makeReadAsMana(item?.id))
    } else {
      dispatch(makeRead(item?.id))
    }
    dispatch(fetchProfile())
  }

  return item?.inner_payload?.status === "waiting" ? (
    <>
      <div className="PaymentNotiCard">
        <div
          className="PaymentNotiCard__leftSide"
          style={{
            width: "55%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="PaymentNotiCard__leftSide_about">
            <div className="PaymentNotiCard__leftSide_about-client">
              <div className="PaymentNotiCard__leftSide_about-client--top">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <div>
                    <div className="PaymentNotiCard__leftSide_about-client--top_title">
                      {item?.inner_payload?.user?.first_name +
                        " " +
                        item.inner_payload?.user?.last_name}
                    </div>
                    <div className="PaymentNotiCard__leftSide_about-client--top_subTitle">
                      Статус:
                      <u>{getPaymentStatus(item?.inner_payload?.status)}</u>
                    </div>
                  </div>
                  <div>
                    <div className="PaymentNotiCard__leftSide_about-client--top_titleId">
                      ID: <u>{item?.inner_payload?.id}</u>
                    </div>
                    <div className="PaymentNotiCard__leftSide_about-client--top_titleId">
                      Баланс:{" "}
                      <u className="balance">
                        ${item?.inner_payload?.user?.client?.balance}
                      </u>
                    </div>
                  </div>
                </div>

                <button onClick={() => setImage(!image)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.5 12C9.53757 12 12 9.53757 12 6.5C12 3.46243 9.53757 1 6.5 1C3.46243 1 1 3.46243 1 6.5C1 9.53757 3.46243 12 6.5 12ZM13 6.5C13 10.0899 10.0899 13 6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5Z"
                      fill="white"
                    />
                    <path
                      d="M10.3439 11.7422C10.3734 11.7822 10.4062 11.8204 10.4424 11.8566L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L11.8566 10.4424C11.8204 10.4062 11.7822 10.3734 11.7422 10.3439C11.3499 10.878 10.878 11.3499 10.3439 11.7422Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.5 3C6.77614 3 7 3.22386 7 3.5V6H9.5C9.77614 6 10 6.22386 10 6.5C10 6.77614 9.77614 7 9.5 7H7V9.5C7 9.77614 6.77614 10 6.5 10C6.22386 10 6 9.77614 6 9.5V7H3.5C3.22386 7 3 6.77614 3 6.5C3 6.22386 3.22386 6 3.5 6H6V3.5C6 3.22386 6.22386 3 6.5 3Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
              <div className="PaymentNotiCard__leftSide_about-client--bottom">
                <div className="PaymentNotiCard__leftSide_about-client--bottom_check">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M1 3C1 2.44772 1.44772 2 2 2H14C14.5523 2 15 2.44772 15 3L1 3Z"
                      fill="#83C251"
                    />
                    <path
                      d="M8 11C9.10457 11 10 10.1046 10 9C10 7.89543 9.10457 7 8 7C6.89543 7 6 7.89543 6 9C6 10.1046 6.89543 11 8 11Z"
                      fill="#83C251"
                    />
                    <path
                      d="M0 5C0 4.44772 0.447715 4 1 4H15C15.5523 4 16 4.44772 16 5V13C16 13.5523 15.5523 14 15 14H1C0.447715 14 0 13.5523 0 13V5ZM3 5C3 6.10457 2.10457 7 1 7V11C2.10457 11 3 11.8954 3 13H13C13 11.8954 13.8954 11 15 11V7C13.8954 7 13 6.10457 13 5H3Z"
                      fill="#83C251"
                    />
                  </svg>
                  Пополнения счета: +{item?.inner_payload?.value}
                </div>
                {/* <button
                  onClick={() => setShowDetail(!showDetail)}
                  className="PaymentNotiCard__leftSide_about-client--bottom_detail"
                >
                  Смотреть детально
                </button> */}
              </div>
            </div>
            <div className="PaymentNotiCard__leftSide_about-way">
              <div className="PaymentNotiCard__leftSide_about-way--rec">
                <div className="PaymentNotiCard__leftSide_about-way--rec_recieverName">
                  Получатель:{" "}
                  <u>{item?.user?.first_name + " " + item?.user?.last_name} </u>
                </div>
                <div className="PaymentNotiCard__leftSide_about-way--rec_recieverName">
                  Телефон:{" "}
                  <u>{formatPhoneNumber(item?.inner_payload?.user?.phone)}</u>
                </div>
              </div>
              {/* <div className="PaymentNotiCard__leftSide_about-way-flex">
                <div className="PaymentNotiCard__leftSide_about-way--country">
                  <img src={LocationIcon} alt="" />
                  Бишкек KR
                </div>
                <br />
                <div className="PaymentNotiCard__leftSide_about-way--line"></div>
                <div className="PaymentNotiCard__leftSide_about-way--country">
                  <img src={LocationIcon} alt="" />
                  Бишкек KR
                </div>
              </div> */}
            </div>
          </div>
          <div className="PaymentNotiCard__leftSide_btns">
            <button className="red-bth-with-white-back" onClick={rejectCheck}>
              Отменить
            </button>
            <button className="Green-bth" onClick={confirmCheck}>
              Потвердить
            </button>
          </div>
        </div>
        <div className="PaymentNotiCard__rightSide" style={{ width: "45%" }}>
          <img
            style={
              isHovered === true
                ? { maxHeight: "230px" }
                : { maxHeight: "140px" }
            }
            src={item?.inner_payload?.image}
            alt=""
          />
        </div>
      </div>
      {image && (
        <div className="modal-background" onClick={() => setImage(!image)}>
          <PaymentImageCard
            img={item?.inner_payload?.image}
            setImage={setImage}
          />
        </div>
      )}
      {/* {showDetail && (
        <div className="modal-background">
          <AllCargoInfoModl setShowDetail={setShowDetail} />
        </div>
      )} */}
    </>
  ) : null
}

export default PaymentConfirmation

import React from "react"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"

import DropDown from "../../assets/svg/dropDown.svg"

type Props = {
  items: any
  setSelectedItem: any
  title: any
}

const SelectFilter = ({ title, setSelectedItem, items }: Props) => {
  const { control } = useForm<Props>()

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      color: state ? "#fff" : "#fff",
      borderRadius: "4px",
      cursor: "pointer",
      background: "#83C251",
      border: "1px solid #E5E5E5",
      width: "150px",
      padding: "0",
      paddingRight: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      outline: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      backgroundColor: "lightblue",
      "&:hover": {
        outline: state.isFocused ? 0 : 0,
        transform: state.isFocused ? 0 : 0,
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: "#808080",
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent",
      "&:hover": {
        backgroundColor: state.isFocused
          ? "rgba(157, 194, 81, 0.5)"
          : "transparent",
      },
      padding: "5px",
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: "#fff",
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: "#fff",
      "&:hover": {
        color: "#fff",
      },
    }),
  }

  return (
    <div className="select_filter">
      <Controller
        name={title}
        control={control}
        render={({ field }) => (
          <Select
            styles={customStyles}
            id="favouriteAvenger"
            {...field}
            placeholder={"Все"}
            isSearchable={false}
            onChange={(e) => setSelectedItem(e)}
            options={
              items ? [{ value: "Все", label: "Все", id: null }, ...items] : []
            }
          />
        )}
      />
    </div>
  )
}

export default SelectFilter

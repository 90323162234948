import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { fetchCities } from "../../../redux/cargos/cities"
import { fetchCountries } from "../../../redux/cargos/countries"
import { fetchStockTypes } from "../../../redux/cargos/stock-types"
import { addStock } from "../../../redux/cargos/stock"

import { fetchStockEquipments } from "../../../redux/cargos/stock-equipments"
import { fetchStockService } from "../../../redux/cargos/stock-service"

type Props = {
  setModal: any
}

export const AddStock = ({ setModal }: Props) => {
  const {
    stocks,
    countries,
    cities,
    stockTypes,
    stockEquipments,
    stockService,
  } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm()

  const [menu1, setMenu1] = useState(true)
  const [menu2, setMenu2] = useState(true)
  const [avatar, setAvatar] = useState<any>()
  const [avatarShow, setAvatarShow] = useState<any>()

  const [typesList, setTypesList] = useState<any>([])
  const [equipmentsList, setEquipmentsList] = useState<any>([])
  const [servicesList, setServicesList] = useState<any>([])

  const availableOptions = [
    { id: 1, label: "Доступный", value: true },
    { id: 2, label: "Скрытый", value: false },
  ]

  const handleAvatar = (e: any) => {
    setAvatar(e.target.files[0])
    setAvatarShow(URL.createObjectURL(e.target.files[0]))
  }

  const setFunc = () => {
    setModal(false)
    reset()
  }

  useEffect(() => {
    dispatch(fetchCountries())
    dispatch(fetchStockTypes())
    dispatch(fetchStockEquipments())
    dispatch(fetchStockService())
  }, [])

  const onSubmit = (data: any) => {
    dispatch(
      addStock(
        {
          name: data?.name,
          address: data?.address,
          volume: data?.volume,
          city: data?.city?.id,
          is_available: data?.is_available?.value,
          type: typesList?.map((item: any) => item?.id),
          equipment: equipmentsList?.map((item: any) => item?.id),
          services: servicesList?.map((item: any) => item?.id),
        },
        avatar,
        setFunc,
      ),
    )
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      padding: "2px 5px",
      borderRadius: "10px",
      cursor: "pointer",
      marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent",
      color: "var(--lc-black-800, #333)",
    }),
  }

  const Cancel = () => {
    setModal(false)
    reset()
  }

  const getCitiesByCountry = (id: number) => {
    dispatch(fetchCities(id))
  }

  return (
    <>
      {
        <div className={menu1 ? "catalog_list" : ""}>
          <div
            className={"catalog_category_block"}
            onClick={() => setMenu1(!menu1)}
          >
            <div>Основные</div>
            <svg
              className={menu1 ? "reverse" : ""}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
                fill="#1A1A1A"
              />
            </svg>
          </div>

          <div
            className={menu1 ? "catalog_items" : "none"}
            style={{
              paddingBottom: "20px",
            }}
          >
            <div
              className="catalog_item"
              style={{ alignItems: "start", marginTop: "10px" }}
            >
              <div>Фото:</div>
              <input
                onChange={(e) => handleAvatar(e)}
                type="file"
                id="avatar"
                hidden
                accept="image/png, image/jpeg, image/webp, image/svg+xml"
              />

              <label
                htmlFor="avatar"
                style={{
                  width: "56.7%",
                  height: "184px",
                  border: "1px solid #CCC",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  objectFit: "cover",
                }}
              >
                {avatarShow ? null : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                  >
                    <path
                      d="M14.4167 2.66675L18.5834 2.66675C19.2761 2.66675 19.8334 3.22404 19.8334 3.91675V12.6667H24.4011C25.3282 12.6667 25.7917 13.7865 25.1355 14.4428L17.2136 22.3699C16.823 22.7605 16.1824 22.7605 15.7917 22.3699L7.85946 14.4428C7.20321 13.7865 7.66675 12.6667 8.59383 12.6667L13.1667 12.6667L13.1667 3.91675C13.1667 3.22404 13.724 2.66675 14.4167 2.66675ZM29.8334 22.2501V28.0834C29.8334 28.7761 29.2761 29.3334 28.5834 29.3334L4.41675 29.3334C3.72404 29.3334 3.16675 28.7761 3.16675 28.0834L3.16675 22.2501C3.16675 21.5574 3.72404 21.0001 4.41675 21.0001H12.0574L14.6095 23.5522C15.6563 24.599 17.3438 24.599 18.3907 23.5522L20.9428 21.0001H28.5834C29.2761 21.0001 29.8334 21.5574 29.8334 22.2501ZM23.3751 26.8334C23.3751 26.2605 22.9063 25.7917 22.3334 25.7917C21.7605 25.7917 21.2917 26.2605 21.2917 26.8334C21.2917 27.4063 21.7605 27.8751 22.3334 27.8751C22.9063 27.8751 23.3751 27.4063 23.3751 26.8334ZM26.7084 26.8334C26.7084 26.2605 26.2397 25.7917 25.6667 25.7917C25.0938 25.7917 24.6251 26.2605 24.6251 26.8334C24.6251 27.4063 25.0938 27.8751 25.6667 27.8751C26.2397 27.8751 26.7084 27.4063 26.7084 26.8334Z"
                      fill="#999999"
                    />
                  </svg>
                )}
                {avatarShow ? (
                  <img
                    src={avatarShow ? avatarShow : ""}
                    style={{
                      width: "100%",
                      height: "184px",
                      cursor: "pointer",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                    alt=""
                  />
                ) : null}
              </label>
            </div>
            <div className="catalog_item">
              <div>
                Название склада: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("name", { required: false })}
                  type="text"
                />
                <div className="error">{stocks?.error?.name}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                Физический адрес склада: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("address", { required: false })}
                  type="text"
                />
                <div className="error">{stocks?.error?.address}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Типы складских помещений:</div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <Controller
                  control={control}
                  {...register("type", {
                    required: false,
                  })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder="Выбрать"
                      onChange={(e) => {
                        if (!typesList.find((item: any) => item.id === e.id)) {
                          setTypesList((type: any) => [...type, e])
                        }
                      }}
                      options={stockTypes?.data?.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">{stocks?.error?.type}</div>
              </div>
            </div>
            {typesList?.length !== 0 && (
              <div
                style={{
                  textAlign: "end",
                  display: "flex",
                  justifyContent: "end",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {typesList?.map((item: any) => (
                  <div
                    onClick={() => {
                      setTypesList(
                        typesList.filter((tem: any) => tem.id !== item.id),
                      )
                    }}
                    style={{
                      padding: "6px 12px",
                      background: "#83C251",
                      fontSize: "12px",
                      color: "white",
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      gap: "4px",
                    }}
                  >
                    {item.value}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                ))}
              </div>
            )}

            <div className="catalog_item">
              <div>Доступность:</div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <Controller
                  control={control}
                  {...register("is_available", {
                    required: false,
                  })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder="Выбрать"
                      options={availableOptions?.map((item: any) => ({
                        value: item.value,
                        label: item.label,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">{stocks?.error?.is_available}</div>
              </div>
            </div>

            <div className="catalog_item">
              <div>Оборудование на складе:</div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <Controller
                  control={control}
                  {...register("equipments", {
                    required: false,
                  })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder="Выбрать"
                      onChange={(e) => {
                        if (
                          !equipmentsList.find((item: any) => item.id === e.id)
                        ) {
                          setEquipmentsList((type: any) => [...type, e])
                        }
                      }}
                      options={stockEquipments?.data?.results?.map(
                        (item: any) => ({
                          value: item.name,
                          label: item.name,
                          id: item.id,
                        }),
                      )}
                    />
                  )}
                />
                <div className="error">{stocks?.error?.equipment}</div>
              </div>
            </div>
            {equipmentsList?.length !== 0 && (
              <div
                style={{
                  textAlign: "end",
                  display: "flex",
                  justifyContent: "end",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {equipmentsList?.map((item: any) => (
                  <div
                    onClick={() => {
                      setEquipmentsList(
                        equipmentsList.filter((tem: any) => tem.id !== item.id),
                      )
                    }}
                    style={{
                      padding: "6px 12px",
                      background: "#83C251",
                      fontSize: "12px",
                      color: "white",
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      gap: "4px",
                    }}
                  >
                    {item.value}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                ))}
              </div>
            )}

            <div className="catalog_item">
              <div>
                <div>Услуги, предоставляемые</div>
                <div>на складе:</div>
              </div>
              <div
                style={{
                  width: "57%",
                }}
              >
                <Controller
                  control={control}
                  {...register("services", {
                    required: false,
                  })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder="Выбрать"
                      onChange={(e) => {
                        if (
                          !servicesList.find((item: any) => item.id === e.id)
                        ) {
                          setServicesList((type: any) => [...type, e])
                        }
                      }}
                      options={stockService?.data?.results?.map(
                        (item: any) => ({
                          value: item.name,
                          label: item.name,
                          id: item.id,
                        }),
                      )}
                    />
                  )}
                />
                <div className="error">{stocks?.error?.type}</div>
              </div>
            </div>
            {servicesList?.length !== 0 && (
              <div
                style={{
                  textAlign: "end",
                  display: "flex",
                  justifyContent: "end",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {servicesList?.map((item: any) => (
                  <div
                    onClick={() => {
                      setServicesList(
                        servicesList.filter((tem: any) => tem.id !== item.id),
                      )
                    }}
                    style={{
                      padding: "6px 12px",
                      background: "#83C251",
                      fontSize: "12px",
                      color: "white",
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      gap: "4px",
                    }}
                  >
                    {item.value}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                ))}
              </div>
            )}

            {
              <div
                className="catalog_item"
                style={{
                  alignItems: "start",
                }}
              >
                <div>
                  Город: <u className="redStar">*</u>
                </div>
                <div
                  style={{
                    width: "57%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <Controller
                    control={control}
                    {...register("country", { required: false })}
                    render={({ field }) => (
                      <Select
                        styles={customStyless}
                        id="favouriteAvenger"
                        {...field}
                        onChange={(e) => getCitiesByCountry(e.id)}
                        placeholder="Страна"
                        options={countries?.data?.results?.map((item: any) => ({
                          value: item.name,
                          label: item.name,
                          id: item.id,
                        }))}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    {...register("city", { required: false })}
                    render={({ field }) => (
                      <Select
                        styles={customStyless}
                        id="favouriteAvenger"
                        {...field}
                        placeholder="Город"
                        isDisabled={cities?.data?.length !== 0 ? false : true}
                        options={cities?.data?.map((item: any) => ({
                          value: item.name,
                          label: item.name,
                          id: item.id,
                        }))}
                      />
                    )}
                  />

                  <div className="error">{stocks?.error?.city}</div>
                </div>
              </div>
            }
            <div className="catalog_item">
              <div>
                Общий объем: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="0к.м"
                  {...register("volume", { required: false })}
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                />
                <div className="error">{stocks?.error?.volume}</div>
              </div>
            </div>
          </div>

          <div
            className="form_button"
            style={{
              paddingTop: "20px",
              position: "absolute",
              bottom: "20px",
              left: "20px",
              right: "20px",
            }}
          >
            <button className="TruckBtn2" onClick={Cancel}>
              Отмена
            </button>
            <button
              onClick={handleSubmit(onSubmit)}
              type="submit"
              className="TruckBtn"
            >
              Сохранить
            </button>
          </div>
        </div>
      }
    </>
  )
}

import React, { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import GraphicSliderIcon from "../assets/svg/graficSlider.svg"
import { addLastMessages, fetchChatRoom, fetchChatRooms, fetchOwnChatRooms, readMessage, setItem, setMessages, setOnlineItems, setSortItems } from "../redux/chats"
import ConactListItem from "../components/chatComponents/ConactListItem"
import backIcon from "../assets/svg/Back.svg"
import BackChat from "../assets/img/BackChat.png"
import { access_token, user } from "../services"
import ChatComponent from "../components/chatComponents/FormChat"
import NoIconGreen from "../assets/svg/noIcongreen.svg"
import { setLoading } from "../redux/loading"
import axios from "axios"
import { API_URL } from "../api/api_config"
import { toast } from "react-toastify"
import { fetchProfile, setMessege } from "../redux/auth/authSlice"


const ChatPage = () => {
  const dispatch: any = useAppDispatch()
  const { auth, chats } = useAppSelector((state) => state)
  const { status, uuid }: any = useParams()
  const navigate = useNavigate()
  const [open, setOpen] = useState(true)



  useEffect(() => {

    !auth?.data?.role && dispatch(fetchProfile())
    status !== '' && getChats(status)
    uuid !== 'none' && getChatRoom(uuid)

  }, [uuid, auth])

  const fetchData = async () => {
    dispatch(setLoading(true))
    axios.get(`${API_URL}api/v1/chat/messages/room/${uuid}/?offset=${offset}`,
      {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      },
    ).then((res: any) => {
      dispatch(setLoading(false))

      const newData = res?.data?.results
      dispatch(addLastMessages(newData))

      setTimeout(() => {
        if (offset === 1) {
          scrollToBottom()
        }
      }, 100)
    }).catch((er: any) => {
      dispatch(setLoading(false))
      if (uuid !== 'none') {
        setError(er?.response?.data?.detail)
      }
    })
  }

  useEffect(() => {
    fetchData()
    setSocketMesege([])
  }, [uuid])

  useEffect(() => {
    // dispatch(setMessages([]))
    setOffset(1)
  }, [uuid])

  const getChats = (status: any, clear?: any) => {
    if (status === 'active') {
      dispatch(fetchOwnChatRooms({
        // dispatcher: status === 'waiting' || status === 'closed' ? '' : auth.data?.id,
        status: status,
      }, (response: any) => {
        clear ? navigate(`/chat/none/${status}`) : null
        dispatch(setLoading(false))
      }))
    } else if (status !== 'active') {
      dispatch(fetchChatRooms({
        dispatcher: status === 'waiting' || status === 'closed' ? '' : auth.data?.id,
        status: status,
      }, (response: any) => {
        clear ? navigate(`/chat/none/${status}`) : null
        dispatch(setLoading(false))
      }))
    }
  }

  const getChatRoom = (uuid: number) => {
    dispatch(fetchChatRoom(uuid))
  }

  const roomOut = async () => {
    axios
      .get(`${API_URL}api/v1/chat/rooms/refuse-room/${uuid}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response) => {
        toast.success(response?.data?.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        navigate(`/chat/none/waiting`)
      }).catch((error) => {
        toast.error(error?.response?.data?.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      })
  }

  const handleCloseRoom = () => {
    axios
      .get(`${API_URL}api/v1/chat/rooms/close-room/${uuid}`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response) => {
        toast.success(response?.data?.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        navigate(`/chat/none/closed`)
      }).catch((error: any) => {
        toast.error(error?.response?.data?.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      })
  }


  const [state, setState] = useState(false)
  const [SocketMesege, setSocketMesege] = useState<any[]>([])
  const [offset, setOffset] = useState(1)
  const [CurentUser, setCurentUser] = useState<any>()
  const [isAsept, setIsAsept] = useState(false)

  const [onModal, setOnModal] = useState(false)

  const [error, setError] = useState<any>()
  const [isArhiv, setIsArhiv] = useState(false)
  const [reSoket, setReSoket] = useState(false)
  const [typing, setTyping] = useState(false)
  const [online, setOnline] = useState(false)

  const chatContainerRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      if (chatContainerRef.current.scrollTop !== chatContainerRef.current.scrollHeight) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }
  };

  const [isRoomOpen, setRoomOpen] = useState(false)

  useEffect(() => {
    scrollToBottom();
  }, [chats?.messages]);

  const BackBtn = () => {
    return (
      <div style={{ color: "black" }} className="chat_main-leftBar-Back">
        <div>
          <img src={backIcon} alt="" />
        </div>
        <div>Вернутся</div>
      </div>
    )
  }

  const handleScroll = (e: any) => {
    const target = e.currentTarget
    if (target.scrollTop === 0) {
      dispatch(setLoading(true))
      axios.get(`${API_URL}api/v1/chat/messages/room/${uuid}/?offset=${offset + 1}`,
        {
          headers: {
            Authorization: `Token ${access_token}`,
          },
        },
      ).then((res: any) => {
        const newData = res?.data?.results

        newData?.forEach((item: any) => {
          if (item?.user?.id !== Number(localStorage.getItem('id')) && item?.is_read === false) {

            ws.current.send(JSON.stringify({ type: 'make_read_message', message_id: item.id, id: Number(localStorage.getItem('id')), is_read: true, ds: 1 }));
          }
        });
        dispatch(setLoading(false))
        setOffset(offset + 1)

        dispatch(addLastMessages(newData))

      }).catch((er: any) => {
        dispatch(setLoading(false))
        if (uuid !== 'none') {
          setError(er?.response?.data?.detail)
        }
      })
    }
  }

  const fetchNewData = async () => {
    dispatch(setLoading(true))
    try {
      const response = await axios.get(
        `${API_URL}api/v1/chat/messages/room/${uuid}/?offset=${offset}`,
        {
          headers: {
            Authorization: `Token ${access_token} `,
          },
        },
      )
      const newData = response?.data?.results

      setMessages(newData)

      newData?.forEach((item: any) => {
        if (item?.user?.id !== Number(localStorage.getItem('id')) && item?.is_read === false) {

          ws.current.send(JSON.stringify({ type: 'make_read_message', message_id: item.id, id: Number(localStorage.getItem('id')), is_read: true, ds: 1 }));
        }
      });
      setTimeout(() => {
        if (offset === 1) {
          scrollToBottom()
        }
      }, 100)
    } catch (error: any) {
      if (uuid !== 'none') {
        setError(error?.response?.data?.detail)
      }
    } finally {
      dispatch(setLoading(false))
    }
  }
  const HandleAsseptChat = () => {
    axios
      .get(`${API_URL}api/v1/chat/rooms/accept-request/${uuid}`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then(() => {
        navigate(`/chat/${uuid}/active/`)
        dispatch(fetchOwnChatRooms({
          // dispatcher: status === 'waiting' || status === 'closed' ? '' : auth.data?.id,
          status: status,
        }, (response: any) => {
          navigate(`/chat/${uuid}/active`)
          dispatch(setLoading(false))
        }))

        setError(null)
        toast.success("Вы успешно приняли запрос на переписку", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,

          theme: "light",
        })
        setRoomOpen(true)
        setIsAsept(true)
      })
  }

  const queries = new URLSearchParams({
    Authorization: `Token ${access_token}`,
    mode: "dispatcher",
  }).toString()

  const ws: any = useRef<WebSocket | null>(null)



  const makeReadMessage = (message: any) => {
    // ws.current.send(JSON.stringify({ type: 'make_read_message', message_id: message.id, id: null, is_read: true }))
  }



  useEffect(() => {
    ws.current = new WebSocket(
      `wss://back.lidercargo.net/chat-room/${uuid}/?${queries}`
    );



    ws.current.onopen = () => {
      // console.log('WebSocket connection established.');
      fetchNewData()
      // Mark existing messages as read after the connection is established
      // messages.forEach((item) => {
      //   makeReadMessage(item);
      // });
    };

    ws.current.onmessage = (e: any) => {
      const data = e.data.toString();

      const message: any = JSON.parse(data);

      console.log(message);
      

      if (message.type === 'make_read_message') {

        if (message.is_read === true) {
          dispatch(readMessage(message))
        }
      }



    };
  }, [])

  console.log(error);
  

  return (
    <main className="chat">
      <div className="chat_form">
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%', }}>
          <div className="SearchBox">
            <input
              // onChange={(e: any) => handleSearch(e)}
              className="SearchBox__input"
              type="text"
              placeholder="Поиск по тематике"
            />
            <div className={"SearchBox__btns"}>
              <button className={status === 'waiting' ? 'active' : ''} onClick={() => uuid ? getChats('waiting', true) : getChats('waiting')}>В ожидании</button>
              <button className={status === 'active' ? 'active' : ''} onClick={() => uuid ? getChats('active', true) : getChats('active')}>Активные</button>
              <button className={status === 'closed' ? 'active' : ''} onClick={() => uuid ? getChats('closed', true) : getChats('closed')}>Закрытые</button>
            </div>
            <button className="SearchBox_btn" onClick={() => setOpen(!open)}>
              <img src={GraphicSliderIcon} alt="" />
            </button>
          </div>
        </div>
        <div className="title">Сообщения</div>
      </div>

      <div className="chat_main">
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          gap: '16px',
          position: 'relative'
        }}>
          <div className="chat_main-leftBar" style={{ width: '100%', maxWidth: '320px' }}>
            {uuid !== 'none' ? <div onClick={() => navigate('/chat/none/active')} style={{
              padding: '5px 0px 26px 0px'
            }}>
              <BackBtn />
            </div> : ""}
            <div className="chat_main-leftBar-ContactsList">
              {chats?.data?.results?.length === 0 && <div style={{ justifyContent: 'center', padding: '15px 10px' }} className={"contact"}>Здесь пусто</div>}
              {chats?.data?.results?.map((item: any) => <ConactListItem key={item?.uuid} item={item} />)}
            </div>
          </div>

          <div style={{
            width: '100%',
            maxWidth: 'calc(100% - 304px)',
            background: uuid === 'none' ? `url(${BackChat}) center no-repeat` : '',
          }}>
            {uuid !== 'none' && (
              <>
                <div className="chat_main-rightChat">
                  <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '16px', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <div className="chat_main-rightChat-ContactInf-image">
                        <img src={chats?.oneData?.user?.avatar || NoIconGreen} alt="" />
                      </div>
                      <p>
                        {chats?.oneData?.user?.first_name} {chats?.oneData?.user?.last_name}
                      </p>

                      <p style={{
                        color: '#83C251'
                      }}>
                        {typing && 'печатает...'}
                        {online && 'в сети'}
                      </p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      {uuid && status === 'active' ? (
                        <div
                          style={{ display: "flex", alignItems: 'center', gap: "10px" }}
                          onClick={() => setIsArhiv(true)}
                        >
                          <button
                            onClick={handleCloseRoom}
                            className="GreenAddBtn Small"
                          >
                            Закрыть комнату
                          </button>
                          <button
                            onClick={roomOut}
                            className="GreenAddBtn Small"
                          >
                            Выйти из комнаты
                          </button>
                        </div>
                      ) : null}
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M4.5 14.25C3.25736 14.25 2.25 13.2426 2.25 12C2.25 10.7574 3.25736 9.75 4.5 9.75C5.74264 9.75 6.75 10.7574 6.75 12C6.75 13.2426 5.74264 14.25 4.5 14.25ZM12 14.25C10.7574 14.25 9.75 13.2426 9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25ZM19.5 14.25C18.2574 14.25 17.25 13.2426 17.25 12C17.25 10.7574 18.2574 9.75 19.5 9.75C20.7426 9.75 21.75 10.7574 21.75 12C21.75 13.2426 20.7426 14.25 19.5 14.25Z" fill="black" />
                      </svg>
                    </div>

                  </div>
                  <div className="chat_main-rightChat-action">
                    <div
                      className="chat_main-rightChat-action-canva"
                      style={{
                        height: uuid === 'none' ? 'calc(100vh - 380px)' : 'calc(100vh - 442px)',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                      }}
                      ref={chatContainerRef}
                      onScroll={handleScroll}
                    >
                      {error ? (
                        <div className="asseptChat">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="100%"
                              height="100%"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M0 5C0 3.61929 1.11929 2.5 2.5 2.5H17.5C18.8807 2.5 20 3.61929 20 5V15C20 16.3807 18.8807 17.5 17.5 17.5H2.5C1.11929 17.5 0 16.3807 0 15V5ZM2.5 3.75C1.80964 3.75 1.25 4.30964 1.25 5V5.27113L10 10.5211L18.75 5.27113V5C18.75 4.30964 18.1904 3.75 17.5 3.75H2.5ZM18.75 6.72887L12.8649 10.2599L18.75 13.8815V6.72887ZM18.7078 15.3233L11.6573 10.9845L10 11.9789L8.34272 10.9845L1.29221 15.3233C1.43468 15.8569 1.92144 16.25 2.5 16.25H17.5C18.0786 16.25 18.5653 15.8569 18.7078 15.3233ZM1.25 13.8815L7.1351 10.2599L1.25 6.72887V13.8815Z"
                                fill="#83C251"
                              />
                            </svg>
                          </div>
                          <h1
                            style={{ margin: "1rem auto" }}
                          >
                            {error === "Неправильная страница"
                              ? "это все сообшения"
                              : error}{" "}
                          </h1>

                          <button
                            onClick={() => HandleAsseptChat()}
                            style={{ margin: "1rem auto" }
                            }
                            className="GreenAddBtn Small"
                          >
                            Открыть чат чтобы написать сообщения.
                          </button>
                        </div>
                      ) : null}
                      {chats?.messages?.filter((item: any) => !item?.user_id || !item?.user)?.map((item: any, idx: any) => (
                        item?.type_message === 'announcement' ?
                          <div className="mesegeBlock" style={{ justifyContent: 'center' }} key={idx}>
                            <div className="mesegeBlockR">
                              <div className="mesegeBlockR-text">
                                <div>
                                  <div>{item.body}</div>
                                  {
                                    item.file ?
                                      <a style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '3px',
                                        color: '#83C251'
                                      }} href={item.file}>Посмотреть файл <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M14 2.26946V6.4C14 6.96005 14 7.24008 14.109 7.45399C14.2049 7.64215 14.3578 7.79513 14.546 7.89101C14.7599 8 15.0399 8 15.6 8H19.7305M20 9.98822V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H12.0118C12.7455 2 13.1124 2 13.4577 2.08289C13.7638 2.15638 14.0564 2.27759 14.3249 2.44208C14.6276 2.6276 14.887 2.88703 15.4059 3.40589L18.5941 6.59411C19.113 7.11297 19.3724 7.3724 19.5579 7.67515C19.7224 7.94356 19.8436 8.2362 19.9171 8.5423C20 8.88757 20 9.25445 20 9.98822Z" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                      </a> : null
                                  }
                                  {item.photo && <img src={item.photo} alt="" style={{
                                    width: '100%',
                                    height: '200px'
                                  }} />}
                                </div>
                                <div style={{ paddingTop: '5px', display: 'flex', alignItems: 'center', }}>{item?.created_at?.toString()?.slice(0, 10)} {item?.created_at?.toString()?.slice(11, 16)}.
                                  {/* {
                                    item.is_read ? <div style={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 6L9 17L4 12" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      </svg>

                                      <svg style={{
                                        position: 'relative',
                                        left: '-17px'
                                      }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 6L9 17L4 12" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      </svg>
                                    </div> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M20 6L9 17L4 12" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                  } */}
                                </div>
                              </div>{" "}
                            </div>
                          </div> : item?.user?.id && item?.user?.id !== auth?.data?.id || item?.id !== auth?.data?.id && item?.type && item?.type !== 'send_message' ? <div className="mesegeBlock" key={item.uuid}>
                            <div className="">
                              <div className="chatMessegeAvatar">
                                <div
                                  className="flex"
                                >
                                  <img src={chats?.oneData?.user?.avatar || NoIconGreen} alt="" />
                                  <p>{chats?.oneData?.user?.first_name} {chats?.oneData?.user?.last_name}</p>
                                </div>
                              </div>
                              <div className="mesegeBlockR">
                                {" "}
                                <div className="mesegeBlockR-text">
                                  <div>
                                    <div onClick={() => makeReadMessage(item)}>{item.body}</div>
                                    {
                                      item.file ?
                                        <a style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '3px',
                                          color: '#83C251'
                                        }} href={item.file}>Посмотреть файл <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14 2.26946V6.4C14 6.96005 14 7.24008 14.109 7.45399C14.2049 7.64215 14.3578 7.79513 14.546 7.89101C14.7599 8 15.0399 8 15.6 8H19.7305M20 9.98822V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H12.0118C12.7455 2 13.1124 2 13.4577 2.08289C13.7638 2.15638 14.0564 2.27759 14.3249 2.44208C14.6276 2.6276 14.887 2.88703 15.4059 3.40589L18.5941 6.59411C19.113 7.11297 19.3724 7.3724 19.5579 7.67515C19.7224 7.94356 19.8436 8.2362 19.9171 8.5423C20 8.88757 20 9.25445 20 9.98822Z" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                          </svg>
                                        </a> : null
                                    }
                                    {item.photo && <img src={item.photo} alt="" style={{
                                      width: '100%',
                                      height: '200px'
                                    }} />}
                                  </div>
                                  <div style={{ paddingTop: '5px', display: 'flex', alignItems: 'center', }}>{item?.created_at?.toString()?.slice(0, 10)} {item?.created_at?.toString()?.slice(11, 16)}.
                                    {/* {
                                      item.is_read ? <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M20 6L9 17L4 12" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                        <svg style={{
                                          position: 'relative',
                                          left: '-17px'
                                        }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M20 6L9 17L4 12" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                      </div> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 6L9 17L4 12" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      </svg>

                                    } */}
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div> :

                            item?.mode === 'client' ? <div className="mesegeBlock" key={item.uuid}>
                              <div className="">
                                <div className="chatMessegeAvatar">
                                  <div
                                    className="flex"
                                  >
                                    <img src={chats?.oneData?.user?.avatar || NoIconGreen} alt="" />
                                    <p>{chats?.oneData?.user?.first_name} {chats?.oneData?.user?.last_name}</p>
                                  </div>
                                </div>
                                <div className="mesegeBlockR">
                                  {" "}
                                  <div className="mesegeBlockR-text">
                                    <div>
                                      <div>{item.body}</div>
                                      {
                                        item.file ?
                                          <a style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '3px',
                                            color: '#83C251'
                                          }} href={item.file}>Посмотреть файл <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M14 2.26946V6.4C14 6.96005 14 7.24008 14.109 7.45399C14.2049 7.64215 14.3578 7.79513 14.546 7.89101C14.7599 8 15.0399 8 15.6 8H19.7305M20 9.98822V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H12.0118C12.7455 2 13.1124 2 13.4577 2.08289C13.7638 2.15638 14.0564 2.27759 14.3249 2.44208C14.6276 2.6276 14.887 2.88703 15.4059 3.40589L18.5941 6.59411C19.113 7.11297 19.3724 7.3724 19.5579 7.67515C19.7224 7.94356 19.8436 8.2362 19.9171 8.5423C20 8.88757 20 9.25445 20 9.98822Z" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                          </a> : null
                                      }
                                      {item.photo && <img src={item.photo} alt="" style={{
                                        width: '100%',
                                        height: '200px'
                                      }} />}
                                    </div>
                                    <div style={{ paddingTop: '5px', display: 'flex', alignItems: 'center', }}>{item?.created_at?.toString()?.slice(0, 10)} {item?.created_at?.toString()?.slice(11, 16)}.
                                      {/* {
                                        item.is_read ? <div style={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }} ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 6L9 17L4 12" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                          </svg>

                                          <svg style={{
                                            position: 'relative',
                                            left: '-17px'
                                          }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 6L9 17L4 12" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                          </svg>
                                        </div> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M20 6L9 17L4 12" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                      } */}
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                            </div> :
                              <div className="mesegeBlock" style={{ justifyContent: 'end' }} key={item.uuid}>
                                <div className="">
                                  <div className="chatMessegeAvatar">

                                    {<div
                                      className={'flex'}
                                      style={{ justifyContent: 'end' }}
                                    >
                                      <p>{chats?.oneData?.dispatcher?.first_name || auth?.data?.first_name}</p>
                                      <img
                                        src={chats?.oneData?.dispatcher?.avatar || auth?.data?.avatar || NoIconGreen}
                                        alt=""
                                      />
                                    </div>}
                                  </div>
                                  <div className="mesegeBlockR">
                                    {" "}
                                    <div></div>
                                    <div className="mesegeBlockR-text">
                                      <div>
                                        <div>{item.body}</div>
                                        {
                                          item.file ?
                                            <a style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: '3px',
                                              color: '#83C251'
                                            }} href={item.file}>Посмотреть файл <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 2.26946V6.4C14 6.96005 14 7.24008 14.109 7.45399C14.2049 7.64215 14.3578 7.79513 14.546 7.89101C14.7599 8 15.0399 8 15.6 8H19.7305M20 9.98822V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H12.0118C12.7455 2 13.1124 2 13.4577 2.08289C13.7638 2.15638 14.0564 2.27759 14.3249 2.44208C14.6276 2.6276 14.887 2.88703 15.4059 3.40589L18.5941 6.59411C19.113 7.11297 19.3724 7.3724 19.5579 7.67515C19.7224 7.94356 19.8436 8.2362 19.9171 8.5423C20 8.88757 20 9.25445 20 9.98822Z" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                              </svg>
                                            </a> : null
                                        }
                                        {item.photo && <img src={item.photo} alt="" style={{
                                          width: '100%',
                                          height: '200px'
                                        }} />}
                                      </div>
                                      <div style={{ paddingTop: '5px', display: 'flex', alignItems: 'center', }}>{item?.created_at?.toString()?.slice(0, 10)} {item?.created_at?.toString()?.slice(11, 16)}.
                                        {
                                          item.is_read ? <div style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                          }}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M20 6L9 17L4 12" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            <svg style={{
                                              position: 'relative',
                                              left: '-17px'
                                            }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M20 6L9 17L4 12" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                          </div> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 6L9 17L4 12" stroke="#83C251" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                          </svg>
                                        }
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                      ))}
                      {/* {SocketMesege.map((item: any, index: any) => (
                        <div className="mesegeBlock" key={item?.uuid}>
                          <div
                            className=""
                            style={
                              item?.mode === 'client'
                                ? { opacity: 1 }
                                : { opacity: 0 }
                            }
                          >
                            <div className="chatMessegeAvatar">
                              <div
                                style={
                                  item?.mode === 'client'
                                    ? { opacity: 1 }
                                    : { opacity: 0 }
                                }
                                className={SocketMesege[index - 1]?.mode !== 'dispatcher' || messages[messages.length - 1]?.mode !== 'dispatcher' ? 'flex' : 'none'}
                              >
                                <img src={chats?.oneData?.user_detail?.avatar || NoIconGreen} alt="" />
                                <p>{chats?.oneData?.user_detail?.first_name}</p>
                              </div>
                              <div
                                style={
                                  item?.mode === 'client'


                                    ? { opacity: 1 }
                                    : { opacity: 0 }
                                }
                                className={
                                  SocketMesege[index]?.user ===
                                    SocketMesege[index - 1]?.user
                                    ? "none"
                                    : "flex"
                                }
                              >
                                <p>{chats?.oneData?.user_detail?.first_name}</p>
                                <img src={chats?.oneData?.user_detail?.avatar || NoIconGreen} alt="" />
                              </div>
                            </div>
                            <div className="mesegeBlockR">
                              {" "}
                              <div className="mesegeBlockR-text">
                                <div> {item.body}</div>
                                <div style={{ paddingTop: '5px', display:'flex', alignItems: 'center', }}>{item?.created_at?.toString()?.slice(0, 10)} {item?.created_at?.toString()?.slice(11, 16)}.</div>
                              </div>{" "}
                            </div>
                          </div>
                          <div
                            className=""
                            style={
                              item?.mode === 'dispatcher'
                                ? { opacity: 1 }
                                : { opacity: 0 }
                            }
                          >
                            <div className="chatMessegeAvatar">
                              <div
                                style={
                                  item?.mode === 'dispatcher'
                                    ? { opacity: 1 }
                                    : { opacity: 0 }
                                }
                                className={
                                  SocketMesege[index - 1]?.mode === 'client'
                                    ? "flex"
                                    : "none"
                                }
                              >
                                <img
                                  src={chats?.oneData?.dispatcher_detail?.avatar || NoIconGreen}
                                  alt=""
                                />
                                <p>{chats?.oneData?.dispatcher_detail?.first_name}</p>
                              </div>
                              <div
                                style={
                                  item?.mode === 'dispatcher'
                                    ? { opacity: 1 }
                                    : { opacity: 0 }
                                }
                                className={
                                  SocketMesege[index]?.user ===
                                    SocketMesege[index - 1]?.user
                                    ? "none"
                                    : "flex"
                                }
                              >
                                <p>{chats?.oneData?.dispatcher_detail?.first_name}</p>
                                <img
                                  src={chats?.oneData?.dispatcher_detail?.avatar || NoIconGreen}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="mesegeBlockR">
                              {" "}
                              <div className="mesegeBlockR-text">
                                <div> {item.body}</div>
                                <div style={{ paddingTop: '5px', display:'flex', alignItems: 'center', }}>{item?.created_at?.toString()?.slice(0, 10)} {item?.created_at?.toString()?.slice(11, 16)}.</div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      ))} */}
                    </div>
                    <br />
                    {uuid && status === 'active' && chats?.messages ? <ChatComponent
                      reSoket={reSoket}
                      messages={chats?.messages}
                      setTyping={setTyping}
                      setOnline={setOnline}
                      setMessages={setMessages}
                      scrollToBottom={scrollToBottom}
                    /> : null}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  )
}

export default ChatPage


import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../../api/api_config"
import { access_token } from "../../../services"
import { toast } from "react-toastify"
import { setLoading } from "../../loading"

const initialState: any = {
  data: [],
  oneData: {},
  error: false,
}

const URL = "cargo/transportations/attributes"

const transportationsAttributesSlice = createSlice({
  name: "transportations-attributes",
  initialState,
  reducers: {
    getItems: (state, { payload }) => {
      state.data = {
        ...payload,
        results: payload?.results,
      }
    },
    getItem: (state, { payload }) => {
      state.oneData = payload
    },
    addItem(state, { payload }) {
      state.data.push({ payload })
    },
    editItems: (state, { payload }) => {
      const updatedResults = state.data.results.map((item: any) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
      state.data.results = updatedResults
    },
    editItem: (state, { payload }) => {
      state.oneData = payload
    },
    removeItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    addItemsLast: (state, { payload }) => {
      state.data?.results?.push(...payload)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const {
  getItems,
  getItem,
  setError,
  addItem,
  editItems,
  removeItem,
  editItem,
  addItemsLast,
} = transportationsAttributesSlice.actions
export default transportationsAttributesSlice.reducer

export const fetchTransportationsAttributes = (params: any, AfterGet?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/`, {
        params: params,
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItems(response.data))
        AfterGet(response.data)
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        dispatch(setLoading(false))
      })
  }
}

export const fetchTransportationsAttribute = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItem(response.data))

        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        dispatch(setLoading(false))
      })
  }
}

export const addTransportationsAttribute = (data: any, setFunc?: any) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/${URL}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        setFunc()
        dispatch(addItem(response.data))

        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
        dispatch(setLoading(false))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const makeCheckedTransportationsAttribute = (
  id: number,
  setFunc?: any,
) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/maked-chacked/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        setFunc()
        dispatch(getItem(response.data))

        dispatch(setLoading(false))
      })
      .catch((er) => {
        toast.error(`${er?.response?.data?.detail}`)
        dispatch(setLoading(false))
      })
  }
}

export const editTransportationsAttribute = (
  data: any,
  id: any,
  setFunc?: any,
) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/${URL}/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        setFunc()
        dispatch(editItems(res.data))
        dispatch(editItem(res.data))

        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
        dispatch(setLoading(false))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const removeTransportationsAttribute = (id: any, setFunc?: any) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .delete(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        setFunc()
        dispatch(removeItem(res.data))

        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
        dispatch(setLoading(false))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

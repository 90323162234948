import React from "react"

import CloseIcon from "../../assets/svg/closeIcon.svg"
import { SubmitHandler, useForm } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { changePassword, setError } from "../../redux/users"

interface Props {
  password: boolean
  setPassword: any
}

type Inputs = {
  old_password: string
  password: string
}

const ChangePswd = ({ password, setPassword }: Props) => {
  const { users } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()
  
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    dispatch(
      changePassword({
        old_password: data.old_password,
        password: data.password,
      }, () => {
        setPassword(false)
      }),
    )
    
  }

  const Cancel = () => {
    dispatch(setError(false))
    setPassword(!password)
  }

  return (
    <div
      className={password ? "exitModal" : "none"}
    >
      <div className="exitModal__content" onClick={(e) => e.stopPropagation()}>
        <img src={CloseIcon} alt="" onClick={Cancel} />
        <div className="exitModal__content_title">Изменение пароля</div>
        <input
          type="text"
          placeholder="Старый пароль"
          {...register("old_password", { required: true })}
        />
        {errors && <span className="error">{users.error?.old_password}</span>}
        <br />
        <input
          type="text"
          placeholder="Новый пароль"
          {...register("password", { required: true })}
        />
        {errors && (
          <span className="error">
            <br />
            {users.error?.password}
          </span>
        )}
        <div className="exitModal__content_btns">
          <button className="outline" onClick={Cancel}>
            Отмена
          </button>
          <button className="background" onClick={handleSubmit(onSubmit)}>
            Сохранить
          </button>
        </div>
      </div>
    </div>
  )
}

export default ChangePswd

import { createSlice } from "@reduxjs/toolkit"
import { BalanceState } from "../types"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { setLoading } from "../loading"

const initialState: BalanceState = {
  data: [],
  error: false,
}

const transports_productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.error = false
      state.data = payload
    },
    addItem(state, { payload }) {
      state.data.push({ payload })
    },
    editBalance: (state, { payload }) => {
      state.data = payload
    },
    deleteItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const { setItems, setError, addItem, editBalance, deleteItem } =
  transports_productsSlice.actions
export default transports_productsSlice.reducer

//fetchBalances
export const fetchProducts = (params?: any, After?: any, status2?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/cargo/products/?${status2}`, {
        params: params,
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
        After(response.data)
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setLoading(false))

        dispatch(setError(er.response))
      })
  }
}

export const fetchProduct = (id?: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/cargo/transportations/products/${id}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        After(response.data)
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setLoading(false))

        dispatch(setError(er.response))
      })
  }
}

//addBalances
export const addProduct = (data = {}, After: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .post(`${API_URL}api/v1/cargo/transportations/products/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        After(response.data)
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
  }
}

export const deleteProduct = (id: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .delete(`${API_URL}api/v1/cargo/transportations/products/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        After(res.data)
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
  }
}

//editBalances
export const editBalances = (data = {}, id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .put(`${API_URL}balance/${id}`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editBalance(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
  }
}

//deleteBalances
export const deleteBalances = (id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .delete(`${API_URL}balance/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(deleteItem(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
  }
}

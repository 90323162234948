import { createSlice } from "@reduxjs/toolkit"
import { BalanceState } from "../types"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"

const initialState: BalanceState = {
  data: [],
  error: false,
}

const balanceSlice = createSlice({
  name: "balance",
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.error = false
      state.data = {
        ...payload, results: payload.results.reverse()
      }
    },
    addItem(state, { payload }) {
      state.data.results.unshift(payload)
    },
    editBalance: (state, { payload }) => {
      state.data = {...state.data, results: state.data.results.map((item: any) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })}
    },
    deleteItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const { setItems, setError, addItem, editBalance, deleteItem } =
  balanceSlice.actions
export default balanceSlice.reducer

//fetchBalances
export const fetchBalances = () => {
  return async (dispatch: Dispatch) => {
    axios
      .get(`${API_URL}balance/all/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
      })
      .catch((er) => {

        dispatch(setError(er.response))
      })
  }
}

//addBalances
export const addBalances = (data = {}) => {
  return async (dispatch: Dispatch) => {
    axios
      .post(`${API_URL}balance/create/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(addItem(response.data))
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
  }
}

//editBalances
export const editBalances = (data = {}, id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .put(`${API_URL}balance/${id}`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editBalance(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
  }
}

//deleteBalances
export const deleteBalances = (id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .delete(`${API_URL}balance/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(deleteItem(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
  }
}

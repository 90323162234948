import React, { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import GraphicSliderIcon from "../../assets/svg/graficSlider.svg"
import PlusIcon from "../../assets/svg/plusIcon.svg"
import { useNavigate, useParams } from "react-router-dom"
import Item from "./Item"
import {
  addItemsLast,
  fetchGeneralTransports,
} from "../../redux/cargos/general-transports"
import { setLoading } from "../../redux/loading"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"

const Index = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { status } = useParams()
  const { auth, generalTransports } = useAppSelector((state: any) => state)

  const [open, setOpen] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [allItems, setAllItems] = useState<any>("")
  const [onWay, setOnWay] = useState<any>(false)
  const [waiting, setWaiting] = useState(false)
  const [delivered, setDelivered] = useState(false)

  useEffect(() => {
      getMyGeneralsTransports(status)
  }, [status])

  const getMyGeneralsTransports = (status?: any) => {
    dispatch(
      fetchGeneralTransports(
        {
          limit: 24,
          offset: currentPage,
          transportation__status: status || "on_way",
        },
        (data: any) => {
          setTotalCount(data?.count)
          navigate(`/onways/${status}/`)
        },
      ),
    )
  }

  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const target: any = e.currentTarget
    if (
      target.scrollHeight - (target.scrollTop + target.clientHeight) < 1 &&
      generalTransports?.data?.results?.length < totalCount
    ) {

      dispatch(setLoading(true))
      axios
        .get(`${API_URL}api/v1/cargo/transports/`, {
          params: {
            limit: 24,
            offset: currentPage + 1,
            transportation__status:
              onWay === true
                ? "on_way"
                : allItems || waiting === true
                ? "planning"
                : allItems,
          },
          headers: {
            Authorization: `Token ${access_token} `,
          },
        })
        .then((response: any) => {
          dispatch(addItemsLast(response.data.results))
          dispatch(setLoading(false))
        })
        .catch((er) => {
          dispatch(setLoading(false))
        })
        .finally(() => {})
    }
  }

  let timeId: any = 0
  const handleSearch = (e: any) => {
    setCurrentPage(1)
    if (timeId) {
      clearTimeout(timeId)
    }
    timeId = setTimeout(() => {
      dispatch(
        fetchGeneralTransports({
          limit: 24,
          offset: currentPage,
          search: e,
        }),
      )
    }, 500)
  }

  return (
    <div className="Trucks">
      <div className="spaceBetween" style={{ flexWrap: "nowrap" }}>
        <div className="Trucks__header" style={{ width: "87%" }}>
          <div className="flex" style={{ gap: "15px" }}>
            {auth?.data?.role !== "dispatcher" && (
              <div onClick={() => navigate("/stock/addgoodpage/")}>
                <button className="stock_button">
                  <img src={PlusIcon} alt="" />
                  <div className="button_title">Отправить груз</div>
                </button>
              </div>
            )}

            <div className="SearchBox">
              <input
                className="SearchBox__input"
                type="text"
                placeholder="Поиск"
                onChange={(e: any) => handleSearch(e.target?.value)}
              />
              {open ? (
                <>
                  <div className="Trucks__header_check">
                    <label
                      className="label__for_truck"
                      style={{
                        whiteSpace: "nowrap",
                        width: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        checked={status === "on_way" ? true : false}
                        onChange={() => getMyGeneralsTransports("on_way")}
                        type="checkbox"
                        className="customfor_truck"
                        style={{ whiteSpace: "nowrap" }}
                      />
                      В пути
                    </label>

                    <label
                      className="label__for_truck"
                      style={{
                        whiteSpace: "nowrap",
                        width: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        checked={status === "planning" ? true : false}
                        onChange={() => getMyGeneralsTransports("planning")}
                        type="checkbox"
                        className="customfor_truck"
                      />
                      В сборке
                    </label>
                    <label
                      className="label__for_truck"
                      style={{
                        whiteSpace: "nowrap",
                        width: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        checked={status === "delivered" ? true : false}
                        onChange={() => getMyGeneralsTransports("delivered")}
                        type="checkbox"
                        className="customfor_truck"
                      />
                      Доставлено
                    </label>
                  </div>
                </>
              ) : null}

              <button
                style={{
                  marginLeft: "5px",
                }}
                className="SearchBox_btn"
                onClick={() => setOpen(!open)}
              >
                <img src={GraphicSliderIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <div className="Clients__header_btns">
            <div className="title" style={{ paddingLeft: "0px" }}>
              В пути
            </div>
          </div>
        </div>
      </div>
      <div className="Trucks__height">
        {generalTransports?.data?.results?.length === 0 && (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Пусто!
          </div>
        )}
        <div
          className="Trucks__cards"
          ref={scrollableDivRef}
          onScroll={scrollHandler}
        >
          {generalTransports?.data?.results?.length !== 0
            ? generalTransports?.data?.results?.map((item: any) => (
                <Item
                  dispatcher={auth?.data?.role === "dispatcher" ? true : false}
                  key={item?.id}
                  item={item}
                />
              ))
            : null}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: generalTransports?.loading ? "10vh" : "fit-content",
            width: "100%",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {generalTransports?.data?.results?.length !== 0 ? "" : "Пусто!"}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index

import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"

import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
// import { editAnotherFormOfTruck } from "../../../redux/trucks"
import SelectInput from "../../Forms/Selekted/New-React-selekt"

const options = [
  { id: 1, value: "odddption1", label: "Optionddd1" },
  { id: 2, value: "opddtion2", label: "Optiond 2" },
  { id: 3, value: "opdddtion3", label: "Option d3" },
]

type Props = {
  licence_date_begin: string
  licence_date_end: string
  experience_driver_year: string
  experience: string
  intenational_licence: string
  fine_info: string
  languages: number
}

interface AddDriverFormProps {
  setOnAnother: Function
  setCreate: Function
}

const AboutAnotherForm = ({ setOnAnother, setCreate }: AddDriverFormProps) => {
  const { trucks } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  const [intenationalLicence, setIntenationalLicence] = useState<any>([])
  const [fineInfo, setFineInfo] = useState<any>([])
  const [languages, setLanguages] = useState<any>([])

  const combineOptionValues = (options: any): string => {
    return options.map((option: any) => option.label).join(",")
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Props>()
  const onSubmit: SubmitHandler<Props> = (data) => {
    // dispatch(
    //   editAnotherFormOfTruck({
    //     licence_date_begin: data.licence_date_begin,
    //     licence_date_end: data.licence_date_end,
    //     experience_driver_year: data.experience_driver_year,
    //     experience: data.experience,
    //     intenational_licence: combineOptionValues(intenationalLicence),
    //     fine_info: combineOptionValues(fineInfo),
    //     languages: combineOptionValues(languages),
    //   }),
    // )
    setOnAnother(false)
    setCreate(true)
    reset()
  }

  return (
    <div className="form_inputs">
      <div className="form_inputs-input">
        <div>
          <p>Дата выдачи прав: <u className="redStar">*</u></p>
        </div>
        <div>
          <input
            placeholder="Дата"
            className="form_inputs-input-text"
            type="text"
            defaultValue={trucks?.data?.licence_date_begin}
            {...register("licence_date_begin")}
          />
        </div>
      </div>

      <div className="form_inputs-input">
        <div>
          <p>Срок действия прав до: <u className="redStar">*</u></p>
        </div>
        <div>
          <input
            placeholder="Срок действия:"
            className="form_inputs-input-text"
            type="text"
            defaultValue={trucks?.data?.licence_date_end}
            {...register("licence_date_end")}
          />
        </div>
      </div>

      <div className="form_inputs-input">
        <div>
          <p>Стаж вождения: <u className="redStar">*</u></p>
        </div>
        <div>
          <input
            placeholder="Стаж:"
            className="form_inputs-input-text"
            type="text"
            defaultValue={trucks?.data?.experience_driver_year}
            {...register("experience_driver_year")}
          />
        </div>
      </div>
      {/* <div className="form_inputs-input">
        <div>
          <p>Образование:</p>
        </div>
        <SelectInput
          error={null}
          setState={setTest}
          state={test}
          placeholder="Образование:"
          options={options}
        />
      </div> */}
      {/* <div className="form_inputs-input">
        <div>
          <p>Частота платежей:</p>
        </div>
        <SelectInput
          error={null}
          setState={setTest}
          state={test}
          placeholder="Частота платежей:"
          options={options}
        />
      </div> */}
      <div className="form_inputs-input">
        <div>
          <p>Опыт работы в сфере перевозок грузов: <u className="redStar">*</u></p>
        </div>
        <div>
          <input
            placeholder="Опыт работы:"
            className="form_inputs-input-text"
            type="text"
            defaultValue={trucks?.data?.experience}
            {...register("experience")}
          />
        </div>
      </div>
      <div className="form_inputs-input">
        <div>
          <p>Лицензии на международные перевозки: <u className="redStar">*</u></p>
        </div>
        <SelectInput
          error={null}
          setState={setIntenationalLicence}
          state={intenationalLicence}
          placeholder={
            trucks?.data?.intenational_licence === true ? "Есть" : "Нет"
          }
          options={options}
        />
      </div>
      <div className="form_inputs-input">
        <div>
          <p>Информация о наличии судимостей или штрафов водителя: <u className="redStar">*</u></p>
        </div>
        <SelectInput
          error={null}
          setState={setFineInfo}
          state={fineInfo}
          placeholder={trucks?.data?.fine_info === true ? "Есть" : "Нет"}
          options={options}
        />
      </div>
      <div className="form_inputs-input">
        <div>
          <p>Знание языков: <u className="redStar">*</u></p>
        </div>
        <SelectInput
          error={null}
          setState={setLanguages}
          state={languages}
          placeholder={
            trucks?.data?.languages ? trucks?.data?.languages : "Знание языков:"
          }
          options={options}
        />
      </div>
      <br />
      {errors && <div className="error">{trucks.error?.detail}</div>}
      <div className="form_button">
        <button onClick={handleSubmit(onSubmit)} className="TruckBtn">
          Сохранить
        </button>
        <button onClick={() => setOnAnother(false)} className="TruckBtn2">
          Отмена
        </button>
      </div>
      <br />
    </div>
  )
}

export default AboutAnotherForm

import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Good_stock_zone } from './Good_stock_zone';
import Select from 'react-select'
import { fetchTruckByType } from '../../redux/cargos/trucks';
import { Zone } from './Zone';
import { ActualItem } from './ActualItem';
import { AdditionalItem } from './AdditionalItem';
import { deleteProduct } from '../../redux/transports-products';
import { editTransportationTransport, removeProductTransportation } from '../../redux/cargos/transportations';
import { setRecheck } from '../../redux/cargos/stock';
import axios from 'axios';
import { API_URL } from '../../api/api_config';
import { access_token } from '../../services';
import { toast } from 'react-toastify';

const AssemblyItem = ({ item, truck }: any) => {
    const dispatch = useAppDispatch()
    const [actualOpener, setActualOpener] = useState(true)
    const [additionalOpener, setAdditionalOpener] = useState(true)

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,                                                                                                                                                                                        
            padding: '2px 5px',
            borderRadius: "10px",
            cursor: "pointer",
            marginRight: "1px",
            width: '100% - 135px', // Width set to 100%

        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused
                ? "rgba(157, 194, 81, 0.5)"
                : "transparent",
            color: "var(--lc-black-800, #333)",
        }),
    }
    const { trucks, transportations } = useAppSelector((state) => state)

    const selectCargo = (data: any) => {
        axios.patch(`${API_URL}api/v1/cargo/transportations/transports/${item?.id}/`, data, {
            headers: {
                Authorization: `Token ${access_token}`
            }
        }).then((res1) => {
            axios.get(`${API_URL}api/v1/cargo/transportations/transports/${res1?.data?.id}/`, {
                headers: {
                    Authorization: `Token ${access_token}`
                }
            }).then((res2) => {
                dispatch(editTransportationTransport({ ...res1?.data, item: res2?.data?.item }))
                toast.success('Успешно изменён!')
            })
        })
    }

    const removeItemProduct = (data: any) => {
        dispatch(deleteProduct(data.id, (item: any) => {
            console.log(data);
            
            dispatch(setRecheck(false))
            dispatch(removeProductTransportation({ id: data?.id, truckId: truck?.id, taken_quantity: data?.product?.taken_quantity }))
        }))
    }

    useEffect(() => {
        if (item?.item?.type === 'head') {
            dispatch(fetchTruckByType({ type: 'head' }))
        }

        if (item?.item?.type === 'semitrailer') {
            dispatch(fetchTruckByType({ type: 'semitrailer' }))
        }

        if (item?.item?.type === 'trailer') {
            dispatch(fetchTruckByType({ type: 'trailer' }))
        }

        if (item?.item?.type === 'truck') {
            dispatch(fetchTruckByType({ type: 'truck' }))
        }
    }, [])

    const percentage = (transportations?.oneData?.transports[0]?.taken_lifting_capacity / transportations?.oneData?.transports[0]?.lifting_capacity) * 100;

    return (
        <div style={{
            paddingTop: '8px'
        }}>
            <div className="good_select_item">
                <div className="good_select_title">
                    {item?.item?.type === 'trailer' ? 'Прицеп' : ''}
                    {item?.item?.type === 'semitrailer' ? 'Полуприцеп' : ''}
                    {item?.item?.type === 'head' ? 'Машина' : ''}
                    {item?.item?.type === 'truck' ? 'Машина' : ''}
                </div>
                {<Select onChange={(e: any) => selectCargo({
                    item: e.id,
                    cargo_general_transport: transportations?.oneData?.transports[0]?.id
                })}
                    options={item?.item?.type === 'trailer' ? trucks?.trailers?.results?.map((item: any) => ({
                        label: item?.number,
                        id: item?.id,
                    })) : item?.item?.type === 'semitrailer' ? trucks?.semitrailers?.results?.map((item: any) => ({
                        label: item?.number,
                        id: item?.id,
                    })) : item?.item?.type === 'head' ? trucks?.heads?.results?.map((item: any) => ({
                        label: item?.number,
                        id: item?.id,
                    })) : item?.item?.type === 'truck' ? trucks?.trucks?.results?.map((item: any) => ({
                        label: item?.number,
                        id: item?.id,
                    })) : null} styles={customStyles} placeholder={item?.item?.number} />}
            </div>

            <div className="good_select_zone">
                <div className="good_select_zone_items_inner">
                    <Good_stock_zone type={item?.item?.type} elem={
                        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'space-between', right: item?.item?.type === 'truck' ? '62px' : item?.item?.type === 'semitrailer' ? '14px' : item?.item?.type === 'trailer' ? '0' : '0', top: '-20px' }}>
                            {
                                item?.item?.type !== 'head' && item?.item?.zones?.map((item: any) => (
                                    <Zone number={item?.order} item={item} />
                                ))
                            }
                        </div>
                    } />
                </div>
            </div>

            {
                item?.item?.type !== 'head' ? <>
                    <div className="good_indicator">
                        <div className="good_indicator_titles">
                            <div className="good_indicator_title">Общий объем:</div>
                            <div className="good_indicator_mini_title">{transportations?.oneData?.transports[0]?.lifting_capacity}кг</div>
                        </div>
                        <div className="good_indicator_items" style={{
                            marginTop: '20px'
                        }}>
                            <div className="good_indicator_grey good_indicator_position"></div>
                            <div style={{ width: `${percentage ? (percentage > 100 ? 100 : percentage) : '0'}%`, background: `${percentage > 100 ? 'red' : ''}` }} className="good_indicator_green good_indicator_position">
                                <div className="good_indicator_green_title">
                                    <div className="good_indicator_green_number good_indicator_position_green" style={
                                        percentage < 8 ?
                                            { right: `-30px`, position: 'unset', marginTop: '-23px', color: `${percentage > 100 ? 'red' : ''}`, } :
                                            percentage > 92 ?
                                                { right: `30px`, position: 'unset', marginTop: '-23px', display: 'flex', justifyContent: 'end' } :
                                                { right: `-20px`, color: `${percentage > 100 ? 'red' : ''}`, }
                                    }>{transportations?.oneData?.transports[0]?.taken_lifting_capacity || 0}к.м.</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ paddingTop: '5px' }}>
                        <div className="good_actual">
                            <div className="good_opener" onClick={() => setActualOpener(!actualOpener)}>
                                <div className="good_opener_title">Фактическая загрузка</div>
                                {
                                    actualOpener ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.73484 3.98483C5.88128 3.83839 6.11872 3.83839 6.26516 3.98483L10.7652 8.48484C10.9116 8.63128 10.9116 8.86872 10.7652 9.01516C10.6187 9.16161 10.3813 9.16161 10.2348 9.01516L6 4.78033L1.76517 9.01516C1.61872 9.16161 1.38128 9.16161 1.23483 9.01516C1.08839 8.86872 1.08839 8.63128 1.23483 8.48484L5.73484 3.98483Z" fill="#1A1A1A" />
                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.23483 3.98483C1.38128 3.83839 1.61872 3.83839 1.76517 3.98483L6 8.21967L10.2348 3.98483C10.3813 3.83839 10.6187 3.83839 10.7652 3.98483C10.9116 4.13128 10.9116 4.36872 10.7652 4.51517L6.26516 9.01516C6.11872 9.16161 5.88128 9.16161 5.73484 9.01516L1.23483 4.51517C1.08839 4.36872 1.08839 4.13128 1.23483 3.98483Z" fill="#1A1A1A" />
                                    </svg>
                                }
                            </div>

                            {
                                actualOpener ?
                                    <div className="good_actual_items">
                                        {
                                            item?.inner_products?.map((i: any) => (
                                                i?.from_route?.congress === transportations?.oneData?.routes?.[0]?.congress && !i?.is_delivered ? <ActualItem removeItem={removeItemProduct} item={i} truck={truck} /> : null
                                            ))
                                        }
                                       {item?.inner_products?.length !== 0  ? <div>{!item?.inner_products?.find((i: any) => i?.from_route?.congress === transportations?.oneData?.routes?.[0]?.congress) ? <div style={{ display: 'flex', justifyContent: 'center' }}>Пусто!</div> : null}</div>: <div style={{ display: 'flex', justifyContent: 'center' }}>Пусто!</div>}
                                       {/* {item?.inner_products?.filter((item: any) => item?.is_delivered)?.item?.inner_products?.length !== 0 && <div style={{textAlign: 'center'}}>Пусто</div>} */}
                                    </div> : null
                            }
                        </div>

                        <div className="good_actual">
                            <div className="good_opener" onClick={() => setAdditionalOpener(!additionalOpener)}>
                                <div className="good_opener_title">Догрузка</div>
                                {
                                    additionalOpener ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.73484 3.98483C5.88128 3.83839 6.11872 3.83839 6.26516 3.98483L10.7652 8.48484C10.9116 8.63128 10.9116 8.86872 10.7652 9.01516C10.6187 9.16161 10.3813 9.16161 10.2348 9.01516L6 4.78033L1.76517 9.01516C1.61872 9.16161 1.38128 9.16161 1.23483 9.01516C1.08839 8.86872 1.08839 8.63128 1.23483 8.48484L5.73484 3.98483Z" fill="#1A1A1A" />
                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.23483 3.98483C1.38128 3.83839 1.61872 3.83839 1.76517 3.98483L6 8.21967L10.2348 3.98483C10.3813 3.83839 10.6187 3.83839 10.7652 3.98483C10.9116 4.13128 10.9116 4.36872 10.7652 4.51517L6.26516 9.01516C6.11872 9.16161 5.88128 9.16161 5.73484 9.01516L1.23483 4.51517C1.08839 4.36872 1.08839 4.13128 1.23483 3.98483Z" fill="#1A1A1A" />
                                    </svg>
                                }
                            </div>

                            {
                                additionalOpener ?
                                    <div className="good_actual_items">
                                        {
                                            item?.inner_products?.map((i: any) => (
                                               i?.from_route?.congress !== transportations?.oneData?.routes?.[0]?.congress  && !i?.is_delivered ? <ActualItem removeItem={removeItemProduct} item={i} truck={truck} /> : null
                                            ))
                                        }
                                       {item?.inner_products?.length !== 0  ? <div>{!item?.inner_products?.find((i: any) => i?.from_route?.congress !== transportations?.oneData?.routes?.[0]?.congress) ? <div style={{ display: 'flex', justifyContent: 'center' }}>Пусто!</div> : null}</div>: <div style={{ display: 'flex', justifyContent: 'center' }}>Пусто!</div>}
                                       {/* {item?.inner_products?.filter((item: any) => item?.is_delivered)?.item?.inner_products?.length !== 0 && item?.inner_products?.length === 0 ? <div style={{textAlign: 'center'}}>Пусто</div> :} */}

                                    </div> : null

                            }
                        </div>
                    </div>
                </> : null
            }


        </div>
    )
}

export default AssemblyItem

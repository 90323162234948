import { useEffect, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Select from "react-select"

import { ToastContainer, toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { useParams } from "react-router-dom"
import { fetchAllUsers, fetchUserById, setItemsById } from "../../redux/users"

type Props = {
  user: any
  value: any
}

const insuranseOption = [
  { id: 1, label: "Есть", value: "Есть" },
  { id: 2, label: "Нет", value: "Нет" },
]

export const PaymantModal = ({ setModal, addPayment }: any) => {
  const { users, stocks, receipts } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Props>()

  const [menu1, setMenu1] = useState(true)
  const [choosedUser, setChoosedUser] = useState<any>()
  const [value, setValue] = useState<any>()
  const [currentPage, setCurrentPage] = useState(1)

  const AfterGetFunc = (data: any) => {
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    // dispatch()
    if (id) {
      dispatch(fetchUserById(id))
    } else {
      null
    }

    dispatch(
      fetchAllUsers(
        { limit: 24, roles: "client", is_active: true, is_confirmed: true },
        () => { },
        true,
      ),
    )
  }, [])

  const usersScrollHandler = (e: any) => {
    dispatch(
      fetchAllUsers(
        {
          limit: 24,
          offset: currentPage + 1,
          roles: "client",
          is_active: true,
          is_confirmed: true,
        },
        AfterGetFunc,
      ),
    )
  }

  const onSubmit: SubmitHandler<Props> = (item: any) => {
    addPayment(
      {
        user: choosedUser?.id || users?.dataId?.id,
        value: item.value,
      },
      (data: any) => {
        console.log(data)

        dispatch(
          setItemsById({
            ...users?.dataId,
            client: {
              ...users?.dataId?.client,
              balance:
                Number(users?.dataId?.client?.balance) + Number(item.value),
            },
          }),
        )
      },
    )
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px",
      cursor: "pointer",
      background: "#fff",
      marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      zIndex: "10000000",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent", // LC Green color on focus
      color: "var(--lc-black-800, #333)",
    }),
  }

  return (
    <div style={{ height: "fit-content" }} className="catalog_list">
      <div className={menu1 ? "catalog_items" : "menunone"}>
        <div className="catalog_item">
          <div>
            Пользователь ФИО: <u className="redStar">*</u>
          </div>
          <div
            style={{
              width: "58.7%",
            }}
          >
            <Controller
              control={control}
              {...register("user")}
              render={({ field }) => (
                <Select
                  styles={customStyless}
                  {...field}
                  onMenuScrollToBottom={(e: any) => usersScrollHandler(e)}
                  placeholder={
                    users.dataId?.first_name
                      ? users.dataId?.first_name + " " + users.dataId?.last_name
                      : "Пусто"
                  }
                  defaultValue={
                    users.dataId?.first_name
                      ? users.dataId?.first_name + " " + users.dataId?.last_name
                      : null
                  }
                  isDisabled={id ? true : false}
                  onChange={(e) => setChoosedUser(e)}
                  options={users.dataSelect?.results?.map((item: any) => ({
                    value: item.first_name + " " + item.last_name,
                    label: item.first_name + " " + item.last_name,
                    id: item.id,
                  }))}
                />
              )}
            />
            <div className="error">{errors && stocks.error?.equipment}</div>
          </div>
        </div>
        <div className="catalog_item">
          <div>
            ID: <u className="redStar">*</u>
          </div>
          <div
            style={{
              width: "58.7%",
            }}
          >
            <input
              value={users.dataId?.id ? users?.dataId?.id : choosedUser?.id}
              placeholder="Пусто"
              type="text"
            />
            <div className="error">{errors && stocks.error?.address}</div>
          </div>
        </div>
        <div className="catalog_item">
          <div>
            Сумма: <u className="redStar">*</u>
          </div>
          <div
            style={{
              width: "58.7%",
            }}
          >
            <input
              {...register("value", { required: false })}
              placeholder="Пусто"
              type="number"
              onWheel={(e) => e.currentTarget.blur()}
            />
            <div className="error">{errors && stocks.error?.volume}</div>
          </div>
        </div>
      </div>
      <br />

      <div
        className="form_button"
        style={{
          paddingTop: "30px",
        }}
      >
        <button onClick={() => setModal(false)} className="TruckBtn2">
          Отмена
        </button>

        <button onClick={handleSubmit(onSubmit)} className="TruckBtn">
          Сохранить
        </button>
      </div>
    </div>
  )
}

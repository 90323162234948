import { useEffect, useRef, useState } from "react"
import NewsCard from "../../components/new/NewsCard"
import Select from "react-select"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { addItemsLast, deleteNews, fetchNews } from "../../redux/news"
import { Link, useParams } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"

import CloseIcon from "../../assets/svg/closeIcon.svg"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { setItems } from "../../redux/news/index"
import { Controller, useForm } from "react-hook-form"
import { fetchNewsCategories } from "../../redux/news/news-categories"
import { setLoading } from "../../redux/loading"
import { access_token } from "../../services"

type Props = {
  categories: any
}

const NewsPage = () => {
  const dispatch = useAppDispatch()
  const { auth, news, newsCategories } = useAppSelector((state: any) => state)
  const { register, control } = useForm<Props>()

  const [onModal, setOnModal] = useState(false)
  const [curentPost, setCurentPost] = useState<any>()
  const [categoties, setCategories] = useState<any>()
  const [isPublic, setIsPublic] = useState<any>(null)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)

  const handleDeletePost = () => {
    dispatch(deleteNews(curentPost)).then(() => setOnModal(false))
  }

  useEffect(() => {
    dispatch(fetchNewsCategories({}))
  }, [])

  const AfterGet = (data: any) => {
    setTotalCount(data?.count)
  }

  useEffect(() => {
    dispatch(
      fetchNews(
        {
          limit: 24,
          offset: currentPage,
          category: categoties?.id ? categoties?.id : null,
          is_published:
            isPublic === true ? true : isPublic === !true ? false : null,
        },
        AfterGet,
      ),
    )
  }, [categoties, isPublic])

  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    if (
      target.scrollHeight - (target.scrollTop + target.clientHeight) < 1 &&
      news?.data?.results?.length < totalCount
    ) {
      dispatch(setLoading(true))
      axios
        .get(`${API_URL}api/v1/news/`, {
          params: {
            limit: 24,
            offset: currentPage + 1,
            type: categoties?.value ? categoties?.value : null,
            is_published:
              isPublic === true ? true : isPublic === !true ? false : null,
          },
          headers: {
            Authorization: `Token ${access_token} `,
          },
        })
        .then((response: any) => {
          dispatch(addItemsLast(response.data.results))
        })
        .catch((er) => {})
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px",
      cursor: "pointer",
      background: "#fff",
      marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      outline: "none",
      color: "#808080",
      boxShadow: state.isFocused ? 0 : 0,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent", // LC Green color on focus
      color: "#808080",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),
  }

  const NewsOptions = [
    { label: "Все", value: null, id: 0 },
    { id: 1, value: "news", label: "Новости" },
    { id: 2, value: "discount", label: "Акции" },
  ]

  return (
    <>
      <div style={{ overflow: "hidden", height: "100%" }} className="">
        <div className="newsheader">
          {auth?.data?.role !== "dispatcher" && (
            <Link to={"/news/add"}>
              <div className="newsheader-button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M7.00003 0.142883C7.47341 0.142883 7.85717 0.526639 7.85717 1.00003V6.14288H13C13.4734 6.14288 13.8572 6.52664 13.8572 7.00003C13.8572 7.47341 13.4734 7.85717 13 7.85717H7.85717V13C7.85717 13.4734 7.47341 13.8572 7.00003 13.8572C6.52664 13.8572 6.14288 13.4734 6.14288 13V7.85717H1.00003C0.526639 7.85717 0.142883 7.47341 0.142883 7.00003C0.142883 6.52664 0.526639 6.14288 1.00003 6.14288H6.14288V1.00003C6.14288 0.526639 6.52664 0.142883 7.00003 0.142883Z"
                    fill="white"
                  />
                </svg>
                <div>Добавить новость</div>
              </div>
            </Link>
          )}
          <div className="newsheader-filter">
            <div className="SearchBox" style={{ width: "50%" }}>
              <button
                className={isPublic === null ? "news_backbtn" : "news_btn"}
                onClick={() => setIsPublic(null)}
              >
                Все
              </button>
              <button
                className={isPublic === !true ? "news_backbtn" : "news_btn"}
                onClick={() => setIsPublic(false)}
              >
                Черновик
              </button>
              <button
                className={isPublic === true ? "news_backbtn" : "news_btn"}
                onClick={() => setIsPublic(true)}
              >
                Опубликованые
              </button>
            </div>
            <div>
              <div className="flex">
                <Controller
                  control={control}
                  {...register("categories", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      defaultValue={NewsOptions[0]}
                      isSearchable={false}
                      options={NewsOptions?.map((item: any) => ({
                        value: item?.value,
                        label: item?.label,
                        id: item.id,
                      }))}
                      onChange={(e) => {
                        setCurrentPage(1)
                        setCategories(e)
                      }}
                    />
                  )}
                />
                <h1>Новости</h1>
              </div>
            </div>
          </div>
        </div>
        <div
          className="newsScrollBar"
          onScroll={scrollHandler}
          ref={scrollableDivRef}
        >
          <main className="newsList">
            {news.data?.results?.map((item: any) => {
              return (
                <NewsCard
                  dispatcher={auth?.data?.role === "dispatcher" ? true : false}
                  setCurentPost={setCurentPost}
                  onModal={setOnModal}
                  key={item.id}
                  item={item}
                />
              )
            })}
          </main>
          {news.data?.results?.length === 0 && (
            <div style={{ textAlign: "center", width: "100%" }}>Пусто!</div>
          )}
        </div>
      </div>
      {onModal && (
        <div className="modal-background" onClick={() => setOnModal(!onModal)}>
          <div className={onModal ? "exitModal" : "none"}>
            <div
              className="exitModal__content"
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src={CloseIcon}
                alt=""
                onClick={() => setOnModal(!onModal)}
              />
              <div className="exitModal__content_title">
                Вы действительно хотите удaлить?
              </div>
              <div className="exitModal__content_btns">
                <button
                  className="outline"
                  onClick={() => setOnModal(!onModal)}
                >
                  Отмена
                </button>
                <button
                  onClick={handleDeletePost}
                  style={{ background: "#EB5757", border: "none" }}
                  className="background"
                >
                  удaлить
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default NewsPage

import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../../api/api_config"
import { access_token } from "../../../services"
import { toast } from "react-toastify"
import { setLoading } from "../../loading"

const initialState: any = {
  loading: false,
  data: [],
  data_from: [],
  data_to: [],
  oneData: {},
  error: false,
}

const URL = "cities"

const citiesSlice = createSlice({
  name: "cities",
  initialState,
  reducers: {
    getItems: (state, { payload }) => {
      state.data = payload
    },
    getItemsFrom: (state, { payload }) => {
      state.data_from = payload
    },
    getItemsTo: (state, { payload }) => {
      state.data_to = payload
    },
    getItem: (state, { payload }) => {
      state.oneData = payload
    },
    addItem(state, { payload }) {
      state.data.push({ payload })
    },
    editItems: (state, { payload }) => {
      const updatedResults = state.data.results.map((item: any) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
      state.data.results = updatedResults
    },
    editItem: (state, { payload }) => {
      state.oneData = payload
    },
    removeItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const {
  getItems,
  getItem,
  setError,
  addItem,
  editItems,
  removeItem,
  editItem,
  getItemsFrom,
  getItemsTo,
} = citiesSlice.actions
export default citiesSlice.reducer

export const fetchCities = (id?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/`, {
        params: {
          use_pagination: false,
          country: id ? id : null
        },
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        if (response?.data?.results) {
          dispatch(getItems(response?.data?.results))
        } else {
          dispatch(getItems(response?.data))
        }
      })
      .catch((er) => {
        dispatch(setLoading(false))
         
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const fetchCitiesFrom = (id?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/`, {
        params: {
          use_pagination: false,
          country: id ? id : null
        },
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        if (response?.data?.results) {
          dispatch(getItemsFrom(response?.data?.results))
        } else {
          dispatch(getItemsFrom(response?.data))
        }
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const fetchCitiesTo = (id?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/`, {
        params: {
          use_pagination: false,
          country: id ? id : null
        },
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        if (response?.data?.results) {
          dispatch(getItemsTo(response?.data?.results))
        } else {
          dispatch(getItemsTo(response?.data))
        }
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const fetchCity = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(getItem(response.data))
      })
      .catch((er) => {
         dispatch(setError(er?.response?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const addCity = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/${URL}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(addItem(response.data))
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const editCity = (data: any, id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/${URL}/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editItem(res.data))
        dispatch(editItems(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const removeCity = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .delete(`${API_URL}api/v1/${URL}/${id}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(removeItem(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

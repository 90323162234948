export function getRoleName(name: string) {
  switch (name) {
    case "client":
      return "Клиент"
    case "manager":
      return "Менеджер"
    case "driver":
      return "Водитель"
    case "admin":
      return "Администратор"
    case "dispatcher":
      return "Диспетчер"
    default:
      return "Ошибка"
  }
}

export function getInsuranceById(name: any) {
  switch (name) {
    case 1:
      return true
    case 2:
      return false
    default:
      return false
  }
}

export function getInsurance(name: any) {
  switch (name) {
    case true:
      return "Есть"
    case false:
      return "Нет"
    default:
      return "Ошибка"
  }
}

export function getWeekByName(name: string) {
  switch (name) {
    case "monday":
      return "Понедельник"
    case "tuesday":
      return "Вторник"
    case "wednesday":
      return "Среда"
    case "thursday":
      return "Четверг"
    case "friday":
      return "Пятница"
    case "saturday":
      return "Суббота"
    case "sunday":
      return "Воскресенье"
    default:
      return "Ошибка"
  }
}

export function getWeekByName2(name: string) {
  switch (name) {
    case "Понедельник":
      return "monday"
    case "Вторник":
      return "tuesday"
    case "Среда":
      return "wednesday"
    case "Четверг":
      return "thursday"
    case "Пятница":
      return "friday"
    case "Суббота":
      return "saturday"
    case "Воскресенье":
      return "sunday"
    default:
      return "Ошибка"
  }
}

export function getStateOfStock(name: any) {
  switch (name) {
    case true:
      return "Доступный"
    case false:
      return "Недоступный"
  }
}

export function getStateOfStockById(name: any) {
  switch (name) {
    case 1:
      return true
    case 2:
      return false
    default:
      return "Ошибка"
  }
}

export function getTruckStatus(name: any) {
  switch (name) {
    case "on_way":
      return "В пути"
    case "waiting":
      return "В ожидании"
    case "planning":
      return "Планирование"
    case "booked":
      return "Забронировано"
    default:
      return "Ошибка"
  }
}

export function getPaymentStatus(name: any) {
  switch (name) {
    case "confirmed":
      return "Подтвержденный"
    case "waiting":
      return "В ожидании"
    case "canceled":
      return "Отмененный "
    default:
      return "Ошибка"
  }
}

export function getTrucksType(type: any) {
  switch (type) {
    case "truck":
      return "Стандарт"
    case "head":
      return "Тягач"
    case "trailer":
      return "Прицеп"
    case "semitrailer":
      return "Тандем"
  }
}

export function getProductsType(type: any) {
  switch (type) {
    case "plastic_bag":
      return "Пакет"
    case "sack":
      return "Мешок"
    case "box":
      return "Коробка"
    default:
      return "Ошибка"
  }
}

export function getCargoStatus(status: any) {
  switch (status) {
    case "waiting":
      return "В ожидании"
    case "canceled":
      return "Отменено"
    case "in_stock":
      return "На складе"
    case "delivered":
      return "Доставлено"
    case "picked":
      return "Товар получен клиентом"
    default:
      return "Ошибка"
  }
}

export function getStockTypes(type: any) {
  switch (type) {
    case true:
      return "Доступный"
    case false:
      return "Скрытый"
    default:
      return "Ошибка"
  }
}

export function getStockTypeID(typeId: any) {
  switch (typeId) {
    case 1:
      return true
    case 2:
      return false
    default:
      return "Ошибка"
  }
}

export function formatPhoneNumber(phoneNumber: any) {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "") // Remove non-numeric characters
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{3})$/)

  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
  }

  return phoneNumber // Return the original number if the format doesn't match
}

import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
  EditSenderStock,
  addItemsLast,
  addSenderStock,
  editSenderIntermediateItem,
  editSenderIntermediateItem2,
  editSenderIntermediateStock,
  fetchStock,
  fetchStocks,
} from "../../redux/cargos/stock"
import {
  editRouteItems,
  editRouteItems2,
  editTransportations,
} from "../../redux/cargos/transportations"
import { useParams } from "react-router-dom"
import { editRoute } from "../../redux/cargos/cargo-routes"
import { setLoading } from "../../redux/loading"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { fetchCargos } from "../../redux/cargos/cargo"

export const StockItem = ({ elem, intermediate, stock, index, route }: any) => {
  const [swiper, setSwiper] = useState(false)
  const [open, setOpen] = useState(false)
  const { id } = useParams()

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      padding: "2px 40px",
      borderRadius: "4px",
      cursor: "pointer",
      marginRight: "1px",
      width: "230px", // Width set to 100%
      backgroundColor: "#83C251",
    }),

    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent",
      color: "var(--lc-black-800, #333)",
    }),
  }

  const customStyles2 = {
    control: (provided: any, state: any) => ({
      ...provided,
      padding: "2px 5px",
      cursor: "pointer",
      marginRight: "1px",
      width: "100% - 135px", // Width set to 100%
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent",
      color: "var(--lc-black-800, #333)",
    }),
  }

  const dispatch = useAppDispatch()
  const { stocks, transportations, cargo } = useAppSelector((state) => state)
  const [currentPage, setCurrentPage] = useState(1)

  const editStock = (e: any) => {
    if (index !== 0) {
      if (id) {
        dispatch(
          editRoute(
            {
              stock: e.id,
              transportation: transportations?.oneData?.id,
            },
            route,
            (data: any) => {
              console.log(data)
              axios
                .get(`${API_URL}api/v1/cargo/stocks/${data?.stock}/`, {
                  headers: {
                    Authorization: `Token ${access_token} `,
                  },
                })
                .then((response: any) => {
                  dispatch(
                    editRouteItems2({ ...response.data, routeId: route }),
                  )
                  setOpen(false)
                  dispatch(setLoading(false))
                })
                .catch((er) => {
                  dispatch(setLoading(false))
                })
                .finally(() => {})
            },
          ),
        )
      } else {
        dispatch(editSenderIntermediateStock(e.id, index))
      }
    } else {
      if (id) {
        dispatch(
          editRoute(
            {
              stock: e.id,
              transportation: transportations?.oneData?.id,
            },
            route,
            (data: any) => {
              console.log(data)

              axios
                .get(`${API_URL}api/v1/cargo/stocks/${data?.stock}/`, {
                  headers: {
                    Authorization: `Token ${access_token} `,
                  },
                })
                .then((response: any) => {
                  dispatch(
                    editRouteItems2({ ...response.data, routeId: route }),
                  )
                  setOpen(false)
                  dispatch(setLoading(false))
                })
                .catch((er) => {
                  dispatch(setLoading(false))
                })
                .finally(() => {})
            },
          ),
        )
      } else {
        dispatch(EditSenderStock(e.id))
        dispatch(editSenderIntermediateStock(e.id, index))
      }
    }
    setOpen(false)

    // dispatch(editTransportations({
    //     stock: e.id
    // }, e.id))
  }
  const scrollHandler = (e: any) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/cargo/stocks/`, {
        params: {
          limit: 24,
          offset: currentPage + 1,
        },
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        setCurrentPage(currentPage + 1)
        dispatch(addItemsLast(response.data.results))

        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
      .finally(() => {})
  }

  const percentage = (stock?.products_weight / stock?.volume) * 100

  const GetStockProducts = () => {
    console.log(stock?.id)

    if (!open) {
      dispatch(setLoading(true))
      axios
        .get(`${API_URL}api/v1/cargo/stocks/${stock?.id}/`, {
          headers: {
            Authorization: `Token ${access_token} `,
          },
        })
        .then((response: any) => {
          dispatch(editRouteItems(response.data))
          setOpen(!open)
          dispatch(setLoading(false))
        })
        .catch((er) => {
          dispatch(setLoading(false))
        })
        .finally(() => {})
    } else {
      setOpen(!open)
    }
  }

  const [arrDayBefore, setArrDayBefore] = useState<any>()
  const [arrDayAfter, setArrDayAfter] = useState<any>()

  useEffect(() => {
    dispatch(
      fetchCargos(
        {
          limit: 24,
          offset: currentPage,
          status: "in_stock",
          sender__stock: stock?.id,
          arriving_day_before: arrDayBefore,
          arriving_day_after: arrDayAfter,
        },
        (data: any) => {},
      ),
    )
  }, [arrDayAfter, arrDayBefore])

  return (
    <div>
      <div className="good_stock_header">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "5px",
          }}
        >
          {index === 0 ? (
            <div className="good_stock_header_title">Склада отправки</div>
          ) : (
            ""
          )}
          {index !== 0 ? (
            <div className="good_stock_header_title">Склада отправки -</div>
          ) : (
            ""
          )}
          {index !== 0 ? (
            <div className="good_stock_header_title">
              промежуточной отгрузки
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="good_stock_header_btns">
          <Select
            onMenuScrollToBottom={(e: any) => scrollHandler(e)}
            options={stocks?.data?.results?.map((item: any) => ({
              id: item.id,
              value: item.name,
              label: item.name,
            }))}
            placeholder={stock?.name}
            styles={customStyles}
            onChange={(e) => editStock(e)}
          />
          {transportations?.oneData ? (
            <button onClick={GetStockProducts}>
              <div style={{ width: "60px" }}>
                {open ? "Скрыть " : "Открыть"}
              </div>
            </button>
          ) : null}
        </div>
      </div>

      {open && (
        <div className="good_stock_info">
          <div
            className="good_indicator"
            style={{ padding: "0px", gap: "22px" }}
          >
            <div className="good_indicator_titles">
              <div className="good_indicator_title">Общий объем:</div>
              <div className="good_indicator_mini_title">
                {stock?.volume}к.м.
              </div>
            </div>
            <div className="good_indicator_items">
              <div className="good_indicator_grey good_indicator_position"></div>
              <div
                style={{
                  width: `${
                    percentage ? (percentage > 100 ? 100 : percentage) : "0"
                  }%`,
                  background: `${percentage > 100 ? "red" : ""}`,
                }}
                className="good_indicator_green good_indicator_position"
              >
                <div className="good_indicator_green_title">
                  <div
                    className="good_indicator_green_number good_indicator_position_green"
                    style={
                      percentage < 8
                        ? {
                            right: `-30px`,
                            position: "unset",
                            marginTop: "-23px",
                            color: `${percentage > 100 ? "red" : ""}`,
                          }
                        : percentage > 92
                        ? {
                            right: `30px`,
                            position: "unset",
                            marginTop: "-23px",
                            display: "flex",
                            justifyContent: "end",
                          }
                        : {
                            right: `-20px`,
                            color: `${percentage > 100 ? "red" : ""}`,
                          }
                    }
                  >
                    {stock?.products_weight}к.м.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="good_stock_info_sorts">
            <div className="good_stock_info_sorts_left">
              <div className="good_stock_info_sorts_right_title">
                Сортировка по времени от:
              </div>
              <input
                style={{
                  padding: "10px 14px",
                  borderRadius: "6px",
                  border: "1px solid #ccc",
                }}
                onChange={(e: any) => setArrDayAfter(e?.target?.value)}
                placeholder=""
                type="date"
              />
              {/* <div>Клиент</div>
                        <div className='good_swiper' onClick={() => setSwiper(!swiper)}>
                            <div className={swiper ? 'good_swiper_border_black good_swiper_border' : 'good_swiper_border'}></div>
                            <div className={swiper ? 'good_swiper_circle_black good_swiper_circle' : 'good_swiper_circle'}></div>
                        </div>
                        <div>Товар</div> */}
            </div>
            <div className="good_stock_info_sorts_right">
              <div className="good_stock_info_sorts_right_title">
                Сортировка по времени до:
              </div>
              <input
                style={{
                  padding: "10px 14px",
                  borderRadius: "6px",
                  border: "1px solid #ccc",
                }}
                onChange={(e: any) => setArrDayBefore(e?.target?.value)}
                placeholder=""
                type="date"
              />
            </div>
          </div>
          {elem}
        </div>
      )}

      <div className="error">
        {
          transportations?.error?.routes?.find(
            (item: any, index1: any) => index1 === index,
          )?.stock
        }
      </div>
    </div>
  )
}

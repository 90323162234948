import React, { useEffect, useRef, useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"

import { GreenAddBtn, NotificationBtn } from "../btns"
import MessageIcon from "../../assets/svg/messageIcon.svg"
import NotificationIcon from "../../assets/svg/notificationIcon.svg"
import Avatar from "../../assets/img/no-profile.jpeg"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { fetchProfile } from "../../redux/auth/authSlice"
import {
  addItemsLast,
  fetchOwnNotifications,
} from "../../redux/auth/notifications"
import { getRoleName } from "../../utils/functions"
import POPShipment from "../shipment/Modal/POPShipment"
import { fetchCities } from "../../redux/cargos/cities"
import { Modal } from "../Modal"
import { AddCargo } from "../cargos/Add"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import ExitModal from "../profileModals/exitModal"
import { setOnlineItems, setSortItems } from "../../redux/chats"
import { toast } from "react-toastify"

interface Props {
  show: boolean
}

const Index = ({ show }: Props) => {
  const { auth, notifications } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  const [state, setState] = useState(false)
  const [isHovered, setIsHovered] = React.useState(false)
  const [exit, setExit] = useState(false)
  const [active, setActiveMessageHeader] = React.useState(false)

  useEffect(() => {
    dispatch(fetchProfile())
  }, [])

  const location = useLocation();

  // Access the pathname property of the location object to get the URL path
  const pathName = location.pathname;

  useEffect(() => {
    if (pathName === '/chat/none/active') {
      setActiveMessageHeader(false)
    }
  }, [pathName])


  const queries = new URLSearchParams({
    Authorization: `Token ${access_token}`,
    mode: "dispatcher",
  }).toString()

  const online_user_ws = useRef<WebSocket | null>(null)


  useEffect(() => {
    online_user_ws.current = new WebSocket(
      `wss://back.lidercargo.net/online-user/?${queries}`
    )

    online_user_ws.current.onmessage = (e) => {
      const data = e.data.toString()
      const message: any = JSON.parse(data)


      if (message.type === 'new_message') {

        toast.success('у вас новое сообщение')
        setActiveMessageHeader(true)
        dispatch(setSortItems(message))


      } else if (message.type === 'online_user') {
        dispatch(setOnlineItems(message))
      }

      if (data.type === 'online') {
        if (data.user_id === Number(localStorage.getItem('id'))) {
          // setOnline(data.is_online)
        }
      }
    }

    online_user_ws.current.onopen = () => {
      console.log("Online WebSocket connected");
    }

    online_user_ws.current.onclose = () => {
      console.log("Online WebSocket closed");
    }

    return () => {
      if (online_user_ws.current) {
        online_user_ws.current.close();
      }
    };
  }, []);

  return (
    <>
      <div className="Header">
        <div className="header wrapper">
          <div></div>

          <div className="header__inner">
            <div className="header__inner_info">
              <div className="header__inner_info-notifications">
                <NavLink to="chat/none/active">
                  <div style={{ position: "relative" }}>
                    {state === true ? (
                      <svg
                        style={{
                          position: "absolute",
                          right: "-3px",
                          top: "-3px",
                          zIndex: "1",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <circle
                          cx="6"
                          cy="6"
                          r="5.5"
                          fill="#83C251"
                          stroke="white"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                    {auth?.data?.role === "dispatcher" ||
                      auth?.data?.role === "admin" ? (
                      <div>
                        {auth?.data?.role === "dispatcher" && <NotificationBtn image={MessageIcon} active={active} />}
                      </div>
                    ) : null}
                  </div>
                </NavLink>

                <div
                  onClick={() => setIsHovered(!isHovered)}
                  style={
                    auth?.data?.role === "admin" ||
                      auth?.data?.role === "dispatcher"
                      ? { display: "none", position: "relative" }
                      : { display: "block", position: "relative" }
                  }
                >
                  {auth.data?.notification_count?.length !== 0 ? (
                    <svg
                      style={{
                        position: "absolute",
                        right: "-3px",
                        top: "-3px",
                        zIndex: "1",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <circle
                        cx="6"
                        cy="6"
                        r="5.5"
                        fill="#83C251"
                        stroke="white"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                  <button
                    style={{ position: "relative" }}
                    className="NotificationBtn"
                  >
                    <div className="NotificationBtn_icon">
                      <img src={NotificationIcon} alt="" />
                    </div>
                    <div className="NotificationBtn_count">
                      {auth.data?.notification_count}
                    </div>
                  </button>
                </div>
              </div>
              <Link to={"profile"}>
                <div className="header__inner_profile">
                  <div className="header__inner_profile-avatar">
                    {/* <div className={active ? "activeback" : "none"}>
                      <div className={active ? "active" : "none"}></div>
                    </div> */}
                    {auth?.data?.avatar ? (
                      <img src={auth?.data?.avatar} alt="" />
                    ) : (
                      <img src={Avatar} alt="" />
                    )}
                  </div>
                  <div>
                    <div className="header__inner_profile-title">
                      {auth?.data?.first_name + " " + auth?.data?.last_name}
                    </div>
                    <div className="header__inner_profile-subtitle">
                      {getRoleName(auth?.data?.role)}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <button
              className="header__inner_exit"
              onClick={() => setExit(!exit)}
            >
              Выйти
            </button>
          </div>
        </div>
      </div>
      {notifications?.data?.results?.length !== 0
        ? isHovered && (
          <div
            className="modal-background"
            onClick={() => setIsHovered(!isHovered)}
          >
            <POPShipment isHovered={isHovered} setIsHovered={setIsHovered} />
          </div>
        )
        : null}
      <ExitModal exit={exit} setExit={setExit} />
    </>
  )
}

export default Index

import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { getAnalytics } from "firebase/analytics"
import axios from "axios"
import { toast } from "react-toastify"

import { access_token, fbToken, user } from "../services"

const firebaseConfig = {
  apiKey: "AIzaSyBBcuQqf-ZmmM_A2RGC7YY7wGOxFfku37M",
  authDomain: "lider-cargo-b2c.firebaseapp.com",
  projectId: "lider-cargo-b2c",
  storageBucket: "lider-cargo-b2c.appspot.com",
  messagingSenderId: "269825875169",
  appId: "1:269825875169:web:87acd043f94a388c792ba0",
  measurementId: "G-JPD8GJ9TEE",
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
export const messaging = getMessaging()

export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission()
    if (permission === "granted") {
      const token = await getToken(messaging)
      if (fbToken !== token) {
        axios
          .post(
            "https://back.lidercargo.net/api/v1/auth/devices/",
            {
              name: user?.first_name,
              registration_id: token,
              type: "web",
              active: true,
            },
            {
              headers: {
                Authorization: `Token ${access_token} `,
              },
            },
          )
          .then((response: any) => {
            if (response.request.status >= 200) {
              localStorage.setItem("fcm_token", JSON.stringify(token))
            }
          })
      }
    } else {
      console.log("Notification permission denied.")
      toast.warn(
        `Разрешение на уведомление было отклонено. Пожалуйста, включите разрешение на уведомления!!!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      )
    }
  } catch (error) {
    console.error("Failed to request notification permission:", error)
  }
}

export const onMessageListener = async () => {
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
}

import { createSlice } from "@reduxjs/toolkit"
import { ProductsState, TariffsState, GoodsSliceTypes } from "../types"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { setLoading } from "../loading"

const initialState: GoodsSliceTypes = {
  error: false,
  data: [],
  stockSendGoods: [],
  stoksIntermediateGoods: [],
  stockReceiveGoods: [],
  category: [],
  brand: [],
}

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.error = false
      state.data = payload
    },
    setBrands: (state, { payload }) => {
      state.brand = payload
    },
    setSendItems: (state, { payload }) => {
      state.error = false
      state.stockSendGoods = payload
    },
    setIntermediateItems: (state, { payload }) => {
      state.error = false
      state.stoksIntermediateGoods = payload
    },
    setReceiveItems: (state, { payload }) => {
      state.error = false
      state.stockReceiveGoods = payload
    },
    addItem(state, { payload }) {
      state.data.push({ payload })
    },
    addCategory: (state, { payload }) => {
      state.category.push(payload)
    },
    editProduct: (state, { payload }) => {
      state.data = payload
    },
    deleteItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    deleteCategory(state, { payload }) {
      state.category = state.category.filter((item: any) => item.id !== payload)
    },
    deletefetchedCategories(state, actions) {
      state.data = state.data.map((item: any) => {
        if (item.id === actions.payload.producktId) {
          item.products = item.products.filter(
            (item: any) => item.id !== actions.payload.id,
          )
        }
      })
      console.log(actions)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const {
  setItems,
  setError,
  addItem,
  addCategory,
  editProduct,
  deleteItem,
  deleteCategory,
  setSendItems,
  setIntermediateItems,
  setReceiveItems,
  setBrands,
  deletefetchedCategories,
} = productSlice.actions
export default productSlice.reducer

export const fetchProductForSend = (id?: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .get(`${API_URL}api/v1/product-categories/`, {
        params: {
          use_pagination: false,
        },
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setSendItems(response.data))
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const fetchProductsSend = (id?: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .get(`${API_URL}api/v1/product-categories/`, {
        params: {
          use_pagination: false,
        },
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setSendItems(response.data))
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))

      })
  }
}

export const fetchProductBrands = () => {
  return async (dispatch: Dispatch) => {
    axios
      .get(`${API_URL}api/v1/product-brands/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setBrands(response.data))
      })
      .catch((er) => {})
  }
}
export const fetchProductForIntermediate = (id: any, setLoading: Function) => {
  setLoading(true)
  return async (dispatch: Dispatch) => {
    axios
      .get(`${API_URL}loads/stock/${id}`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setIntermediateItems(response.data))
        console.log(response.data)
      })
      .catch((er) => {})
      .finally(        dispatch(setLoading(false))
      )
  }
}
export const fetchProductForReceive = (id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .get(`${API_URL}loads/stock/${id}`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setReceiveItems(response.data))
        console.log(response.data)
      })
      .catch((er) => {})
  }
}

//addProduct
export const addProduct = (data = {}) => {
  return async (dispatch: Dispatch) => {
    axios
      .post(`${API_URL}loads/create/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(addItem(response?.data))
      })
      .catch((er) => {
        setError(er?.response?.data)
      })
  }
}

//fetchProductById
export const fetchProductById = (id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .get(`${API_URL}products/user/${id}/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
        console.log(response.data)
      })
      .catch((er) => {})
  }
}

import { useEffect, useState } from "react"
import { Routes, Route, useNavigate } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "./App.scss"

import MainLayout from "./layouts/MainLayout"
import HomePage from "./pages/homePage"
import Maps from "./pages/inways/index"
import ChatPage from "./pages/ChatPage"
import RoomPage from "./pages/RoomPage"
import StockPages from "./pages/StockPages"
import StaffPage from "./pages/StaffPage"
import TrucksMore from "./pages/trucksMore"
import AuthPage from "./pages/auth/AuthPage"
import RegisterPage from "./pages/auth/RegisterPage"
import Clients from "./pages/clients/index"
import Trucks from "./pages/trucks/index"
import { access_token } from "./services"
import NotFound from "./pages/NotFound/index"
import ClientsInfo from "./pages/clients/clientsInfo"
import Clienthistory from "./pages/clientHistory/index"
import Shipment from "./pages/shipment"
import NewsPage from "./pages/news/NewsPage"
import NewsMorePage from "./pages/news/NewsMorePage"
import AddNewsPage from "./pages/news/AddNewsPage"
import RecoverPswd from "./pages/auth/RecoverPswd"
import Profile from "./pages/profile/index"
import EditNewsPage from "./pages/news/EditNewsPage"
import StockMore from "./pages/StockMore/StockMore"
import AddGoodsPage from "./pages/AddGoodsPage/AddGoodsPage"
import ResetPswd2 from "./pages/auth/ResetPswd2"
import { requestNotificationPermission } from "./firebase/firbase"
import { useAppSelector } from "./redux/hooks"
import Loading from "./assets/img/loading.gif"

function App() {
  const navigate = useNavigate()
  const currentHref = window.location.pathname

  useEffect(() => {
    if (!access_token && currentHref !== "/auth/reset_password_second_step") {
      navigate("login")
    }
  }, [])
 
  useEffect(() => {
    requestNotificationPermission()
  }, [])

  const { loading } = useAppSelector((state) => state)

  return (
    <>
      <div className={!loading.loading ? "loading_none" : "loading"}>
        <div className="loading_content">
          <img
            style={{
              margin: "0 auto",
              display: "block",
              width: "130px",
              zIndex: "100000000000000",
            }}
            src={Loading}
          />
        </div>
      </div>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="" element={<HomePage />} />
          <Route path="/onways/:status" element={<Maps />} />
          <Route path="/stock" element={<StockPages />} />
          <Route path="/stock/:id/:type" element={<StockMore />} />
          <Route path="/stock/addgoodpage" element={<AddGoodsPage />} />
          <Route path="/stock/addgoodpage/:id" element={<AddGoodsPage />} />
          <Route path="/staff" element={<StaffPage />} />
          <Route path="/chat/:uuid/:status" element={<ChatPage />} />
          <Route path="/room/:uuid" element={<RoomPage />} />
          <Route path="/trucks" element={<Trucks />} />
          <Route path="/clientInfo" element={<ClientsInfo />} />
          <Route path="/trucks/edit/:id" element={<TrucksMore />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/clients/payment-history/:id" element={<Clienthistory />} />
          <Route path="/clients/payment-history/" element={<Clienthistory />} />
          <Route path="/clients/history/:id" element={<ClientsInfo />} />
          <Route path="/notifications" element={<Shipment />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news/:id" element={<NewsMorePage />} />
          <Route path="/news/add" element={<AddNewsPage />} />
          <Route path="/news/edit/:id" element={<EditNewsPage />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route path="login" element={<AuthPage />} />
        <Route
          path="/auth/reset_password_send_message"
          element={<RecoverPswd />}
        />
        <Route path="/auth/reset_password" element={<ResetPswd2 />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </>
  )
}

export default App

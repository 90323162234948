import { createSlice } from "@reduxjs/toolkit"
import { NewsState } from "../types"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { toast } from "react-toastify"

const initialState: any = {
  data: [],
  error: false,
}

const CategoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.error = false
      state.data = payload
    },
    addItem(state, { payload }) {
      state.data.push({ payload })
    },
    editNews: (state, { payload }) => {
      state.data = payload
    },
    deleteItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const { setItems, setError, addItem, editNews, deleteItem } =
  CategoriesSlice.actions
export default CategoriesSlice.reducer

//fetchNews
export const fetchCategories = () => {
  return async (dispatch: Dispatch) => {
    axios
      .get(`${API_URL}api/v1/news-categories/`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        console.log("ff")
      })
  }
}

export const fetchNewsById = (id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .get(`${API_URL}news/${id}`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
      })
  }
}

//addNews
export const addNews = (data = {}, setLoading: Function, navigate: any) => {
  return async (dispatch: Dispatch) => {
    setLoading(true)
    axios
      .post(`${API_URL}news/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(addItem(response.data))
        navigate("/news")
        toast.success("✅ Пост успешно добавлен", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
      .finally(() => setLoading(false))
  }
}

// edidBrand
export const EditNews = (
  data = {},
  id: any,
  setLoading: Function,
  navigate: any,
) => {
  return async (dispatch: Dispatch) => {
    setLoading(true)
    axios
      .patch(`${API_URL}news/${id}`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editNews(res.data))
        navigate(`/news/${id}`)
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => setLoading(false))
  }
}

// edidBrand
export const EditAllNews = (data = {}, id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .put(`${API_URL}news/${id}`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editNews(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
  }
}

//deleteNews
export const deleteNews = (id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .delete(`${API_URL}news/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(deleteItem(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
  }
}

import React, { FC, useEffect, useRef, useState } from "react"
import StockMoreClientCard from "./StockMoreClientCard"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import Select from "react-select"

import { fetchStock, fetchStocks } from "../../../redux/cargos/stock"
import { addItemsLast, fetchCargos } from "../../../redux/cargos/cargo"
import axios from "axios"
import { API_URL } from "../../../api/api_config"
import { setLoading } from "../../../redux/loading"
import { access_token } from "../../../services"
import { useParams } from "react-router-dom"

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    width: "160px",
    borderRadius: "10px",

    border: state.isFocused
      ? "1px solid rgba(157, 194, 81, 1)" // LC Green color on focus
      : "1px solid var(--lc-black-200, #CCC)",

    background: "var(--lc-white, #FFF)",
    padding: "0px 16px",
    boxShadow: state.isFocused ? "0 0 0 2px rgba(157, 194, 81, 0.3)" : "none", // LC Green color on focus
    "&:hover": {
      border: "1px solid rgba(157, 194, 81, 1)", // LC Green color on hover
    },

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? "rgba(157, 194, 81, 0.5)"
      : "transparent", // LC Green color on focus
    color: "var(--lc-black-800, #333)", // LC Black-800 color
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "rgba(157, 194, 81, 0.3)", // LC Green color for selected values
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: "var(--lc-black-800, #333)", // LC Black-800 color for selected value labels
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: "var(--lc-black-800, #333)", // LC Black-800 color for selected value remove icon
    ":hover": {
      backgroundColor: "rgba(157, 194, 81, 0.5)", // LC Green color on remove icon hover
      color: "#FFF", // White color on remove icon hover
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "var(--lc-black-800, #333)", // LC Black-800 color
    fontFamily: "Inter", // Inter font family
    fontSize: "14px", // 14px font size
    fontStyle: "normal", // normal font style
    fontWeight: 500, // font weight 500
    lineHeight: "130%", // 130% line height
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    paddingRight: "0",
    paddingLeft: "8px",
  }),
}

const index = () => {
  const { stocks, auth, cargo } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const [arrDayBefore, setArrDayBefore] = useState<any>()
  const [arrDayAfter, setArrDayAfter] = useState<any>()

  const percentage = ((stocks?.oneData?.volume - stocks?.oneData?.available_volume) / stocks?.oneData?.volume) * 100
  const [stockCategory, setStockCategory] = useState(1)


  const getCargosSenders = () => {
    dispatch(
      fetchCargos(
        {
          limit: 24,
          offset: currentPage,
          status: "in_stock",
          sender__stock: id,
          arriving_day_before: arrDayBefore,
          arriving_day_after: arrDayAfter,
        },
        (data: any) => {
          setStockCategory(1)
        },
      ),
    )
  }

  const getCargosReceivers = () => {
    dispatch(
      fetchCargos(
        {
          limit: 24,
          offset: currentPage,
          status: "delivered",
          receiver__stock: id,
          arriving_day_before: arrDayBefore,
          arriving_day_after: arrDayAfter,
        },
        (data: any) => {
          setStockCategory(2)
        },
      ),
    )
  }

  useEffect(() => {
    dispatch(
      fetchCargos(
        {
          limit: 24,
          offset: currentPage,
          status: "in_stock",
          sender__stock: id,
          arriving_day_before: arrDayBefore,
          arriving_day_after: arrDayAfter,
        },
        (data: any) => {
          setStockCategory(1)
        },
      ),
    )
  }, [arrDayAfter, arrDayBefore])

  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    if (
      target.scrollHeight - (target.scrollTop + target.clientHeight) < 1 &&
      cargo?.data?.results?.length < totalCount
    ) {
      dispatch(setLoading(true))
      axios
        .get(`${API_URL}api/v1/cargo/`, {
          params: {
            limit: 24,
            offset: currentPage + 1,
            status: "in_stock",
            sender__stock: id,
          },
          headers: {
            Authorization: `Token ${access_token} `,
          },
        })
        .then((response: any) => {
          dispatch(addItemsLast(response.data.results))
        })
        .catch((er) => {
          dispatch(setLoading(false))
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }


  return (
    <div className="allClient">
      <header>
        <div className="allClient_flex">
          <div>От:</div>
          <input
            onChange={(e: any) => setArrDayAfter(e?.target?.value)}
            className="allClient_date"
            type="date"
          />
        </div>

        <div>
          <h1>Все клиенты</h1>
        </div>
        <div className="allClient_flex">
          <div>До:</div>
          <input
            onChange={(e: any) => setArrDayBefore(e?.target?.value)}
            className="allClient_date"
            type="date"
          />
        </div>
      </header>
      <main style={{ height: "100%" }}>
        <br />
        <div className="good_indicator" style={{ padding: "0px", gap: "22px" }}>
          <div className="good_indicator_titles">
            <div className="good_indicator_title">Общий вес:</div>
            <div className="good_indicator_mini_title">
              {stocks?.oneData?.volume}кг.
            </div>
          </div>
          <div className="good_indicator_items">
            <div className="good_indicator_grey good_indicator_position"></div>
            <div
              style={{
                width: `${
                  percentage ? (percentage > 100 ? 100 : percentage) : "0"
                }%`,
                background: `${(stocks?.oneData?.volume - stocks?.oneData?.available_volume) > stocks?.oneData?.volume ? "red" : ""}`,
              }}
              className="good_indicator_green good_indicator_position"
            >
              <div className="good_indicator_green_title">
                <div
                  className="good_indicator_green_number good_indicator_position_green"
                  style={
                    percentage < 8
                      ? {
                          right: `-30px`,
                          position: "unset",
                          marginTop: "-23px",
                          color: `${(stocks?.oneData?.volume - stocks?.oneData?.available_volume) > stocks?.oneData?.volume ? "red" : ""}`,
                        }
                      : percentage < 92
                      ? {
                          right: `30px`,
                          position: "unset",
                          marginTop: "-23px",
                          display: "flex",
                          justifyContent: "end",
                        }
                      : {
                          right: `-20px`,
                          color: `${(stocks?.oneData?.volume - stocks?.oneData?.available_volume) > stocks?.oneData?.volume ? "red" : ""}`,
                        }
                  }
                >
                  {(stocks?.oneData?.volume - stocks?.oneData?.available_volume)}кг.
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <section
          style={{ height: "calc(100% - 135px)" }}
          className="allClient-list"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1
              className="allClient-Overallvolume-title"
              style={{ paddingBottom: "8px" }}
            >
              Клиенты
            </h1>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              
              <button
                style={ stockCategory === 1 ?{
                  padding: "9px 30px",
                  background: "#83C251",
                  color: "white",
                  border: "none",
                }: {
                  padding: "9px 30px",
                  background: "white",
                  color: "#83C251",
                  border: '1px solid #83C251'
                }}
                onClick={getCargosSenders}
              >
                Отправляющие
              </button>
              <button
                style={ stockCategory === 2 ?{
                  padding: "9px 30px",
                  background: "#83C251",
                  color: "white",
                  border: "none",
                }: {
                  padding: "9px 30px",
                  background: "white",
                  color: "#83C251",
                  border: '1px solid #83C251'
                }}
                onClick={getCargosReceivers}
              >
                Прибывшие
              </button>
            </div>
          </div>

          <div
            className="allClient-Overallvolume-list"
            ref={scrollableDivRef}
            onScroll={scrollHandler}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {cargo?.data?.results?.map((item: any) => (
                <StockMoreClientCard
                  dispatcher={auth?.data?.role === "dispatcher" ? true : false}
                  key={item?.id}
                  item={item}
                />
              ))}

              {cargo?.data?.results?.length === 0 && (
                <div style={{ textAlign: "center" }}>Пусто!</div>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default index

import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"

import NoImage from "../../assets/img/no-profile.jpeg"
import HeadOfTruck from "../../assets/svg/theHeadOfTruck.svg"
import BigTruck from "../../assets/svg/theLongestTruck.svg"
import MediumTruck from "../../assets/svg/theMediumTruck.svg"
import SmallTruck from "../../assets/svg/theSmallestTruck.svg"

interface Props {
  item: any
  setIsHovered?: any
  onMouseOver?: (id: number) => void
  id: string
}

const ShowNewUser = ({ item, id, onMouseOver }: Props) => {
  const { auth } = useAppSelector((state) => state)
  const dispatch = useAppDispatch()

  const [show, setShow] = React.useState<any>()

  return (
    <div
      style={{ display: "flex", alignItems: "center", gap: "16px" }}
      id={id}
      onMouseOver={() => (onMouseOver ? onMouseOver(item?.id) : undefined)}
    >
      <div
        className="AddedNewUser"
        style={{ position: "relative", width: "50%" }}
      >
        {/* <svg
          style={{
            position: "absolute",
            top: "0",
            right: "0px",
            cursor: "pointer",
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8.96963 4.96967C9.26253 4.67678 9.7374 4.67678 10.0303 4.96967C10.3196 5.25897 10.3231 5.72582 10.0409 6.01947L6.04873 11.0097C6.04297 11.0169 6.03682 11.0238 6.03029 11.0303C5.7374 11.3232 5.26253 11.3232 4.96963 11.0303L2.32319 8.38388C2.03029 8.09099 2.03029 7.61612 2.32319 7.32322C2.61608 7.03033 3.09095 7.03033 3.38385 7.32322L5.47737 9.41674L8.94974 4.9921C8.95592 4.98424 8.96256 4.97674 8.96963 4.96967Z"
            fill="black"
          />
          <path
            d="M8.04921 10.1099L8.96963 11.0303C9.26253 11.3232 9.7374 11.3232 10.0303 11.0303C10.0368 11.0238 10.043 11.0169 10.0487 11.0097L14.0409 6.01947C14.3231 5.72582 14.3196 5.25897 14.0303 4.96967C13.7374 4.67678 13.2625 4.67678 12.9696 4.96967C12.9626 4.97674 12.9559 4.98424 12.9497 4.9921L9.47737 9.41674L8.99202 8.9314L8.04921 10.1099Z"
            fill="black"
          />
        </svg> */}
        <div
          className="AddedNewUser_avatar"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={
              item?.inner_payload?.avatar
                ? item?.inner_payload?.avatar
                : NoImage
            }
            alt=""
          />
        </div>
        <div className="AddedNewUser_form">
          <h1>Добавлен новый пользователь</h1>
          <div className="AddedNewUser_form-label">
            <p>Роль:</p>
            <input value={item?.inner_payload?.role} type="text" disabled />
          </div>
          <div className="AddedNewUser_form-label">
            <p>ФИО:</p>
            <input
              value={
                item?.inner_payload?.last_name +
                " " +
                item?.inner_payload?.first_name
              }
              type="text"
              disabled
            />
          </div>
        </div>
      </div>
      {item?.inner_payload?.role === "driver" &&
      item.inner_payload?.avatar !== null ? (
        <>
          <div className="shipmentImage">
            <div>Фото:</div>
            <img src={item.inner_payload?.avatar} alt="" />
          </div>
        </>
      ) : null}

      {item?.inner_payload?.role === "driver" ? (
        // turn uncomment the div before using !!!
        // <div className="truckBorders">
        <img
          src={
            item?.inner_payload?.driver?.type === "head"
              ? HeadOfTruck
              : item?.inner_payload?.item?.type === "truck"
              ? SmallTruck
              : item?.inner_payload?.item?.type === "trailer"
              ? BigTruck
              : item?.inner_payload?.item?.type === "semitrailer"
              ? MediumTruck
              : ""
          }
          alt=""
        />
      ) : // </div>
      undefined}

      {/* <div
        onClick={() => {
          setShow(!show)
          setIsHovered(false)
        }}
        className={show ? "PaymentConfirmation_rightBar-buttons" : "none"}
      >
        <div className="Green-bth2">Потвердить</div>
      </div> */}
    </div>
  )
}

export default ShowNewUser

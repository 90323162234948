import { useEffect, useState } from "react"

import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Select from "react-select"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"

import AddCatagoryForm from "../../Forms/Goods/AddCategoryForm"
import {
  CalcData,
  addCargo,
  calcPrice,
  deleteCategory,
  setCategories,
  setError,
} from "../../../redux/cargos/cargo"
import {
  addItemsLastSelect,
  editUserById,
  fetchAllUsers,
  fetchUserById,
} from "../../../redux/users"
import { fetchStocks } from "../../../redux/cargos/stock"
import {
  fetchCities,
  fetchCitiesFrom,
  fetchCitiesTo,
} from "../../../redux/cargos/cities"
import { fetchCountries } from "../../../redux/cargos/countries"
import axios from "axios"
import { API_URL } from "../../../api/api_config"
import { access_token } from "../../../services"
import { setLoading } from "../../../redux/loading"
import CategoryIcon from "../../../assets/svg/categoryPlusIcon.svg"
import DropDownArrow from "../../../assets/svg/downarrow.svg"
import CategoryRemoveIcon from "../../../assets/svg/minusCategoryIcon.svg"
import EditCatagoryForm from "../../Forms/GoodsEdit/EditCategoryForm"
import {
  fetchCargoReciever,
  fetchCargoRecievers,
} from "../../../redux/cargos/cargo-recievers"
import { setCategory } from "../../../redux/cargos/cargo"

type Props = {
  name: string
  arriving_day: string
  phone: string
  country: object
  city: object
  stock: object
  services: any
  distance_stock: string
  coefficient_delivery: number
  consumption: string
  number_stock: string
  sender: any
  receiver: any
  receiver_user: any
  receiver_name: string
  receiver_phone: string
  receiver_country: object
  receiver_city: object
  receiver_stock: object
  comment: any
  total_weight: number
  total_coefficient: string
  total_price: string
  status?: string
  user: any
}

const insuranseOption = [
  { id: 1, label: "Есть", value: "Есть" },
  { id: 2, label: "Нет", value: "Нет" },
]

export const AddCargo = ({ setModal, modal }: any) => {
  const { users, countries, cities, stocks, cargo, cargoRecieve } =
    useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<Props>()

  const [menu1, setMenu1] = useState(true)
  const [menu2, setMenu2] = useState(false)
  const [menu3, setMenu3] = useState(false)
  const [menu4, setMenu4] = useState(true)
  const [openAddCategory, setOpenCategory] = useState(false)
  const [editCategory, setEditCategory] = useState(false)
  const [updatingCatId, setUpdatingCatId] = useState<any>()
  const [coefficientDelivery, setCoefficientDelivery] = useState<any>()
  const [consumption, setConsumption] = useState<any>()
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    dispatch(fetchStocks())
    dispatch(editUserById({}))
    dispatch(setCategories([]))


    dispatch(fetchCities(false))
    dispatch(fetchCountries(false))
    dispatch(
      fetchAllUsers(
        { roles: "client", is_active: true, is_confirmed: true, use_pagination: false },
        () => { },
        true,
      ),
    )
    dispatch(fetchCargoRecievers({}))
    if (modal === !true) {
      dispatch(calcPrice(null))
    }

  }, [])

  console.log(users?.dataSelect);
  

  useEffect(() => {
    dispatch(editUserById({}))
    dispatch(setCategories([]))
  }, [modal])

  useEffect(() => {
    coefficientDelivery &&
      consumption &&
      dispatch(
        CalcData({
          products: cargo?.category?.map((item: any) => ({
            discount: item?.discount,
            weight: item.weight,
            coefficient: item?.coefficient,
          })),

          coefficient_delivery: +coefficientDelivery,
          consumption: +consumption,
        }),
      )
  }, [coefficientDelivery, consumption, cargo?.category])

  const setFunction = () => {
    setModal(false)
    dispatch(setError(null))
    dispatch(setCategory([]))
    dispatch(calcPrice({}))
    dispatch(editUserById({}))
    dispatch(setCategories([]))
  }
console.log(consumption);

  const onSubmit: SubmitHandler<Props> = (data: any) => {

    dispatch(
      addCargo(
        {
          sender: {
            arriving_day: data?.arriving_day,
            name: data?.name ? data?.name : users?.dataId?.first_name,
            phone: data?.phone ? data?.phone : users?.dataId?.phone,
            country: country?.id
              ? country?.id
              : users?.dataId?.client?.city?.country,
            city: data?.city?.id
              ? data?.city?.id
              : users?.dataId?.client?.city?.id,
            stock: data?.stock?.id,
          },
          receiver: {
            name: data?.receiver_name
              ? data?.receiver_name
              : cargoRecieve?.oneData?.name,
            phone: data?.receiver_phone,
            country: receiver_country?.id
              ? receiver_country?.id
              : cargoRecieve?.oneData?.city?.country,
            city: data?.receiver_city?.id
              ? data?.receiver_city?.id
              : cargoRecieve?.oneData?.city?.id,
            stock: data?.receiver_stock?.id
              ? data?.receiver_stock?.id
              : cargoRecieve?.oneData?.stock?.id,
          },
          products: cargo?.category?.map((item: any) => ({
            ...item,
            brand: item.brand?.id,
            category: item?.category?.id,
          })),
          comment: data?.comment,
          total_weight: cargo?.calcData?.total_weight,
          total_coefficient: Number(cargo?.calcData?.total_coefficient?.toFixed(2)),
          total_price: Math?.round(cargo?.calcData?.total_price),
          coefficient_delivery: cargo?.calcData?.coefficient_delivery,
          consumption: consumption,
          status: "in_stock",
          user: cargoRecieve?.oneData?.id,
        },
        setFunction,
      ),
    )

    if (cargo.error !== false || cargo.error !== undefined) {
      setMenu3(true)
      setMenu4(true)
    }
  }

  const [country, setCountry] = useState<any>(null)
  const [receiver_country, setReceiverCountry] = useState<any>(null)
  const getCitiesByCountryFrom = (e: any) => {
    setCountry(e)
    dispatch(fetchCitiesFrom(e.id))
  }

  const getCitiesByCountryTo = (e: any) => {
    setReceiverCountry(e)
    dispatch(fetchCitiesTo(e.id))
  }

  const getSenderInfo = (e: any) => {
    dispatch(fetchUserById(e?.id))
    setMenu2(true)
  }

  const getReceiverInfo = (e: any) => {
    dispatch(
      fetchCargoReciever(e?.id, (data: any) => {
        console.log(data)
        setValue("receiver_name", data.first_name)
        setValue("receiver_phone", data.phone)
      }),
    )
    setMenu3(true)
  }

  const handleDeleteCategory = (id: any) => {
    dispatch(deleteCategory(id))
  }

  const scrollHandler = (e: any) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/auth/users/`, {
        params: {
          limit: 24,
          offset: currentPage + 1,
          roles: "client",
          is_active: true,
        },
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        setCurrentPage(currentPage + 1)
        dispatch(addItemsLastSelect(response.data.results))
      })
      .catch((er) => { })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }


  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px",
      cursor: "pointer",
      background: "#fff",
      marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent", // LC Green color on focus
      color: "var(--lc-black-800, #333)",
    }),
  }

  return (
    <>
      <div className="cargosScroll">
        <div className="catalog_category_block">
          <div>Груз</div>
        </div>

        <div className={menu1 ? "" : "menunone"}>
          <div
            className="form_inputs-input"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div>
              <p
                style={{
                  color: `#808080`,
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  width: "435px",
                  lineHeight: "130%",
                }}
              >
                Категории: <u className="redStar">*</u>
              </p>
            </div>
            <div
              className="addedItemOption"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
              onClick={() => setOpenCategory(true)}
            >
              Добавить категорию
              <img src={CategoryIcon} alt="" />
            </div>
            <div
              key={null}
              className={
                cargo.category?.length % 2 === 0
                  ? "categoryWidth1"
                  : "categoryWidth"
              }
            >
              {cargo.category?.map((item: any) => (
                <div
                  key={item.id}
                  className="addedItemCategory"
                  onClick={() => {
                    setEditCategory(!editCategory)
                    setUpdatingCatId(item.id)
                  }}
                >
                  {item.name}
                  <img
                    onClick={(e) => {
                      e.stopPropagation()
                      handleDeleteCategory(item.id)
                    }}
                    src={CategoryRemoveIcon}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          {openAddCategory && <AddCatagoryForm setOpen={setOpenCategory} />}
          {editCategory && (
            <EditCatagoryForm
              updatingCatId={updatingCatId}
              setOpen={setEditCategory}
            />
          )}
        </div>
        <div>
          <div
            className="catalog_category_block"
            onClick={() => setMenu2(!menu2)}
          >
            <div>Оправитель</div>
            <img
              className={!menu2 ? "" : "reverse"}
              src={DropDownArrow}
              alt=""
            />
          </div>
          <div className="catalog_item">
            <div>
              Выбрать отправителя:
            </div>
            <div
              style={{
                width: "57.4%",
              }}
            >
              <Controller
                control={control}
                {...register("user", { required: false })}
                render={({ field }) => (
                  <Select
                    styles={customStyless}
                    id="favouriteAvenger"
                    {...field}
                    placeholder="Пусто"
                    onMenuScrollToBottom={(e: any) => scrollHandler(e)}
                    options={users?.dataSelect?.map((item: any) => ({
                      value: item.first_name + " " + item.last_name,
                      label: item.first_name + " " + item.last_name,
                      id: item.id,
                    }))}
                    onChange={(e: any) => getSenderInfo(e)}
                  />
                )}
              />
              <div className="error">{errors && cargo.error?.user}</div>
            </div>
          </div>
          <div className={menu2 ? "catalog_items" : "menunone"}>
            <div className="catalog_item">
              <div>
                Номер телефона отправителя:
              </div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("phone", { required: false })}
                  defaultValue={users?.dataId?.phone}
                  type="text"
                />

                <div className="error">
                  {errors && cargo.error?.sender?.phone}
                </div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                Имя отправителя:
              </div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("name", { required: false })}
                  defaultValue={users?.dataId?.first_name}
                  type="text"
                />

                <div className="error">
                  {errors && cargo.error?.sender?.name}
                </div>
              </div>
            </div>

            <div className="catalog_item">
              <div>
                Страна отправителя: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <Controller
                  control={control}
                  {...register("country", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={
                        users?.dataId?.client?.city?.country_name || "Пусто"
                      }
                      onChange={(e: any) => getCitiesByCountryFrom(e)}
                      options={countries?.data?.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">
                  {errors && cargo.error?.sender?.country}
                </div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                Город отправителя: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <Controller
                  control={control}
                  {...register("city", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={users?.dataId?.client?.city?.name || "Пусто"}
                      isDisabled={
                        cities?.data_from?.length !== 0 ? false : true
                      }
                      options={cities?.data_from?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />

                <div className="error">
                  {errors && cargo.error?.sender?.city}
                </div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                Отправляемый склад: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <Controller
                  control={control}
                  {...register("stock", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder="Пока пуcто"
                      options={stocks?.data?.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />

                <div className="error">
                  {errors && cargo.error?.sender?.stock}
                </div>
              </div>
            </div>

            <div className="catalog_item">
              <div>
                День прибытия: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("arriving_day", { required: false })}
                  type="date"
                />

                <div className="error">
                  {errors && cargo.error?.sender?.arriving_day}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div>
          <div
            className="catalog_category_block"
            onClick={() => setMenu3(!menu3)}
          >
            <div>Получатель</div>
            <img
              className={!menu3 ? "" : "reverse"}
              src={DropDownArrow}
              alt=""
            />
          </div>

          <div className="catalog_item">
            <div>
              Выбрать получателя:
              <u className="redStar">*</u>
            </div>
            <div
              style={{
                width: "57.4%",
              }}
            >
              <Controller
                control={control}
                {...register("receiver_user", { required: false })}
                render={({ field }) => (
                  <Select
                    styles={customStyless}
                    id="favouriteAvenger"
                    {...field}
                    placeholder="Пусто"
                    onMenuScrollToBottom={(e: any) => scrollHandler(e)}
                    options={users?.dataSelect?.map((item: any) => ({
                      value: item.first_name + " " + item.last_name,
                      label: item.first_name + " " + item.last_name,
                      id: item.id,
                    }))}
                    onChange={(e: any) => getReceiverInfo(e)}
                  />
                )}
              />
              <div className="error">{errors && cargo.error?.user}</div>
            </div>
          </div>

          <div className={menu3 ? "catalog_items" : "menunone"}>
            <div className="catalog_item">
              <div>
                ФИО: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("receiver_name", { required: false })}
                  type="text"
                />

                <div className="error">
                  {errors && cargo.error?.receiver?.name}
                </div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                Номер телефона: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("receiver_phone", { required: false })}
                  type="text"
                />

                <div className="error">
                  {errors && cargo.error?.receiver?.phone}
                </div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                Страна получения: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <Controller
                  control={control}
                  {...register("receiver_country", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={
                        cargoRecieve?.oneData?.city?.country_name
                          ? cargoRecieve?.oneData?.city?.country_name
                          : "Пусто"
                      }
                      onChange={(e) => getCitiesByCountryTo(e)}
                      defaultValue={cargoRecieve?.oneData?.city?.country}
                      options={countries?.data?.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">
                  {errors && cargo.error?.receiver?.country}
                </div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                Город получения: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <Controller
                  control={control}
                  {...register("receiver_city", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={
                        cargoRecieve?.oneData?.city?.name
                          ? cargoRecieve?.oneData?.city?.name
                          : "Пусто"
                      }
                      defaultValue={cargoRecieve?.oneData?.city?.id}
                      isDisabled={cities?.data_to?.length !== 0 ? false : true}
                      options={cities?.data_to?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />

                <div className="error">
                  {errors && cargo.error?.receiver?.city}
                </div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                Выбор склада получения: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <Controller
                  control={control}
                  {...register("receiver_stock", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={
                        cargoRecieve?.oneData?.stock?.name
                          ? cargoRecieve?.oneData?.stock?.name
                          : "Пусто"
                      }
                      defaultValue={cargoRecieve?.oneData?.stock?.id}
                      options={stocks?.data?.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />

                <div className="error">
                  {errors && cargo.error?.receiver?.stock}
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div>
          <div
            className="catalog_category_block"
            onClick={() => setMenu4(!menu4)}
          >
            <div>Другие услуги</div>
            <img
              className={!menu4 ? "" : "reverse"}
              src={DropDownArrow}
              alt=""
            />
          </div>

          <div className={menu4 ? "catalog_items" : "menunone"}>
            {/* <div className="catalog_item">
              <div>
                Доставка до склада: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "56.8%",
                }}
              >
                <input
                  placeholder="Введите данные"
                  type="number"
                  style={{ maxWidth: "322px" }}
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => setDistanceStock(e.target.value)}
                />

                <div className="error">
                  {cargo.error?.services?.map(
                    (item: any) => item.distance_stock,
                  )}
                </div>
              </div>
            </div> */}
            <div className="catalog_item">
              <div>
                Дорожные расходы: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "56.8%",
                }}
              >
                <input
                  placeholder="Введите данные"
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                  style={{ maxWidth: "322px" }}
                  onChange={(e) => setCoefficientDelivery(e.target.value)}
                />

                <div className="error">{cargo.error?.total_price}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                Доп росходы: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "56.8%",
                }}
              >
                <input
                  placeholder="Введите данные"
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                  style={{ maxWidth: "322px" }}
                  onChange={(e) => setConsumption(e.target.value)}
                />

                <div className="error">{cargo.error?.total_weight}</div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="catalog_item">
          <div>Комментарий:</div>
        </div>
        <div className="catalog_item">
          <div
            style={{
              width: "calc(100% - 26px)",
            }}
          >
            <textarea
              style={{
                padding: "8px 12px",
                width: "100%",
                marginTop: "12px",
                outline: "none",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
              {...register("comment")}
              rows={1}
              cols={73.5}
              placeholder="Введите еоментарий"
            />
          </div>
        </div>
      </div>
      <div className="greenBackground">
        <div className="catalog_item">
          <div>Общий вес:</div>
          <div
            style={{
              width: "59.7%",
            }}
          >
            <input
              placeholder="Пусто"
              // {...register("total_weight", { required: false })}
              type="text"
              style={{ maxWidth: "322px", border: "none" }}
              defaultValue={cargo?.calcData?.total_weight}
              disabled
            />
            <div className="error">
              {errors && users?.error?.lifting_capacity}
            </div>
          </div>
        </div>
        <br />
        <div className="catalog_item">
          <div>Дорожные расходы:</div>
          <div
            style={{
              width: "59.7%",
            }}
          >
            <input
              placeholder="Пусто"
              type="text"
              style={{ maxWidth: "322px", border: "none" }}
              defaultValue={cargo?.calcData?.coefficient_delivery}
              disabled
            />
            <div className="error">
              {/* {errors && users?.error?.lifting_capacity} */}
            </div>
          </div>
        </div>
        <br />

        <div className="catalog_item">
          <div>Общий тариф:</div>
          <div
            style={{
              width: "59.7%",
            }}
          >
            <input
              placeholder="Пусто"
              type="number"
              onWheel={(e) => e.currentTarget.blur()}
              value={cargo?.calcData?.total_coefficient}
              disabled
              style={{ maxWidth: "322px", border: "none" }}
            />
            <div className="error">
              {/* {errors && users?.error?.lifting_capacity} */}
            </div>
          </div>
        </div>
        <br />
        <div
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: "#000",
            textAlign: "center",
          }}
        >
          Общая стоимость: ${cargo.calcData?.total_price?.toFixed(2) || 0}
        </div>
        <br />
        <div
          className="form_button"
          style={{
            background: "rgba(171, 211, 93, 0.2)",
          }}
        >
          <button
            onClick={() => {
              setModal(false)
              dispatch(setError(false))
              dispatch(calcPrice(null))
            }}
            className="TruckBtn2"
            style={{ padding: "9px 105px" }}
          >
            Отмена
          </button>

          <button
            onClick={handleSubmit(onSubmit)}
            className="TruckBtn"
            style={{ padding: "9px 105px" }}
          >
            Сохранить
          </button>
        </div>
      </div>
    </>
  )
}

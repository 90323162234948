import React from "react"
import CloseIcon from "../../assets/svg/closeIcon.svg"

interface Props {
  exit: boolean
  setExit: any
}

const ExitModal = ({ exit, setExit }: Props) => {
  const logOut = () => {
    localStorage.clear()
    window.location.replace("/login")
  }
  return (
    <div className={exit ? "exitModal" : "none"}>
      <div className="exitModal__content" onClick={(e) => e.stopPropagation()}>
        <img src={CloseIcon} alt="" onClick={() => setExit(!exit)} />
        <div className="exitModal__content_title">
          Вы действительно хотите выйти?
        </div>
        <div className="exitModal__content_btns">
          <button className="button" onClick={() => setExit(!exit)}>
            Отмена
          </button>
          <button className="button red fill" onClick={logOut}>
            Выйти
          </button>
        </div>
      </div>
    </div>
  )
}

export default ExitModal

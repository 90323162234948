import React, { useState } from "react"
import { useForm, SubmitHandler, Controller } from "react-hook-form"

import SelectInput from "../Selekted/New-React-selekt"
import AboutSenderForm from "./AboutSenderForm"
import AboutGeterForm from "./AbutGetterForm"
import AbuotAnoterGoods from "./AbuotAnoterGoods"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { addProduct } from "../../../redux/products/productSlice"
import { Product } from "./AddCategoryForm"
interface AddGoodsFormProps {
  setOnGoodsModal: Function
  setOnModal: Function
}
const senderOptions = [
  { id: 1, value: "Иван Иванов Иванов", label: "Иван Иванов Иванов" },
  { id: 2, value: "Umar Umarov Umarovich", label: "Umar Umarov Umarovich" },
  { id: 3, value: "Beka Bekov Bekeevich", label: "Beka Bekov Bekeevich" },
]

const RecieverOptions = [
  { id: 1, value: "Иван Иванов Иванов", label: "Иван Иванов Иванов" },
  { id: 2, value: "Umar Umarov Umarovich", label: "Umar Umarov Umarovich" },
  { id: 3, value: "Beka Bekov Bekeevich", label: "Beka Bekov Bekeevich" },
]
interface HeadProps {
  title: string
  state: boolean
  setState: Function
  state2: boolean
}
interface CreateNewClient {
  setOnModal: Function
}
type Props = {
  load_name: string
  sender_id: number
  receiver_id: number
  delivery_service: string
  delivery_coefficient_additional: any
  additional_expenses: string
  comment: string
  total_weight: string
  delivery_coefficient: string
  general_coefficient: string
  receive_country: string
  receive_city: string
  receive_stock_id: number
  sender_country: string
  sender_city: string
  sender_stock_id: number
  total_sum: any
  products: Product
}

const Form_head: React.FC<HeadProps> = ({ title, setState, state, state2 }) => {
  return (
    <div className="form_midle">
      <h2>{title}</h2>
      <div className={state2 === false ? "" : "none"}>
        <h2
          className={
            state === true ? "form_midle-iconON" : "form_midle-iconOff"
          }
          onClick={() => setState(!state)}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="bootstrap/Chevrons/chevron-up">
              <path
                id="Path (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
                fill="#1A1A1A"
              />
            </g>
          </svg>
        </h2>
      </div>
    </div>
  )
}

const AddGoodsForm: React.FC<AddGoodsFormProps> = ({
  setOnGoodsModal,
  setOnModal,
}) => {
  const { produckt } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  const [create, setCreate] = useState(true)
  const [choosedCategories, setChoosedCategories] = useState<any>([])
  const [onSender, setOnSender] = useState(false)
  const [onGeter, setOnGeter] = useState(false)
  const [onAnother, setOnAnother] = useState(false)
  const [onGoods, setONGoods] = useState(true)
  const [onPop, setOnPop] = useState(false)
  const [sender, setSender] = useState<any>([])
  const [reciever, setReciever] = useState<any>([])

  const [senderCity, setSenderCity] = useState<any>([])
  const [senderStock, setSenderStock] = useState<any>([])
  const [sendingDay, setSendingDay] = useState<any>([])
  const [senderCountry, setSenderCountry] = useState<any>([])
  const [recievingCity, setRecievingCity] = useState<any>([])
  const [recievingStock, setRecievingStock] = useState<any>([])
  const [recievingDiscount, setRecievingDiscount] = useState<any>([])

  const combineOptionIds = (options: any): string => {
    return options.map((option: any) => option.id).join(",")
  }
  const combineOptionValues = (options: any): string => {
    return options.map((option: any) => option.id).join(",")
  }

  const categoryData = produckt?.category

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Props>()
  const onSubmit: SubmitHandler<Props> = (data) => {
    dispatch(
      addProduct({
        load_name: data.load_name,
        sender_id: combineOptionIds(sender),
        receiver_id: combineOptionIds(reciever),
        delivery_service: data.delivery_service,
        delivery_coefficient_additional: data.delivery_coefficient_additional,
        comment: "",
        total_weight: data.total_weight,
        delivery_coefficient: data.delivery_coefficient,
        general_coefficient: data.general_coefficient,
        sender_country: combineOptionValues(senderCountry),
        sender_city: combineOptionValues(senderCity),
        sender_stock_id: combineOptionIds(senderStock),
        receive_country: combineOptionValues(recievingDiscount),
        receive_city: combineOptionValues(recievingCity),
        receive_stock_id: combineOptionIds(recievingStock),
        total_sum: data.total_sum,
        additional_expenses: 200,
        products: categoryData,
      }),
    )

    setOnGoodsModal(false)
  }

  const handleSelectChange = (selectedOption: any) => {
    const find = choosedCategories.find(
      (item: any) => item?.id == selectedOption?.id,
    )

    if (!find) {
      setChoosedCategories([...choosedCategories, selectedOption])
    }
  }

  const OpenCreateClientPOP = () => {
    setOnGoodsModal(false)
    setTimeout(() => {
      setOnModal(true)
    }, 2000)
  }

  let exampleError
  return (
    <div className="form" onClick={(e) => e.stopPropagation()}>
      <div className="form_head">
        <h1>Добавить груз</h1>
        <svg
          onClick={() => setOnGoodsModal(false)}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Font Awesome Free/Regular/window-close">
            <path
              id="Vector"
              d="M20.125 3.25H3.875C2.83984 3.25 2 4.08984 2 5.125V18.875C2 19.9102 2.83984 20.75 3.875 20.75H20.125C21.1602 20.75 22 19.9102 22 18.875V5.125C22 4.08984 21.1602 3.25 20.125 3.25ZM20.125 18.6406C20.125 18.7695 20.0195 18.875 19.8906 18.875H4.10938C3.98047 18.875 3.875 18.7695 3.875 18.6406V5.35938C3.875 5.23047 3.98047 5.125 4.10938 5.125H19.8906C20.0195 5.125 20.125 5.23047 20.125 5.35938V18.6406ZM15.9258 9.60156L13.5273 12L15.9258 14.3984C16.1055 14.5781 16.1055 14.8711 15.9258 15.0547L15.0547 15.9258C14.875 16.1055 14.582 16.1055 14.3984 15.9258L12 13.5273L9.60156 15.9258C9.42188 16.1055 9.12891 16.1055 8.94531 15.9258L8.07422 15.0547C7.89453 14.875 7.89453 14.582 8.07422 14.3984L10.4727 12L8.07422 9.60156C7.89453 9.42188 7.89453 9.12891 8.07422 8.94531L8.94531 8.07422C9.125 7.89453 9.41797 7.89453 9.60156 8.07422L12 10.4727L14.3984 8.07422C14.5781 7.89453 14.8711 7.89453 15.0547 8.07422L15.9258 8.94531C16.1094 9.125 16.1094 9.41797 15.9258 9.60156V9.60156Z"
              fill="black"
            />
          </g>
        </svg>
      </div>
      <br />

      <div className="form_scroll">
        <Form_head
          title="Груз"
          setState={setONGoods}
          state={onGoods}
          state2={false}
        />
        <br />
        <div className={onGoods === false ? "form_popOff" : "form_popON2"}>
          <div className="form_inputs-input">
            <div>
              <p>Номер доставки:</p>
            </div>
            <div>
              <input
                className="form_inputs-input-text"
                type="text"
                {...register("load_name", { required: true })}
              />
            </div>
          </div>
          <AbuotAnoterGoods />
        </div>
        <br />
        {/* ============================================= */}
        <Form_head
          title="Отправитель"
          setState={setOnSender}
          state={onSender}
          state2={false}
        />
        <br />
        <div className={create === true ? "fuck" : "none"}>
          <div>
            <p>Выбрать отправителя:</p>
          </div>
          <div>
            <SelectInput
              setState={setSender}
              state={sender}
              placeholder="отправитель"
              error={produckt?.error?.datail}
              options={senderOptions}
            />
          </div>

          <div>
            <button
              style={{
                zIndex: "222",
              }}
              className="AddDriverBtn"
              onClick={() => {
                setOnGoodsModal(false)

                setTimeout(() => {
                  setOnModal(true)
                }, 100)
              }}
            >
              создать_новго
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M14.3052 2.8147L3.19412 2.8147C2.58069 2.8147 2.08301 3.31238 2.08301 3.92581L2.08301 12.074C2.08301 12.6874 2.58069 13.1851 3.19412 13.1851L14.3052 13.1851C14.9187 13.1851 15.4163 12.6874 15.4163 12.074L15.4163 3.92581C15.4163 3.31238 14.9187 2.8147 14.3052 2.8147ZM14.3052 12.074L3.19412 12.074L3.19412 3.92581L14.3052 3.92581L14.3052 12.074ZM6.89782 7.99988C7.71495 7.99988 8.3793 7.33553 8.3793 6.5184C8.3793 5.70127 7.71495 5.03692 6.89782 5.03692C6.08069 5.03692 5.41634 5.70127 5.41634 6.5184C5.41634 7.33553 6.08069 7.99988 6.89782 7.99988ZM4.82375 10.9628H8.9719C9.25893 10.9628 9.49042 10.7638 9.49042 10.5184V10.074C9.49042 9.33785 8.79366 8.74062 7.93486 8.74062C7.68486 8.74062 7.50199 8.92581 6.89782 8.92581C6.27514 8.92581 6.12467 8.74062 5.86079 8.74062C5.00199 8.74062 4.30523 9.33785 4.30523 10.074V10.5184C4.30523 10.7638 4.53671 10.9628 4.82375 10.9628ZM10.4163 9.48136L13.0089 9.48136C13.1108 9.48136 13.1941 9.39803 13.1941 9.29618V8.92581C13.1941 8.82396 13.1108 8.74062 13.0089 8.74062L10.4163 8.74062C10.3145 8.74062 10.2312 8.82396 10.2312 8.92581V9.29618C10.2312 9.39803 10.3145 9.48136 10.4163 9.48136ZM10.4163 7.99988L13.0089 7.99988C13.1108 7.99988 13.1941 7.91655 13.1941 7.8147V7.44433C13.1941 7.34248 13.1108 7.25914 13.0089 7.25914L10.4163 7.25914C10.3145 7.25914 10.2312 7.34248 10.2312 7.44433V7.8147C10.2312 7.91655 10.3145 7.99988 10.4163 7.99988ZM10.4163 6.5184L13.0089 6.5184C13.1108 6.5184 13.1941 6.43507 13.1941 6.33322V5.96285C13.1941 5.86099 13.1108 5.77766 13.0089 5.77766L10.4163 5.77766C10.3145 5.77766 10.2312 5.86099 10.2312 5.96285V6.33322C10.2312 6.43507 10.3145 6.5184 10.4163 6.5184Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className={onSender === false ? "form_popOff" : "form_popON2"}>
          <AboutSenderForm
            senderCity={senderCity}
            setSenderCity={setSenderCity}
            senderStock={senderStock}
            setSenderStock={setSenderStock}
            sendingDay={sendingDay}
            setSendingDay={setSendingDay}
            senderCountry={senderCountry}
            setSenderCountry={setSenderCountry}
            setCreate={setCreate}
            setOnPop={setOnPop}
          />
        </div>
        <br />
        <br />
        <Form_head
          title="Получатель"
          setState={setOnGeter}
          state={onGeter}
          state2={false}
        />
        <br />
        <div className={create === true ? "fuck" : "none"}>
          <div>
            <p>Выбрать Получателя:</p>
          </div>
          <div>
            <SelectInput
              error={exampleError}
              setState={setReciever}
              state={reciever}
              placeholder="Получатель"
              options={RecieverOptions}
            />
          </div>

          <div>
            <button
              onClick={() => {
                setOnGoodsModal(false)

                setTimeout(() => {
                  setOnModal(true)
                }, 200)
              }}
              className="AddDriverBtn"
            >
              создать_новго
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M14.3052 2.8147L3.19412 2.8147C2.58069 2.8147 2.08301 3.31238 2.08301 3.92581L2.08301 12.074C2.08301 12.6874 2.58069 13.1851 3.19412 13.1851L14.3052 13.1851C14.9187 13.1851 15.4163 12.6874 15.4163 12.074L15.4163 3.92581C15.4163 3.31238 14.9187 2.8147 14.3052 2.8147ZM14.3052 12.074L3.19412 12.074L3.19412 3.92581L14.3052 3.92581L14.3052 12.074ZM6.89782 7.99988C7.71495 7.99988 8.3793 7.33553 8.3793 6.5184C8.3793 5.70127 7.71495 5.03692 6.89782 5.03692C6.08069 5.03692 5.41634 5.70127 5.41634 6.5184C5.41634 7.33553 6.08069 7.99988 6.89782 7.99988ZM4.82375 10.9628H8.9719C9.25893 10.9628 9.49042 10.7638 9.49042 10.5184V10.074C9.49042 9.33785 8.79366 8.74062 7.93486 8.74062C7.68486 8.74062 7.50199 8.92581 6.89782 8.92581C6.27514 8.92581 6.12467 8.74062 5.86079 8.74062C5.00199 8.74062 4.30523 9.33785 4.30523 10.074V10.5184C4.30523 10.7638 4.53671 10.9628 4.82375 10.9628ZM10.4163 9.48136L13.0089 9.48136C13.1108 9.48136 13.1941 9.39803 13.1941 9.29618V8.92581C13.1941 8.82396 13.1108 8.74062 13.0089 8.74062L10.4163 8.74062C10.3145 8.74062 10.2312 8.82396 10.2312 8.92581V9.29618C10.2312 9.39803 10.3145 9.48136 10.4163 9.48136ZM10.4163 7.99988L13.0089 7.99988C13.1108 7.99988 13.1941 7.91655 13.1941 7.8147V7.44433C13.1941 7.34248 13.1108 7.25914 13.0089 7.25914L10.4163 7.25914C10.3145 7.25914 10.2312 7.34248 10.2312 7.44433V7.8147C10.2312 7.91655 10.3145 7.99988 10.4163 7.99988ZM10.4163 6.5184L13.0089 6.5184C13.1108 6.5184 13.1941 6.43507 13.1941 6.33322V5.96285C13.1941 5.86099 13.1108 5.77766 13.0089 5.77766L10.4163 5.77766C10.3145 5.77766 10.2312 5.86099 10.2312 5.96285V6.33322C10.2312 6.43507 10.3145 6.5184 10.4163 6.5184Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className={onGeter === false ? "form_popOff" : "form_popON2"}>
          <AboutGeterForm
            recievingCity={recievingCity}
            setRecievingCity={setRecievingCity}
            recievingStock={recievingStock}
            setRecievingStock={setRecievingStock}
            recievingDiscount={recievingDiscount}
            setRecievingDiscount={setRecievingDiscount}
            setCreate={setCreate}
            setOnPop={setOnPop}
          />
        </div>
        <br />
        <Form_head
          title="Другие услуги"
          setState={setOnAnother}
          state={onAnother}
          state2={false}
        />
        <div className={onAnother === false ? "form_popOff" : "form_popON2"}>
          <br />
          <div className="">
            <div className="form_inputs-input">
              <div>
                <p>Доставка до склада:</p>
              </div>
              <div>
                <input
                  className="form_inputs-input-text"
                  type="text"
                  {...register("delivery_service", { required: true })}
                />
              </div>
            </div>

            <div className="form_inputs-input">
              <div>
                <p>Коифицент доставки:</p>
              </div>
              <div>
                <input
                  className="form_inputs-input-text"
                  type="text"
                  {...register("delivery_coefficient_additional", {
                    required: true,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="GoodsGreenBack">
        <div>
          <div className="">
            <div className="form_inputs-input">
              <div>
                <div className="form_inputs-input--title">Общий вес:</div>
              </div>
              <div>
                <input
                  className="form_inputs-input-text"
                  type="text"
                  {...register("total_weight", { required: true })}
                />
              </div>
            </div>

            <div className="form_inputs-input">
              <div>
                <div className="form_inputs-input--title">Дорожные расходы:</div>
              </div>
              <div>
                <input
                  className="form_inputs-input-text"
                  type="text"
                  {...register("delivery_coefficient")}
                />
              </div>
            </div>
            <div className="form_inputs-input">
              <div>
                <div className="form_inputs-input--title">
                  Общий тариф:
                </div>
              </div>
              <div>
                <input
                  className="form_inputs-input-text"
                  type="text"
                  {...register("general_coefficient")}
                />
              </div>
            </div>
            <div className="form_inputs-input">
              <div>
                <div className="form_inputs-input--title">Общая стоимость:</div>
              </div>
              <div>
                <input
                  className="form_inputs-input-text"
                  type="text"
                  {...register("total_sum", { required: true })}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          {errors.load_name ? (
            <p className="error">Заполните все строки!!!</p>
          ) : null}
        </div>
        {/* <br />
        <div className="form_head">
          <h1>Общая стоимость: ${"365"}</h1>
        </div> */}
        <br />
        <div className="form_button">
          <button className="TruckBtn" onClick={handleSubmit(onSubmit)}>
            Добавить
          </button>
          <button onClick={() => setOnGoodsModal(false)} className="TruckBtn2">
            Отмена
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddGoodsForm

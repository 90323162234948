import { createSlice } from "@reduxjs/toolkit"
import { UsersState } from "../types"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { setLoading } from "../loading"

const initialState: any = {
  data: [],
  oneData: [],
  error: false,

  messages: [],
}

const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.data = {
        ...payload, results: payload.results.sort((a: any, b: any) => b.unread_messages - a.unread_messages)
      }
    },
    setItem: (state, { payload }) => {
      state.oneData = payload
    },
    setMessages: (state, { payload }) => {
      console.log('dsds');
      
      state.messages = payload
    },

    addLastMessages: (state, { payload }) => {
      state.messages = [...payload, ...state.messages]
    },

    readMessage: (state, { payload }) => {
      const updatetResults = state.messages.map((item: any) => {
        if (item?.message_id === payload?.message_id) {
          return { ...item, is_read: payload?.is_read }
        }
        return item
      })
      state.messages = updatetResults
    },

    
    addMessage: (state, { payload }) => {
      state.messages.push(payload)
    },
    setSortItems: (state, { payload }) => {

      const updatedResults = state.data.results.map((item: any) => {

        if (item.uuid === payload.room_uuid) {

          return { ...item, unread_messages: payload.messages_count };

        } else {

          return item;
        }
      });

      updatedResults.sort((a: any, b: any) => b.unread_messages - a.unread_messages);

      return {
        ...state,
        data: {
          ...state.data,
          results: updatedResults,
        },
      };
    },

    setOnlineItems: (state, { payload }) => {
      // Use map to create a new array with updated items
      const updatedResults = state.data.results.map((item: any) => {
        if (item?.user?.id === payload.user_id) {
          // Update online status for the matching item's user
          return { ...item, user: { ...item.user, online: payload.is_online } };
        } else {
          // Return the unchanged item for others
          return item;
        }
      });

      // Sort the updated results by unread_messages
      updatedResults.sort((a: any, b: any) => b.unread_messages - a.unread_messages);

      // Return the updated state
      return {
        ...state,
        data: {
          ...state.data,
          results: updatedResults,
        },
      };
    },

    setPsswd: (state, { payload }) => {
      state.data = payload
    },
    setRecovery: (state, { payload }) => {
      state.data = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const { setItems, setPsswd,addMessage,readMessage, setRecovery, setError, setItem, setSortItems, setOnlineItems, setMessages, addLastMessages } = chatsSlice.actions
export default chatsSlice.reducer

//get all users
export const fetchChatRooms = (params: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/chat/rooms/`, {
        params: params,
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
        After(response)
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
  }
}

export const fetchOwnChatRooms = (params: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/chat/rooms/own-rooms`, {
        params: { ...params, status: 'active' },
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
        After(response)
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
  }
}

export const fetchChatRoom = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/chat/rooms/${id}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(setLoading(false))

        dispatch(setItem(response.data))
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
  }
}

import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import TruckImg from "../../assets/img/Rectangle 413.png"
import EditIcon from "../../assets/svg/editIcon.svg"
import EditWayModal from "../../components/EditWayModal/EditWayModal"
import { useAppDispatch } from "../../redux/hooks"

import ClientIcon from "../../assets/svg/whiteClientIcon.svg"
import { Modal } from "../../components/Modal"
import ShowSecondaryRoutes from "./betweenMainRoutes"

interface Props {
  dispatcher?: any
  item: any
}

const Index = ({ item, dispatcher }: Props) => {
  const dispatch = useAppDispatch()
  const params = useParams()

  const [editWay, setEditWay] = useState(false)
  const [editRoute, setEditRoute] = useState(false)

  return (
    <>
      <div
        className={
          params?.status === "planning" ? "TruckCard onhover" : "TruckCard"
        }
      >
        <div className="TruckCard__inner">
          <div
            className="TruckCard__inner_content"
            style={{ display: "block" }}
          >
            <div
              className="TruckCard__inner_content-titles"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div className="TruckCard__inner_content-titles--maintitle">
                <div style={{ whiteSpace: "nowrap" }}>Сборка ID: {item.id}</div>
              </div>

              {!dispatcher && (
                <div className="flex" style={{ justifyContent: "flex-end" }}>
                  <div onClick={() => setEditRoute(true)}>
                    <button
                      className={
                        params?.status === "planning"
                          ? "waiting"
                          : "ClientsInfo__content_left_btns-editBtn"
                      }
                    >
                      <img src={ClientIcon} alt="" />
                    </button>
                  </div>
                  <div onClick={() => setEditWay(true)}>
                    <button
                      className={
                        params?.status === "planning"
                          ? "waiting"
                          : "ClientsInfo__content_left_btns-editBtn"
                      }
                    >
                      <img src={EditIcon ? EditIcon : TruckImg} alt="" />
                    </button>
                  </div>{" "}
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                paddingTop: "20px",
              }}
            >
              <div className="TruckCard__inner_content-titles--subtitle">
                <p className="TruckCard__inner_content-titles--subtitle_status">
                  <p style={{ display: "flex", gap: "5px", color: "#808080" }}>
                    Статус:
                    <u
                      style={
                        params?.status === "planning"
                          ? { color: "#f2994a" }
                          : { color: "#83C251" }
                      }
                    >
                      {" "}
                      {params?.status === "on_way"
                        ? "В пути"
                        : params?.status === "planning"
                        ? "В сборке"
                        : "Доставлено"}
                    </u>
                  </p>
                </p>
                <p className="TruckCard__inner_content-titles--subtitle_status">
                  <p style={{ display: "flex", gap: "5px", color: "#808080" }}>
                    Тек. мест-е:
                    <u style={{ color: "#83C251" }}>
                      {item?.current_location?.stock?.city?.name}
                    </u>
                  </p>
                </p>
                <p className="TruckCard__inner_content-titles--subtitle_status">
                  <p style={{ display: "flex", gap: "5px", color: "#808080" }}>
                    Водитель:
                    <u style={{ color: "#83C251" }}>
                      {item?.driver?.user?.first_name}{" "}
                      {item?.driver?.user?.last_name}
                    </u>
                  </p>
                </p>
                <p className="TruckCard__inner_content-titles--subtitle_status">
                  <p style={{ display: "flex", gap: "5px", color: "#808080" }}>
                    Дата изменения:
                    <u style={{ color: "#83C251" }}>
                      {item?.updated_at?.toString()?.slice(0, 10)}
                    </u>
                  </p>
                </p>
                <br />

                {item?.transport_assembly?.map((item: any, index: number) => (
                  <p className="TruckCard__inner_content-titles--subtitle_status">
                    <p
                      style={{ display: "flex", gap: "5px", color: "#808080" }}
                    >
                      Машина {index + 1} :
                      <u style={{ color: "#83C251" }}>{item?.item?.number}</u>
                    </p>
                  </p>
                ))}
              </div>
              <div className="TruckCard__inner_content-image">
                <img
                  style={{
                    height: "100px",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                  src={item?.image ? item?.image : TruckImg}
                  alt=""
                />
              </div>
            </div>
          </div>

          {/* <div className="TruckCard__bottom">
                        {item?.status == "on_way" ? (
                            <div className="TruckCard__bottom_info">
                                <div className="TruckCard__bottom_info-number">Ош:</div>
                                <div className="TruckCard__bottom_info-line"></div>
                                <div className="TruckCard__bottom_info-number">Бишкек</div>
                            </div>
                        ) : (
                            <div></div>
                        )}


                    </div> */}
        </div>
      </div>

      {editWay && (
        <div className="modal-background">
          <EditWayModal
            item={item}
            setEditWay={setEditWay}
            country={undefined}
          />
        </div>
      )}

      <Modal
        width={"530px"}
        height={"500px"}
        modal={editRoute}
        title={"Расположение складов"}
        setModal={setEditRoute}
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              overflow: "hidden",
              height: "105%",
            }}
          >
            <div className="catalog">
              <ShowSecondaryRoutes item={item} setEditRoute={setEditRoute} />
            </div>
          </div>
        }
      />
    </>
  )
}

export default Index

import React, { useEffect, useRef, useState } from "react"
import Select from "react-select"
import { useNavigate } from "react-router"

import { GreenBackgroundBtn } from "../../components/btns"
import HistoryCard from "../../components/clientHistory/index"

import GraphicSliderIcon from "../../assets/svg/graficSlider.svg"
import AddGoodsForm from "../../components/Forms/Goods/AddGoodsForm"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { addItemsLast, addReciept, fetchReseips } from "../../redux/receipts"
import { Modal } from "../../components/Modal"
import { PaymantModal } from "../../components/clientHistory/PaimentModal"
import { setLoading } from "../../redux/loading"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"

const Index = () => {
  const { balance, auth, receipts } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const [pay, setPay] = useState(false)
  const navigate = useNavigate()
  const [onGoodsModal, setOnGoodsModal] = useState(false)
  const [onModal, setOnModal] = React.useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)

  const AfterGet = (data: any) => {
    setTotalCount(data?.count)
  }

  useEffect(() => {
    dispatch(
      fetchReseips(
        {
          offset: currentPage,
          limit: 24,
        },
        AfterGet,
      ),
    )
  }, [])

  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    if (
      target.scrollHeight - (target.scrollTop + target.clientHeight) < 1 &&
      receipts?.data?.results?.length < totalCount
    ) {
      dispatch(setLoading(false))
      axios
        .get(`${API_URL}api/v1/cargo/receipts/`, {
          params: {
            offset: currentPage + 1,
            limit: 24,
          },
          headers: {
            Authorization: `Token ${access_token} `,
          },
        })
        .then((response: any) => {
          dispatch(addItemsLast(response.data.results))
        })
        .catch((er) => {})
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }
  // Динамическа пагинация закончилась

  let timeID: any = 0

  const handleSearch = (event: any) => {
    setCurrentPage(1)
    if (timeID) {
      clearTimeout(timeID)
    }
    timeID = setTimeout(() => {
      dispatch(
        fetchReseips(
          {
            offset: currentPage,
            limit: 24,
            search: event?.target?.value,
          },
          AfterGet,
        ),
      )
    }, 500)
  }

  const addPayment = (data: any) => {
    dispatch(
      addReciept(data, () => {
        setPay(false)
        dispatch(
          fetchReseips(
            {
              offset: currentPage,
              limit: 24,
            },
            AfterGet,
          ),
        )
      }),
    )
  }

  return (
    <div className="ClientHistory">
      <div className="ClientsInfo__header">
        <div className="ClientsInfo__header_left">
          <input
            className="SearchBox__input"
            type="text"
            placeholder="Поиск"
            onChange={(e) => handleSearch(e)}
          />
        </div>
        <div className="title">История платежей</div>
      </div>
      <div className="ClientHistory__content">
        <div className="flex " style={{ justifyContent: "space-between" }}>
          <div onClick={() => navigate(-1)}>
            <GreenBackgroundBtn title="Назад" />
          </div>
          {auth?.data?.role !== "dispatcher" && (
            <div onClick={() => setPay(true)}>
              <GreenBackgroundBtn title="Оплатить" />
            </div>
          )}
        </div>

        <div
          className="ClientHistory__content_cards"
          onScroll={scrollHandler}
          ref={scrollableDivRef}
        >
          <div className="ClientHistory__content_cards-inner">
            {receipts.data?.results?.map((item: any) => (
              <HistoryCard
                dispatcher={auth?.data?.role === "dispatcher" ? true : false}
                key={item.id}
                image={item.image}
                status={item.status}
                id={item.id}
                user={item.user}
                value={item.value}
              />
            ))}
          </div>
        </div>
      </div>

      {onGoodsModal && (
        <div
          className="modal-background"
          onClick={() => setOnGoodsModal(!onGoodsModal)}
        >
          <AddGoodsForm
            setOnModal={setOnModal}
            setOnGoodsModal={setOnGoodsModal}
          />
        </div>
      )}
      <Modal
        height={"fit-content"}
        title={"Оплата"}
        modal={pay}
        setModal={setPay}
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div className="catalog">
              <PaymantModal addPayment={addPayment} setModal={setPay} />
            </div>
          </div>
        }
      />
    </div>
  )
}

export default Index

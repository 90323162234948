import React, { useState } from "react"
import NoImage from "../../assets/img/no-profile.jpeg"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { makeRead, makeReadAsMana } from "../../redux/auth/notifications"
import ImageForShipUI from "./ImageForShipUI"
import { Link } from "react-router-dom"
interface Props {
  item: any
  setIsHovered?: any;
  onMouseOver?: (id:number) => void;
  id:string;
}

const ShipAddedNewUser = ({ item, setIsHovered,onMouseOver, id}: Props) => {
  const { auth } = useAppSelector((state) => state)
  const dispatch = useAppDispatch()

  const [show, setShow] = React.useState(true)

  const makeReadAsManager = () => {
    if (auth.data?.role === "manager") {
      dispatch(makeReadAsMana(item?.id, setIsHovered))
    } else {
      dispatch(makeRead(item?.id, setIsHovered))
    }
  }

  return (
      <div>
        <div className="AddedNewUser" style={{ position: "relative" }}>
          <div
            className="AddedNewUser_avatar"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={
                item?.inner_payload?.avatar
                  ? item?.inner_payload?.avatar
                  : NoImage
              }
              alt=""
            />
          </div>
          <div className="AddedNewUser_form">
            <h1>Добавлен новый пользователь</h1>
            <div className="AddedNewUser_form-label">
              <p>Роль:</p>
              <input value={item?.inner_payload?.role} type="text" disabled />
            </div>
            <div className="AddedNewUser_form-label">
              <p>ФИО:</p>
              <input
                value={
                  item?.inner_payload?.last_name +
                  " " +
                  item?.inner_payload?.first_name
                }
                type="text"
                disabled
              />
            </div>
          </div>
          {/* <ImageForShipUI
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmdqXpYO0Wcnt5YHtqkIxyl_b10p_BFTWm7A&usqp=CAU"
          title="Фото грузовика:"
        /> */}
        </div>
        {/* {show && (
          <div
            onClick={() => {
              setShow(!show)
              setIsHovered(false)
            }}
            className={show ? "PaymentConfirmation_rightBar-buttons" : "none"}
          >
            <div className="Green-bth2">Смотреть детально</div>
          </div>
        )} */}
      </div>
  )
}

export default ShipAddedNewUser

import React from "react"
import Close from "../../../../assets/svg/greenCloseIcon.svg"

const PaymentImageCard = ({ img, setImage }: any) => {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid #83C251",
        borderRadius: "6px",
        position: "relative",
      }}
    >
      <img
        onClick={() => setImage(false)}
        style={{
          position: "absolute",
          top: "6px",
          right: "6px",
          width: "18px",
          cursor: "pointer",
        }}
        src={Close}
        alt=""
      />
      <img
        style={{
          maxWidth: "500px",
          maxHeight: "500px",
          width: "100%",
          height: "100%",
          borderRadius: "6px",
        }}
        src={img}
        alt=""
      />
    </div>
  )
}

export default PaymentImageCard

import React, { useEffect } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Select from "react-select"

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import { editTruckZone } from "../../../../redux/cargos/trucks"

type Props = {
  zone_capacity?: number
  zone_order?: any
}

const TruckZoneEdit = ({ setMenu3 }: any) => {
  const { trucks } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const { register, setValue, control, handleSubmit } = useForm()

  useEffect(() => {
    setValue("zone_capacity", trucks.selectedZones?.lifting_capacity)
    setValue("zone_order", {
      value: trucks.selectedZones?.order,
      label: trucks.selectedZones?.order,
      id: trucks.selectedZones?.order,
    })
  }, [])

  const editZones: SubmitHandler<Props> = (data: any) => {
    dispatch(
      editTruckZone({
        lifting_capacity: +data.zone_capacity,
        order: data?.zone_order?.id,
      }),
    )
    setMenu3(false)
  }

  const zonesCount = [
    { value: 1, label: 1, id: 1 },
    { value: 2, label: 2, id: 2 },
    { value: 3, label: 3, id: 3 },
    { value: 4, label: 4, id: 4 },
    { value: 5, label: 5, id: 5 },
    { value: 6, label: 6, id: 6 },
    { value: 7, label: 7, id: 7 },
  ]

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px",
      cursor: "pointer",
      background: "#fff",
      marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent", // LC Green color on focus
      color: "var(--lc-black-800, #333)",
    }),
  }

  console.log(trucks.selectedZones)

  return (
    <div className="catalog_items">
      <div className="catalog_item">
        <div>
          Грузоподъемность <u className="redStar">*</u>
        </div>
        <div style={{ width: "56.9%" }}>
          <input
            {...register("zone_capacity", {
              required: true,
            })}
            placeholder="Грузоподъемность"
            type="number"
            onWheel={(e) => e.currentTarget.blur()}
          />
          <div className="error">{trucks?.error?.zones?.lifting_capacity}</div>
        </div>
      </div>
      <div className="catalog_item">
        <div>
          Количество зон <u className="redStar">*</u>
        </div>
        <div style={{ width: "56.9%" }}>
          <Controller
            control={control}
            {...register("zone_order", { required: true })}
            render={({ field }) => (
              <Select
                styles={customStyless}
                id="favouriteAvenger"
                {...field}
                placeholder={trucks?.selectedZones?.order}
                options={zonesCount?.map((item: any) => ({
                  value: item.value,
                  label: item.label,
                  id: item.id,
                }))}
              />
            )}
          />
          <div className="error">{trucks?.error?.zones?.order}</div>
        </div>
      </div>
      <div className="form_button" style={{ width: "100%" }}>
        <div style={{ width: "50%" }}>
          <button className="TruckBtn2" onClick={() => setMenu3(false)}>
            Отмена
          </button>
        </div>
        <div style={{ width: "50%" }}>
          <button className="Green-bth" onClick={handleSubmit(editZones)}>
            Редактировать зону
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                d="M9.08333 4.87565V1.33398L3.875 1.33398C3.52865 1.33398 3.25 1.61263 3.25 1.95898L3.25 14.0423C3.25 14.3887 3.52865 14.6673 3.875 14.6673L12.625 14.6673C12.9714 14.6673 13.25 14.3887 13.25 14.0423L13.25 5.50065H9.70833C9.36458 5.50065 9.08333 5.2194 9.08333 4.87565ZM10.7807 10.5009L9.08333 10.5009L9.08333 12.5842C9.08333 12.8145 8.89687 13.0009 8.66667 13.0009H7.83333C7.60313 13.0009 7.41667 12.8145 7.41667 12.5842L7.41667 10.5009H5.71927C5.3474 10.5009 5.16172 10.0507 5.42578 9.78841L7.93672 7.29622C8.1099 7.12409 8.38958 7.12409 8.56276 7.29622L11.0737 9.78841C11.338 10.0507 11.1526 10.5009 10.7807 10.5009ZM13.0677 4.06836L10.5182 1.51628C10.401 1.39909 10.2422 1.33398 10.0755 1.33398H9.91667V4.66732L13.25 4.66732V4.50846C13.25 4.3444 13.1849 4.18555 13.0677 4.06836Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export default TruckZoneEdit

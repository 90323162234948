import React, { useState } from "react"

import SelectInput from "../Selekted/New-React-selekt"
interface AddDriverFormProps {
  setOnPop: Function
  setCreate: Function
  recievingCity: any
  setRecievingCity: any
  recievingStock: any
  setRecievingStock: any
  recievingDiscount: any
  setRecievingDiscount: any
}
const options = [
  { id: 1, value: "odddption1", label: "Optionddd1" },
  { id: 2, value: "opddtion2", label: "Optiond 2" },
  { id: 3, value: "opdddtion3", label: "Option d3" },
]
const CitiesOptions = [
  { id: 1, value: "Osh", label: "Osh" },
  { id: 2, value: "Tashkent", label: "Tashkent" },
  { id: 3, value: "Bishkek", label: "Bishkek" },
]
const StocksOptions = [
  { id: 1, value: "№341", label: "№341" },
  { id: 2, value: "№342", label: "№342" },
  { id: 3, value: "№350", label: "№350" },
]
const CountriesOptions = [
  { id: 1, value: "Russia", label: "Russia" },
  { id: 2, value: "UK", label: "UK" },
  { id: 3, value: "Uzbekistan", label: "Uzbekistan" },
  { id: 4, value: "Kyrgyzstan", label: "Kyrgyzstan" },
]
const AboutGeterForm: React.FC<AddDriverFormProps> = ({
  setOnPop,
  setCreate,
  recievingCity,
  setRecievingCity,
  recievingStock,
  setRecievingStock,
  recievingDiscount,
  setRecievingDiscount,
}) => {
  const Censeled = () => {
    setOnPop(false)
    setCreate(true)
    
  }
    let exampleError;
  return (
    <form className="form_inputs">
      <div className="form_inputs-input">
        <div>
          <p>Выбрать принимающюю стрвну:</p>
        </div>
        <SelectInput error={exampleError} placeholder="получение:" setState={setRecievingCity} state={recievingCity}   options={CitiesOptions} />
      </div>
      <div className="form_inputs-input">
        <div>
          <p>Выбор склада получения:</p>
        </div>
        <SelectInput error={exampleError} placeholder="склада получения:" setState={setRecievingStock} state={recievingStock}  options={StocksOptions} />
      </div>
{/*      
      <div className="form_inputs-input">
        <div>
          <p>Общая стоимость:</p>
        </div>
        <div>
          <input className="form_inputs-input-text" type="text" name="" id="" />
        </div>
      </div> */}
      <div className="form_inputs-input">
        <div>
          <p>Принимающая страна:</p>
        </div>
        <SelectInput error={exampleError} placeholder="Сделать скидку:" setState={setRecievingDiscount} state={recievingDiscount}  options={CountriesOptions} />
      </div>
    </form>
  )
}

export default AboutGeterForm

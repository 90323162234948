import { createSlice } from "@reduxjs/toolkit"
import { UsersState } from "../types"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { toast } from "react-toastify"
import { setLoading } from "../loading"

const initialState: UsersState = {
  loadingEdit: false,
  data: [],
  dataId: [],
  allItems: [],
  status: [],
  statusId: [],
  dataSelect: [],
  error: false,
}

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setLoadingEdit: (state, { payload }) => {
      state.loadingEdit = payload
    },
    setItems: (state, { payload }) => {
      state.data = payload
    },
    setAllItems: (state, { payload }) => {
      state.data = payload
    },
    setAllSelectItems: (state, { payload }) => {
      state.dataSelect = payload
    },
    setItemsById: (state, { payload }) => {
      console.log(payload)

      state.dataId = payload
    },
    setDrivers: (state, { payload }) => {
      state.status = payload
    },
    addDriversLast: (state, { payload }) => {
      state.status.results.push(...payload)
    },
    setDriver: (state, { payload }) => {
      state.statusId = payload
    },
    setPsswd: (state, { payload }) => {
      state.data = payload
    },
    setRecovery: (state, { payload }) => {
      state.data = payload
    },
    addItem: (state, { payload }) => {
      state.data.results.unshift(payload)
    },
    addItemsLast: (state, { payload }) => {
      state.data.results.push(...payload)
    },
    addItemsLastSelect: (state, { payload }) => {
      state.dataSelect.results.push(...payload)
    },
    changePaswd: (state, { payload }) => {
      state.data = payload
    },
    editUser: (state, { payload }) => {
      const updatedResults = state.data.results.map((item: any) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
      state.data.results = updatedResults
    },

    editUserById: (state, { payload }) => {
      state.dataId = payload
    },
    deleteUser(state, { payload }) {
      console.log(payload)

      state.data.results = state.data.results.filter(
        (item: any) => item.id !== payload,
      )
    },
    deactivateuser: (state, { payload }) => {
      state.data = payload
    },
    setRoles: (state, { payload }) => {
      state.allItems = payload
    },
    addrole: (state, { payload }) => {
      state.allItems.push({ payload })
    },
    editRoles: (state, { payload }) => {
      state.allItems = payload
    },
    deleteRole(state, { payload }) {
      state.allItems = state.allItems.filter((item: any) => item.id !== payload)
    },
    setStatus: (state, { payload }) => {
      state.status = payload
    },
    addstatus: (state, { payload }) => {
      state.status.push({ payload })
    },
    editstatus: (state, { payload }) => {
      state.status = payload
    },
    deletestatus(state, { payload }) {
      state.status = state.status.filter((item: any) => item.id !== payload)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const {
  setLoadingEdit,
  addItemsLastSelect,
  setItems,
  setItemsById,
  setDrivers,
  setPsswd,
  setRecovery,
  changePaswd,
  addItem,
  editUser,
  deleteUser,
  deactivateuser,
  setError,
  setRoles,
  addrole,
  editRoles,
  deleteRole,
  setStatus,
  addstatus,
  editstatus,
  deletestatus,
  editUserById,
  setAllItems,
  addItemsLast,
  setAllSelectItems,
  setDriver,
  addDriversLast,
} = usersSlice.actions
export default usersSlice.reducer

//SignUp
export const SignUp = (data = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}users/registration/`, data)
      .then((response: any) => {
        dispatch(setItems(response.data))
        window.location.replace("auth")
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//recoveryPassword
export const recoveryPassword = (data = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/auth/send-reset-password-key/`, data)
      .then((response: any) => {
        dispatch(setPsswd(response))
      })
      .catch((er) => {
        if (er?.response?.status === 404) {
          toast.error("Почта не существуйт", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
        }
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//setRecoveryPassword
export const setRecoveryPassword = (data = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/auth/reset-password-by-key/`, data)
      .then((response: any) => {
        dispatch(setRecovery(response.data))
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//change_password
export const changePassword = (data = {}, After: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/auth/change-password/ `, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(changePaswd(response.data))
        After()
        toast.success("Вы успешно изменили пароль", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

// get users by roleName
export const fetchAndSearchUsers = (name: any, search?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/auth/users/`, {
        params: {
          roles: name,
          search: search,
        },
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
      })
      .catch((er) => {})
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//fetch all users
export const fetchAllUsers = (params: any, After?: any, select?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/auth/users/`, {
        params: params,
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        if (select) {
          dispatch(setAllSelectItems(response.data))
        } else {
          dispatch(setAllItems(response.data))
        }
        After(response.data)
      })
      .catch((er) => {})
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//get user by id
export const fetchUserById = (id: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/auth/users/${id}`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setItemsById(response.data))
        After()
      })
      .catch((er) => {})
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//fetch all drivers
export const fetchAllDrivers = (params: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/auth/driving-info/`, {
        params: params,
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setDrivers(response.data))
        After(response.data)

        dispatch(setLoading(false))
      })
      .catch((er) => {})
      .finally(() => {})
  }
}

//fetch a Driver
export const fetchDriver = (params: any, id: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/auth/driving-info/${id}`, {
        params: params,
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        dispatch(setDriver(response.data))
        After(response.data)
      })
      .catch((er) => {})
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//add users
export const addUser = (
  data: any,
  avatar?: any,
  setFunc?: any,
  active?: any,
  staff?: any,
) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/auth/users/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        axios
          .patch(
            `${API_URL}api/v1/auth/users/${response.data.id}/`,
            {
              avatar: avatar,
            },
            {
              headers: {
                Authorization: `Token ${access_token}`,
                "Content-type": "multipart/form-data",
              },
            },
          )
          .then((res: any) => {
            if (staff) {
              dispatch(addItem(res.data))
            } else {
              if (active === response?.data?.is_confirmed) {
                dispatch(addItem(res.data))
              }
            }

            dispatch(setError(false))
            setFunc()

            dispatch(setLoading(false))
          })
          .catch((er) => {
            dispatch(setError(er?.response?.data))

            dispatch(setLoading(false))
          })
          .finally(() => {})
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//add client-Balance
export const addClientBalance = (data = {}, setFunc2?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/auth/clients/`, data, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        setFunc2()
        dispatch(addItem(response.data))
      })
      .catch((er) => {
        dispatch(setError(er.response?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//add client
export const addClientInfo = (data = {}, setFunc3: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/auth/client-info/`, data, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        setFunc3()
        dispatch(addItem(response.data))
      })
      .catch((er) => {
        dispatch(setError(er.response?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//user edit
export const userEditById = (data = {}, id: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/auth/users/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        After()
        dispatch(editUser(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const userEditByIdDataId = (data = {}, id: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/auth/users/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        After()
        dispatch(editUserById(res.data))
        dispatch(editUser(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const userEditByIdDataIdAvatar = (data = {}, id: any, After?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/auth/users/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
          "Content-type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        dispatch(editUserById(res.data))
        dispatch(editUser(res.data))
        After()
      })
      .catch((er) => {
        dispatch(setError(er?.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//user edit
export const userEdit = (
  data = {},
  id: any,
  setModal?: any,
  deleteId?: any,
) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/auth/users/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        deleteId && dispatch(deleteUser(res.data.id))
        dispatch(editUser(res.data))
        setModal(false)
      })
      .catch((er) => {
        dispatch(setError(er.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//delete user
export const userDelete = (id: any, AfterDelete?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(
        `${API_URL}api/v1/auth/users/${id}/`,
        {
          is_active: false,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Token ${access_token}`,
          },
        },
      )
      .then((res: any) => {
        dispatch(deleteUser(id))
        AfterDelete()
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//user diactive
export const usersDeactivate = (data = {}, id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .put(`${API_URL}users/deactivate/${id}`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(deactivateuser(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//user diactive
export const userDeactivate = (data = {}, id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}users/deactivate/${id}`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(deactivateuser(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//fetchRoles
export const fetchRoles = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}users/role/`)
      .then((res: any) => {
        dispatch(setRoles(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//addRole
export const addRole = (data = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}users/role/`, data)
      .then((res: any) => {
        dispatch(addrole(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

// edit roles
export const EditRoles = (data = {}, id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .put(`${API_URL}/users/role/${id}`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editRoles(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

// edit roles
export const EditRole = (data = {}, id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}/users/role/${id}`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editRoles(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//delete roles
export const deleteRoles = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .delete(`${API_URL}/users/role/${id}/`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(deleteRole(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//fetchStatus
export const fetchStatus = () => {
  return async (dispatch: Dispatch) => {}
}

// fetchByIdStatus
export const fetchByIdStatus = (id: any) => {
  return async (dispatch: Dispatch) => {}
}

//addStatus
export const addStatus = (data = {}) => {
  return async (dispatch: Dispatch) => {}
}

// edit status
export const EditStatus = (data = {}, id: any) => {
  return async (dispatch: Dispatch) => {}
}

// edit roles
export const EditStatuss = (data = {}, id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}users/status/${id}`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editstatus(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//delete roles
export const deleteStatus = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .delete(`${API_URL}users/status/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(deletestatus(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

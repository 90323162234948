import React, { useState } from "react"

export const Zone = ({ number, item }: any) => {
  const [zone, setZone] = useState(item ? true : false)
  return (
    <div>
      <>
        {number === 1 || number === 3 || number === 5 || number === 7 ? (
          <div
            className="good_select_zone_item"
            onClick={() => setZone(!zone)}
            style={{
              justifyContent: "center",
              bottom: !zone ? "17.5px" : "",
              position: "relative",
            }}
          >
            {!zone ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                  fill="#83C251"
                />
              </svg>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                    fill="#83C251"
                  />
                  <path
                    d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
                    fill="#83C251"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="16"
                  viewBox="0 0 2 16"
                  fill="none"
                >
                  <path d="M1 0L1 16" stroke="#83C251" />
                </svg>
                <div
                  className="good_select_zone_title"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                  }}
                >
                  {item ? (
                    <div style={{whiteSpace: 'nowrap'}}>
                      {number}: {item?.lifting_capacity}кг
                    </div>
                  ) : (
                    <div>Зона {number}</div>
                  )}
                </div>
              </>
            )}
          </div>
        ) : (
          <div
            className="good_select_zone_item"
            onClick={() => setZone(!zone)}
            style={{
              justifyContent: !zone ? "center" : "",
              bottom: !zone ? "17.5px" : "",
              position: "relative",
            }}
          >
            {!zone ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                  fill="#83C251"
                />
              </svg>
            ) : (
              <>
                <div
                  className="good_select_zone_title"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                  }}
                >
                  {item ? (
                    <div style={{whiteSpace: 'nowrap'}}>
                      {number}: {item?.lifting_capacity}кг
                    </div>
                  ) : (
                    <div>Зона {number}</div>
                  )}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="16"
                  viewBox="0 0 2 16"
                  fill="none"
                >
                  <path d="M1 0L1 16" stroke="#83C251" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                    fill="#83C251"
                  />
                  <path
                    d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
                    fill="#83C251"
                  />
                </svg>
              </>
            )}
          </div>
        )}
      </>
    </div>
  )
}

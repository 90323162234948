import React, { useState } from "react"

import SelectInput from "../Selekted/New-React-selekt"
interface AddDriverFormProps {
  setOnPop: Function
  setCreate: Function
  senderCity: any
  setSenderCity: any
  setSenderStock: any
  senderStock: any
  sendingDay: any
  setSendingDay: any
  senderCountry: any
  setSenderCountry: any
}
const CitiesOptions = [
  { id: 1, value: "Osh", label: "Osh" },
  { id: 2, value: "Tashkent", label: "Tashkent" },
  { id: 3, value: "Bishkek", label: "Bishkek" },
]
const StocksOptions = [
  { id: 1, value: "№241", label: "№241" },
  { id: 2, value: "№242", label: "№242" },
  { id: 3, value: "№250", label: "№250" },
]
const CountriesOptions = [
  { id: 1, value: "Russia", label: "Russia" },
  { id: 2, value: "UK", label: "UK" },
  { id: 3, value: "Uzbekistan", label: "Uzbekistan" },
  { id: 4, value: "Kyrgyzstan", label: "Kyrgyzstan" },
]
const AboutSenderForm: React.FC<AddDriverFormProps> = ({
  setOnPop,
  setCreate,
  senderCity,
  setSenderCity,
  setSenderStock,
  senderStock,
  sendingDay,
  setSendingDay,
  senderCountry,
  setSenderCountry,
}) => {
  const Censeled = () => {
    setOnPop(false)
    setCreate(true)
  }

  let exapmleError
  return (
    <form className="form_inputs">
     
      <div className="form_inputs-input">
        <div>
          <p>Город отправки:</p>
        </div>
        <SelectInput
          error={exapmleError}
          setState={setSenderCity}
          state={senderCity}
          placeholder="Город:"
          options={CitiesOptions}
        />
      </div>
      <div className="form_inputs-input">
        <div>
          <p>Выбор склада отправки:</p>
        </div>
        <SelectInput
          error={exapmleError}
          setState={setSenderStock}
          state={senderStock}
          placeholder="склада отправки:"
          options={StocksOptions}
        />
      </div>
      {/* <div className="form_inputs-input">
        <div>
          <p>День отправки:</p>
        </div>
        <SelectInput
          error={exapmleError}
          setState={setSendingDay}
          state={sendingDay}
          placeholder="День отправки:"
          options={options}
        />
      </div> */}
      {/* <div className="form_inputs-input">
        <div>
          <p>Номер паспорта:</p>
        </div>
        <div>
          <input className="form_inputs-input-text" type="text" name="" id="" />
        </div>
      </div> */}
      <div className="form_inputs-input">
        <div>
          <p>Гражданство:</p>
        </div>
        <SelectInput
          error={exapmleError}
          setState={setSenderCountry}
          state={senderCountry}
          placeholder="Гражданство:"
          options={CountriesOptions}
        />
      </div>
    </form>
  )
}

export default AboutSenderForm

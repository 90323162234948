import { SetStateAction, useEffect, useState } from "react"
import { ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Select from "react-select"
import { getRoleName } from "../../utils/functions"

import Avatar from "../../assets/img/no-profile.jpeg"
import EditIcon from "../../assets/svg/editIcong.svg"
import UserIcon from "../../assets/svg/userIconline.svg"
import TruckImg from "../../assets/img/Rectangle 413.png"
import TruckImgLayout from "../../assets/svg/Track.svg"
// import GraphicSliderIcon from "../../assets/svg/graficSlider.svg"
import ExitModal from "../../components/profileModals/exitModal"

// import SearchBox from "../../components/searchBox/index"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import ChangePswd from "../../components/profileModals/changePswd"
import { access_token, user } from "../../services"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import {
  editProfile,
  profileEdit,
  setError,
  setProfile,
} from "../../redux/auth/authSlice"
import { fetchUserById } from "../../redux/users"
import { fetchCountries } from "../../redux/cargos/countries"
import { fetchLanguages } from "../../redux/cargos/languages"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { fetchCities } from "../../redux/cargos/cities"
import { setLoading } from "../../redux/loading"

interface Props {
  setIsEdit: React.Dispatch<SetStateAction<boolean>>
  isEdit: boolean
}

type Inputs = {
  first_name: string
  last_name: string
  phone: any
  email: string
  avatar: any
  address: string
  citizen: any
  city: any
  date_of_birth: string
  experience: number
  languages: any
  passport: string
  living_country: string
  country: any
}

const Index = ({ setIsEdit, isEdit }: Props) => {
  const { auth, users, cities, countries, languages } = useAppSelector(
    (state: any) => state,
  )
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<Inputs>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [languagesList, setLanguagesList] = useState<any>([])
  const [exit, setExit] = useState(false)

  const getCitiesByCountry = (id: number) => {
    dispatch(fetchCities(id))
  }

  useEffect(() => {
    axios
      .get(`${API_URL}api/v1/auth/profile/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(setProfile(response.data))
        setValue("first_name", response.data.first_name)
        setValue("last_name", response.data.last_name)
        setValue("email", response.data.email)
        setValue("phone", response.data.phone)
        setValue("address", response?.data?.staff?.address)
        setValue("experience", response?.data?.staff?.experience)
        setValue("date_of_birth", response?.data?.staff?.date_of_birth)
        setValue("passport", response?.data?.staff?.passport)
        setValue("city", {
          value: response?.data?.staff?.city?.name,
          label: response?.data?.staff?.city?.name
            ? response?.data?.staff?.city?.name
            : "Город",
          id: response?.data?.staff?.city?.id,
        })
        setValue("citizen", {
          value: response?.data?.staff?.citizen?.name,
          label: response?.data?.staff?.citizen?.name
            ? response?.data?.staff?.citizen?.name
            : "Гражданство",
          id: response?.data?.staff?.citizen?.id,
        })
        setValue("country", {
          value: response?.data?.staff?.city?.country_name,
          label: response?.data?.staff?.city?.country_name
            ? response?.data?.staff?.city?.country_name
            : "Страна",
          id: response?.data?.staff?.city?.country,
        })
        getCitiesByCountry(response?.data?.staff?.city?.country)
        setLanguagesList(
          response?.data?.staff?.languages?.map((item: any) => ({
            value: item.name,
            label: item.name,
            id: item.id,
          })) || [],
        )
      })
      .catch((er) => {
        if (er?.request?.status === 401) {
          localStorage.clear()
          window.location.replace("login")
        }
      })
  }, [])

  useEffect(() => {
    dispatch(fetchCountries())
    dispatch(fetchLanguages())
  }, [])

  const [password, setPassword] = useState(false)
  const [avatar, setAvatar] = useState<File | null>(null)
  let role = 2

  const handleAvatarChange = (e: any) => {
    dispatch(setLoading(true))
    axios
      .patch(
        `${API_URL}api/v1/auth/profile/`,
        {
          avatar: e?.target?.files[0],
        },
        {
          headers: {
            Authorization: `Token ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
        },
      )
      .then((res: any) => {
        dispatch(editProfile(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
        alert("Размер фото слишком большой")
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    dispatch(
      profileEdit({
        first_name: data?.first_name ? data?.first_name : null,
        last_name: data?.last_name ? data?.last_name : null,
        phone: data?.phone,
        email: data?.email,
        staff: {
          address: data?.address,
          citizen: data?.citizen?.id,
          city: data?.city?.id,
          languages: languagesList?.map((item: any) => item.id),
          experience: data?.experience,
          date_of_birth: data.date_of_birth,
          passport: data?.passport,
        },
      }),
    )
    // dispatch(
    //   editStaff(
    //     {
    //       address: data.address,
    //       citizen: data.citizen?.id,
    //       city: data.city?.id,
    //       date_of_birth: data.date_of_birth,
    //       experience: data.experience,
    //       languages: [data.languages?.id],
    //       passport: data.passport,
    //     },
    //     user?.staff?.id,
    //   ),
    // )
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px",
      cursor: "pointer",
      background: "#fff",
      marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "273px",
      padding: "0px 7px",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent", // LC Green color on focus
      color: "var(--lc-black-800, #333)",
    }),
  }

  return (
    <>
      <div className="Homepage__header">
        {/* <div className="SearchBox">
          <SearchBox
            title="По коду"
            title2="Номер трека"
            title3="По складу"
            title4="По городу"
            open={open}
          />
          <button className="SearchBox_btn" onClick={() => setOpen(!open)}>
            <img src={GraphicSliderIcon} alt="" />
          </button>
        </div> */}
        <div></div>
        <div className="title">Личный кабинет</div>
      </div>
      <div className="Profile">
        <div
          style={{
            overflowY: "scroll",
            height: "calc(100% - 64px)",
            marginBottom: "64px",
          }}
        >
          <div className="Profile__header">
            <div className="Profile__header_about">
              <label
                style={{ cursor: "pointer", borderRadius: "100px" }}
                htmlFor="avatarImg"
                className="Profile__header_avatar"
              >
                {auth?.loading ? (
                  <img
                    style={{
                      margin: "0 auto",
                      display: "block",
                      width: "25%",
                      zIndex: "100",
                      marginTop: "40px",
                    }}
                    src={
                      "https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca_w200.gif"
                    }
                  />
                ) : (
                  <img
                    className="Profile__header_avatar-img"
                    src={
                      auth.data?.avatar
                        ? auth.data?.avatar
                        : avatar
                        ? URL.createObjectURL(avatar)
                        : Avatar
                    }
                    alt=""
                  />
                )}
                {!auth?.loading ? (
                  <button>
                    <input
                      onChange={(e: any) => handleAvatarChange(e)}
                      id="avatarImg"
                      style={{
                        opacity: "0",
                        width: "1px",
                        height: "1px",
                      }}
                      type="file"
                    />
                    <label style={{ cursor: "pointer" }} htmlFor="avatarImg">
                      <img src={EditIcon} alt="" />
                    </label>
                  </button>
                ) : null}
              </label>
              <div className="Profile__header_info">
                <div className="Profile__header_info-status">
                  <div className="Profile__header_info-status--title">
                    Роль:
                  </div>
                  <div
                    className="flex"
                    style={{
                      width: "100%",
                      maxWidth: "311px",
                    }}
                  >
                    {getRoleName(auth?.data?.role)}
                    {/* <button className="Profile__header_info-status--allow">
                      Запросить <img src={UserIcon} alt="" />
                    </button> */}
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="Profile__header_info-status--title">
                      Фамилия:
                    </div>
                    <div className="flex">
                      <input
                        className="Profile__header_info-status--name"
                        type="text"
                        {...register("last_name")}
                      />
                    </div>
                  </div>
                  <div className="error">{auth?.error?.last_name}</div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="Profile__header_info-status--title">
                      Имя:
                    </div>
                    <div className="flex">
                      <input
                        className="Profile__header_info-status--name"
                        type="text"
                        {...register("first_name")}
                      />
                    </div>
                  </div>
                  <div className="error">{auth?.error?.first_name}</div>
                </div>
                <div
                  className="Profile__header_info-status"
                  style={{
                    alignItems: "start",
                    width: "411px",
                  }}
                >
                  <div className="Profile__header_info-status--title">
                    Пароль:
                  </div>
                  <div className="flex">
                    <div style={{ width: "100%" }}>
                      <div
                        className="Profile__header_info-paswd--change"
                        onClick={() => setPassword(!password)}
                      >
                        Поменять пароль
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Profile__header_trucks">
              <div
                className={role === 1 ? "Profile__header_imgOfTruck" : "none"}
              >
                <div>Фото грузовика:</div>
                <img
                  className="Profile__header_imgOfTruck-img"
                  src={TruckImg}
                  alt=""
                />
                <button>
                  <img src={EditIcon} alt="" />
                </button>
              </div>
              <div
                className={role === 1 ? "Profile__header_imgOfTruck" : "none"}
              >
                <div>Макет грузовика:</div>
                <img
                  className="Profile__header_imgOfTruck-imglayout"
                  src={TruckImgLayout}
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* <div className="Profile__switch">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleToggle}
              />
              <span className="slider"></span>
            </label>
            <div>Выключить уведомления</div>
          </div> */}
          <div
            className="profile_items"
            style={{
              widows: "100%",
            }}
          >
            <>
              <div className="profile_item">
                <div className="Profile__content_main-title">Основные</div>
                <div className="profile_item_item" style={{ display: "block" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="profile_item_title">Почта:</div>
                    <div>
                      <input
                        type="email"
                        className="Profile__content_main-value"
                        placeholder="Email"
                        {...register("email")}
                      />
                    </div>
                  </div>
                  <span
                    style={{ display: "flex", justifyContent: "end" }}
                    className="error"
                  >
                    {auth.error?.email}
                  </span>
                </div>
                <div className="profile_item_item" style={{ display: "block" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="profile_item_title"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Номер телефона:
                    </div>
                    <div>
                      <input
                        type="text"
                        className="Profile__content_main-value"
                        {...register("phone")}
                      />
                    </div>
                  </div>
                  <span
                    style={{ display: "flex", justifyContent: "end" }}
                    className="error"
                  >
                    {auth.error?.phone}
                  </span>
                </div>
                <div className="profile_item_item" style={{ display: "block" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="profile_item_title">Дата рождения:</div>
                    <div>
                      <input
                        type="date"
                        placeholder="Пусто"
                        className="Profile__content_main-value"
                        {...register("date_of_birth", { required: false })}
                      />
                    </div>
                  </div>
                  <span
                    style={{ display: "flex", justifyContent: "end" }}
                    className="error"
                  >
                    {auth.error?.staff?.date_of_birth}
                  </span>
                </div>
                <div className="profile_item_item" style={{ display: "block" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="profile_item_title">Паспорт:</div>
                    <div>
                      <input
                        type="text"
                        placeholder="Пусто"
                        className="Profile__content_main-value"
                        {...register("passport", { required: false })}
                      />
                    </div>
                  </div>
                  <span
                    style={{ display: "flex", justifyContent: "end" }}
                    className="error"
                  >
                    {auth.error?.staff?.passport}
                  </span>
                </div>
                <div className="profile_item_item">
                  <div className="profile_item_title">Дата регитрации:</div>
                  <div>
                    <input
                      disabled
                      style={{
                        background: "rgb(229, 229, 229)",
                      }}
                      type="text"
                      className="Profile__content_main-value"
                      value={auth.data?.date_joined?.slice(0, 10)}
                    />
                  </div>
                </div>
              </div>
              <div className="profile_item">
                <div className="Profile__content_main-title">
                  Место жительства
                </div>
                {
                  <div className="profile_item_item">
                    <div className="profile_item_title">Гражданство:</div>
                    <div>
                      <Controller
                        control={control}
                        {...register("citizen", { required: false })}
                        render={({ field }) => (
                          <Select
                            styles={customStyless}
                            id="favouriteAvenger"
                            isSearchable={false}
                            {...field}
                            placeholder={
                              auth?.data?.staff?.city?.country_name
                                ? auth?.data?.staff?.city?.country_name
                                : "Страна"
                            }
                            options={countries?.data?.results?.map(
                              (item: any) => ({
                                value: item.name,
                                label: item.name,
                                id: item.id,
                              }),
                            )}
                          />
                        )}
                      />
                    </div>
                  </div>
                }
                <div className="profile_item_item">
                  <div
                    className="profile_item_title"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Страна проживания:
                  </div>
                  <div>
                    <Controller
                      control={control}
                      {...register("country", { required: false })}
                      render={({ field }) => (
                        <Select
                          styles={customStyless}
                          id="favouriteAvenger"
                          {...field}
                          onChange={(e) => getCitiesByCountry(e.id)}
                          isSearchable={false}
                          placeholder={
                            auth?.data?.staff?.city?.country_name
                              ? auth?.data?.staff?.city?.country_name
                              : "Страна"
                          }
                          options={countries?.data?.results?.map(
                            (item: any) => ({
                              value: item.name,
                              label: item.name,
                              id: item.id,
                            }),
                          )}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="profile_item_item">
                  <div className="profile_item_title">Город проживания:</div>
                  <div>
                    <Controller
                      control={control}
                      {...register("city", { required: false })}
                      render={({ field }) => (
                        <Select
                          styles={customStyless}
                          isSearchable={false}
                          id="favouriteAvenger"
                          {...field}
                          placeholder={
                            auth?.data?.staff?.city?.name
                              ? auth?.data?.staff?.city?.name
                              : "Город"
                          }
                          isDisabled={cities?.data?.length !== 0 ? false : true}
                          options={cities?.data?.map((item: any) => ({
                            value: item.name,
                            label: item.name,
                            id: item.id,
                          }))}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="profile_item_item" style={{ display: "block" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="profile_item_title">Адрес проживания:</div>
                    <div>
                      <input
                        {...register("address", { required: false })}
                        type="text"
                        className="Profile__content_main-value"
                        placeholder="Пусто"
                        defaultValue={auth.data?.staff?.address}
                      />
                    </div>
                  </div>
                  <span
                    className="error"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    {auth.error?.staff?.address}
                  </span>
                </div>
              </div>
              <div className="profile_item">
                <div className="Profile__content_main-title">Другое</div>
                {
                  <div
                    className="profile_item_item"
                    style={{ display: "block" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="profile_item_title">Опыт работы:</div>
                      <div>
                        <input
                          placeholder="Не указано"
                          {...register("experience", { required: false })}
                          defaultValue={auth?.data?.staff?.experience}
                          type="number"
                          onWheel={(e) => e.currentTarget.blur()}
                          className="Profile__content_main-value"
                        />
                        {errors && (
                          <span className="error">{users.error?.email}</span>
                        )}
                      </div>
                    </div>
                    <span
                      className="error"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      {auth.error?.staff?.experience}
                    </span>
                  </div>
                }

                <div
                  className="profile_item_item"
                  style={{
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div className="profile_item_title">Знание языков:</div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "end",
                        flexDirection: "column",
                      }}
                    >
                      <Controller
                        control={control}
                        {...register("languages", { required: false })}
                        render={({ field }) => (
                          <Select
                            styles={customStyless}
                            id="favouriteAvenger"
                            isSearchable={false}
                            {...field}
                            onChange={(e) => {
                              if (
                                !languagesList.find(
                                  (item: any) => item.id === e.id,
                                )
                              ) {
                                setLanguagesList((languages: any) => [
                                  ...languages,
                                  e,
                                ])
                              }
                            }}
                            placeholder="Добавить"
                            options={
                              languages?.data?.map((item: any) => ({
                                value: item.name,
                                label: item.name,
                                id: item.id,
                              })) ||
                              languages?.data?.map((item: any) => ({
                                value: item.name,
                                label: item.name,
                                id: item.id,
                              }))
                            }
                          />
                        )}
                      />
                      {languagesList?.length !== 0 && (
                        <div
                          style={{
                            textAlign: "end",
                            display: "flex",
                            justifyContent: "end",
                            paddingTop: "10px",
                            gap: "5px",
                          }}
                        >
                          {languagesList?.map((item: any) => (
                            <div
                              onClick={() => {
                                setLanguagesList(
                                  languagesList.filter(
                                    (tem: any) => tem.id !== item.id,
                                  ),
                                )
                              }}
                              style={{
                                padding: "6px 12px",
                                background: "#83C251",
                                fontSize: "12px",
                                color: "white",
                                borderRadius: "12px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                gap: "4px",
                              }}
                            >
                              {item.value}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <span
                  className="error"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  {auth.error?.staff?.languages}
                </span>
              </div>
            </>
          </div>
        </div>
        <div className="Profile__bottom">
          <div className="Profile__content_main-btns">
            <button
              className="Profile__content_main-btns--withoutBack"
              onClick={() => {
                setIsEdit(false)
                navigate(-1)
                dispatch(setError(false))
              }}
            >
              Назад
            </button>
            <button
              onClick={handleSubmit(onSubmit)}
              className="Profile__content_main-btns--withBack"
            >
              Сохранить
            </button>
          </div>
          <div>
            <button className="button red" onClick={() => setExit(true)}>
              Выйти
            </button>
          </div>
        </div>
      </div>
      <ChangePswd setPassword={setPassword} password={password} />
      <ToastContainer />
      <ExitModal exit={exit} setExit={setExit} />
    </>
  )
}

export default Index

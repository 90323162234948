import React from "react"
import { useForm, SubmitHandler } from "react-hook-form"

import Start from "../../assets/img/Start.png"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"

import { Link, useParams } from "react-router-dom"
import { setRecoveryPassword } from "../../redux/users"

type Inputs = {
  username: string
  code: number
  password: string
}

const ResetPswd2 = () => {
  const dispatch = useAppDispatch()
  const { users } = useAppSelector((state: any) => state)
  const key = new URL(window.location.href).search.split("=").at(-1)
  console.log(users?.data)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Inputs>()
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    dispatch(setRecoveryPassword({ key: key, new_password: data?.password }))
    reset()
  }
  if (users?.data?.is_changed === true) {
    window.location.replace("/login")
  }

  return (
    <div
      className="auth"
      style={{
        backgroundImage: `url(${Start})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div
        className="form"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Восстановления пароля</h1>
          <br />

          <input
            className={users?.error ? "inputError" : "input"}
            {...register("password", { required: true })}
            type="password"
            placeholder="Новый пароль"
          />

          <br />
          {errors && (
            <span className="error">{users?.error?.new_password}</span>
          )}
          <button>Поменять</button>
        </form>
      </div>
    </div>
  )
}

export default ResetPswd2

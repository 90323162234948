import React, { useEffect, useRef, useState } from "react"
import CreatableSelect from "react-select/creatable"
import { useForm, SubmitHandler, Controller } from "react-hook-form"

import SearchBox from "../components/searchBox/index"
import { StaffArray } from "../data-test"
import StaffCard from "../components/staff"
import { GreenAddBtn } from "../components/btns"
import GraphicSliderIcon from "../assets/svg/graficSlider.svg"
import AddRolesPop from "../components/StaffPopInfo/addRolesPop"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import {
  fetchRoles,
  fetchAndSearchUsers,
  setItems,
  deleteUser,
  addItemsLast,
} from "../redux/users"
import CreateNewWorker from "../components/Forms/Staff/CreateNewWorker"
import axios from "axios"
import { API_URL } from "../api/api_config"
import { access_token } from "../services"
import { Modal } from "../components/Modal"
import { AddClient } from "../components/clients/Add"
import { setLoading } from "../redux/loading"

type RoleInputs = {
  role: any
}

const options = [
  { label: "Все", value: null, id: 0 },
  { label: "Администратор", value: "admin", id: 1 },
  { label: "Менеджер", value: "manager", id: 2 },
  { label: "Диспетчер", value: "dispatcher", id: 3 },
  { label: "Водитель", value: "driver", id: 4 },
]

const StaffPage = () => {
  const { users, auth } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()

  const [open, setOpen] = React.useState(true)
  const [onModal, setOnModal] = React.useState(false)
  const [selectValue, setSelectValue] = React.useState<any>()

  const [eventSelect, setEventSelect] = useState<any>("")

  useEffect(() => {
    dispatch(setLoading(true))

    axios
      .get(
        selectValue?.value
          ? `${API_URL}api/v1/auth/users/`
          : `${API_URL}api/v1/auth/users/?roles=admin&roles=driver&roles=manager&roles=dispatcher&roles=stock_manager`,
        {
          params: {
            search: eventSelect,
            limit: 24,
            offset: currentPage,
            is_active: true,
            roles: selectValue?.value ? selectValue?.value : null,
          },
          headers: {
            Authorization: `Token ${access_token}`,
          },
        },
      )
      .then((res: any) => {
        dispatch(setLoading(false))
        dispatch(setItems(res?.data))
        setTotalCount(res.data.count)
      })
      .catch(() => {
        dispatch(setLoading(false))
      })
  }, [selectValue])

  let timeID: any = 0

  const handleSearch = (event: any) => {
    setEventSelect(eventSelect)
    if (timeID) {
      clearTimeout(timeID)
    }
    timeID = setTimeout(() => {
      dispatch(setLoading(true))
      axios
        .get(
          selectValue?.value
          ? `${API_URL}api/v1/auth/users/`
          : `${API_URL}api/v1/auth/users/?roles=admin&roles=driver&roles=manager&roles=dispatcher&roles=stock_manager`,
          {
            params: {
              search: event,
              limit: 24,
              offset: 1,
              is_active: true,
              roles: selectValue?.value ? selectValue?.value : null,
            },
            headers: {
              Authorization: `Token ${access_token} `,
            },
          },
        )
        .then((res: any) => {
          setCurrentPage(1)
          dispatch(setItems(res?.data))
          dispatch(setLoading(false))
        })
        .finally(() => { })
    }, 500)
  }

  const {
    control,
    formState: { errors, isValid },
  } = useForm<RoleInputs>({
    mode: "onBlur",
  })

  const DeleteUser = (id: number, setModal: any) => {
    axios
      .delete(`${API_URL}api/v1/auth/users/${id}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(deleteUser(id))
        setModal(false)
      })
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px",
      cursor: "pointer",
      background: "#fff",
      marginRight: "1px",
      width: "200px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      outline: "none",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      outline: "none",

      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent",
      color: "var(--lc-black-800, #333)",
    }),
  }
  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget

    if (
      target.scrollHeight - (target.scrollTop + target.clientHeight) < 10 &&
      users?.data?.results?.length < totalCount
    ) {
      dispatch(setLoading(true))

      axios
        .get(
          selectValue?.value
          ? `${API_URL}api/v1/auth/users/`
          : `${API_URL}api/v1/auth/users/?roles=admin&roles=driver&roles=manager&roles=dispatcher&roles=stock_manager`,
          {
            params: {
              search: eventSelect,
              limit: 24,
              offset: currentPage + 1,
              is_active: true,
              roles: selectValue?.value ? selectValue?.value : null,
            },
            headers: {
              Authorization: `Token ${access_token} `,
            },
          },
        )
        .then((response: any) => {
          setCurrentPage(currentPage + 1)
          dispatch(addItemsLast(response.data.results))
        })
        .catch((er) => { })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }
  const scrollContainerRef: any = useRef()
  useEffect(() => {
    const handleWheel = (event: any) => {
      scrollContainerRef.current.scrollLeft += event.deltaY
      event.preventDefault()
    }

    const scrollContainer = scrollContainerRef.current
    scrollContainer.addEventListener("wheel", handleWheel)

    return () => {
      scrollContainer.removeEventListener("wheel", handleWheel)
    }
  }, [])

  return (
    <>
      <div className="spaceBetween">
        <div
          className="Clients__header"
          style={{
            width: "calc(100% - 400px)",
          }}
          ref={scrollContainerRef}
        >
          <div className="flex">
            <input
              className="SearchBox__input"
              type="text"
              placeholder="Поиск"
              onChange={(e) => {
                handleSearch(e.target.value)
              }}
            />
            <div className="SearchBox">
              {open ? (
                <>
                  <div className="staff_role">Роль</div>
                  <div>
                    <Controller
                      name="role"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CreatableSelect
                          {...field}
                          isSearchable={false}
                          isClearable={false}
                          isDisabled={users.loading}
                          isLoading={users.loading}
                          defaultValue={options[0]}
                          styles={customStyless}
                          options={options.map((item: any) => ({
                            value: item.value,
                            label: item.label,
                            id: item.id,
                          }))}
                          placeholder={<div>Роль...</div>}
                          formatCreateLabel={(userInput) =>
                            `Нет такой роли... ${userInput}`
                          }
                          onChange={(e) => {
                            setCurrentPage(1)
                            setSelectValue(e)
                          }}
                        />
                      )}
                    />
                  </div>
                </>
              ) : null}
              <button className="SearchBox_btn" onClick={() => setOpen(!open)}>
                <img src={GraphicSliderIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "32px",
          }}
        >
          {auth?.data?.role !== "dispatcher" && (
            <div onClick={() => setOnModal(!onModal)}>
              <GreenAddBtn title="Добавить" />
            </div>
          )}
          <div className="title">Персонал</div>
        </div>
      </div>
      <section
        className="stockWrap"
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        <div
          className="Clients__cards"
          style={{
            overflowY: "scroll",
            height: "calc(100% - 64px)",
          }}
          ref={scrollableDivRef}
          onScroll={scrollHandler}
        >
          {users?.data?.results?.map((item: any) =>
            item.role === "client" ? null : (
              <StaffCard
                DeleteUser={DeleteUser}
                dispatcher={auth?.data?.role === "dispatcher" ? true : false}
                key={item.id}
                item={item}
              />
            ),
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // height: loading ? "60vh" : 'fit-content',
              width: "100%",
              position: "absolute",
              top: "0px",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {users?.data?.results?.length !== 0 ? "" : "Пусто!"}
              </div>
            </div>
          </div>
        </div>
        <Modal
          width={"630px"}
          modal={onModal}
          title={"Создать нового сотрудника"}
          setModal={setOnModal}
          element={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "98%",
              }}
            >
              <div className="catalog">
                <AddClient
                  staff={true}
                  setModal={setOnModal}
                  manager={auth?.data?.role === "manager" ? true : false}
                />
              </div>
            </div>
          }
        />
      </section>
    </>
  )
}

export default StaffPage

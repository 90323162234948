import { useEffect, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Select from "react-select"

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import {
  addTransportationsRoute,
  getTransportation,
} from "../../../../redux/cargos/transportations"
import { fetchUserById } from "../../../../redux/users"
import { getTruckStatus } from "../../../../utils/functions"
import { editAllStocksSenderArr } from "../../../../redux/cargos/stock"

const AddProductWayInfo = ({ setModal, item }: any) => {
  const { transportations, stocks, users } = useAppSelector(
    (state: any) => state,
  )
  const dispatch = useAppDispatch()
  const { register, handleSubmit, control } = useForm()
  const [congressOrder, setCongressOrder] = useState<any>()

  useEffect(() => {
    dispatch(fetchUserById(transportations?.oneData?.user))
    setCongressOrder(transportations?.oneData?.routes?.length)
  }, [])

  const setFunc = () => {
    setModal(false)
    dispatch(
      getTransportation(item.transportation, (data: any) => {
        dispatch(
          editAllStocksSenderArr(
            data?.routes?.map((item: any) => ({
              ...item?.stock,
              routeId: item?.id,
            })),
          ),
        )
      }),
    )
  }

  const onSubmit = (data: any) => {
    dispatch(
      addTransportationsRoute(
        {
          congress: congressOrder + 1,
          stock: data?.stock?.id,
          transportation: transportations?.oneData?.id,
          date: data?.sending_day,
        },
        setFunc,
      ),
    )
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px",
      cursor: "pointer",
      background: "#fff",
      // marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent", // LC Green color on focus
      color: "var(--lc-black-800, #333)",
    }),
  }

  return (
    <div>
      <div className="EditWayModal__content_item">
        <div>Выбрать перевозки:</div>
        <button
          style={{
            width: "58.7%",
            whiteSpace: "nowrap",
            background: "#fff",
            border: "none",
          }}
          disabled={true}
        >
          <Controller
            control={control}
            {...register("city", { required: false })}
            render={({ field }) => (
              <Select
                styles={customStyless}
                {...field}
                placeholder={
                  users?.dataId?.last_name +
                  " " +
                  users?.dataId?.first_name +
                  " - " +
                  getTruckStatus(transportations?.oneData?.status)
                }
                // isDisabled={true}
                value={
                  users?.dataId?.last_name +
                  " " +
                  users?.dataId?.first_name +
                  " - " +
                  getTruckStatus(transportations?.oneData?.status)
                }
                // onMenuScrollToBottom={(e: any) => scrollHandler(e)}
                options={[]}
              />
            )}
          />
        </button>
      </div>
      <div className="EditWayModal__content_item">
        <div>Выбор склада отправки:</div>
        <div style={{ width: "58.7%" }}>
          <Controller
            control={control}
            {...register("stock", { required: true })}
            render={({ field }) => (
              <Select
                {...register("stock", { required: true })}
                styles={customStyless}
                {...field}
                placeholder={"Пусто"}
                // onMenuScrollToBottom={(e: any) => scrollHandler(e)}
                options={stocks?.data?.results?.map((item: any) => ({
                  value: item?.name,
                  label: item?.name,
                  id: item?.id,
                }))}
              />
            )}
          />
        </div>
      </div>
      <div className="EditWayModal__content_item">
        <div>День отправки:</div>
        <div style={{ width: "58.7%" }}>
          <input
            {...register("sending_day", { required: false })}
            type="date"
          />
        </div>
      </div>
      <div className="wayline"></div>

      <div className="form_button" style={{ width: "100%" }}>
        <div style={{ width: "50%" }}>
          <button className="TruckBtn2" onClick={() => setModal(false)}>
            Отмена
          </button>
        </div>
        <div style={{ width: "50%" }}>
          <button className="Green-bth" onClick={handleSubmit(onSubmit)}>
            Добавить склад
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                d="M9.08333 4.87565V1.33398L3.875 1.33398C3.52865 1.33398 3.25 1.61263 3.25 1.95898L3.25 14.0423C3.25 14.3887 3.52865 14.6673 3.875 14.6673L12.625 14.6673C12.9714 14.6673 13.25 14.3887 13.25 14.0423L13.25 5.50065H9.70833C9.36458 5.50065 9.08333 5.2194 9.08333 4.87565ZM10.7807 10.5009L9.08333 10.5009L9.08333 12.5842C9.08333 12.8145 8.89687 13.0009 8.66667 13.0009H7.83333C7.60313 13.0009 7.41667 12.8145 7.41667 12.5842L7.41667 10.5009H5.71927C5.3474 10.5009 5.16172 10.0507 5.42578 9.78841L7.93672 7.29622C8.1099 7.12409 8.38958 7.12409 8.56276 7.29622L11.0737 9.78841C11.338 10.0507 11.1526 10.5009 10.7807 10.5009ZM13.0677 4.06836L10.5182 1.51628C10.401 1.39909 10.2422 1.33398 10.0755 1.33398H9.91667V4.66732L13.25 4.66732V4.50846C13.25 4.3444 13.1849 4.18555 13.0677 4.06836Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="wayline"></div>
    </div>
  )
}

export default AddProductWayInfo

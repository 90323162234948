import React, { useEffect, useRef, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Select from "react-select"

import { GreenAddBtn, GreenBackgroundBtn } from "../../components/btns"
import SearchBox from "../../components/searchBox/index"
// import { options } from "../onways"
import Payment from "../../components/clients/payment"
import StockInfo from "../../components/AllInfoStock/index"

import GraphicSliderIcon from "../../assets/svg/graficSlider.svg"
import ClientImag from "../../assets/img/no-profile.jpeg"
import PaymentIcon from "../../assets/svg/paymentIcon.svg"
import EditIcon from "../../assets/svg/editIcon.svg"
import DoubleCheckmark from "../../assets/svg/doublecheckArr.svg"
import CashStack from "../../assets/svg/cash.svg"
import BillImg from "../../assets/img/Rectangle 252.png"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { fetchUserById, userEditById } from "../../redux/users"
import { Modal } from "../../components/Modal"
import { Menu1 } from "../../components/clients/Menu1"
import { Menu2 } from "../../components/clients/Menu2"
import { EditClient } from "../../components/clients/Edit"
import { addItemsLast, fetchCargos } from "../../redux/cargos/cargo"
import { API_URL } from "../../api/api_config"
import { setLoading } from "../../redux/loading"
import axios from "axios"
import { access_token } from "../../services"
import { fetchBalanceHistories } from "../../redux/balance"
import { addItem, setError, setItems } from "../../redux/balance-history"
import { PaymantModal } from "../../components/clientHistory/PaimentModal"
import { addReciept, fetchReseips } from "../../redux/receipts"
import { formatPhoneNumber } from "../../utils/functions"

const ClientsInfo = () => {
  const dispatch = useAppDispatch()
  const { users, auth, cargo, balanceHistory } = useAppSelector(
    (state: any) => state,
  )
  const params: any = useParams()
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    dispatch(fetchUserById(params.id))
    dispatch(
      fetchCargos({
        offset: currentPage,
        limit: 24,
        user: params.id,
      }),
    )

    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/auth/balance-histories/`, {
        params: {
          user: params?.id,
        },
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setLoading(false))
        dispatch(setError(er.response))
      })
  }, [])

  console.log(balanceHistory)

  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    if (
      target.scrollHeight - (target.scrollTop + target.clientHeight) < 1 &&
      cargo?.data?.results?.length < totalCount
    ) {
      dispatch(setLoading(true))
      axios
        .get(`${API_URL}api/v1/cargo/transports/`, {
          params: {
            limit: 24,
            offset: currentPage + 1,
            user: params.id,
          },
          headers: {
            Authorization: `Token ${access_token} `,
          },
        })
        .then((response: any) => {
          dispatch(addItemsLast(response.data.results))
        })
        .catch((er) => {})
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }

  let timeId: any = 0

  const handleSearch = (e: any) => {
    setCurrentPage(1)
    if (timeId) {
      clearTimeout(timeId)
    }
    timeId = setTimeout(() => {
      dispatch(
        fetchCargos({
          offset: currentPage,
          search: e.target.value,
        }),
      )
    }, 500)
  }

  const { avatar, first_name, last_name, id, is_confirmed } = users.dataId
  const [modal, setModal] = useState(false)

  const editUserById = (data: any, id: any) => {
    dispatch(userEditById(data, id))
    setModal(false)
  }

  const [pay, setPay] = useState(false)

  const addPayment = (data: any, setFunc: any) => {
    dispatch(addItem({ ...data }))

    dispatch(
      addReciept(data, (data: any) => {
        setFunc(data)
        setPay(false)
      }),
    )
  }
  console.log(users?.dataId)

  return (
    <>
      <div
        className="ClientsInfo"
        style={{
          height: "100%",
        }}
      >
        <div className="ClientsInfo__content" style={{ marginTop: "0px" }}>
          <div className="ClientsInfo__content_left" style={{ width: "40%" }}>
            <div className="ClientsInfo__content_left_btns">
              <div onClick={() => navigate(-1)}>
                <GreenBackgroundBtn title="Назад" />
              </div>
              {auth?.data?.role !== "dispatcher" && (
                <>
                  <button
                    onClick={() => setModal(true)}
                    className="ClientsInfo__content_left_btns-editBtn"
                  >
                    <img src={EditIcon} alt="" />
                  </button>
                  <div onClick={() => setPay(!pay)}>
                    <button className="Clients_payments">
                      <img src={PaymentIcon} alt="" />
                      Пополнение счета
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="ClientsCard__inner">
              <div className="ClientsCard__inner_image">
                <img src={avatar ? avatar : ClientImag} alt="" />
              </div>
              <div className="ClientsCard__inner_info">
                <div className="ClientsCard__inner_info-name">
                  {first_name} {last_name}
                </div>
                <div className="ClientsCard__inner_info-ids">
                  <div>
                    <div className="ClientsCard__inner_info-ids--titlesStatus">
                      Статус:{" "}
                      {is_confirmed ? (
                        <b style={{ fontWeight: "400", color: "#83C251" }}>
                          Потвержденный
                        </b>
                      ) : (
                        <b style={{ fontWeight: "400", color: "#c25151" }}>
                          Не потвержденный
                        </b>
                      )}
                    </div>
                    <div className="ClientsCard__inner_info-ids--titlesId">
                      ID: <u>{id}</u>
                    </div>
                    <div className="ClientsCard__inner_info-ids--titlesBalance">
                      Баланс:
                      <span
                        style={{
                          color: users?.dataId?.client?.balance
                            ?.toString()
                            ?.startsWith("-")
                            ? "red"
                            : "#83C251",
                        }}
                      >
                        ${users?.dataId?.client?.balance}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ClientsInfo__content_left-info">
              <div className="ClientsInfo__content_left-info--inner">
                <div>
                  Город:{" "}
                  <u>{users?.dataId?.client?.city?.name || "Не указано"}</u>
                </div>
                <div>
                  Адрес: <u>{users?.dataId?.client?.address || "Не указано"}</u>
                </div>
                <div>
                  Номер телефона:{" "}
                  <u>
                    {formatPhoneNumber(users?.dataId?.phone) || "Не указано"}
                  </u>
                </div>
                <div>
                  Количество заказов:{" "}
                  <u>{users?.dataId?.client?.orders_count}</u>
                </div>
                <div>
                  Объем перевозок клиента:{" "}
                  <u>{users?.dataId?.client_info?.volume}к.м.</u>
                </div>
                <div>
                  Ежемесячный: <u>{users?.dataId?.client_info?.monthly}к.м.</u>
                </div>
                {/* <div>
                  Частота платежей: <u>Вовремя</u>
                </div> */}
                <div>
                  Маршруты перевозок:{" "}
                  <u>
                    {users?.dataId?.client_info?.city_from?.name} -{" "}
                    {users?.dataId?.client_info?.city_to?.name}
                  </u>
                </div>
                <div>
                  Размер компании клиента:{" "}
                  <u>
                    {users?.dataId?.client_info?.company_size === "small"
                      ? "Малая"
                      : "" ||
                        users?.dataId?.client_info?.company_size === "medium"
                      ? "Средняя"
                      : "" || users?.dataId?.client_info?.company_size === "big"
                      ? "Большая"
                      : "Не указано"}
                  </u>
                </div>
                {/* <div>
                  Степень срочности доставки груза: <u>Срочно</u>
                </div> */}
                <div>
                  Предпочитаемые виды товара:
                  {users?.dataId?.client_info?.product_categories?.length >
                  0 ? (
                    users?.dataId?.client_info?.product_categories?.map(
                      (item: any) => <u>{item?.name}</u>,
                    )
                  ) : (
                    <u>Не указано</u>
                  )}
                </div>
                <div>
                  Требования к перевозкам:
                  {users?.dataId?.client_info?.transportation_assignments
                    ?.length > 0 ? (
                    users?.dataId?.client_info?.transportation_assignments?.map(
                      (item: any) => <u>{item.name}</u>,
                    )
                  ) : (
                    <u>Не указано</u>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="ClientsInfo__content_center"
            style={{ width: "100%", maxWidth: "383px" }}
          >
            <div className="ClientsInfo__content_center-inner">
              <div
                style={{ justifyContent: "center" }}
                className="ClientsInfo__content_center-title"
              >
                История баланса:
              </div>
              {/* <div className="ClientsInfo__content_center-addMoney">
                <img src={DoubleCheckmark} alt="" />
                <div>
                  <img src={CashStack} alt="" />
                  <div>Пополнения счета: +$103</div>
                </div>
              </div>
              <img
                className="ClientsInfo__content_center-img"
                src={BillImg}
                alt=""
              /> */}
              {balanceHistory?.data?.results?.map((item: any) => (
                <Payment item={item} key={item?.id}/>
              ))}
              {balanceHistory?.data?.results?.length === 0 && (
                <div style={{ textAlign: "center", paddingTop: "20px" }}>
                  Пусто
                </div>
              )}
            </div>
          </div>
          <div className="ClientsInfo__content_right" style={{ width: "100%" }}>
            <input
              style={{
                width: "calc(100% - 24px)",
                marginBottom: "20px",
                borderColor: "grey",
                maxWidth: "unset",
                minWidth: "fit-content",
              }}
              className="SearchBox__input"
              type="text"
              placeholder="Поиск"
              onChange={(e) => handleSearch(e)}
            />
            <div
              className="ClientsInfo__content_right-inner"
              onScroll={scrollHandler}
              ref={scrollableDivRef}
            >
              {cargo.data?.results?.map((item: any) => (
                <StockInfo key={item.id} item={item} />
              ))}
              {cargo.data?.results?.length === 0 && (
                <div style={{ textAlign: "center", color: "#666" }}>
                  Пока пусто!!!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={"Редактировать клиента"}
        width={"633px"}
        modal={modal}
        setModal={setModal}
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "98%",
            }}
          >
            <div className="catalog">
              <EditClient user_id={id} setModal={setModal} />
            </div>
          </div>
        }
      />

      <Modal
        height={"fit-content"}
        title={"Оплата"}
        modal={pay}
        setModal={setPay}
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div className="catalog">
              <PaymantModal addPayment={addPayment} setModal={setPay} />
            </div>
          </div>
        }
      />
    </>
  )
}

export default ClientsInfo

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import auth from "./auth/authSlice"
import tariffs from "./cargos/tariffs"
import balance from "./balance"
import balanceHistory from "./balance-history"
import users from "./users/index"
import stock from "./stocks/stokSlice"
import news from "./news/index"
import chats from "./chats/index"
import produckt from "./products/productSlice"
import sendProduct from "./sendProductArray/sendProductSlice"
import trucks from "./cargos/trucks/index"
import truckGoods from "./truckGoods/TruckGoods"
import categories from "./categories/index"
import cities from "./cargos/cities/index"
import countries from "./cargos/countries/index"
import transTypes from "./cargos/transport-types/index"
import brands from "./brands/index"
import brandModels from "./brand-models/index"
import license from "./cargos/transport-licenses"
import transConditions from "./cargos/transport-tech-conditions"
import stocks from "./cargos/stock"
import stockSchedule from "./cargos/stock-schedual"
import receipts from "./receipts/index"
import notifications from "./auth/notifications/index"
import stocksCargoSlice from "./cargos/inStock/index"
import cargo from "./cargos/cargo"
import productCategories from "./cargos/product-categories"
import transAssignments from "./cargos/transport-assigments"
import stockTypes from "./cargos/stock-types/index"
import stockService from "./cargos/stock-service/index"
import stockEquipments from "./cargos/stock-equipments"
import producktCategories from "./cargos/produckt-categories"
import languages from "./cargos/languages/index"
import loading from "./loading"
import education from "./cargos/educations"
import newsCategories from "./news/news-categories/index"
import products from "./transports-products"
import transportations from "./cargos/transportations"
import routes from "./cargos/cargo-routes"
import generalTransports from "./cargos/general-transports"
import transportatAttrib from "./cargos/transportations-atributes/index"
import cargoRecieve from './cargos/cargo-recievers/index'

export const store = configureStore({
  reducer: {
    generalTransports,
    products,
    transportations,
    routes,
    loading,
    education,
    auth,
    tariffs,
    balance,
    users,
    stock,
    news,
    chats,
    trucks,
    produckt,
    sendProduct,
    truckGoods,
    categories,
    cities,
    countries,
    transTypes,
    brands,
    brandModels,
    license,
    transConditions,
    stocks,
    stockSchedule,
    receipts,
    notifications,
    stocksCargoSlice,
    cargo,
    productCategories,
    transAssignments,
    stockTypes,
    stockService,
    stockEquipments,
    producktCategories,
    languages,
    newsCategories,
    balanceHistory,
    transportatAttrib,
    cargoRecieve,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

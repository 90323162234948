import React, { useEffect, useState } from "react"
import Select, { components } from "react-select"
import { Good_stock_zone } from "./Good_stock_zone"
import { Zone } from "./Zone"
import { ActualItem } from "./ActualItem"
import { AdditionalItem } from "./AdditionalItem"
import { DownloadModal } from "./DownloadModal"
import { StockItem } from "./StockItem"
import { StockClient } from "./StockClient"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
  addItemsLast,
  addSenderIntermediateStock,
  addSenderStock,
  clearAllStock,
  editAllStocksSenderArr,
  fetchStocks,
  setStockCar,
} from "../../redux/cargos/stock"
import { StockTruckItems } from "./StockTruckItems"
import { addItemsLast as addItemsLastUsers } from "../../redux/users"
import {
  fetchTruckByType,
  fetchTrucks,
  fetchTrucksById,
} from "../../redux/cargos/trucks"
import { fetchAllUsers } from "../../redux/users"
import { Controller, useForm } from "react-hook-form"
import {
  addCargoTransportation,
  addTransportationTruck,
  addTransportations,
  clearAll,
  editTransportationDriver,
  editTransportationTransport,
  getTransportation,
} from "../../redux/cargos/transportations"
import { useNavigate, useParams } from "react-router-dom"
import { Modal } from "../../components/Modal"
import ConnectionTrucks from "./ConnectionTrucks"
import { ToastContainer, toast } from "react-toastify"
import TruckModel1 from "../../assets/svg/TruckModel1.svg"
import TruckModel2 from "../../assets/svg/TruckModel2.svg"
import TruckModel3 from "../../assets/svg/TruckModel3.svg"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { setLoading } from "../../redux/loading"
import { fetchTransportLicenses } from "../../redux/cargos/transport-licenses"
import Assemblies from "./Assemblies"
import { fetchGeneralTransports } from "../../redux/cargos/general-transports"
import { fetchCountries } from "../../redux/cargos/countries"
import { fetchProducts } from "../../redux/transports-products"
import { fetchProductsSend } from "../../redux/products/productSlice"

// import Good_select_zone from '../../assets/img/good_select_zone.svg'

const AddGoodsPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm()
  const [modal, setModal] = useState(false)

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      padding: "2px 5px",
      borderRadius: "10px",
      cursor: "pointer",
      marginRight: "1px",
      width: "100% - 135px", // Width set to 100%
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent",
      color: "var(--lc-black-800, #333)",
    }),
  }

  const greenCustomStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      padding: "2px 40px",
      borderRadius: "4px",
      cursor: "pointer",
      marginRight: "1px",
      // width: '230px', // Width set to 100%
      backgroundColor: "#83C251",
    }),

    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent",
      color: "var(--lc-black-800, #333)",
    }),
  }

  const dispatch = useAppDispatch()
  const { id }: any = useParams()
  const {
    stocks,
    trucks,
    users,
    auth,
    transportations,
    generalTransports,
    cargo,
  } = useAppSelector((state) => state)
  const [category, setCategory] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageUsers, setCurrentPageUsers] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [generalBtn, setGeneralBtn] = useState(2)

  useEffect(() => {
    dispatch(
      fetchAllUsers({
        roles: "driver",
        limit: 24,
        offset: currentPageUsers,
      }),
    )
    dispatch(
      fetchStocks(
        {
          limit: 24,
          offset: currentPage,
        },
        (data: any) => {
          setTotalCount(data.count)
        },
      ),
    )
    !id && dispatch(fetchTrucks())
    getGenerals("on_way")

    dispatch(
      getTransportation(id, (data: any) => {
        dispatch(
          editAllStocksSenderArr(
            data?.routes?.map((item: any) => ({
              ...item?.stock,
              routeId: item?.id,
            })),
          ),
        )
      }),
    )

    !id && dispatch(clearAll([]))
    !id && dispatch(clearAllStock([]))

    dispatch(fetchCountries())
    dispatch(fetchTrucks())
    dispatch(fetchProducts())
    dispatch(fetchProductsSend())
  }, [])

  const navigate: any = useNavigate()

  const [driver, setDriver] = useState<any>(false)
  const [downloadModal, setDownloadModal] = useState(false)
  const [truckDis, setTruckDis] = useState<any>(false)
  const [additionalOpener, setAdditionalOpener] = useState(false)
  const [actualOpener, setActualOpener] = useState(false)
  const [error, setError] = useState<any>(false)

  const AddStock = (e: any) => {
    if (stocks?.stock) {
      dispatch(addSenderIntermediateStock(e.id))
    } else {
      dispatch(addSenderStock(e.id))
    }
    setValue("addStock", { id: 0, value: "Не выбрано", label: "Не выбрано" })
  }

  const getGenerals = (status: any) => {
    dispatch(
      fetchGeneralTransports(
        {
          transportation__status: status,
        },
        (data: any) => {
          if (status === "on_way") {
            setGeneralBtn(1)
          } else if (status === "planning") {
            setGeneralBtn(2)
          }
        },
      ),
    )
  }

  const addSenderStockRoom = () => {
    dispatch(
      addTransportations(
        {
          routes: stocks?.stocksSenderArr?.map((item: any, index: number) => ({
            congress: index + 1,
            stock: item.id,
          })),
          status: "planning",
          user: auth?.data?.id,
        },
        (data: any) => {
          dispatch(
            getTransportation(data.id, (data: any) => {
              dispatch(
                editAllStocksSenderArr(
                  data?.routes?.map((item: any) => ({
                    ...item?.stock,
                    routeId: item?.id,
                  })),
                ),
              )
            }),
          )
          navigate(`/stock/addgoodpage/${data?.id}/`)
        },
      ),
    )
  }

  // const selectTruck = (e: any) => {
  //   setSelectedTruck(e)
  //   dispatch(fetchTrucksById(e?.id, (data: any) => {
  //     dispatch(setStockCar(data))

  //     setValue('driver', {
  //       id: trucks?.oneData?.driver?.id,
  //       label: trucks?.oneData?.driver?.user?.first_name,
  //       value: trucks?.oneData?.driver?.user?.first_name,
  //     })
  //   }))
  // }

  const scrollHandlerUsers = (e: any) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/auth/users/`, {
        params: {
          roles: "driver",
          limit: 24,
          offset: currentPageUsers + 1,
        },
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        setCurrentPageUsers(currentPageUsers + 1)
        dispatch(addItemsLastUsers(response.data.results))

        dispatch(setLoading(false))
      })
      .catch((er) => {})
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  useEffect(() => {
    // dispatch(addTransportationTruck({}))
    // dispatch(addCargoTransportation({}))
    setError(false)

    if (category === 1) {
      dispatch(fetchTruckByType({ type: "trailer" }))
      dispatch(fetchTruckByType({ type: "head" }))
    }

    if (category === 2) {
      dispatch(fetchTruckByType({ type: "truck" }))
    }

    if (category === 3) {
      dispatch(fetchTruckByType({ type: "truck" }))
      dispatch(fetchTruckByType({ type: "semitrailer" }))
    }
  }, [category])

  const selectTruck = (e: any) => {
    dispatch(
      fetchTrucksById(e.id, (data: any) => {
        dispatch(addTransportationTruck(data))
      }),
    )
    setTruckDis(true)
  }

  const selectCargo = (e: any) => {
    dispatch(
      fetchTrucksById(e.id, (data: any) => {
        dispatch(addCargoTransportation(data))
      }),
    )
  }

  const editSelectCargo = (data: any) => {
    axios
      .patch(
        `${API_URL}api/v1/cargo/transportations/general-transports/${transportations?.oneData?.transports[0].id}/`,
        {
          driver: data.id,
        },
        {
          headers: {
            Authorization: `Token ${access_token}`,
          },
        },
      )
      .then((res1) => {
        axios
          .get(`${API_URL}api/v1/auth/driving-info/${res1.data.driver}/`, {
            headers: {
              Authorization: `Token ${access_token}`,
            },
          })
          .then((res2) => {
            dispatch(editTransportationDriver(res2.data))
            toast.success("Успешно изменён!")
          })
          .catch((er: any) => {})
      })
  }

  const onSubmit = () => {
    dispatch(setLoading(true))
    axios
      .post(
        `${API_URL}api/v1/cargo/transportations/general-transports/`,
        {
          transport_assembly:
            category === 1
              ? [
                  {
                    item: transportations?.truck?.id
                      ? transportations?.truck?.id
                      : 0,
                  },
                  {
                    item: transportations?.cargo?.id
                      ? transportations?.cargo?.id
                      : 0,
                  },
                ]
              : category === 2
              ? [
                  {
                    item: transportations?.truck?.id
                      ? transportations?.truck?.id
                      : 0,
                  },
                ]
              : category === 3
              ? [
                  {
                    item: transportations?.truck?.id
                      ? transportations?.truck?.id
                      : 0,
                  },
                  {
                    item: transportations?.cargo?.id
                      ? transportations?.cargo?.id
                      : 0,
                  },
                ]
              : null,

          current_location: stocks?.stocksSenderArr[0]?.routeId,
          driver: driver?.id ? driver?.id : null,
          transportation: id,
        },
        {
          headers: {
            Authorization: `Token ${access_token}`,
          },
        },
      )
      .then((res) => {
        dispatch(
          getTransportation(id, (data: any) => {
            dispatch(
              editAllStocksSenderArr(
                data?.routes?.map((item: any) => ({
                  ...item?.stock,
                  routeId: item?.id,
                })),
              ),
            )
          }),
        )
        dispatch(setLoading(false))
        setError(false)
        toast.success("Транспорт создан")
      })
      .catch((er: any) => {
        if (er?.request?.status === 500) {
          alert("errror 500")
        } else {
          er && setError(er.response.data)
        }
        dispatch(setLoading(false))
      })
  }

  const scrollHandler = (e: any) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/cargo/stocks/`, {
        params: {
          limit: 24,
          offset: currentPage + 1,
        },
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        setCurrentPage(currentPage + 1)
        dispatch(addItemsLast(response.data.results))
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
      .finally(() => {})
  }

  const filteredHeads = trucks.heads?.results?.filter(
    (item: any) => item?.current_transportation === null,
  )

  const filteredTrucks = trucks.trucks?.results?.filter(
    (item: any) => item?.current_transportation === null,
  )

  const filteredTrailers = trucks.trailers?.results?.filter(
    (item: any) => item?.current_transportation === null,
  )

  const filteredSemitrailers = trucks.semitrailers?.results?.filter(
    (item: any) => item?.current_transportation === null,
  )

  console.log(trucks)
  console.log(filteredHeads, filteredTrucks, filteredTrailers, filteredSemitrailers);
  

  return (
    <div className="good">
      <div className="good_inner">
        <div className="good_content">
          <div className="good_content_inner">
            <div className="good_content_item_left">
              <div className="good_content_item_left_inner">
                {!transportations?.oneData?.routes && (
                  <>
                    {stocks?.stocksSenderArr?.length !== 0 &&
                      stocks?.stocksSenderArr?.map(
                        (item: any, index: number) => (
                          <StockItem
                            index={index}
                            stock={item}
                            elem={
                              <div className="good_stock_clients">
                                {item?.senders_cargos?.map(
                                  (item: any, index: number) => (
                                    <StockClient item={item} />
                                  ),
                                )}
                                {item?.stock?.senders_cargos?.length === 0 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Пусто!
                                  </div>
                                )}
                              </div>
                            }
                          />
                        ),
                      )}
                  </>
                )}

                {transportations?.oneData?.routes?.length !== 0 && (
                  <>
                    {transportations?.oneData?.routes?.map(
                      (item: any, index: number) => (
                        <StockItem
                          route={item.id}
                          index={index}
                          stock={item.stock}
                          elem={
                            <div className="good_stock_clients">
                              {item?.stock?.senders_cargos?.map((item: any) => (
                                <StockClient item={item} />
                              ))}
                              {item?.stock?.senders_cargos?.length === 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  Пусто!
                                </div>
                              )}
                            </div>
                          }
                        />
                      ),
                    )}
                  </>
                )}
                {
                  <>
                    {!id ? (
                      <div className="good_stock_header">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            gap: "5px",
                          }}
                        >
                          {!stocks.stock ? (
                            <div className="good_stock_header_title">
                              Склада отправки
                            </div>
                          ) : (
                            ""
                          )}
                          {stocks.stock ? (
                            <div className="good_stock_header_title">
                              Склада отправки -
                            </div>
                          ) : (
                            ""
                          )}
                          {stocks.stock ? (
                            <div className="good_stock_header_title">
                              промежуточной отгрузки
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="good_stock_header_btns">
                          <Controller
                            {...register("addStock")}
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                defaultValue={{
                                  id: 0,
                                  value: "Не выбрано",
                                  label: "Не выбрано",
                                }}
                                onMenuScrollToBottom={(e: any) =>
                                  scrollHandler(e)
                                }
                                options={stocks?.data?.results?.map(
                                  (item: any) => ({
                                    id: item.id,
                                    value: item.name,
                                    label: item.name,
                                  }),
                                )}
                                placeholder="Не выбрано"
                                styles={greenCustomStyles}
                                onChange={(e) => AddStock(e)}
                              />
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                }
                {transportations?.error?.user && (
                  <div className="error">{transportations?.error?.user}</div>
                )}
                {transportations?.error?.detail && (
                  <div className="error">{transportations?.error?.detail}</div>
                )}
                {stocks?.stocksIntermediate?.length >= 1 &&
                  !transportations?.oneData && (
                    <div style={{ textAlign: "end" }}>
                      <button
                        onClick={addSenderStockRoom}
                        style={{ padding: "12px 40px" }}
                      >
                        Сохранить
                      </button>
                    </div>
                  )}
              </div>
            </div>
            <div className="good_content_right">
              <div
                className="good_content_item_center"
                style={{
                  opacity: id ? "" : "0.6",
                  height: "calc(100% - 42px)",
                }}
              >
                <div
                  className="scroll_none"
                  style={{
                    overflowY: "scroll",
                    height: "calc(100%)",
                    padding: "0px 2px",
                  }}
                >
                  {transportations?.oneData === null ||
                  transportations?.oneData?.transports?.length === 0 ? (
                    <div>
                      {category ? (
                        <div
                          className="good_select_item"
                          style={{ flexDirection: "column", gap: "2px" }}
                        >
                          <div className="good_select_item">
                            <div className="good_select_title">Водитель:</div>
                            <Select
                              onMenuScrollToBottom={(e: any) =>
                                scrollHandlerUsers(e)
                              }
                              onChange={(e) => setDriver(e)}
                              options={users?.data?.results?.map(
                                (item: any) => ({
                                  label:
                                    item?.first_name + " " + item?.last_name,
                                  value:
                                    item?.first_name + " " + item?.last_name,
                                  id: item?.driver?.id,
                                }),
                              )}
                              styles={customStyles}
                              placeholder="Не выбрано"
                            />
                          </div>
                          <div
                            className="error"
                            style={{ paddingBottom: "15px", textAlign: "end" }}
                          >
                            {error?.driver}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {!category ? (
                        <div>Выбрать тип транспорта для отправки</div>
                      ) : (
                        ""
                      )}
                      {category === 1 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          <div>
                            <div className="good_select_item">
                              <div className="good_select_title">Машина:</div>
                              <div
                                style={
                                  driver === !true
                                    ? { width: "100%" }
                                    : { width: "100%" }
                                }
                              >
                                <Select
                                  isDisabled={driver === !true ? true : false}
                                  onChange={(e) => selectTruck(e)}
                                  options={filteredHeads?.map(
                                    (item: any) => ({
                                      label: item?.number,
                                      value: item?.number,
                                      id: item?.id,
                                    }),
                                  )}
                                  styles={customStyles}
                                  placeholder="Не выбрано"
                                />
                              </div>
                            </div>

                            <div className="good_select_zone">
                              <div className="good_select_zone_items_inner">
                                <Good_stock_zone type={"head"} />
                              </div>
                            </div>
                            <div className="error">
                              {error?.transport_assembly &&
                                error?.transport_assembly[0]?.item}
                            </div>
                          </div>

                          <div>
                            <div className="good_select_item">
                              <div className="good_select_title">Прицеп:</div>
                              <div
                                style={
                                  driver === !true
                                    ? { width: "100%" }
                                    : { width: "100%" }
                                }
                              >
                                <Select
                                  isDisabled={driver === !true ? true : false}
                                  onChange={(e) => selectCargo(e)}
                                  options={filteredTrailers?.map(
                                    (item: any) => ({
                                      label: item?.number,
                                      value: item?.number,
                                      id: item?.id,
                                    }),
                                  )}
                                  styles={customStyles}
                                  placeholder="Не выбрано"
                                />
                              </div>
                            </div>

                            <div className="good_select_zone">
                              <div className="good_select_zone_items_inner">
                                <Good_stock_zone
                                  type={"trailer"}
                                  elem={
                                    <>
                                      <div className="good_select_zones">
                                        {/* <Zone number={1} />
                                                <Zone number={2} />
                                                <Zone number={3} />
                                                <Zone number={4} /> */}
                                      </div>
                                    </>
                                  }
                                />
                              </div>
                            </div>
                            <div className="error">
                              {error?.transport_assembly &&
                                error?.transport_assembly[1]?.item}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {category === 2 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          <div>
                            <div className="good_select_item">
                              <div className="good_select_title">Машина:</div>
                              <div
                                style={
                                  driver === !true
                                    ? { width: "100%" }
                                    : { width: "100%" }
                                }
                              >
                                <Select
                                  isDisabled={driver === !true ? true : false}
                                  onChange={(e) => selectTruck(e)}
                                  options={filteredTrucks?.map(
                                    (item: any) => ({
                                      label: item?.number,
                                      value: item?.number,
                                      id: item?.id,
                                    }),
                                  )}
                                  styles={customStyles}
                                  placeholder="Не выбрано"
                                />
                              </div>
                            </div>

                            <div className="good_select_zone">
                              <div className="good_select_zone_items_inner">
                                <Good_stock_zone type={"truck"} />
                              </div>
                            </div>
                            <div className="error">
                              {error?.transport_assembly &&
                                error?.transport_assembly[0]?.item}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {category === 3 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          <div>
                            <div className="good_select_item">
                              <div className="good_select_title">Машина:</div>
                              <div
                                style={
                                  driver === !true
                                    ? { width: "100%" }
                                    : { width: "100%" }
                                }
                              >
                                <Select
                                  isDisabled={driver === !true ? true : false}
                                  onChange={(e) => selectTruck(e)}
                                  options={filteredTrucks?.map(
                                    (item: any) => ({
                                      label: item?.number,
                                      value: item?.number,
                                      id: item?.id,
                                    }),
                                  )}
                                  styles={customStyles}
                                  placeholder="Не выбрано"
                                />
                              </div>
                            </div>

                            <div className="good_select_zone">
                              <div className="good_select_zone_items_inner">
                                <Good_stock_zone type={"truck"} />
                              </div>
                            </div>
                            <div className="error">
                              {error?.transport_assembly &&
                                error?.transport_assembly[0]?.item}
                            </div>
                          </div>
                          <div>
                            <div className="good_select_item">
                              <div className="good_select_title">
                                Полуприцеп:
                              </div>
                              <div
                                style={
                                  driver === !true
                                    ? { width: "100%" }
                                    : { width: "100%" }
                                }
                              >
                                <Select
                                  isDisabled={driver === !true ? true : false}
                                  onChange={(e) => selectCargo(e)}
                                  options={filteredSemitrailers?.map(
                                    (item: any) => ({
                                      label: item?.number,
                                      value: item?.number,
                                      id: item?.id,
                                    }),
                                  )}
                                  styles={customStyles}
                                  placeholder="Не выбрано"
                                />
                              </div>
                            </div>

                            <div className="good_select_zone">
                              <div className="good_select_zone_items_inner">
                                <Good_stock_zone type={"semitrailer"} />
                              </div>
                            </div>
                            <div className="error">
                              {error?.transport_assembly &&
                                error?.transport_assembly[1]?.item}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {!category && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            width: "100%",
                            gap: "10px",
                            paddingTop: "20px",
                          }}
                        >
                          <div
                            className="truckmodel"
                            onClick={(e) =>
                              id ? setCategory(1) : e.stopPropagation()
                            }
                          >
                            <img src={TruckModel1} alt="" />
                          </div>
                          <div
                            className="truckmodel"
                            onClick={(e) =>
                              id ? setCategory(2) : e.stopPropagation()
                            }
                          >
                            <img src={TruckModel2} alt="" />
                          </div>
                          <div
                            className="truckmodel"
                            onClick={(e) =>
                              id ? setCategory(3) : e.stopPropagation()
                            }
                          >
                            <img src={TruckModel3} alt="" />
                          </div>
                        </div>
                      )}
                      {error ? (
                        <div className="error">{error?.transport_assembly}</div>
                      ) : null}
                      {category ? (
                        <div
                          style={{
                            paddingTop: "10px",
                            bottom: "20px",
                            right: "20px",
                            left: "20px",
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <button
                            onClick={() => setCategory(0)}
                            style={{
                              width: "50%",
                              padding: "9px 0px",
                              color: "#8DC63F",
                              background: "white",
                              border: "1px solid #8DC63F",
                            }}
                          >
                            Отменить
                          </button>
                          <button
                            disabled={truckDis === !true ? true : false}
                            style={{ width: "50%", padding: "9px 0px" }}
                            onClick={onSubmit}
                          >
                            Сохранить
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    transportations?.oneData?.transports?.map((item: any) => (
                      <div>
                        {
                          <div
                            className="good_select_item"
                            style={{ flexDirection: "column", gap: "2px" }}
                          >
                            <div className="good_select_item">
                              <div className="good_select_title">Водитель:</div>
                              <Select
                                onMenuScrollToBottom={(e: any) =>
                                  scrollHandlerUsers(e)
                                }
                                defaultValue={{
                                  label:
                                    item?.driver?.user?.first_name +
                                    " " +
                                    item?.driver?.user?.last_name,
                                  value:
                                    item?.driver?.user?.first_name +
                                    " " +
                                    item?.driver?.user?.last_name,
                                  id: item?.driver?.user?.id,
                                }}
                                onChange={(e) => editSelectCargo(e)}
                                options={users?.data?.results?.map(
                                  (item: any) => ({
                                    label:
                                      item?.first_name + " " + item?.last_name,
                                    value:
                                      item?.first_name + " " + item?.last_name,
                                    id: item?.driver?.id,
                                  }),
                                )}
                                styles={customStyles}
                                placeholder="Не выбрано"
                              />
                            </div>
                            <div
                              className="error"
                              style={{
                                paddingBottom: "15px",
                                textAlign: "end",
                              }}
                            >
                              {error?.driver}
                            </div>
                          </div>
                        }

                        {/* <div>
                        <div className="good_select_item">
                          <div className="good_select_title">Машина:</div>
                          <Select onChange={(e) => selectTruck(e)} options={trucks?.heads?.results?.map((item: any) => ({
                            label: item?.number,
                            value: item?.number,
                            id: item?.id,
                          }))} styles={customStyles} placeholder='Не выбрано' />
                        </div>

                        <div className="good_select_zone">
                          <div className="good_select_zone_items_inner">
                            <Good_stock_zone type={'head'} />
                          </div>
                        </div>
                        <div className="error">{error?.transport_assembly && error?.transport_assembly[0]?.item}</div>
                      </div> */}

                        <Assemblies data={item?.transport_assembly} />
                      </div>
                    ))
                  )}
                  {transportations?.oneData !== null &&
                  transportations?.oneData?.transports?.length !== 0 ? (
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                      <button
                        onClick={(e: any) =>
                          transportations?.oneData?.transports?.length !== 0
                            ? setDownloadModal(true)
                            : null
                        }
                        style={{ padding: "9px 40px", marginTop: "10px" }}
                      >
                        Загрузить
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="good_content_item_right">
                <div className="good_content_item_right_btns">
                  <button
                    className={
                      generalBtn === 1
                        ? "good_content_item_right_btns_free"
                        : ""
                    }
                    onClick={() => getGenerals("planning")}
                  >
                    В сборке
                  </button>
                  <button
                    className={
                      generalBtn === 2
                        ? "good_content_item_right_btns_free"
                        : ""
                    }
                    onClick={() => getGenerals("on_way")}
                  >
                    В пути
                  </button>
                </div>

                <div className="good_content_item_right_list">
                  {generalTransports?.data?.results?.map((item: any) => (
                    <StockTruckItems item={item} />
                  ))}
                  {generalTransports?.data?.results?.length === 0 && (
                    <div style={{ textAlign: "center" }}>Пусто</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DownloadModal modal={downloadModal} setModal={setDownloadModal} />
      {/* <Modal title={'Транспорт для отправки'} width={'600px'} modal={modal} setModal={setModal} element={<ConnectionTrucks />} /> */}
    </div>
  )
}

export default AddGoodsPage

import React, { useState } from "react"

interface StarRatingProps {
  initialRating: number
}

const StarRating: React.FC<StarRatingProps> = ({ initialRating }) => {
  const [rating, setRating] = useState(initialRating)
  let totalStars = 5

  const handleStarClick = (clickedStar: number) => {
    setRating(clickedStar)
  }

  return (
    <div>
      {[...Array(totalStars)].map((_, index) => (
        <span
          key={index}
          style={{
            cursor: "pointer",
            fontSize: "12px",
            color: rating <= index ? "grey" : "gold",
          }}
        >
          ★
        </span>
      ))}
    </div>
  )
}

export default StarRating

import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { fetchproductCategories } from "../../../redux/cargos/product-categories"
import { fetchTransportAssignments } from "../../../redux/cargos/transport-assigments"
import {
  fetchCities,
  fetchCitiesFrom,
  fetchCitiesTo,
  getItems,
  getItemsFrom,
  getItemsTo,
} from "../../../redux/cargos/cities"
import { fetchCountries } from "../../../redux/cargos/countries"
import { fetchTariffs } from "../../../redux/cargos/tariffs"
import { addUser, setError } from "../../../redux/users"
import { fetchLanguages } from "../../../redux/cargos/languages"
import { fetchEducations } from "../../../redux/cargos/educations"
type Props = {
  staff: any
  setModal: any
  filteredItems?: any
  setFilteredItems?: any
  manager?: any
  active?: any
}
export function getMinDate() {
  const minDate = new Date()
  minDate.setFullYear(minDate.getFullYear() - 50)
  return minDate.toISOString().split("T")[0]
}

export function getCurrentDate() {
  const currentDate = new Date()
  return currentDate.toISOString().split("T")[0]
}
export const AddClient = ({ staff, setModal, manager, active }: Props) => {
  const {
    users,
    productCategories,
    transAssignments,
    cities,
    countries,
    tariffs,
    languages,
    education,
  } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm()

  const [choosedWorker, setChoosedWorker] = useState<any>()
  const [menu1, setMenu1] = useState(true)
  const [menu2, setMenu2] = useState(true)
  const [avatar, setAvatar] = useState<any>()
  const [avatarShow, setAvatarShow] = useState<any>()
  const [fullName, setFullName] = useState<any>()

  const workers = [
    { value: "admin", label: "Администратор", id: 1 },
    { value: "manager", label: "Менеджер", id: 2 },
    { value: "driver", label: "Водитель", id: 4 },
    { value: "dispatcher", label: "Диспетчер", id: 5 },
  ]

  const [languagesList, setLanguagesList] = useState<any>([])

  const workersManager = [{ value: "driver", label: "Водитель", id: 4 }]

  const handleAvatar = (e: any) => {
    setAvatar(e.target.files[0])
    setAvatarShow(URL.createObjectURL(e.target.files[0]))
  }

  const setFunc = () => {
    setModal(false)
    reset()
  }

  const handleInputChange = (e: any) => {
    const value = e.target.value
    setFullName(value.split(" "))
  }

  useEffect(() => {
    dispatch(fetchproductCategories())
    dispatch(fetchTransportAssignments())
    dispatch(fetchCountries())
    dispatch(fetchTariffs())
    dispatch(fetchLanguages())
    dispatch(fetchEducations())

    dispatch(getItemsFrom([]))
    dispatch(getItemsTo([]))
    dispatch(getItems([]))
  }, [])

  const getCitiesByCountryFrom = (id: number) => {
    dispatch(fetchCitiesFrom(id))
  }

  const getCitiesByCountryTo = (id: number) => {
    dispatch(fetchCitiesTo(id))
  }

  const getCitiesByCountry = (id: number) => {
    dispatch(fetchCities(id))
  }

  const onSubmit = (data: any) => {
    dispatch(
      addUser(
        {
          staff: staff
            ? {
                passport: data?.passport,
                date_of_birth: data?.date_of_birth || null,
                address: data?.address,
                experience: data?.experience || null,
                citizen: data?.citizen?.id,
                city: data?.city?.id,
                languages: languagesList?.map((item: any) => item.id),
              }
            : null,
          driver:
            staff && choosedWorker?.value === "driver"
              ? {
                  date_license: data?.date_license || null,
                  validity_license: data?.validity_license || null,
                  experience: data?.experience_driver || null,
                  experience_as_truck_driver:
                    data?.experience_as_truck_driver || null,
                  license_external_transportation:
                    data?.license_external_transportation || false,
                  info_criminal_record_or_fines:
                    data?.info_criminal_record_or_fines || false,
                  license_country: data?.license_country?.id || null,
                  education: data?.education?.id || null,
                  transport: data?.transport || null,
                }
              : null,
          client: staff
            ? null
            : {
                balance: data?.balance ? data?.balance : 0,
                address: data?.address,
                orders_count: data?.orders_count ? data?.orders_count : 0,
                city: data?.city?.id,
                tariff: data?.tariff?.id ? data?.tariff?.id : null,
              },
          client_info: staff
            ? null
            : {
                volume: data?.volume ? data?.volume : 0,
                daily: data?.daily ? data?.daily : 0,
                weekly: data?.weekly ? data?.weekly : 0,
                monthly: data?.monthly ? data?.monthly : 0,
                company_size: data?.company_size?.value
                  ? data?.company_size?.value
                  : null,
                city_from: data?.city_from?.id ? data?.city_from?.id : null,
                city_to: data?.city_to?.id ? data?.city_to?.id : null,
                product_categories:
                  categoriesList.length !== 0
                    ? categoriesList?.map((item: any) => item.id)
                    : [],
                transportation_assignments:
                  assigmentsList.length !== 0
                    ? assigmentsList?.map((item: any) => item.id)
                    : [],
              },
          password:
            staff && choosedWorker?.value === "driver"
              ? "254668kg"
              : data?.password,
          is_superuser: staff
            ? choosedWorker?.value === "admin" && true
            : false,
          first_name: fullName?.[1] ? fullName?.[1] : null,
          last_name: fullName?.[0] ? fullName?.[0] : null,
          is_staff: staff,
          is_active: true,
          phone: data?.phone,
          email: data?.email || null,
          role: staff ? choosedWorker?.value : "client",
          is_confirmed: data?.is_confirmed,
          uuid: data?.uuid,
        },
        avatar,
        setFunc,
        active,
        staff,
      ),
    )
  }

  const customStyless = {
    control: (provided: any, state: any) => ({
      ...provided,
      padding: "2px 5px",
      borderRadius: "10px",
      cursor: "pointer",
      marginRight: "1px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent",
      color: "var(--lc-black-800, #333)",
    }),
  }

  const Cancel = () => {
    setModal(false)
    dispatch(setError(false))
    reset()
  }
  const [categoriesList, setCategoriesList] = useState<any>([])
  const [assigmentsList, setAssigmentsList] = useState<any>([])

  const companySize = [
    { id: 1, label: "Малая", value: "small" },
    { id: 2, label: "Средняя", value: "medium" },
    { id: 3, label: "Большая", value: "big" },
  ]

  console.log(users?.error)

  return (
    <>
      {staff && (
        <>
          <div className="catalog_category_block">
            <div>
              Выберите роль <u className="redStar">*</u>
              <div
                className="error"
                style={{
                  color: "red",
                }}
              >
                {users?.error?.role}
              </div>
            </div>
          </div>
          <div
            className="catalog_category_block"
            style={{
              justifyContent: "space-around",
              flexWrap: "wrap",
              rowGap: "20px",
              columnGap: "50px",
            }}
          >
            {!manager &&
              workers.map((worker: any) => (
                <div
                  onClick={() => setChoosedWorker(worker)}
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <input
                    className="custom-checkbox"
                    checked={choosedWorker?.id === worker?.id ? true : false}
                    style={{
                      width: "15px",
                      cursor: "pointer",
                      borderRadius: "15px",
                      padding: "9px",
                      borderColor: "#83C251",
                    }}
                    type="radio"
                  />
                  <div>{worker.label}</div>
                </div>
              ))}
            {manager &&
              workersManager.map((worker: any) => (
                <div
                  onClick={() => setChoosedWorker(worker)}
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <input
                    className="custom-checkbox"
                    checked={choosedWorker?.id === worker?.id ? true : false}
                    style={{
                      width: "15px",
                      cursor: "pointer",
                      borderRadius: "15px",
                      padding: "9px",
                      borderColor: "#83C251",
                    }}
                    type="radio"
                  />
                  <div>{worker.label}</div>
                </div>
              ))}
          </div>
        </>
      )}
      {staff === false || choosedWorker?.id ? (
        <div className={menu1 ? "catalog_list" : ""}>
          <div
            className={"catalog_category_block"}
            onClick={() => setMenu1(!menu1)}
          >
            <div>Основные</div>
            <svg
              className={menu1 ? "reverse" : ""}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
                fill="#1A1A1A"
              />
            </svg>
          </div>

          <div
            className={menu1 ? "catalog_items" : "none"}
            style={{
              paddingBottom: "20px",
            }}
          >
            <div className="catalog_item">
              <div>Статус:</div>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  width: "56.7%",
                }}
              >
                <input
                  className="custom-checkbox"
                  placeholder="Пусто"
                  {...register("is_confirmed", { required: false })}
                  style={{
                    width: "15px",
                    cursor: "pointer",
                    borderRadius: "0px",
                    padding: "9px",
                    borderColor: "#83C251",
                  }}
                  type="checkbox"
                  id="status_label"
                />
                <label
                  style={{
                    cursor: "pointer",
                  }}
                  htmlFor="status_label"
                >
                  Подтвержденный
                </label>
              </div>
            </div>
            <div
              className="catalog_item"
              style={{ alignItems: "start", marginTop: "10px" }}
            >
              <div>Фото:</div>
              <div style={{ width: "56.7%" }}>
                <input
                  onChange={(e) => handleAvatar(e)}
                  type="file"
                  id="avatar"
                  hidden
                  accept="image/png, image/jpeg, image/webp, image/svg+xml"
                />

                <label
                  htmlFor="avatar"
                  style={{
                    // width: "56.7%",
                    height: "184px",
                    border: "1px solid #CCC",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    objectFit: "cover",
                  }}
                >
                  {avatarShow ? null : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                    >
                      <path
                        d="M14.4167 2.66675L18.5834 2.66675C19.2761 2.66675 19.8334 3.22404 19.8334 3.91675V12.6667H24.4011C25.3282 12.6667 25.7917 13.7865 25.1355 14.4428L17.2136 22.3699C16.823 22.7605 16.1824 22.7605 15.7917 22.3699L7.85946 14.4428C7.20321 13.7865 7.66675 12.6667 8.59383 12.6667L13.1667 12.6667L13.1667 3.91675C13.1667 3.22404 13.724 2.66675 14.4167 2.66675ZM29.8334 22.2501V28.0834C29.8334 28.7761 29.2761 29.3334 28.5834 29.3334L4.41675 29.3334C3.72404 29.3334 3.16675 28.7761 3.16675 28.0834L3.16675 22.2501C3.16675 21.5574 3.72404 21.0001 4.41675 21.0001H12.0574L14.6095 23.5522C15.6563 24.599 17.3438 24.599 18.3907 23.5522L20.9428 21.0001H28.5834C29.2761 21.0001 29.8334 21.5574 29.8334 22.2501ZM23.3751 26.8334C23.3751 26.2605 22.9063 25.7917 22.3334 25.7917C21.7605 25.7917 21.2917 26.2605 21.2917 26.8334C21.2917 27.4063 21.7605 27.8751 22.3334 27.8751C22.9063 27.8751 23.3751 27.4063 23.3751 26.8334ZM26.7084 26.8334C26.7084 26.2605 26.2397 25.7917 25.6667 25.7917C25.0938 25.7917 24.6251 26.2605 24.6251 26.8334C24.6251 27.4063 25.0938 27.8751 25.6667 27.8751C26.2397 27.8751 26.7084 27.4063 26.7084 26.8334Z"
                        fill="#999999"
                      />
                    </svg>
                  )}
                  {avatarShow ? (
                    <img
                      src={avatarShow ? avatarShow : ""}
                      style={{
                        width: "100%",
                        height: "184px",
                        cursor: "pointer",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                      alt=""
                    />
                  ) : null}
                </label>

                <div className="error">{users?.error?.avatar}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                UID: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("uuid", { required: false })}
                  type="text"
                />
                <div className="error">{users?.error?.uuid}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>
                ФИО: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="Пусто"
                  onChange={(e: any) => handleInputChange(e)}
                  type="text"
                />
                <div className="error">
                  {users?.error?.first_name || users?.error?.last_name}
                </div>
              </div>
            </div>

            <div className="catalog_item">
              <div>Email:</div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("email", { required: false })}
                  type="text"
                />
                <div className="error">{users?.error?.email}</div>
              </div>
            </div>
            {staff && choosedWorker?.value === "driver" ? null : (
              <div className="catalog_item">
                <div>
                  Пароль: <u className="redStar">*</u>
                </div>
                <div
                  style={{
                    width: "57.1%",
                  }}
                >
                  <input
                    placeholder="Пусто"
                    {...register("password", { required: false })}
                    type="text"
                  />
                  <div className="error">{users?.error?.password}</div>
                </div>
              </div>
            )}

            <div className="catalog_item">
              <div>
                Номер телефона: <u className="redStar">*</u>
              </div>
              <div
                style={{
                  width: "57.1%",
                }}
              >
                <input
                  placeholder="+996 *** ** ** **"
                  {...register("phone", { required: false })}
                  type="text"
                />
                <div className="error">{users?.error?.phone}</div>
              </div>
            </div>
            {
              <div className="catalog_item">
                <div>Адрес:</div>
                <div
                  style={{
                    width: "57.1%",
                  }}
                >
                  <input
                    placeholder="Пусто"
                    {...register("address", { required: false })}
                    type="text"
                  />
                  <div className="error">{users?.error?.client?.address}</div>
                  <div className="error">{users?.error?.staff?.address}</div>
                </div>
              </div>
            }

            {
              <div
                className="catalog_item"
                style={{
                  alignItems: "start",
                }}
              >
                <div>Город:</div>
                <div
                  style={{
                    width: "57%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <Controller
                    control={control}
                    {...register("country_from", { required: false })}
                    render={({ field }) => (
                      <Select
                        styles={customStyless}
                        id="favouriteAvenger"
                        {...field}
                        onChange={(e) => getCitiesByCountry(e.id)}
                        placeholder="Страна"
                        options={countries?.data?.results?.map((item: any) => ({
                          value: item.name,
                          label: item.name,
                          id: item.id,
                        }))}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    {...register("city", { required: false })}
                    render={({ field }) => (
                      <Select
                        styles={customStyless}
                        id="favouriteAvenger"
                        {...field}
                        placeholder="Город"
                        isDisabled={cities?.data?.length !== 0 ? false : true}
                        options={cities?.data?.map((item: any) => ({
                          value: item.name,
                          label: item.name,
                          id: item.id,
                        }))}
                      />
                    )}
                  />
                  <div className="error">{users?.error?.staff?.city}</div>
                </div>
              </div>
            }
          </div>

          {staff ? (
            <div>
              <div
                className="catalog_category_block"
                onClick={() => setMenu2(!menu2)}
              >
                <div>Другие</div>
                <svg
                  className={menu2 ? "reverse" : ""}
                  // onClick={() => setMenu2(!menu2)}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
                    fill="#1A1A1A"
                  />
                </svg>
              </div>
              {staff && choosedWorker?.value === "driver" ? (
                <div>
                  <div className={menu2 ? "catalog_items" : "none"}>
                    <div className="catalog_item">
                      <div>Дата лицензии:</div>
                      <div
                        style={{
                          width: "57.1%",
                        }}
                      >
                        <input
                          placeholder="0к.м"
                          onWheel={(e) => e.currentTarget.blur()}
                          {...register("date_license", { required: false })}
                          type="date"
                        />
                        <div className="error">
                          {users?.error?.driver?.date_license}
                        </div>
                      </div>
                    </div>

                    <div className="catalog_item">
                      <div>Срок лицензии:</div>
                      <div
                        style={{
                          width: "57.1%",
                        }}
                      >
                        <input
                          placeholder="0к.м"
                          onWheel={(e) => e.currentTarget.blur()}
                          {...register("validity_license", { required: false })}
                          type="date"
                        />
                        <div className="error">
                          {users?.error?.driver?.validity_license}
                        </div>
                      </div>
                    </div>

                    <div className="catalog_item">
                      <div>Опыт работы:</div>
                      <div
                        style={{
                          width: "57.1%",
                        }}
                      >
                        <input
                          placeholder="Пусто"
                          {...register("experience_driver", {
                            required: false,
                          })}
                          type="text"
                        />
                        <div className="error">
                          {users?.error?.driver?.experience}
                        </div>
                      </div>
                    </div>
                    <div className="catalog_item">
                      <div>Опыт работы как водитель:</div>
                      <div
                        style={{
                          width: "57.1%",
                        }}
                      >
                        <input
                          placeholder="Пусто"
                          {...register("experience_as_truck_driver", {
                            required: false,
                          })}
                          type="text"
                        />
                        <div className="error">
                          {users?.error?.driver?.experience_as_truck_driver}
                        </div>
                      </div>
                    </div>

                    <div className="catalog_item">
                      <div>Лицензия на внешние перевозки:</div>
                      <div
                        style={{
                          width: "57.1%",
                        }}
                      >
                        <div
                          // onClick={() => setChoosedWorker(worker)}
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            padding: "10px 0px",
                          }}
                        >
                          <input
                            className="custom-checkbox"
                            {...register("license_external_transportation")}
                            style={{
                              width: "15px",
                              cursor: "pointer",
                              borderRadius: "15px",
                              padding: "9px",
                              borderColor: "#83C251",
                            }}
                            id="licence_driver"
                            type="checkbox"
                          />

                          <label
                            htmlFor="licence_driver"
                            style={{ cursor: "pointer" }}
                          >
                            Лицензия
                          </label>
                        </div>
                        <div className="error">
                          {
                            users?.error?.driver
                              ?.license_external_transportation
                          }
                        </div>
                      </div>
                    </div>
                    <div className="catalog_item">
                      <div>Информация о судимости или штрафах:</div>
                      <div
                        style={{
                          width: "57.1%",
                        }}
                      >
                        <div
                          // onClick={() => setChoosedWorker(worker)}
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            padding: "10px 0px",
                          }}
                        >
                          <input
                            className="custom-checkbox"
                            {...register("info_criminal_record_or_fines")}
                            style={{
                              width: "15px",
                              cursor: "pointer",
                              borderRadius: "15px",
                              padding: "9px",
                              borderColor: "#83C251",
                            }}
                            id="licence_driver2"
                            type="checkbox"
                          />

                          <label
                            htmlFor="licence_driver2"
                            style={{ cursor: "pointer" }}
                          >
                            Штраф
                          </label>
                        </div>
                        <div className="error">
                          {users?.error?.driver?.info_criminal_record_or_fines}
                        </div>
                      </div>
                    </div>

                    <div
                      className="catalog_item"
                      style={{
                        alignItems: "start",
                      }}
                    >
                      <div>Страна лицензии:</div>
                      <div
                        style={{
                          width: "57%",
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <Controller
                          control={control}
                          {...register("license_country", { required: false })}
                          render={({ field }) => (
                            <Select
                              styles={customStyless}
                              id="favouriteAvenger"
                              {...field}
                              placeholder="Страна"
                              options={countries?.data?.results?.map(
                                (item: any) => ({
                                  value: item.name,
                                  label: item.name,
                                  id: item.id,
                                }),
                              )}
                            />
                          )}
                        />
                        <div className="error">
                          {users?.error?.driver?.license_country}
                        </div>
                      </div>
                    </div>

                    <div
                      className="catalog_item"
                      style={{
                        alignItems: "start",
                      }}
                    >
                      <div>Образование:</div>
                      <div
                        style={{
                          width: "57%",
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <Controller
                          control={control}
                          {...register("education", { required: false })}
                          render={({ field }) => (
                            <Select
                              styles={customStyless}
                              id="favouriteAvenger"
                              {...field}
                              placeholder="Образование"
                              options={education?.data?.map((item: any) => ({
                                value: item.name,
                                label: item.name,
                                id: item.id,
                              }))}
                            />
                          )}
                        />
                        <div className="error">
                          {users?.error?.driver?.education}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={menu2 ? "catalog_items" : "none"}>
                <div
                  className="catalog_item"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <div>Гражданство:</div>
                  <div
                    style={{
                      width: "57%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Controller
                      control={control}
                      {...register("citizen", { required: false })}
                      render={({ field }) => (
                        <Select
                          styles={customStyless}
                          id="favouriteAvenger"
                          {...field}
                          placeholder="Страна"
                          options={countries?.data?.results?.map(
                            (item: any) => ({
                              value: item.name,
                              label: item.name,
                              id: item.id,
                            }),
                          )}
                        />
                      )}
                    />
                    <div className="error">{users?.error?.staff?.citizen}</div>
                  </div>
                </div>

                <div
                  className="catalog_item"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <div>Знание языков:</div>
                  <div
                    style={{
                      width: "57%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Controller
                      control={control}
                      {...register("languages", { required: false })}
                      render={({ field }) => (
                        <Select
                          styles={customStyless}
                          id="favouriteAvenger"
                          {...field}
                          placeholder="Выбрать"
                          onChange={(e) => {
                            if (
                              !languagesList.find(
                                (item: any) => item.id === e.id,
                              )
                            ) {
                              setLanguagesList((languages: any) => [
                                ...languages,
                                e,
                              ])
                            }
                          }}
                          options={languages?.data?.map((item: any) => ({
                            value: item.name,
                            label: item.name,
                            id: item.id,
                          }))}
                        />
                      )}
                    />
                    <div className="error">
                      {users?.error?.staff?.languages}
                    </div>
                  </div>
                </div>

                {languagesList?.length !== 0 && (
                  <div
                    style={{
                      textAlign: "end",
                      display: "flex",
                      justifyContent: "end",
                      gap: "5px",
                    }}
                  >
                    {languagesList?.map((item: any) => (
                      <div
                        onClick={() => {
                          setLanguagesList(
                            languagesList.filter(
                              (tem: any) => tem.id !== item.id,
                            ),
                          )
                        }}
                        style={{
                          padding: "6px 12px",
                          background: "#83C251",
                          fontSize: "12px",
                          color: "white",
                          borderRadius: "12px",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: "4px",
                        }}
                      >
                        {item.value}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    ))}
                  </div>
                )}

                <div className="catalog_item">
                  <div>Паспорт:</div>
                  <div
                    style={{
                      width: "57.1%",
                    }}
                  >
                    <input
                      placeholder="Пусто"
                      {...register("passport", { required: false })}
                      type="text"
                    />
                    <div className="error">{users?.error?.staff?.passport}</div>
                  </div>
                </div>
                <div className="catalog_item">
                  <div>Дата рождения:</div>
                  <div
                    style={{
                      width: "57.1%",
                    }}
                  >
                    <input
                      placeholder="0к.м"
                      onWheel={(e) => e.currentTarget.blur()}
                      {...register("date_of_birth", { required: false })}
                      type="date"
                      min={getMinDate()}
                      max={getCurrentDate()}
                    />
                    <div className="error">
                      {users?.error?.staff?.date_of_birth}
                    </div>
                  </div>
                </div>
                {choosedWorker?.value !== "driver" && (
                  <div className="catalog_item">
                    <div>Опыт работы:</div>
                    <div
                      style={{
                        width: "57.1%",
                      }}
                    >
                      <input
                        placeholder="Пусто"
                        {...register("experience", { required: false })}
                        type="text"
                      />
                      <div className="error">
                        {users?.error?.staff?.experience}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {!staff ? (
            <div>
              <div
                className="catalog_category_block"
                onClick={() => setMenu2(!menu2)}
              >
                <div>Другие</div>
                <svg
                  className={menu2 ? "reverse" : ""}
                  // onClick={() => setMenu2(!menu2)}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
                    fill="#1A1A1A"
                  />
                </svg>
              </div>
              <div className={menu2 ? "catalog_items" : "none"}>
                <div className="catalog_item">
                  <div>Объем перевозок клиента:</div>
                  <div
                    style={{
                      width: "57.1%",
                    }}
                  >
                    <input
                      placeholder="0к.м"
                      onWheel={(e) => e.currentTarget.blur()}
                      {...register("volume", { required: false })}
                      type="number"
                    />
                    <div className="error">
                      {users?.error?.client_info?.volume}
                    </div>
                  </div>
                </div>

                <div className="catalog_item">
                  <div>Ежедневный:</div>
                  <div
                    style={{
                      width: "57.1%",
                    }}
                  >
                    <input
                      placeholder="0к.м"
                      onWheel={(e) => e.currentTarget.blur()}
                      {...register("daily", { required: false })}
                      type="number"
                    />
                    <div className="error">
                      {users?.error?.client_info?.daily}
                    </div>
                  </div>
                </div>

                <div className="catalog_item">
                  <div>Еженедельный:</div>
                  <div
                    style={{
                      width: "57.1%",
                    }}
                  >
                    <input
                      placeholder="0к.м"
                      onWheel={(e) => e.currentTarget.blur()}
                      {...register("weekly", { required: false })}
                      type="number"
                    />
                    <div className="error">
                      {users?.error?.client_info?.weekly}
                    </div>
                  </div>
                </div>

                <div className="catalog_item">
                  <div>Ежемесячный:</div>
                  <div
                    style={{
                      width: "57.1%",
                    }}
                  >
                    <input
                      placeholder="0к.м"
                      onWheel={(e) => e.currentTarget.blur()}
                      {...register("monthly", { required: false })}
                      type="number"
                    />
                    <div className="error">
                      {users?.error?.client_info?.monthly}
                    </div>
                  </div>
                </div>

                <div
                  className="catalog_item"
                  style={{
                    alignItems: "start",
                  }}
                >
                  <div>Маршруты перевозок от:</div>
                  <div
                    style={{
                      width: "57%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Controller
                      control={control}
                      {...register("country_from", { required: false })}
                      render={({ field }) => (
                        <Select
                          styles={customStyless}
                          id="favouriteAvenger"
                          {...field}
                          onChange={(e) => getCitiesByCountryFrom(e.id)}
                          placeholder="Страна"
                          options={countries?.data?.results?.map(
                            (item: any) => ({
                              value: item.name,
                              label: item.name,
                              id: item.id,
                            }),
                          )}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      {...register("city_from", { required: false })}
                      render={({ field }) => (
                        <Select
                          styles={customStyless}
                          id="favouriteAvenger"
                          {...field}
                          placeholder="Город"
                          isDisabled={
                            cities?.data_from?.length !== 0 ? false : true
                          }
                          options={cities?.data_from?.map((item: any) => ({
                            value: item.name,
                            label: item.name,
                            id: item.id,
                          }))}
                        />
                      )}
                    />

                    <div className="error">
                      {users?.error?.client_info?.city_from}
                    </div>
                  </div>
                </div>

                <div
                  className="catalog_item"
                  style={{
                    alignItems: "start",
                  }}
                >
                  <div>Маршруты перевозок до:</div>
                  <div
                    style={{
                      width: "57%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Controller
                      control={control}
                      {...register("country_from", { required: false })}
                      render={({ field }) => (
                        <Select
                          styles={customStyless}
                          id="favouriteAvenger"
                          {...field}
                          onChange={(e) => getCitiesByCountryTo(e.id)}
                          placeholder="Страна"
                          options={countries?.data?.results?.map(
                            (item: any) => ({
                              value: item.name,
                              label: item.name,
                              id: item.id,
                            }),
                          )}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      {...register("city_to", { required: false })}
                      render={({ field }) => (
                        <Select
                          styles={customStyless}
                          id="favouriteAvenger"
                          {...field}
                          placeholder="Город"
                          isDisabled={
                            cities?.data_to?.length !== 0 ? false : true
                          }
                          options={cities?.data_to?.map((item: any) => ({
                            value: item.name,
                            label: item.name,
                            id: item.id,
                          }))}
                        />
                      )}
                    />
                    <div className="error">
                      {users?.error?.client_info?.city_to}
                    </div>
                  </div>
                </div>

                <div className="catalog_item">
                  <div>Размер компании клиента:</div>
                  <div
                    style={{
                      width: "57%",
                    }}
                  >
                    <Controller
                      control={control}
                      {...register("company_size", { required: false })}
                      render={({ field }) => (
                        <Select
                          styles={customStyless}
                          id="favouriteAvenger"
                          {...field}
                          placeholder="Пусто"
                          options={companySize?.map((item: any) => ({
                            value: item.value,
                            label: item.label,
                            id: item.id,
                          }))}
                        />
                      )}
                    />
                    <div className="error">
                      {users?.error?.client_info?.company_size}
                    </div>
                  </div>
                </div>
                <div className="catalog_item">
                  <div
                    style={{ display: "flex", flexWrap: "wrap", width: "32%" }}
                  >
                    <div>Предпочитаемые виды товаров:</div>
                  </div>
                  <div
                    style={{
                      width: "57%",
                    }}
                  >
                    <Controller
                      control={control}
                      {...register("product_categories", { required: false })}
                      render={({ field }) => (
                        <Select
                          styles={customStyless}
                          id="favouriteAvenger"
                          {...field}
                          placeholder="Выбрать"
                          onChange={(e) => {
                            if (
                              !categoriesList.find(
                                (item: any) => item.id === e.id,
                              )
                            ) {
                              setCategoriesList((categories: any) => [
                                ...categories,
                                e,
                              ])
                            }
                          }}
                          options={productCategories?.data?.map(
                            (item: any) => ({
                              value: item.name,
                              label: item.name,
                              id: item.id,
                            }),
                          )}
                        />
                      )}
                    />
                    <div className="error">
                      {users?.error?.client_info?.product_categories}
                    </div>
                  </div>
                </div>
                {categoriesList?.length !== 0 && (
                  <div
                    style={{
                      textAlign: "end",
                      display: "flex",
                      justifyContent: "end",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    {categoriesList?.map((item: any) => (
                      <div
                        onClick={() => {
                          setCategoriesList(
                            categoriesList.filter(
                              (tem: any) => tem.id !== item.id,
                            ),
                          )
                        }}
                        style={{
                          padding: "6px 12px",
                          background: "#83C251",
                          fontSize: "12px",
                          color: "white",
                          borderRadius: "12px",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: "4px",
                        }}
                      >
                        {item.value}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    ))}
                  </div>
                )}
                <div className="catalog_item">
                  <div>Требования к перевозкам:</div>
                  <div
                    style={{
                      width: "57%",
                    }}
                  >
                    <Controller
                      control={control}
                      {...register("transportation_assignments", {
                        required: false,
                      })}
                      render={({ field }) => (
                        <Select
                          styles={customStyless}
                          id="favouriteAvenger"
                          {...field}
                          placeholder="Выбрать"
                          onChange={(e) => {
                            if (
                              !assigmentsList.find(
                                (item: any) => item.id === e.id,
                              )
                            ) {
                              setAssigmentsList((assigment: any) => [
                                ...assigment,
                                e,
                              ])
                            }
                          }}
                          options={transAssignments?.data?.results?.map(
                            (item: any) => ({
                              value: item.name,
                              label: item.name,
                              id: item.id,
                            }),
                          )}
                        />
                      )}
                    />
                    <div className="error">
                      {users?.error?.client_info?.transportation_assignments}
                    </div>
                  </div>
                </div>
                {assigmentsList?.length !== 0 && (
                  <div
                    style={{
                      textAlign: "end",
                      display: "flex",
                      justifyContent: "end",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    {assigmentsList?.map((item: any) => (
                      <div
                        onClick={() => {
                          setAssigmentsList(
                            assigmentsList.filter(
                              (tem: any) => tem.id !== item.id,
                            ),
                          )
                        }}
                        style={{
                          padding: "6px 12px",
                          background: "#83C251",
                          fontSize: "12px",
                          color: "white",
                          borderRadius: "12px",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: "4px",
                        }}
                      >
                        {item.value}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    ))}
                  </div>
                )}

                <div className="catalog_item">
                  <div>Тариф:</div>
                  <div
                    style={{
                      width: "57%",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Controller
                      control={control}
                      {...register("tariff", { required: false })}
                      render={({ field }) => (
                        <Select
                          styles={customStyless}
                          id="favouriteAvenger"
                          {...field}
                          placeholder="Пусто"
                          options={tariffs?.data?.map((item: any) => ({
                            value: item.name,
                            label: item.name,
                            id: item.id,
                          }))}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="catalog_item">
                  <div>Баланс:</div>
                  <div
                    style={{
                      width: "57.1%",
                    }}
                  >
                    <input
                      placeholder="Пусто"
                      {...register("balance", { required: false })}
                      type="number"
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                    <div className="error">{users?.error?.client?.balance}</div>
                  </div>
                </div>
                {/* <div className="catalog_item">
              <div>Имя: <u className="redStar">*</u></div>
              <div style={{
                width: "57.1%",
              }}>

                <input
                  placeholder="Пусто"
                  {...register("first_name", { required: false })}
                  type="text"
                />
                <div className="error">{users?.error?.first_name}</div>
              </div>
            </div> */}
                {/* <div className="catalog_item">
              <div>
                Адрес: <u className="redStar">*</u>
              </div>
              <div style={{
                width: "57.1%",
              }}>
                <input
                  placeholder="Пусто"
                  {...register("address", { required: false })}
                  type="text"
                />
                <div className="error">{users?.error?.client?.address}</div>
              </div>

            </div> */}
                <div className="catalog_item">
                  <div>Количество заказов:</div>
                  <div
                    style={{
                      width: "57.1%",
                    }}
                  >
                    <input
                      placeholder="Пусто"
                      {...register("orders_count", { required: false })}
                      type="number"
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                    <div className="error">
                      {users?.error?.client?.orders_count}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div
            className="form_button"
            style={{
              paddingTop: "20px",
              position: "absolute",
              bottom: "20px",
              left: "20px",
              right: "20px",
            }}
          >
            <button className="TruckBtn2" onClick={Cancel}>
              Отмена
            </button>
            <button
              onClick={handleSubmit(onSubmit)}
              type="submit"
              className="TruckBtn"
            >
              Сохранить
            </button>
          </div>
        </div>
      ) : null}
    </>
  )
}

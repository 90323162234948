import { useEffect, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Select from "react-select"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"

import AddCatagoryForm from "../../Forms/Goods/AddCategoryForm"
import {
  CalcData,
  clearCalculatedItems,
  clearCategories,
  deleteCategory,
  editCargo,
  editCargoStatus,
  fetchCargo,
  setError,
  setSelectedCategory,
} from "../../../redux/cargos/cargo"
import { addItemsLastSelect, fetchAllUsers } from "../../../redux/users"

import {
  editAllStocksSenderArr,
  fetchStocks,
  fetchStock,
} from "../../../redux/cargos/stock"

import axios from "axios"
import { access_token } from "../../../services"
import { fetchCities } from "../../../redux/cargos/cities"
import { useLocation, useParams } from "react-router-dom"
import { setLoading } from "../../../redux/loading"
import { API_URL } from "../../../api/api_config"
import EditCatagory from "./editCategory"
import { fetchCountries } from "../../../redux/cargos/countries"

import {
  fetchOwnNotifications,
  makeReadAndConfirmCargo,
  makeReadAsMana,
} from "../../../redux/auth/notifications"

import { getTransportation } from "../../../redux/cargos/transportations"
import { fetchProfile } from "../../../redux/auth/authSlice"

const customStyless = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: "10px",
    cursor: "pointer",
    background: "#fff",
    marginRight: "1px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? "rgba(157, 194, 81, 0.5)"
      : "transparent", // LC Green color on focus
    color: "var(--lc-black-800, #333)",
  }),
}

type Props = {
  name: string
  arriving_day: string
  phone: string
  country: object
  city: object
  stock: object
  services: any
  distance_stock: string
  coefficient_delivery: number
  consumption: string
  number_stock: string
  sender: any
  receiver: any
  receiver_name: string
  receiver_phone: string
  receiver_country: object
  receiver_city: object
  receiver_stock: object
  comment?: string
  total_weight: number
  total_coefficient: string
  status?: string
  new_status?: any
  user: any
}

export const EditCargo = ({ setModal, id, type, status }: any) => {
  const { users, countries, cities, stocks, cargo } = useAppSelector(
    (state: any) => state,
  )
  const dispatch = useAppDispatch()
  const params: any = useParams()
  const location = useLocation()
  const currentHref = location?.pathname
  const { register, handleSubmit, control, setValue } = useForm<Props>()

  const [menu1, setMenu1] = useState(true)
  const [menu2, setMenu2] = useState(true)
  const [menu3, setMenu3] = useState(true)
  const [menu4, setMenu4] = useState(true)
  const [openAddCategory, setOpenCategory] = useState(false)
  const [editCategory, setEditCategory] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [newStatus, setNewStatus] = useState<any>(false)
  const [coefficientDelivery, setCoefficientDelivery] = useState<any>(
    type?.coefficient_delivery,
  )
  const [consumption, setConsumption] = useState<any>(type?.consumption)

  useEffect(() => {
    dispatch(fetchStocks())
    dispatch(fetchCities())
    dispatch(fetchCountries({}))
    dispatch(
      fetchAllUsers({ roles: "client", is_active: true }, () => {}, true),
    )
    dispatch(fetchCargo(id))
  }, [])

  useEffect(() => {
    setValue("coefficient_delivery", type?.coefficient_delivery)
    setValue("consumption", type?.consumption)

    setValue("user", {
      value:
        type?.user?.first_name + " " + type?.user?.last_name ||
        type?.user ||
        cargo.oneData?.user?.first_name + " " + cargo.oneData?.user?.last_name,
      label:
        type?.user?.first_name + " " + type?.user?.last_name ||
        type?.user ||
        cargo.oneData?.user?.first_name + " " + cargo.oneData?.user?.last_name,
      id: type.user?.id || type?.user || cargo.oneData?.user?.id,
    })
    setValue("city", {
      value:
        type.sender?.city?.name ||
        type.sender?.city_name ||
        cargo.oneData?.sender?.city?.name,
      label:
        type.sender?.city?.name ||
        type.sender?.city_name ||
        cargo.oneData?.sender?.city?.name,
      id:
        type.sender?.city?.id ||
        type.sender?.city ||
        cargo.oneData?.sender?.city?.id,
    })
    setValue("country", {
      value:
        type.sender?.country?.name ||
        type.sender?.country_name ||
        cargo.oneData?.sender?.country?.name,
      label:
        type.sender?.country?.name ||
        type.sender?.country_name ||
        cargo.oneData?.sender?.country?.name,
      id:
        type.sender?.country?.id ||
        type.sender?.country ||
        cargo.oneData?.sender?.country?.id,
    })
    setValue("stock", {
      value:
        type.sender?.stock?.name ||
        type.sender?.stock_name ||
        cargo.oneData?.sender?.stock?.name,
      label:
        type.sender?.stock?.name ||
        type.sender?.stock_name ||
        cargo.oneData?.sender?.stock?.name,
      id:
        type.sender?.stock?.id ||
        type.sender?.stock ||
        cargo.oneData?.sender?.stock?.id,
    })
    setValue("receiver_country", {
      value:
        type.receiver?.country?.name ||
        type.receiver?.country_name ||
        cargo.oneData?.receiver?.country?.name,
      label:
        type.receiver?.country?.name ||
        type.receiver?.country_name ||
        cargo.oneData?.receiver?.country?.name,
      id:
        type.receiver?.country?.id ||
        type.receiver?.country ||
        cargo.oneData?.receiver?.country?.id,
    })
    setValue("receiver_city", {
      value:
        type.receiver?.city?.name ||
        type.receiver?.city_name ||
        cargo.oneData?.receiver?.city?.name,
      label:
        type.receiver?.city?.name ||
        type.receiver?.city_name ||
        cargo.oneData?.receiver?.city?.name,
      id:
        type.receiver?.city?.id ||
        type.receiver?.city ||
        cargo.oneData?.receiver?.city?.id,
    })
    setValue("receiver_stock", {
      value:
        type.receiver?.stock?.name ||
        type.receiver?.stock_name ||
        cargo.oneData?.receiver?.stock?.name,
      label:
        type.receiver?.stock?.name ||
        type.receiver?.stock_name ||
        cargo.oneData?.receiver?.stock?.name,
      id:
        type.receiver?.stock?.id ||
        type.receiver?.stock ||
        cargo.oneData?.receiver?.stock?.id,
    })

    setValue("new_status", type?.status === "picked" ? true : false)
    setValue("arriving_day", type?.sender?.arriving_day)
    setValue("name", type?.sender?.name)
    setValue("phone", type?.sender?.phone)
    setValue("receiver_name", type?.receiver?.name)
    setValue("receiver_phone", type?.receiver?.phone)
  }, [])

  const setFunction = (res?: any) => {
    if (!currentHref.includes("/addgoodpage")) {
      dispatch(fetchCargo(id))
      dispatch(fetchStock(params?.id))
    } else {
      dispatch(
        getTransportation(params?.id, (data: any) => {
          dispatch(
            editAllStocksSenderArr(
              data?.routes?.map((item: any) => ({
                ...item?.stock,
                routeId: item?.id,
              })),
            ),
          )
        }),
      )
    }
    // dispatch(fetchCargo(id))
    setModal(false)
  }

  const setFunc = () => {
    dispatch(
      makeReadAndConfirmCargo(status?.id, () => {
        dispatch(
          fetchOwnNotifications({ limit: 24, offset: 1, is_read: false }),
        )
      }),
    )
    dispatch(fetchProfile())
  }

  const onSubmit: SubmitHandler<Props> = (data: any) => {
    dispatch(
      editCargo(
        {
          sender: {
            arriving_day: data.arriving_day,
            name: data.name,
            phone: data.phone,
            country: data.country?.id,
            city: data.city?.id,
            stock: data.stock?.id,
          },
          receiver: {
            name: data.receiver_name,
            phone: data.receiver_phone,
            country: data.receiver_country?.id,
            city: data.receiver_city?.id,
            stock: data.receiver_stock?.id,
          },
          products: cargo?.category?.map((item: any) => ({
            ...item,
            brand: item.brand?.id,
            category: item?.category?.id,
          })),
          comment: data.comment,
          coefficient_delivery: Math?.round(+data.coefficient_delivery),
          total_weight: cargo?.calcData?.total_weightt,
          total_coefficient: Math.round(cargo?.calcData?.total_coefficient),
          total_price: Math?.round(cargo?.calcData?.total_price),
          consumption: +data.consumption,
          distance_stock: 0,
          user: data.user?.id,
          ...(cargo?.oneData?.status === "in_stock"
            ? { status: data.new_status === true ? "picked" : "in_stock" }
            : { status: data.new_status === true ? "picked" : "delivered" }),
        },
        id,
        () => {
          dispatch(setError(null))
          dispatch(clearCalculatedItems(null))
          if (status?.type === "new_cargo") {
            setFunc()
          } else {
            setFunction()
          }
        },
      ),
    )

    if (cargo?.error?.length > 0) {
      setMenu1(true)
      setMenu2(true)
      setMenu3(true)
      setMenu4(true)
    }
  }

  useEffect(() => {
    coefficientDelivery &&
      consumption &&
      dispatch(
        CalcData({
          products: cargo?.category?.map((item: any) => ({
            discount: item?.discount,
            weight: item.weight,
            coefficient: item?.coefficient,
          })),

          distance_stock: 0,
          coefficient_delivery: Number(coefficientDelivery),
          consumption: Number(consumption),
        }),
      )
  }, [coefficientDelivery, consumption, cargo?.category])

  const scrollHandler = (e: any) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/auth/users/`, {
        params: {
          limit: 24,
          offset: currentPage + 1,
          roles: "client",
          is_active: true,
        },
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        setCurrentPage(currentPage + 1)
        dispatch(addItemsLastSelect(response.data.results))
      })
      .catch((er) => {})
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const handleDeleteCategory = (id: any) => {
    dispatch(deleteCategory(id))
  }

  const handleCategory = (e: any) => {
    setEditCategory(!editCategory)
    dispatch(
      setSelectedCategory(cargo.category.find((item: any) => item.id === e)),
    )
  }

  return (
    <>
      <div className="cargosScroll">
        <div className="catalog_category_block">
          <div>Груз</div>
        </div>

        <div className={menu1 ? "" : "menunone"}>
          <div
            className="form_inputs-input"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div>
              <p
                style={{
                  color: `#808080`,
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  width: "435px",
                  lineHeight: "130%",
                }}
              >
                Категории:
              </p>
            </div>
            <div
              className="addedItemOption"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
              onClick={() => setOpenCategory(!openAddCategory)}
            >
              Добавить категорию
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM6.375 3.375C6.375 3.16789 6.20711 3 6 3C5.79289 3 5.625 3.16789 5.625 3.375V5.625H3.375C3.16789 5.625 3 5.79289 3 6C3 6.20711 3.16789 6.375 3.375 6.375H5.625V8.625C5.625 8.83211 5.79289 9 6 9C6.20711 9 6.375 8.83211 6.375 8.625V6.375H8.625C8.83211 6.375 9 6.20711 9 6C9 5.79289 8.83211 5.625 8.625 5.625H6.375V3.375Z"
                  fill="white"
                />
              </svg>
            </div>
            <div
              key={null}
              className={
                cargo?.category?.length % 2 === 0
                  ? "categoryWidth1"
                  : "categoryWidth"
              }
            >
              {cargo?.category?.map((item: any) => (
                <div
                  key={item.id}
                  className="addedItemCategory"
                  onClick={() => handleCategory(item.id)}
                >
                  {item.name}
                  <svg
                    onClick={(e) => {
                      e.stopPropagation()
                      handleDeleteCategory(item.id)
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                  >
                    <path
                      d="M6.75 11.25C3.85051 11.25 1.5 8.8995 1.5 6C1.5 3.10051 3.85051 0.75 6.75 0.75C9.6495 0.75 12 3.10051 12 6C12 8.8995 9.6495 11.25 6.75 11.25ZM6.75 12C10.0637 12 12.75 9.31371 12.75 6C12.75 2.68629 10.0637 0 6.75 0C3.43629 0 0.75 2.68629 0.75 6C0.75 9.31371 3.43629 12 6.75 12Z"
                      fill="white"
                    />
                    <path
                      d="M3.75 6C3.75 5.79289 3.91789 5.625 4.125 5.625H9.375C9.58211 5.625 9.75 5.79289 9.75 6C9.75 6.20711 9.58211 6.375 9.375 6.375H4.125C3.91789 6.375 3.75 6.20711 3.75 6Z"
                      fill="white"
                    />
                  </svg>
                </div>
              ))}
            </div>
          </div>
          {openAddCategory && <AddCatagoryForm setOpen={setOpenCategory} />}
          {editCategory && <EditCatagory setOpen={setEditCategory} />}
        </div>
        <div>
          {cargo?.oneData?.status === "in_stock" ? null : (
            <div className="catalog_item">
              <div>Статус:</div>
              <div
                style={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                  width: "57.7%",
                }}
              >
                <input
                  className="custom-checkbox"
                  placeholder="Пусто"
                  {...register("new_status", { required: false })}
                  style={{
                    width: "15px",
                    cursor: "pointer",
                    borderRadius: "0px",
                    padding: "9px",
                    borderColor: "#83C251",
                  }}
                  type="checkbox"
                  id="status_label"
                />
                <label
                  style={{
                    cursor: "pointer",
                  }}
                  htmlFor="status_label"
                >
                  Товар получен клиентом
                </label>
              </div>
            </div>
          )}

          <div className="catalog_category_block">
            <div onClick={() => setMenu2(!menu2)}>Оправитель</div>
            <svg
              onClick={() => setMenu2(!menu2)}
              className={menu2 ? "" : "reverse"}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
                fill="#1A1A1A"
              />
            </svg>
          </div>
          <div className="catalog_item">
            <div>Выбрать пользователя в качестве отправителя:</div>
            <div
              style={{
                width: "67.7%",
              }}
            >
              <Controller
                control={control}
                {...register("user", { required: false })}
                render={({ field }) => (
                  <Select
                    styles={customStyless}
                    id="favouriteAvenger"
                    {...field}
                    placeholder={
                      type?.user?.first_name + " " + type?.user?.last_name
                    }
                    onMenuScrollToBottom={(e: any) => scrollHandler(e)}
                    defaultValue={type?.user?.id}
                    options={users?.dataSelect?.results?.map((item: any) => ({
                      value: item.first_name + " " + item.last_name,
                      label: item.first_name + " " + item.last_name,
                      id: item.id,
                    }))}
                  />
                )}
              />
              <div className="error">{cargo.error?.user}</div>
            </div>
          </div>
          <div className={menu2 ? "catalog_items" : "menunone"}>
            <div className="catalog_item">
              <div>День прибытия:</div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <input
                  placeholder="Пусто"
                  {...register("arriving_day", { required: false })}
                  defaultValue={type?.sender?.arriving_day}
                  type="date"
                />

                <div className="error">{cargo.error?.sender?.arriving_day}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Имя отправителя:</div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <input
                  placeholder="Пусто"
                  defaultValue={type?.sender?.name}
                  {...register("name", { required: false })}
                  type="text"
                />

                <div className="error">{cargo.error?.sender?.name}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Номер телефона отправителя:</div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <input
                  placeholder="Пусто"
                  defaultValue={type?.sender?.phone}
                  {...register("phone", { required: false })}
                  type="text"
                />

                <div className="error">{cargo.error?.sender?.phone}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Страна отправителя:</div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <Controller
                  control={control}
                  {...register("country", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={
                        type.sender?.country?.name || type.sender?.country_name
                      }
                      options={countries?.data?.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">{cargo.error?.sender?.country}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Город отправителя:</div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <Controller
                  control={control}
                  {...register("city", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={type?.sender?.city?.name}
                      options={cities?.data?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />

                <div className="error">{cargo.error?.sender?.city}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Отправляемый склад:</div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <Controller
                  control={control}
                  {...register("stock", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={type?.sender?.stock?.name}
                      options={stocks?.data?.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />

                <div className="error">{cargo.error?.sender?.stock}</div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div>
          <div className="catalog_category_block">
            <div onClick={() => setMenu3(!menu3)}>Получатель</div>
            <svg
              onClick={() => setMenu4(!menu3)}
              className={menu3 ? "" : "reverse"}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
                fill="#1A1A1A"
              />
            </svg>
          </div>

          <div className={menu3 ? "catalog_items" : "menunone"}>
            <div className="catalog_item">
              <div>ФИО:</div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <input
                  placeholder="Пусто"
                  defaultValue={type?.receiver?.name}
                  {...register("receiver_name", { required: false })}
                  type="text"
                />

                <div className="error">{cargo.error?.receiver?.name}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Номер телефона:</div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <input
                  placeholder="Пусто"
                  defaultValue={cargo?.oneData?.receiver?.phone}
                  {...register("receiver_phone", { required: false })}
                  type="text"
                />

                <div className="error">{cargo.error?.receiver?.phone}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Страна получения:</div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <Controller
                  control={control}
                  {...register("receiver_country", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={
                        cargo?.oneData?.receiver?.country?.name
                          ? cargo?.oneData?.receiver?.country?.name
                          : "Пожалуйста, выберите страну"
                      }
                      defaultValue={cargo?.oneData?.receiver?.country?.id}
                      options={countries?.data?.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />
                <div className="error">{cargo.error?.receiver?.country}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Город получения:</div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <Controller
                  control={control}
                  {...register("receiver_city", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={
                        cargo?.oneData?.receiver?.city?.name
                          ? cargo?.oneData?.receiver?.city?.name
                          : "Пожалуйста, выберите город"
                      }
                      defaultValue={cargo?.oneData?.receiver?.city?.id}
                      options={cities?.data?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />

                <div className="error">{cargo.error?.receiver?.city}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Выбор склада получения:</div>
              <div
                style={{
                  width: "57.4%",
                }}
              >
                <Controller
                  control={control}
                  {...register("receiver_stock", { required: false })}
                  render={({ field }) => (
                    <Select
                      styles={customStyless}
                      id="favouriteAvenger"
                      {...field}
                      placeholder={
                        cargo?.oneData?.receiver?.stock?.name
                          ? cargo?.oneData?.receiver?.stock?.name
                          : "Пожалуйста, выберите склад"
                      }
                      defaultValue={cargo?.oneData?.receiver?.stock?.id}
                      options={stocks?.data?.results?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                      }))}
                    />
                  )}
                />

                <div className="error">{cargo.error?.receiver?.stock}</div>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div>
          <div className="catalog_category_block">
            <div onClick={() => setMenu4(!menu4)}>Другие услуги</div>
            <svg
              onClick={() => setMenu4(!menu4)}
              className={menu4 ? "" : "reverse"}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L14.3536 10.6464C14.5488 10.8417 14.5488 11.1583 14.3536 11.3536C14.1583 11.5488 13.8417 11.5488 13.6464 11.3536L8 5.70711L2.35355 11.3536C2.15829 11.5488 1.84171 11.5488 1.64645 11.3536C1.45118 11.1583 1.45118 10.8417 1.64645 10.6464L7.64645 4.64645Z"
                fill="#1A1A1A"
              />
            </svg>
          </div>

          <div className={menu4 ? "catalog_items" : "menunone"}>
            {/* <div className="catalog_item">
              <div>Доставка до склада:</div>
              <div
                style={{
                  width: "56.8%",
                }}
              >
                <input
                  placeholder="Введите данные"
                  type="number"
                  // {...register("distance_stock", { required: false })}
                  style={{ maxWidth: "322px" }}
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => setDistanceStock(e.target.value)}
                  defaultValue={type.services?.map(
                    (item: any) => item?.distance_stock,
                  )}
                />

                <div className="error">
                  {cargo.error?.services?.map(
                    (item: any) => item.distance_stock,
                  )}
                </div>
              </div>
            </div> */}
            <div className="catalog_item">
              <div>Дорожные расходы:</div>
              <div
                style={{
                  width: "56.8%",
                }}
              >
                <input
                  placeholder="Введите данные"
                  type="number"
                  {...register("coefficient_delivery", { required: false })}
                  onWheel={(e) => e.currentTarget.blur()}
                  style={{ maxWidth: "322px" }}
                  onChange={(e) => setCoefficientDelivery(e.target.value)}
                />

                <div className="error">{cargo.error?.total_coefficient}</div>
              </div>
            </div>
            <div className="catalog_item">
              <div>Доп росходы:</div>
              <div
                style={{
                  width: "56.8%",
                }}
              >
                <input
                  placeholder="Введите данные"
                  type="number"
                  {...register("consumption", { required: false })}
                  onWheel={(e) => e.currentTarget.blur()}
                  style={{ maxWidth: "322px" }}
                  onChange={(e) => setConsumption(e.target.value)}
                />

                <div className="error">{cargo.error?.total_price}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="greenBackground">
        <div className="catalog_item">
          <div>Общий вес:</div>
          <div
            style={{
              width: "59.7%",
            }}
          >
            <input
              placeholder="Пусто"
              // {...register("total_weight", { required: false })}
              type="text"
              style={{ maxWidth: "322px", border: "none" }}
              defaultValue={cargo?.calcData?.total_weight}
              disabled
            />
            <div className="error">{users?.error?.lifting_capacity}</div>
          </div>
        </div>
        <br />
        <div className="catalog_item">
          <div>Дорожные расходы:</div>
          <div
            style={{
              width: "59.7%",
            }}
          >
            <input
              placeholder="Пусто"
              type="text"
              style={{ maxWidth: "322px", border: "none" }}
              defaultValue={cargo?.calcData?.coefficient_delivery}
              disabled
            />
            <div className="error">
              {/* {users?.error?.lifting_capacity} */}
            </div>
          </div>
        </div>
        <br />

        <div className="catalog_item">
          <div>Общий тариф:</div>
          <div
            style={{
              width: "59.7%",
            }}
          >
            <input
              placeholder="Пусто"
              type="number"
              onWheel={(e) => e.currentTarget.blur()}
              value={Math.ceil(cargo?.calcData?.total_coefficient)}
              disabled
              style={{ maxWidth: "322px", border: "none" }}
            />
            <div className="error">
              {/* {users?.error?.lifting_capacity} */}
            </div>
          </div>
        </div>
        <br />
        <div
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: "#000",
            textAlign: "center",
          }}
        >
          Общая стоимость: ${cargo.calcData?.total_price?.toFixed(2) || 0}
        </div>
        <br />
        <div
          className="form_button"
          style={{
            background: "rgba(171, 211, 93, 0.2)",
          }}
        >
          <button
            onClick={() => {
              setModal(false)
              dispatch(clearCategories([]))
            }}
            className="TruckBtn2"
            style={{ padding: "9px 105px" }}
          >
            Отмена
          </button>

          <button
            onClick={handleSubmit(onSubmit)}
            className="TruckBtn"
            style={{ padding: "9px 105px" }}
          >
            {status?.type === "new_cargo" ? "Потвердить" : "Редактировать"}
          </button>
        </div>
      </div>
    </>
  )
}

import React, { useState } from "react"
import { BrandItem } from "./BrandItem"
import { ProductsItem } from "./ProductItem"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { fetchProducktCategoryes } from "../../../redux/cargos/produckt-categories"
import { fetchProducts } from "../../../redux/transports-products"
import { useParams } from "react-router-dom"

const AllGoodsSectionTree = ({ cargo }: any) => {
  const dispatch = useAppDispatch()
  const { stocks, producktCategories } = useAppSelector((state) => state)
  const { id, type } = useParams()

  const [state, setState] = useState<any>([])
  const [open, setOpen] = useState(false)
  const [openBrands, setOpenBrands] = useState(false)
  const [openProducts, setOpenProducts] = useState(false)

  const fetchProductBycategory = () => {
    dispatch(
      fetchProducts(
        {
          category: cargo.id,
          cargo__sender__stock: id,
          cargo_status: "in_stock",
          use_pagination: false,
        },
        (data: any) => {
          setState(data)
          setOpen(!open)
        },
        "cargo_status=delivered",
      ),
    )
  }

  const results = stocks?.oneData?.receivers_cargos?.map((item: any) =>
    item?.products?.filter((product: any) =>
      producktCategories?.data?.results
        ?.map((cat: any) => cat?.id)
        ?.includes(product?.category?.id),
    ),
  )

  console.log(
    results?.map((item: any) => item?.map((name: any) => name?.category?.name)),
  )

  return (
    <div>
      <div
        onClick={fetchProductBycategory}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          paddingTop: "8px",
        }}
      >
        <div style={{ color: "#808080" }}>{cargo.name}:</div>
        <div>
          {!open ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.73484 3.48483C5.88128 3.33839 6.11872 3.33839 6.26516 3.48483L10.7652 7.98484C10.9116 8.13128 10.9116 8.36872 10.7652 8.51516C10.6187 8.66161 10.3813 8.66161 10.2348 8.51516L6 4.28033L1.76517 8.51516C1.61872 8.66161 1.38128 8.66161 1.23483 8.51516C1.08839 8.36872 1.08839 8.13128 1.23483 7.98484L5.73484 3.48483Z"
                fill="#1A1A1A"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.23483 3.98483C1.38128 3.83839 1.61872 3.83839 1.76517 3.98483L6 8.21967L10.2348 3.98483C10.3813 3.83839 10.6187 3.83839 10.7652 3.98483C10.9116 4.13128 10.9116 4.36872 10.7652 4.51517L6.26516 9.01516C6.11872 9.16161 5.88128 9.16161 5.73484 9.01516L1.23483 4.51517C1.08839 4.36872 1.08839 4.13128 1.23483 3.98483Z"
                fill="#1A1A1A"
              />
            </svg>
          )}
        </div>
      </div>
      {/* 
      {open && <div style={{paddingTop: '4px'}}>
        <div onClick={() => setOpenBrands(!openBrands)} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', paddingLeft: '12px', paddingTop: '8px' }}>
          <div style={{color: '#808080'}}>Бренд:</div>
          <div> {
            !openBrands ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.73484 3.48483C5.88128 3.33839 6.11872 3.33839 6.26516 3.48483L10.7652 7.98484C10.9116 8.13128 10.9116 8.36872 10.7652 8.51516C10.6187 8.66161 10.3813 8.66161 10.2348 8.51516L6 4.28033L1.76517 8.51516C1.61872 8.66161 1.38128 8.66161 1.23483 8.51516C1.08839 8.36872 1.08839 8.13128 1.23483 7.98484L5.73484 3.48483Z" fill="#1A1A1A" />
            </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.23483 3.98483C1.38128 3.83839 1.61872 3.83839 1.76517 3.98483L6 8.21967L10.2348 3.98483C10.3813 3.83839 10.6187 3.83839 10.7652 3.98483C10.9116 4.13128 10.9116 4.36872 10.7652 4.51517L6.26516 9.01516C6.11872 9.16161 5.88128 9.16161 5.73484 9.01516L1.23483 4.51517C1.08839 4.36872 1.08839 4.13128 1.23483 3.98483Z" fill="#1A1A1A" />
            </svg>
          }</div>
        </div>
        {openBrands && <div>
          {cargo.brands.map((item: any) => <BrandItem brand={item} />)}
        </div>}
      </div>} */}

      {open && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "12px",
              paddingTop: "8px",
            }}
          >
            <div style={{ color: "#808080" }}>
              Бренд: {state.length === 0 && "Пусто"}
            </div>
          </div>
          {
            <div style={{ paddingLeft: "24px" }}>
              {state?.map((item: any) => (
                <ProductsItem product={item} />
              ))}
            </div>
          }
        </div>
      )}
    </div>
  )
}

export default AllGoodsSectionTree

import React from "react"

import Logo from "../../assets/svg/logo.svg"
import Logo2 from "../../assets/svg/logo2.svg"
import Back from "../../assets/img/BG.png"
import StockImage from "../../assets/img/15.png"
import Track from "../../assets/img/26.png"
import MiniTrack from "../../assets/img/405.png"
import HomeIcon from "../../assets/svg/homeIcon.svg"
import MapIcon from "../../assets/svg/mapIcon.svg"
import ClientIcon from "../../assets/svg/clientIcon.svg"
import TruckIcon from "../../assets/svg/truckIcon.svg"
import VarehouseIcon from "../../assets/svg/varehouseIcon.svg"
import StaffIcon from "../../assets/svg/staffIcon.svg"
import { Link } from "react-router-dom"

const Index = () => {
  return (
    <div className="NotFound">
      <div className="NotFound_logos" >
        <img src={Logo} alt="" />
        <img src={Logo2} alt="" />
      </div>
      <div className="NotFound__titles">
        <div className="NotFound__titles_maintitle">Упссс, груз 404</div>
        <div className="NotFound__titles_subtitle">
          К сожалению, запрашиваемая страница не найдена (ошибка 404). Возможно,
          вы попали сюда по неправильной ссылке, или страница, которую вы ищете,
          была перемещена или удалена.
          <br />
          <br />
          Мы приносим извинения за неудобства. Если вы ищете информацию о наших
          услугах по перевозкам, рекомендуем посетить нашу главную страницу, где
          вы сможете найти полезную информацию о нашей компании, услугах и
          контактных данных.
          <br />
          <br />
          Мы постоянно работаем над улучшением нашего веб-сайта, поэтому если у
          вас есть какие-либо вопросы или предложения, не стесняйтесь связаться
          с нами через указанные контакты. Благодарим вас за понимание и интерес
          к нашей компании.
        </div>
      </div>
      <div
        className="NotFound__content"
        style={{
          backgroundImage: `url(${Back})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100%",
        }}
      >
        <img className="NotFound__content_stockImg" src={StockImage} alt="" />
        <img className="NotFound__content_trackImg" src={Track} alt="" />
        <img
          className="NotFound__content_miniTrackImg"
          src={MiniTrack}
          alt=""
        />
        <div className="NotFound__content_navigation">
          <Link to={"/"}>
            <div className={"SideBar__inner_onhover"}>
              <img src={HomeIcon} alt="" />
              Аналитика
            </div>
          </Link>
          <Link to={`onways/on_way`}>
            <div className={"SideBar__inner_onhover"}>
              <img src={MapIcon} alt="" />В пути
            </div>
          </Link>
          <Link to={"clients"}>
            <div className={"SideBar__inner_onhover"}>
              <img src={ClientIcon} alt="" />
              Клиенты
            </div>
          </Link>
          <Link to={"trucks"}>
            <div className={"SideBar__inner_onhover"}>
              <img src={TruckIcon} alt="" />
              Грузовики
            </div>
          </Link>
          <Link to={"stock"}>
            <div className={"SideBar__inner_onhover"}>
              <img src={VarehouseIcon} alt="" />
              Склады
            </div>
          </Link>
          <Link to={"staff"}>
            <div className={"SideBar__inner_onhover"}>
              <img src={StaffIcon} alt="" />
              Персонал
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Index

import React from "react"

import GraphicSliderIcon from "../../assets/svg/graficSlider.svg"

interface Props {
  title: string
  title2: string
  title3: string
  title4: string
  open?: boolean
  setValue?: any
  handleSearch?:any
}

const Index = ({ title, title2, title3, title4, open, setValue, handleSearch }: Props) => {
  return (
    <div className="SearchBox">
      <input  className="SearchBox__input" type="text" placeholder="Поиск" onChange={(e) => {
        handleSearch(e.target.value)
        setValue(e.target.value)
      }} />
      <div className={open ? "SearchBox__btns" : "none"}>
        <button>{title}</button>
        <button>{title2}</button>
        <button>{title3}</button>
        <button>{title4}</button>
      </div>
    </div>
  )
}

export default Index

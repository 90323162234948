import React, { useState } from "react"
import Select, { Props as SelectProps } from "react-select"

type OptionType = {
  value: any
  label: string
}

type SelectInputProps = {
  options: OptionType[]
  setState: Function
  placeholder: string
  state: any
  error: any
  defaultValue?: any
}
export const SelectInput: React.FC<SelectInputProps> = ({
  options,
  placeholder,
  setState,
  state,
  error,
  defaultValue,
}) => {
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      width: "300px",
      borderRadius: "10px",

      border: state.isFocused
        ? "1px solid rgba(157, 194, 81, 1)" // LC Green color on focus
        : error
        ? "1px solid red" // Red color on error
        : "1px solid var(--lc-black-200, #CCC)",

      background: "var(--lc-white, #FFF)",
      padding: "0px 16px",
      boxShadow: state.isFocused ? "0 0 0 2px rgba(157, 194, 81, 0.3)" : "none", // LC Green color on focus
      "&:hover": {
        border: "1px solid rgba(157, 194, 81, 1)", // LC Green color on hover
      },

      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(157, 194, 81, 0.5)"
        : "transparent", // LC Green color on focus
      color: "var(--lc-black-800, #333)", // LC Black-800 color
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "rgba(157, 194, 81, 0.3)", // LC Green color for selected values
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: "var(--lc-black-800, #333)", // LC Black-800 color for selected value labels
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: "var(--lc-black-800, #333)", // LC Black-800 color for selected value remove icon
      ":hover": {
        backgroundColor: "rgba(157, 194, 81, 0.5)", // LC Green color on remove icon hover
        color: "#FFF", // White color on remove icon hover
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "var(--lc-black-800, #333)", // LC Black-800 color
      fontFamily: "Inter", // Inter font family
      fontSize: "14px", // 14px font size
      fontStyle: "normal", // normal font style
      fontWeight: 500, // font weight 500
      lineHeight: "130%", // 130% line height
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      paddingRight: "0",
      paddingLeft: "8px",
    }),
  }
  const handleSelectChange = (selectedOption: any) => {
    const find = state?.find((item: any) => item?.id === selectedOption?.id)

    if (!find) {
      setState([...state, selectedOption])
    }
  }

  const removeChoosedCategory = (id: number) => {
    setState(state?.filter((item: any) => item?.id !== id))
  }

  return (
    <div className="inppp">
      <div>
        <Select
          placeholder={placeholder}
          isSearchable={false}
          styles={customStyles}
          options={options}
          onChange={(e) => handleSelectChange(e)}
        />
      </div>
      <div>
        <div className="addedItemOptionContayner">
          {state?.map((item: any) => (
            <div key={item?.id}>
              <i className="addedItemOption">
                {item?.value}
                <svg
                  style={{ cursor: "pointer" }}
                  onClick={() => removeChoosedCategory(item?.id)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M10.0625 1.625H1.9375C1.41992 1.625 1 2.04492 1 2.5625V9.4375C1 9.95508 1.41992 10.375 1.9375 10.375H10.0625C10.5801 10.375 11 9.95508 11 9.4375V2.5625C11 2.04492 10.5801 1.625 10.0625 1.625ZM10.0625 9.32031C10.0625 9.38477 10.0098 9.4375 9.94531 9.4375H2.05469C1.99023 9.4375 1.9375 9.38477 1.9375 9.32031V2.67969C1.9375 2.61523 1.99023 2.5625 2.05469 2.5625H9.94531C10.0098 2.5625 10.0625 2.61523 10.0625 2.67969V9.32031ZM7.96289 4.80078L6.76367 6L7.96289 7.19922C8.05273 7.28906 8.05273 7.43555 7.96289 7.52734L7.52734 7.96289C7.4375 8.05273 7.29102 8.05273 7.19922 7.96289L6 6.76367L4.80078 7.96289C4.71094 8.05273 4.56445 8.05273 4.47266 7.96289L4.03711 7.52734C3.94727 7.4375 3.94727 7.29102 4.03711 7.19922L5.23633 6L4.03711 4.80078C3.94727 4.71094 3.94727 4.56445 4.03711 4.47266L4.47266 4.03711C4.5625 3.94727 4.70898 3.94727 4.80078 4.03711L6 5.23633L7.19922 4.03711C7.28906 3.94727 7.43555 3.94727 7.52734 4.03711L7.96289 4.47266C8.05469 4.5625 8.05469 4.70898 7.96289 4.80078Z"
                    fill="white"
                  />
                </svg>
              </i>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SelectInput

import React from "react"

type Props = {
  item: any
}

const CargoCategory = ({ item }: Props) => {
  return (
    <div className="CargoCategory">
      <div className="CargoCategory_title">
        <div>{item?.category?.name}</div>
        <div className="CargoCategory_title-stick"></div>
        <div>{item?.brand?.name}</div>
      </div>
      <div className="CargoCategory_subtitle">
        <div>
          <div>{item?.package_type}</div>
          <div>
            <u>Кол:</u> {item?.quantity}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <div className="CargoCategory_subtitle-stick"></div>
          <div>
            <div>
              <u>Мест:</u> {item?.space}
            </div>
            <div>
              <u>Вес:</u> {item?.weight}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CargoCategory

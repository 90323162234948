import { createSlice } from "@reduxjs/toolkit"

const initialState: any = {
  loading: false,
}

const countriesSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
  },
})

export const { setLoading } = countriesSlice.actions
export default countriesSlice.reducer


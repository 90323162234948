import React, { useEffect, useRef, useState } from "react"
import Terms from "../../../components/mapsCard/terms"
import PlusIcon from "../../../assets/svg/categoryPlusIcon.svg"
import BetweenRouteAdd from "./Add"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import {
  addItemsLast,
  fetchTransportationsAttributes,
} from "../../../redux/cargos/transportations-atributes"
import { setLoading } from "../../../redux/loading"
import axios from "axios"
import { API_URL } from "../../../api/api_config"
import { access_token } from "../../../services"

const ShowSecondaryRoutes = ({ item, setEditRoute }: any) => {
  const { transportatAttrib } = useAppSelector((state: any) => state)
  const dispatch = useAppDispatch()
  const [addPoint, setAddPoint] = useState(false)

  const [currentPage, setCurrentpage] = useState(1)
  const [totalCount, setTotalCount] = useState<any>()

  const AfterGet = (data: any) => {
    setTotalCount(data.count)
  }

  useEffect(() => {
    dispatch(
      fetchTransportationsAttributes(
        {
          limit: 24,
          offset: currentPage,
          cargo_general_transport: item?.id,
          //   checked: false,
        },
        AfterGet,
      ),
    )
  }, [])

  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    if (
      target.scrollHeight - (target.scrollTop + target.clientHeight) < 1 &&
      transportatAttrib?.data?.results?.length < totalCount
    ) {
      dispatch(setLoading(true))
      axios
        .get(`${API_URL}api/v1/cargo/transportations/attributes`, {
          params: {
            limit: 24,
            offset: currentPage + 1,
            cargo_general_transport: item?.id,
            // checked: false,
          },
          headers: {
            Authorization: `Token ${access_token} `,
          },
        })
        .then((response: any) => {
          dispatch(addItemsLast(response.data.results))
        })
        .catch((er) => {
          dispatch(setLoading(false))
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }

  return (
    <div style={{ height: "100%" }}>
      <div className="catalog_item">
        <div>Добавить маршрут</div>
        <button onClick={() => setAddPoint(!addPoint)} className="addZoneBtn">
          <img src={PlusIcon} alt="" /> Добавить
        </button>
      </div>
      {addPoint && <BetweenRouteAdd id={item?.id} setOpen={setAddPoint} />}
      <div
        onScroll={scrollHandler}
        ref={scrollableDivRef}
        className={"wayTerms"}
        style={{
          // display: "flex",
          flexDirection: "column-reverse",
          justifyContent: "flex-end",
        }}
      >
        {transportatAttrib?.data?.results?.map((item: any) => (
          <Terms key={item?.id} item={item} />
        ))}
      </div>
    </div>
  )
}

export default ShowSecondaryRoutes

import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface ClassicTextEditorProps {
  value?: string;
  onChange?: (content: string) => void;
  error?: any;
  style?: any;
}

const ClassicTextEditor: React.FC<ClassicTextEditorProps> = ({
  value,
  onChange,
  error,
  style,
}) => {
  const [editorHtml, setEditorHtml] = useState<string>(value || "");
  const quillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    if (value) {
      setEditorHtml(value);
    } else {
      setEditorHtml("");
    }
  }, [value]);

  

  useEffect(() => {
    const quill: any = quillRef.current?.getEditor();
    if (quill) {
      quill.getModule("toolbar").addHandler("link", () => {
        const url = window.prompt("Enter the URL");
        if (url) {
          const cursorPosition: any = quill.getSelection()?.index;
          if (cursorPosition !== undefined && cursorPosition >= 0) {
            quill.insertText(cursorPosition, " ", "user");
            quill.insertText(cursorPosition + 1, url, "link", url);
            quill.setSelection(cursorPosition + 1 + url.length);
          }
        }
      });
  
      quill.root.addEventListener("click", (event: any) => {
        const target: any = event.target as HTMLElement;
        if (target.tagName === "A") {
          window.open(target.getAttribute("href"), "_blank");
          event.preventDefault();
        }
      });
  
      // Apply cursor pointer style to link elements
      const style = document.createElement("style");
      style.textContent = ".ql-editor a { cursor: pointer; }";
      quill.container.appendChild(style);
    }
  }, [quillRef]);
  
  
  
  
  

  const handleChange = (html: string) => {
    setEditorHtml(html);
    onChange && onChange(html);
  };

  return (
    <div className="ClassicTextEditor" style={{ height: "100%", ...style }}>
      <ReactQuill
        ref={quillRef}
        value={editorHtml}
        onChange={handleChange}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ size: [] }],
            [{ font: [] }],
            [{ align: ["right", "center", "justify"] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            [
              {
                color: [
                  "red",
                  "#785412",
                  "blue",
                  "black",
                  "blueviolet",
                  "cadetblue",
                ],
              },
            ],
            [
              {
                background: [
                  "red",
                  "#785412",
                  "blue",
                  "black",
                  "blueviolet",
                  "cadetblue",
                ],
              },
            ],
          ],
        }}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "link",
          "color",
          "image",
          "background",
          "align",
          "size",
          "font",
        ]}
        theme="snow"
        placeholder="Пусто"
        style={{ height: "88%" }}
      />
      <p style={{ color: "red", textAlign: "center" }}>
        {error && error.content && error.content[0]}
      </p>
    </div>
  );
};

export default ClassicTextEditor;

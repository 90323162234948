// dataSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useAppDispatch } from "../hooks"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"

interface DataItem {
  id: number
  truck_zone: string
  sended_quantity: string
  product: number
  driver: number
  truck: number
}

interface DataState {
  curentTruk: number
  sendSrockId: number
  data: DataItem[]
  zone: string
  driver: number
  truck: number
}

const initialState: DataState = {
  curentTruk: 0,
  sendSrockId: 0,
  data: [],
  zone: "",
  driver: 0,
  truck: 0,
}

const sendProductSlice = createSlice({
  name: "sendProduct",
  initialState,
  reducers: {
    addObject: (state, action: PayloadAction<DataItem>) => {
      state.data.push(action.payload)
    },
    clearData: (state: any) => {
      state.data = []
    },
    setZone: (state, action: PayloadAction<string>) => {
      state.zone = action.payload
    },
    setDriver: (state, action: PayloadAction<number>) => {
      state.driver = action.payload
    },
    setTruck: (state, action: PayloadAction<number>) => {
      state.truck = action.payload
    },
    setSendStockId: (state, action: PayloadAction<number>) => {
      state.sendSrockId = action.payload
    },
    setCurentTruck: (state, action: PayloadAction<number>) => {
      state.curentTruk = action.payload
    },
  },
})

export const handleAddObject = (
  id: number,
  truck_zone: string,
  sended_quantity: string,
  product: number,
  driver: number,
  truck: number,
) => {
  const dispatch = useAppDispatch()

  const newObject = {
    id: id,
    truck_zone: truck_zone,
    sended_quantity: sended_quantity,
    product: product,
    driver: driver,
    truck: truck,
  }
  console.log(newObject)

  dispatch(addObject(newObject))
}
export const {
  addObject,
  clearData,
  setZone,
  setDriver,
  setTruck,
  setSendStockId,
  setCurentTruck
} = sendProductSlice.actions
export default sendProductSlice.reducer

export const sendProduct = (data = []) => {
  return async (dispatch: Dispatch) => {
    axios
      .post(`https://back.lidercargo.net//loads/send/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        console.log(response)

        dispatch(clearData())
        // dispatch(addItem(response.data))
      })
      .catch((er) => {
        dispatch(clearData())
        // dispatch(setError(er.response?.data))
      })
  }
}

import React, { useEffect, useState } from "react"
import { Zone } from "./Zone"
import { Good_stock_zone_2place } from "./Good_stock_zone_2place"
import EditWayModal from "../../components/EditWayModal/EditWayModal"
import { useAppDispatch } from "../../redux/hooks"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { setLoading } from "../../redux/loading"
import { useNavigate } from "react-router-dom"
import { Good_stock_zone } from "./Good_stock_zone"

export const StockTruckItems = ({ item }: any) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [opener, setOpener] = useState(false)
  const [editWay, setEditWay] = useState(false)
  const [stockTruck, setStockTruck] = useState<any>()

  const getItem = () => {
    !opener && dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/cargo/transportations/${item?.transportation}`, {
        headers: {
          Authorization: `Token ${access_token} `,
        },
      })
      .then((response: any) => {
        setStockTruck(response?.data)

        dispatch(setLoading(false))
      })
  }

  const editTransportation = () => {
    navigate(`/stock/addgoodpage/${stockTruck?.id}/`)
    setEditWay(!editWay)
  }

  const sortedArray = stockTruck?.transports[0]?.transport_assembly?.slice()?.sort((a: any, b: any) => {
    if (a?.item?.type === 'head' || a?.item?.type === 'truck') return -1;
    if (b?.item?.type === 'head' || a?.item?.type === 'truck') return 1;
    return 0;
  });
  console.log(sortedArray);

  return (
    <div className="good_truck_item">
      <div
        onClick={() => {
          getItem()
          setOpener(!opener)
        }}
        className="good_truck_item_title"
      >
        Машина ID: {item?.id}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.05806 6.30806C2.30214 6.06398 2.69786 6.06398 2.94194 6.30806L10 13.3661L17.0581 6.30806C17.3021 6.06398 17.6979 6.06398 17.9419 6.30806C18.186 6.55214 18.186 6.94786 17.9419 7.19194L10.4419 14.6919C10.1979 14.936 9.80214 14.936 9.55806 14.6919L2.05806 7.19194C1.81398 6.94786 1.81398 6.55214 2.05806 6.30806Z"
            fill="#1A1A1A"
          />
        </svg>
      </div>

      {opener && (
        <div className="good_truck_item_content">
          <button onClick={editTransportation}>Редактировать</button>
          {
            sortedArray?.map((item: any) => 
              <div className="good_select_zone">
                <div className="good_select_zone_items_inner">
                  <Good_stock_zone type={item?.item?.type} elem={
                    <div style={{ position: 'absolute', display: 'flex', justifyContent: 'space-between', right: item?.item?.type === 'truck' ? '62px' : item?.item?.type === 'semitrailer' ? '14px' : item?.item?.type === 'trailer' ? '0' : '0', top: '-20px' }}>
                      {
                        item?.item?.type !== 'head' && item?.item?.zones?.map((item: any) => (
                          <Zone number={item?.order} item={item} />
                        ))
                      }
                    </div>
                  } />
                </div>
              </div>
            )
          }
          <div className="good_truck_item_content_list">
            {stockTruck?.routes?.map((route: any) => (
              <div className="good_truck_item_titles_item">
                <div className="good_truck_item_titles_item_titles">
                  <div className="good_truck_item_titles_item_titles_title">
                    Скад отбытия №{route?.stock?.id}
                  </div>
                  <div className="good_truck_item_titles_item_titles_text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M5.81395 0.0494088C5.92923 -0.0164696 6.07077 -0.0164696 6.18605 0.0494088L8.99855 1.65655C9.11539 1.72332 9.1875 1.84757 9.1875 1.98214V4.97881L11.8111 6.47798C11.9279 6.54475 12 6.669 12 6.80357V10.0179C12 10.1524 11.9279 10.2767 11.8111 10.3434L8.99855 11.9506C8.88326 12.0165 8.74174 12.0165 8.62645 11.9506L6 10.4498L3.37355 11.9506C3.25827 12.0165 3.11673 12.0165 3.00145 11.9506L0.188948 10.3434C0.0721072 10.2767 0 10.1524 0 10.0179V6.80357C0 6.669 0.0721073 6.54475 0.188948 6.47798L2.8125 4.97881V1.98214C2.8125 1.84757 2.88461 1.72332 3.00145 1.65655L5.81395 0.0494088ZM3.1875 5.62833L1.13084 6.80357L3.1875 7.97881L5.24416 6.80357L3.1875 5.62833ZM5.625 7.44976L3.5625 8.62833V10.9788L5.625 9.80024V7.44976ZM6.375 9.80024L8.4375 10.9788V8.62833L6.375 7.44976V9.80024ZM6.75584 6.80357L8.8125 7.97881L10.8692 6.80357L8.8125 5.62833L6.75584 6.80357ZM8.4375 4.97881V2.62834L6.375 3.80691V6.15738L8.4375 4.97881ZM5.625 6.15738V3.80691L3.5625 2.62834V4.97881L5.625 6.15738ZM3.94334 1.98214L6 3.15738L8.05666 1.98214L6 0.806907L3.94334 1.98214ZM11.25 7.44976L9.1875 8.62833V10.9788L11.25 9.80024V7.44976ZM2.8125 10.9788V8.62833L0.75 7.44976V9.80024L2.8125 10.9788Z"
                        fill="#83C251"
                      />
                    </svg>
                    <div>
                      <div>{route?.stock?.address}</div>
                      <div>
                        {route?.stock?.city?.name +
                          ", " +
                          route?.stock?.city?.country_name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="good_truck_item_titles_item_linear"></div>
              </div>
            ))}
          </div>
        </div>
      )}

      {editWay && (
        <div className="modal-background">
          <EditWayModal
            item={item}
            replace={true}
            setEditWay={setEditWay}
            country={undefined}
          />
        </div>
      )}
    </div>
  )
}

import { createSlice } from "@reduxjs/toolkit"
import { BalanceState } from "../types"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { setLoading } from "../loading"

const initialState: any = {
  data: [],
  error: false,
}

const balanceHistorySlice = createSlice({
  name: "balanceHistory",
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.data = payload
      state.error = false
    },
    addItem(state, { payload }) {
      state.data.push({ payload })
    },
    editBalance: (state, { payload }) => {
      

      state.data = state.data.results.map((item: any) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
    },
    deleteItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const { setItems, setError, addItem, editBalance, deleteItem } =
  balanceHistorySlice.actions
export default balanceHistorySlice.reducer

//fetchBalanceHistories
export const fetchBalanceHistories = (id: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/auth/balance-histories/`, {
        params: {
          user: id
        },
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setLoading(false))
        dispatch(setError(er.response))
      })
  }
}

//addBalanceHistories
export const addBalanceHistories = (data = {}) => {
  return async (dispatch: Dispatch) => {
    axios
      .post(`${API_URL}balance-histories/create/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(addItem(response.data))
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
  }
}

//editBalanceHistories
export const editBalanceHistories = (data = {}, id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .put(`${API_URL}balance-histories/${id}`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(editBalance(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
  }
}

//deleteBalanceHistories
export const deleteBalanceHistories = (id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .delete(`${API_URL}balance-histories/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(deleteItem(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
  }
}

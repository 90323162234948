import { createSlice } from "@reduxjs/toolkit"
import { StockState } from "../types"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"
import { toast } from "react-toastify"

const initialState: StockState = {
  data: [],
  error: false,
}

const StockSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.error = false
      state.data = payload
    },
    addItem(state, { payload }) {
      state.data.push({ payload })
    },
    editstocks: (state, { payload }) => {
      state.data = payload
    },
    deleteItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const { setItems, setError, addItem, editstocks, deleteItem } =
  StockSlice.actions
export default StockSlice.reducer

//fetchstocks
export const fetchstocks = (type: string, name: string) => {
  return async (dispatch: Dispatch) => {
    axios
      .get(`${API_URL}stocks/search?name=${name}&type=${type}`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
      })
      .catch((er) => {
         
      })
  }
}
export const fetchAllstocks = () => {
  return async (dispatch: Dispatch) => {
    axios
      .get(`${API_URL}stocks/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
      })
      .catch((er) => {
         
      })
  }
}
export const addstocksWorkTime = (data = []) => {
  return async (dispatch: Dispatch) => {
    axios
      .post(`${API_URL}stocks/calendar/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {})
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
  }
}
export const EditStocksWorkTime = (data = [], id: number) => {
  return async (dispatch: Dispatch) => {
    axios
      .put(`${API_URL}stocks/calendar/${id}`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        toast.success(" режим работы склада успешно отредоктирован", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      })
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
  }
}
//addstocks
export const addstocks = (
  data = {},
  setLoading: Function,
  setState?: any,
  setCurentStock?: any,
  schedule?: any,
) => {
  return async (dispatch: Dispatch) => {
    setLoading(true)
    axios
      .post(`${API_URL}stocks/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(addItem(response.data))
        setState(false)
        setCurentStock(response.data.id)
        schedule.map((item: any) => (item.stock = response.data.id))
        console.log()

        toast.success("✅ Склад успешно добавлен", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      })
      .then(() => dispatch(addstocksWorkTime(schedule) as any))
      .catch((er) => {
        dispatch(setError(er.response.data))
      })
      .finally(() => setLoading(false))
  }
}

//edidstockggfdsfgdg
export const editstock = (
  data = {},
  setLoading: Function,
  id: any,
  setState?: any,
) => {
  return async (dispatch: Dispatch) => {
    setLoading(true)
    axios
      .put(`${API_URL}stocks/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        setState(false)
        dispatch(editstocks(res.data))
        console.log(data)
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
  }
}

//deletestock
export const deletestock = (id: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .delete(`${API_URL}stock/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${access_token}`,
        },
      })
      .then((res: any) => {
        dispatch(deleteItem(res.data))
      })
      .catch((er) => {
        dispatch(setError(er.res.data))
      })
  }
}

import React, { useState } from 'react'
import { Modal } from '../../components/Modal'
import { EditCargo } from '../../components/Forms/GoodsEdit/EditGoodsForm'

export const DownloadModalItem = ({ i }: any) => {
    const [modal, setModal] = useState(false)
    const [onModal, setOnModal] = useState(false)

    
    return (
        <div className="good_download_products_item">
            <div className="good_download_products_item_inner">
                <div className="good_download_products_item_categories_title good_download_products_item_title">{i?.product?.cargo?.user?.first_name} {i?.product?.cargo?.user?.last_name}: <span>{i?.product?.cargo?.user?.id}</span></div>
                <div className="good_download_products_item_categories">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div className="">{i?.product?.cargo?.user?.first_name} {i?.product?.cargo?.user?.last_name}</div>
                        <div className="good_download_products_item_categories_title">Зона: <span>{i?.zone}</span></div>
                    </div>
                    <div className="good_download_products_item_categories_title">{i?.product?.category?.name}: <span>{i?.weight}кг</span></div>
                    <div className="good_download_products_item_categories_title">Место: <span>{i?.from_route?.stock?.city?.name} - {i?.to_route?.stock?.city?.name}</span></div>
                </div>
                <div className="good_download_products_item_button">
                    <button onClick={() => setModal(!modal)} style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', padding: '9px 0px', borderRadius: '9px' }}>Смотреть <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M9.58333 4.87565V1.33398L4.375 1.33398C4.02865 1.33398 3.75 1.61263 3.75 1.95898L3.75 14.0423C3.75 14.3887 4.02865 14.6673 4.375 14.6673L13.125 14.6673C13.4714 14.6673 13.75 14.3887 13.75 14.0423L13.75 5.50065H10.2083C9.86458 5.50065 9.58333 5.2194 9.58333 4.87565ZM11.2807 10.5009L9.58333 10.5009L9.58333 12.5842C9.58333 12.8145 9.39687 13.0009 9.16667 13.0009H8.33333C8.10313 13.0009 7.91667 12.8145 7.91667 12.5842L7.91667 10.5009H6.21927C5.8474 10.5009 5.66172 10.0507 5.92578 9.78841L8.43672 7.29622C8.6099 7.12409 8.88958 7.12409 9.06276 7.29622L11.5737 9.78841C11.838 10.0507 11.6526 10.5009 11.2807 10.5009ZM13.5677 4.06836L11.0182 1.51628C10.901 1.39909 10.7422 1.33398 10.5755 1.33398H10.4167V4.66732L13.75 4.66732V4.50846C13.75 4.3444 13.6849 4.18555 13.5677 4.06836Z" fill="white" />
                    </svg></button>
                </div>
            </div>

            <Modal width={'524px'} modal={modal} title={'Груз'} height={'fit-content'} setModal={setModal} element={
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '20px',
                    width: '100%',
                    paddingTop: '10px'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        width: '50%'
                    }}>
                        <button style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', padding: '9px 0px', borderRadius: '9px' }}>{i?.product?.category?.name}</button>
                        <div>
                            <div className='good_download_products_item_categories_title'>Номер доставки:</div>
                            <div>{i?.product?.stock?.id}</div>
                        </div>
                        <div>
                            <div className='good_download_products_item_categories_title'>Бренд:</div>
                            <div>{i?.product?.brand?.name}</div>
                        </div>
                        <div>
                            <div className='good_download_products_item_categories_title'>Мешок или пакет:</div>
                            <div>{i?.product?.package_type}</div>
                        </div>
                        <div>
                            <div className='good_download_products_item_categories_title'>Мест:</div>
                            <div>{i?.product?.space}</div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        width: '50%'
                    }}>
                        <button onClick={() => setOnModal(!onModal)} style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', padding: '9px 0px', borderRadius: '9px', gap: '4px' }}>Редактировать <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M10.6457 10.0589L11.3865 9.31819C11.5022 9.20245 11.7036 9.28347 11.7036 9.45014V12.816C11.7036 13.4294 11.2059 13.9271 10.5925 13.9271L2.44414 13.9271C1.8307 13.9271 1.33301 13.4294 1.33301 12.816L1.33301 4.66763C1.33301 4.05419 1.8307 3.55649 2.44414 3.55649L8.7753 3.55649C8.93965 3.55649 9.02299 3.75557 8.90725 3.87363L8.16649 4.61439C8.13177 4.64911 8.08547 4.66763 8.03454 4.66763L2.44414 4.66763L2.44414 12.816L10.5925 12.816V10.1886C10.5925 10.14 10.611 10.0937 10.6457 10.0589ZM14.2708 5.38755L8.19195 11.4664L6.09931 11.6979C5.49282 11.765 4.97661 11.2534 5.04374 10.6423L5.27522 8.54966L11.3541 2.47082C11.8842 1.94072 12.7407 1.94072 13.2685 2.47082L14.2685 3.47084C14.7986 4.00095 14.7986 4.85976 14.2708 5.38755ZM11.9837 6.10284L10.6388 4.75791L6.33775 9.06124L6.16876 10.5728L7.68037 10.4039L11.9837 6.10284ZM13.4837 4.2579L12.4837 3.25787C12.3888 3.16296 12.2337 3.16296 12.1411 3.25787L11.4258 3.97317L12.7708 5.3181L13.486 4.60281C13.5786 4.50559 13.5786 4.35281 13.4837 4.2579Z" fill="white" />
                        </svg></button>
                        <div>
                            <div className='good_download_products_item_categories_title'>Описания товара:</div>
                            <div>{i?.product?.cargo?.comment || 'Пусто'}</div>
                        </div>
                        <div>
                            <div className='good_download_products_item_categories_title'>Получатель на складе:</div>
                            <div>{i?.product?.cargo?.receiver?.name}</div>
                        </div>
                        <div>
                            <div className='good_download_products_item_categories_title'>Количество:</div>
                            <div>{i?.product?.taken_quantity}</div>
                        </div>
                        <div>
                            <div className='good_download_products_item_categories_title'>Вес:</div>
                            <div>{i?.product?.weight}кг</div>
                        </div>
                    </div>
                </div>
            } />

            <Modal
                title={"Редактировать груз"}
                modal={onModal}
                width={"630px"}
                setModal={setOnModal}
                element={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            height: "98%",
                        }}
                    >
                        <div className="catalog">
                            <EditCargo
                                setModal={setOnModal}
                                id={i?.product?.cargo?.id}
                                type={i?.product?.cargo}
                            />
                        </div>
                    </div>
                }
            />
        </div>
    )
}


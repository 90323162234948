import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import axios from "axios"

import { TruckState } from "../../types"
import { API_URL } from "../../../api/api_config"
import { access_token } from "../../../services"
import { setLoading } from "../../loading"
import { toast } from "react-toastify"

const initialState: any = {
  data: [],
  error: false,
  oneData: {},
  zones: [],
  selectedZones: false,

  heads: false,
  trucks: false,
  semitrailers: false,
  trailers: false,
}

const TruckSlice = createSlice({
  name: "trucks",
  initialState,
  reducers: {
    setItemsHead: (state, { payload }) => {
      state.heads = payload
    },
    setItemsTrucks: (state, { payload }) => {
      state.trucks = payload
    },
    setItemsSemiTrailers: (state, { payload }) => {
      state.semitrailers = payload
    },
    setItemsTrailers: (state, { payload }) => {
      state.trailers = payload
    },
    setItems: (state, { payload }) => {
      state.data = payload
      state.error = false
    },
    setZones: (state, { payload }) => {
      state.zones = payload
    },
    setSelectedZones: (state, { payload }) => {
      state.selectedZones = payload
    },
    getItem: (state, { payload }) => {
      state.oneData = payload
    },
    addItem: (state, { payload }) => {
      state.data = [payload, ...state.data]
    },
    addTruckZone: (state, action) => {
      if (
        !state.zones.find((item: any) => action?.payload?.order === item.order)
      ) {
        state.zones.push(action.payload)
      } else {
        toast.error(`Выбранная вами зона уже существует`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }
    },
    editTruckZone: (state, { payload }) => {
      state.selectedZones = payload

      const updatetResults = state.zones.map((item: any) => {
        if (item.order === payload.order) {
          return payload
        }
        return item
      })
      state.zones = updatetResults
    },
    editItem: (state, { payload }) => {
      state.oneData = payload
    },
    editItems: (state, { payload }) => {
      const updatedResults = state.data.results.map((item: any) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
      state.data.results = updatedResults
    },
    searchItem: (state, { payload }) => {
      state.data = payload
    },
    addItemsLast: (state, { payload }) => {
      state.data.results.push(...payload)
    },
    deleteItem: (state, { payload }) => {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    deleteZone(state, { payload }) {
      state.zones = state.zones.filter((item: any) => item.id !== payload)
    },
    clearTrucZone: (state, { payload }) => {
      state.zones = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const {
  setItemsHead,
  setItemsTrucks,
  setItemsSemiTrailers,
  setItemsTrailers,
  setItems,
  addItem,
  editItem,
  editItems,
  deleteItem,
  searchItem,
  setError,
  getItem,
  addItemsLast,
  addTruckZone,
  deleteZone,
  setZones,
  setSelectedZones,
  editTruckZone,
  clearTrucZone,
} = TruckSlice.actions
export default TruckSlice.reducer

const URL = "cargo/transports"

//fetchTrucks
export const fetchTrucks = (params?: any, AfterGet?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/cargo/transports/`, {
        params: params,
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(setItems(response.data))
        AfterGet(response.data)
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const fetchTruckByType = (params?: any, AfterGet?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/cargo/transports/`, {
        params: params,
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        if (params?.type === "head") {
          dispatch(setItemsHead(response.data))
        }
        if (params?.type === "truck") {
          dispatch(setItemsTrucks(response.data))
        }
        if (params?.type === "semitrailer") {
          dispatch(setItemsSemiTrailers(response.data))
        }
        if (params?.type === "trailer") {
          dispatch(setItemsTrailers(response.data))
        }
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//fetchTrucksById
export const fetchTrucksById = (id: any, AfterGetTruck?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .get(`${API_URL}api/v1/cargo/transports/${id}/`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        dispatch(getItem(response.data))
        AfterGetTruck(response.data)
      })
      .catch((er) => {
        dispatch(setLoading(false))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

//addTrucks
export const addTruck = (data: any, avatar: any, setOnModal: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .post(`${API_URL}api/v1/cargo/transports/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        axios
          .patch(
            `${API_URL}api/v1/cargo/transports/${response?.data?.id}/`,
            { image: avatar },
            {
              headers: {
                Authorization: `Token ${access_token}`,
                "Content-type": "multipart/form-data",
              },
            },
          )
          .then((response: any) => {
            setOnModal()
            dispatch(addItem(response.data))
          })
          .catch((er) => {
            dispatch(setError(er?.data))
          })
          .finally(() => {
            dispatch(setLoading(false))
          })

        dispatch(setLoading(false))
      })
      .catch((er) => {
        dispatch(setError(er?.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => {})
  }
}

//editTrucks
export const editTruck = (data: any, id: any, image: any, After: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .patch(`${API_URL}api/v1/${URL}/${id}/`, data, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        axios
          .patch(
            `${API_URL}api/v1/cargo/transports/${response?.data?.id}/`,
            { image: image },
            {
              headers: {
                Authorization: `Token ${access_token}`,
                "Content-type": "multipart/form-data",
              },
            },
          )
          .then((res: any) => {
            After()
            dispatch(editItem(res.data))
            dispatch(editItems(res.data))
            dispatch(setError(false))
          })
          .catch((er) => {
            dispatch(setError(er?.data))
            dispatch(setLoading(false))
          })
          .finally(() => {
            dispatch(setLoading(false))
          })
      })
      .catch((er) => {
        dispatch(setError(er.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

//deleteTruck
export const removeTruck = (id: any, setFunc?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    axios
      .delete(`${API_URL}api/v1/${URL}/${id}`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        setFunc()
        dispatch(deleteItem(response?.data))
      })
      .catch((er) => {
        if (er?.response?.data?.detail) {
          toast.error(`Этот грузовик забронирован`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
        }
        dispatch(setError(er?.response?.data))
        dispatch(setLoading(false))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

//Этот грузовик забронирован

import { createSlice } from "@reduxjs/toolkit"
import { StockState } from "../types"
import { Dispatch } from "redux"
import axios from "axios"
import { API_URL } from "../../api/api_config"
import { access_token } from "../../services"

const initialState: StockState = {
  data: [],
  error: false,
}

const TruckGoodsSlice = createSlice({
  name: "truckGoods",
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.error = false
      state.data = payload
    },
    addItem(state, { payload }) {
      state.data.push({ payload })
    },
    deleteItem(state, { payload }) {
      state.data = state.data.filter((item: any) => item.id !== payload)
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const { setItems, setError, addItem, deleteItem } =
  TruckGoodsSlice.actions
export default TruckGoodsSlice.reducer

export const fetchTruckGoods = (id: any, setLoading: any) => {
  return async (dispatch: Dispatch) => {
    axios
      .get(`${API_URL}loads/sended/${id}`, {
        headers: {
          Authorization: `Token ${access_token}`,
        },
      })
      .then((response: any) => {
        setLoading(false)
        dispatch(setItems(response.data))
      })
      .catch((er) => {
        dispatch(setError(er))
         
      })
  }
}
